/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
@media print, screen and (max-width: 39.99875em) {
  .smartpath-advantages-container .smartpath-advantages-container-alignment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    width: 320px; }
  .smartpath-advantages-container .smartpath-advantages {
    padding-top: 30px;
    height: auto;
    text-align: -webkit-center;
    width: 105px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageActive {
      display: inline-block;
      margin: auto;
      height: 33px; }
      .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageActive img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageInactive {
      display: none;
      height: 33px; }
      .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageInactive img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-lineInactive {
      align-self: center;
      width: 95px;
      background: #DBDBDB;
      border: 2px solid #DBDBDB;
      margin-top: 16px;
      margin-bottom: 9px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-lineActive {
      align-self: center;
      width: 95px;
      background: #EB0A1E;
      border: 2px solid #EB0A1E;
      margin-top: 16px;
      margin-bottom: 9px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageTitleInactive {
      font: 15px "ToyotaType-Semibold", sans-serif;
      color: #000000;
      line-height: 1; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageTitleActive {
      font: 15px "ToyotaType-Black", sans-serif;
      color: #000000;
      line-height: 1; }
  .smartpath-advantages-container .smartpath-advantages:last-child {
    justify-self: flex-start;
    margin-right: auto; }
  .smartpath-advantages-container .smartpath-advantages-subSection {
    height: auto;
    width: 315px;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 12px;
    margin: 30px auto 43px;
    padding: 40px 36px 30px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-subSection-title {
      text-align: left;
      font: 25px "ToyotaType-Black", sans-serif;
      line-height: 1.1; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-subSection-description {
      text-align: left;
      padding-top: 12px;
      font: 16px "ToyotaType-Light", sans-serif;
      margin-bottom: 20px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-footer {
      display: flex;
      flex-direction: column; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats {
      padding-bottom: 20px; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-iconStats {
        display: flex;
        align-items: center;
        padding-bottom: 10px; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-icon {
        padding-left: 3px;
        height: 20px; }
        .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-icon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-text {
        width: 100%;
        text-align: left;
        font: 24px "ToyotaType-Bold", sans-serif;
        padding-left: 14px; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-blackLine {
        align-self: center;
        width: 100%;
        background: #111;
        border: 2px solid #111;
        margin-bottom: 9px; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-description {
        text-align: left;
        font: 16px "ToyotaType-Light", sans-serif; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .smartpath-advantages-container .smartpath-advantages-container-alignment {
    display: flex;
    margin: auto;
    height: auto;
    width: 644px;
    justify-content: space-between;
    padding-top: 72px; }
  .smartpath-advantages-container .smartpath-advantages {
    width: 129px;
    height: auto;
    text-align: -webkit-center; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageActive {
      display: inline-block;
      height: 41px; }
      .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageActive img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageInactive {
      display: none;
      height: 41px; }
      .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageInactive img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-lineInactive {
      align-self: center;
      width: 114px;
      background: #DBDBDB;
      border: 2px solid #DBDBDB;
      margin-top: 21px;
      margin-bottom: 11px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-lineActive {
      align-self: center;
      width: 114px;
      background: #EB0A1E;
      border: 2px solid #EB0A1E;
      margin-top: 21px;
      margin-bottom: 11px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageTitleInactive {
      font: 20px "ToyotaType-Semibold", sans-serif;
      color: #000000; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageTitleActive {
      font: 20px "ToyotaType-Black", sans-serif;
      color: #000000; }
  .smartpath-advantages-container .smartpath-advantages-subSection {
    height: auto;
    width: 644px;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 12px;
    margin-top: 59px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 72px;
    padding-top: 52px;
    padding-left: 59px;
    padding-right: 69px;
    padding-bottom: 32px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-subSection-title {
      height: auto;
      text-align: left;
      font: 36px "ToyotaType-Black", sans-serif; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-subSection-description {
      text-align: left;
      padding-top: 12px;
      font: 18px "ToyotaType-Book", sans-serif;
      margin-bottom: 30px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats {
      width: 231px;
      margin-bottom: 30px; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-iconStats {
        display: flex;
        align-items: center;
        padding-bottom: 15px; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-icon {
        padding-left: 3px;
        margin-top: 7px;
        height: 30px; }
        .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-icon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-text {
        width: auto;
        text-align: left;
        font: 40px "ToyotaType-Bold", sans-serif;
        padding-left: 14px;
        line-height: 1; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-blackLine {
        align-self: center;
        width: auto;
        background: #111;
        border: 2.5px solid #111;
        margin-bottom: 16px; }
      .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-description {
        left: 295px;
        width: auto;
        height: 72px;
        text-align: left;
        font: 18px "ToyotaType-Book", sans-serif; }
  .smartpath-advantages-container .smartpath-advantages:nth-child(2n) {
    margin-right: 14px;
    margin-left: 14px; } }

@media print, screen and (min-width: 64em) {
  .smartpath-advantages-container .smartpath-advantages-container-alignment {
    display: flex;
    align-items: baseline;
    width: 1040px;
    margin: auto;
    justify-content: space-between; }
  .smartpath-advantages-container .smartpath-advantages {
    margin-top: 100px;
    display: inline-block;
    width: 200px;
    height: 164px;
    text-align: -webkit-center; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-header {
      height: 60px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageActive {
      display: inline-block; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageInactive {
      display: none; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-lineInactive {
      align-self: center;
      width: 179px;
      background: #DBDBDB;
      border: 3px solid #DBDBDB;
      margin-top: 30px;
      margin-bottom: 18px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-lineActive {
      align-self: center;
      width: 179px;
      background: #EB0A1E;
      border: 3px solid #EB0A1E;
      margin-top: 30px;
      margin-bottom: 18px; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageTitleInactive {
      width: 155px;
      height: 53px;
      text-align: center;
      font: 20px "ToyotaType-Semibold", sans-serif;
      color: #000000; }
    .smartpath-advantages-container .smartpath-advantages .smartpath-advantages-imageTitleActive {
      width: 155px;
      height: 53px;
      text-align: center;
      font: 20px "ToyotaType-Black", sans-serif;
      color: #000000; }
  .smartpath-advantages-container .smartpath-advantages:hover {
    cursor: pointer; }
  .smartpath-advantages-container .smartpath-advantages-subSection {
    height: auto;
    width: 1040px;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 12px;
    padding-top: 52px;
    padding-left: 97px;
    padding-bottom: 60px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 115px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-subSection-title {
      height: auto;
      text-align: left;
      font: 36px "ToyotaType-Black", sans-serif; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-subSection-description {
      text-align: left;
      padding-top: 14px;
      margin-bottom: 46px;
      height: auto;
      font: 18px "ToyotaType-Light", sans-serif;
      width: 720px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-iconStats {
      display: flex;
      align-items: center;
      padding-bottom: 15px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-icon {
      padding-left: 3px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-text {
      width: 258px;
      height: 57px;
      text-align: left;
      font: 40px "ToyotaType-Bold", sans-serif;
      padding-left: 14px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-blackLine {
      align-self: center;
      width: 258px;
      background: #111;
      border: 3px solid #111;
      margin-bottom: 16px; }
    .smartpath-advantages-container .smartpath-advantages-subSection .smartpath-advantages-stats .smartpath-advantages-stats-description {
      left: 295px;
      width: 258px;
      height: 72px;
      text-align: left;
      font: 18px "ToyotaType-Light", sans-serif; } }
