.app-page-resources.mobile {
  margin: 0 20px 40px; }
  .app-page-resources.mobile .section-header-title {
    width: 1440px; }

.app-page-resources.tablet {
  margin: 0 40px 60px; }
  .app-page-resources.tablet .section-header-title {
    width: 1440px; }

.app-page-resources.desktop {
  margin: 0 40px 80px; }
  .app-page-resources.desktop .section-header-title {
    width: 1440px; }
