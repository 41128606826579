@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.result-carousel-container {
  display: flex;
  justify-content: center;
  max-width: 1440px;
  width: 100vw;
  position: relative;

  @include breakpoint(medium only) {
    max-width: 898px;
  }

  @include breakpoint(small only) {
    max-width: 360px;
    margin-bottom: 153px;
  }

  .carouselV2 {
    padding: 0 109px;
    position: static;

    @include breakpoint(small only) {
      padding: 0;
      flex-direction: column;
    }
  }

  .swiper-button-disabled {
    img {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .carouselV2-arrow {
    top: 50%;
    transform: translateY(-50%);
    width: 57px;
    height: 57px;
    cursor: pointer;

    &.prev {
      transform: translateY(-50%) rotate(180deg);
      left: 24px;
    }

    &.next {
      right: 24px;
    }

    @include breakpoint(small only) {
      top: unset;
      bottom: -85px;
      transform: unset;

      &.prev {
        right: 50%;
        left: unset;
        transform: translateX(-13px) rotate(180deg);
      }

      &.next {
        left: 50%;
        right: unset;
        transform: translateX(13px);
      }
    }
  }
}
