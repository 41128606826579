@import "../../../node_modules/foundation-sites/scss/foundation.scss";
@import "./toyota-style-standards.scss";
@import "base";


.requirements-modules {
  display: flex;
  
  @include breakpoint(small only) {
    flex-flow: column;
    margin: 0 26px 32px;
  }
  @include breakpoint(medium only) {
    flex-flow: column;
    margin: 19px 40px;
  }
  @include breakpoint(large) {
    flex-flow: row;
    margin: 10px 97px 25px;
  }
  @include breakpoint(1440px) {
    flex-flow: row;
    margin: 10px auto 25px;
    max-width: 1247px;
  }
}
.requirements-modules-left-column {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 15px);
  margin-right: 30px;

  .requirements-module:last-child {
    @include breakpoint(large) {
      border-bottom: none;
    }
  }
}
.requirements-modules-right-column {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 15px);

  .requirements-module:nth-last-child(2n) {
    border-bottom: none;
  }
}
.requirements-module {
  border-bottom: 0.5px solid $mid-dark-grey;

  @include breakpoint(small only) {
    padding: 36px 0;
  }
  @include breakpoint(medium only) {
    padding: 36px 0;
  }
  @include breakpoint(large) {
    padding: 50px 0;
  }
}
.requirements-rules-sheets {
  display: flex;

  @include breakpoint(small only) {
    flex-direction: column;
  }
}
.requirements-rules-fixed-ops {
  @include breakpoint(small only) {
    margin-top: 24px;
  }
  @include breakpoint(medium only) {
    flex: 0 0 calc(50% - 15px);
  }
  @include breakpoint(large) {
    flex: 0 0 calc(50% - 15px);
  }
}
.requirements-rules-variable-ops {
  @include breakpoint(medium only) {
    flex: 0 0 calc(50% - 15px);
    margin-right: 30px;
  }
  @include breakpoint(large) {
    flex: 0 0 calc(50% - 15px);
    margin-right: 30px;
  }
}
.requirements-rules-items {
  display: flex;
  flex-direction: column;
}
.requirements-rules-item {
  display: flex;
  margin-bottom: 20px;
}
.requirements-rules-title {
  font: $h4-bold;
  margin-bottom: 20px;
}
.requirements-rules-item-title {
  color: $dark-black;
  font: $h4-semibold;
}
.requirements-rules-item-date {
  color: $dark-black;
  font: $font15-reg;
}
.requirements-rules-item-link {
  font: $h4-semibold;
  text-decoration: underline;
  margin-top: 5px;

  &:first-child {
    margin-right: 15px;
  }
}