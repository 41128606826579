@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.beyond-zero-quick-links-wrapper {
    display: flex;
    margin-bottom: -30px;

    .quick-links-accordion-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 62px;

        @include breakpoint(small only) {
            padding: 50px 34px;
        }

        .quick-links-accordion {
            width: 100%;
            max-width: 522px;

            h1 {
                font: $font35-reg;
                font-weight: 600;
                color: $dark-black;
                margin-bottom: 82px;

                @include breakpoint($lg-tablet down) {
                    font: $h6-reg;
                    font-weight: 600;
                    margin-bottom: 60px;
                }

                @include breakpoint($xl-mobile down) {
                    font: $font25-reg;
                    font-weight: 600;
                    margin-bottom: 40px;
                }
            }

            .accordionV2.beyond-zero-quick-links-accordion {

                width: 100%;
                
                .accordion-rows {
                    .accordion-row {

                        &:first-child {
                            border-top: none;
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        &.expanded {
                            .accordion-row-header .title > div {
                                font: $font25-bold;

                                .roundedButton {
                                    display: flex;
                                }

                                @include breakpoint($lg-tablet down) {
                                    font: $h12-bold;
                                }

                                @include breakpoint($xl-mobile down) {
                                    font: $p14-bold;
                                }
                            }
                        }

                        .accordion-row-header .title > div {
                            display: flex;
                            align-items: center;
                            gap: 40px;
                            font: $font25-reg;
                            color: $dark-black;

                            > span {
                                max-width: 280px;
                            }

                            .roundedButton {
                                max-width: 153px;
                                min-width: 153px;
                                display: none;
                                background: $dark-black;
                                color: $primary-white;
                                font: $h4-bold;

                                @include breakpoint($lg-tablet down) {
                                    max-width: 127px;
                                    min-width: 127px;
                                    font: $p10-bold;
                                    max-height: 33px;
                                }
                            }

                            @include breakpoint($lg-tablet down) {
                                font: $h12-reg
                            }

                            @include breakpoint($xl-mobile down) {
                                font: $p14-reg;
                            }
                        }

                        .expand-collapse-icon {
                            min-width: 30px;
                            max-width: 30px;

                            img {
                                width: 14px;
                                margin-right: 20px;
                            }
                        }

                        .accordion-row-content {
                            
                            padding-bottom: 35px;

                            span {
                                font: $h4-reg;
                                color: $dark-black;
                                line-height: 28px;

                                @include breakpoint($lg-tablet down) {
                                    font: $font15-reg;
                                    line-height: 25px;
                                }
                            }

                            .mobile-cover {
                                display: none;
                                margin-top: 20px;

                                @include breakpoint($xl-mobile down) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                
            }

            @include breakpoint($lg-tablet down) {
                max-width: 416px
            }

            @include breakpoint($xl-mobile down) {
                max-width: 100%;
            }
        }
    }

    .quick-links-banner-container {
        width: 100%;
        height: 856px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: var(--desktop-banner);

        @include breakpoint($lg-tablet down) {
            background-image: var(--tablet-banner);
        }

        @include breakpoint($xl-mobile down) {
            display: none;
        }
    }
}