/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.basic-card img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto; }

@media print, screen and (max-width: 39.99875em) {
  .basic-card {
    text-align: center;
    min-height: 321px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px #00000034;
    border-radius: 12px;
    margin: 0px auto 30px;
    padding: 30px 30px 44px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    justify-content: space-between; }
    .basic-card .basic-card-label-container {
      display: flex;
      justify-content: flex-end;
      height: 0; }
      .basic-card .basic-card-label-container .basic-card-cardLabel {
        text-align: center;
        width: 79px;
        height: 23px;
        font: 16px "ToyotaType-Bold", sans-serif;
        color: #FFFFFF;
        border: 1px solid #007AEC;
        background: #007AEC;
        border-radius: 30px;
        line-height: 1;
        padding-top: 1px; }
    .basic-card .basic-card-cardIcon {
      margin: 20px auto 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px; }
    .basic-card .basic-card-cardIconNoTitle {
      margin: 26px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75px; }
    .basic-card .basic-card-cardTitle {
      font: 18px "ToyotaType-Semibold", sans-serif;
      margin-bottom: 20px; }
    .basic-card .basic-card-cardContent {
      text-align: left;
      font: 16px "ToyotaType-Regular", sans-serif; }
    .basic-card .basic-card-link {
      text-align: left;
      font: 16px "ToyotaType-Regular", sans-serif;
      color: #EB0A1E;
      margin-top: 20px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .basic-card {
    text-align: center;
    min-height: 321px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px #00000034;
    border-radius: 12px;
    margin-bottom: 28px;
    margin-right: 28px;
    padding: 30px 30px 36px;
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - 15px);
    justify-content: space-between; }
    .basic-card .basic-card-label-container {
      display: flex;
      justify-content: flex-end;
      height: 0; }
      .basic-card .basic-card-label-container .basic-card-cardLabel {
        text-align: center;
        width: 79px;
        height: 23px;
        font: 16px "ToyotaType-Bold", sans-serif;
        color: #FFFFFF;
        border: 1px solid #007AEC;
        background: #007AEC;
        border-radius: 30px;
        line-height: 1;
        padding-top: 1px; }
    .basic-card .basic-card-cardIcon {
      margin: 20px auto 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px; }
    .basic-card .basic-card-cardIconNoTitle {
      margin: 26px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75px; }
    .basic-card .basic-card-cardTitle {
      font: 18px "ToyotaType-Semibold", sans-serif;
      margin-bottom: 20px; }
    .basic-card .basic-card-cardContent {
      text-align: left;
      font: 16px "ToyotaType-Regular", sans-serif; }
    .basic-card .basic-card-link {
      text-align: left;
      font: 16px "ToyotaType-Regular", sans-serif;
      color: #EB0A1E;
      margin-top: 20px; }
  .basic-card:nth-child(2n) {
    margin-right: 0; } }

@media print, screen and (min-width: 64em) {
  .basic-card {
    text-align: center;
    width: 327px;
    min-height: 310px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px #00000034;
    border-radius: 12px;
    margin-bottom: 30px;
    padding: 30px 30px 44px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .basic-card .basic-card-label-container {
      height: 0; }
    .basic-card .basic-card-cardLabel {
      display: flex;
      text-align: center;
      width: 79px;
      height: 23px;
      font: 16px "ToyotaType-Bold", sans-serif;
      color: #FFFFFF;
      border: 1px solid #007AEC;
      background: #007AEC;
      margin-left: auto;
      margin-right: 0;
      border-radius: 30px;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 1px; }
    .basic-card .basic-card-cardIcon {
      margin: 24px auto 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px; }
    .basic-card .basic-card-cardIconNoTitle {
      margin: 38px auto 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75px; }
    .basic-card .basic-card-cardTitle {
      font: 18px "ToyotaType-Semibold", sans-serif;
      margin-bottom: 20px; }
    .basic-card .basic-card-cardContent {
      text-align: left;
      font: 16px "ToyotaType-Regular", sans-serif; }
    .basic-card .basic-card-link {
      text-align: left;
      font: 16px "ToyotaType-Regular", sans-serif;
      color: #EB0A1E;
      margin-top: 20px; }
  .basic-card:nth-child(3n-1) {
    margin-right: 28px;
    margin-left: 28px; } }
