/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.smartpath-overview-testimonials {
  background-color: #F7F8F8;
  width: 100%; }
  @media print, screen and (max-width: 39.99875em) {
    .smartpath-overview-testimonials {
      padding-top: 31px;
      padding-bottom: 33px; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-header {
        font: 25px "ToyotaType-Regular", sans-serif;
        text-align: center;
        margin-bottom: 30px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-header strong {
          font: 25px "ToyotaType-Bold", sans-serif; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: auto;
        width: 315px;
        margin-bottom: 38px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-video {
          width: 315px;
          margin: auto;
          margin-right: 35px;
          padding: 0px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-video .kv-info-panel-inner {
            padding: 0px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote {
          margin: 14px auto 0px;
          padding: 54px 42px 56px;
          text-align: left;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-text {
            font: 20px "ToyotaType-Regular", sans-serif;
            margin-bottom: 16px; }
            .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-text strong {
              font: 20px "ToyotaType-Bold", sans-serif; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-name {
            font: 16px "ToyotaType-Regular", sans-serif; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-title {
            font: 16px "ToyotaType-Regular", sans-serif; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 315px;
        margin-bottom: 36px;
        text-align: left;
        flex-direction: column; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote {
          border-top: 1px #707070 solid;
          margin-left: 0px;
          margin-right: 0px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-text {
            margin-top: 20px;
            font: 16px "ToyotaType-Regular", sans-serif;
            margin-bottom: 25px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-name {
            font: 16px "ToyotaType-Bold", sans-serif;
            font-style: italic; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-title {
            font: 16px "ToyotaType-Bold", sans-serif;
            font-style: italic;
            margin-bottom: 30px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote:last-child {
          border-bottom: 1px #707070 solid; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-link {
        margin: auto;
        color: #EB0A1E;
        font: 16px "ToyotaType-Semibold", sans-serif;
        text-align: center; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .smartpath-overview-testimonials {
      padding-top: 61px;
      padding-bottom: 79px; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-header {
        font: 40px "ToyotaType-Regular", sans-serif;
        text-align: center;
        margin-bottom: 47px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-header strong {
          font: 40px "ToyotaType-Bold", sans-serif; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: auto;
        width: 510px;
        margin-bottom: 30px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-video {
          width: 100%;
          margin: auto;
          padding: 0px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-video .kv-info-panel-inner {
            padding: 0px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote {
          height: 322px;
          width: 100%;
          margin: auto;
          margin-top: 30px;
          padding: 64px 70px 55px;
          text-align: left;
          background-size: auto;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-text {
            font: 24px "ToyotaType-Regular", sans-serif;
            line-height: 1.25;
            margin-bottom: 16px; }
            .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-text strong {
              font: 24px "ToyotaType-Bold", sans-serif; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-person .highlight-name {
            font: 16px "ToyotaType-Regular", sans-serif; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-person .highlight-title {
            font: 16px "ToyotaType-Regular", sans-serif; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 505px;
        margin-bottom: 35px;
        text-align: left;
        flex-direction: column; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote {
          border-top: 1px #707070 solid;
          margin-left: 0px;
          margin-right: 0px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-text {
            margin-top: 34px;
            font: 16px "ToyotaType-Regular", sans-serif;
            margin-bottom: 16px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-name {
            font: 16px "ToyotaType-Bold", sans-serif;
            font-style: italic; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-title {
            font: 16px "ToyotaType-Bold", sans-serif;
            font-style: italic;
            margin-bottom: 30px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote:last-child {
          border-bottom: 1px #707070 solid; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-link {
        margin: auto;
        color: #EB0A1E;
        font: 16px "ToyotaType-Semibold", sans-serif;
        text-align: center; } }
  @media print, screen and (min-width: 64em) {
    .smartpath-overview-testimonials {
      padding-top: 100px;
      padding-bottom: 115px; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-header {
        font: 42px "ToyotaType-Regular", sans-serif;
        text-align: center;
        margin-bottom: 52px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-header strong {
          font: 42px "ToyotaType-Bold", sans-serif; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: auto;
        width: 1040px;
        margin-bottom: 60px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-video {
          width: 510px;
          margin: auto;
          margin-right: 35px;
          padding: 0px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-video .kv-info-panel-inner {
            padding: 0px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote {
          height: 330px;
          width: 493px;
          margin: auto;
          padding: 60px 70px 40px;
          text-align: left;
          background-size: auto; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-text {
            font: 24px "ToyotaType-Regular", sans-serif;
            line-height: 1.15;
            margin-bottom: 16px; }
            .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-text strong {
              font: 24px "ToyotaType-Bold", sans-serif; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-name {
            font: 16px "ToyotaType-Regular", sans-serif; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-highlight .highlight-quote .highlight-title {
            font: 16px "ToyotaType-Regular", sans-serif; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        width: 1040px;
        margin-bottom: 50px;
        text-align: left; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote {
          width: calc(100% / 3);
          margin-left: 0px;
          margin-right: 0px; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-text {
            font: 16px "ToyotaType-Regular", sans-serif;
            margin-bottom: 16px;
            margin-top: auto; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-name {
            font: 16px "ToyotaType-Bold", sans-serif;
            font-style: italic; }
          .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote .smartpath-overview-testimonials-subquote-title {
            font: 16px "ToyotaType-Bold", sans-serif;
            font-style: italic;
            margin-bottom: auto; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote:not(:first-child) {
          border-left: 1px #707070 solid;
          padding-left: 21px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote:not(:last-child) {
          margin-right: 21px; }
        .smartpath-overview-testimonials .smartpath-overview-testimonials-quotes .smartpath-overview-testimonials-subquote:nth-child(n) {
          border-bottom: 0px;
          border-top: 0px; }
      .smartpath-overview-testimonials .smartpath-overview-testimonials-link {
        margin: auto;
        color: #EB0A1E;
        font: 16px "ToyotaType-Semibold", sans-serif;
        text-align: center; } }
