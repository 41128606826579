@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.beyond-zero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .beyond-zero-container {
        padding: 0px 62px;

        @include breakpoint(small only) {
            padding: 0px 34px;
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1000px;
    
            @include breakpoint($lg-tablet down) {
                max-width: 772px;
            }
    
            @include breakpoint($lg-mobile down) {
               max-width: 360px;
            }
    
            img {
                width: 230px;
                margin: 70px 0 30px 0px;
    
                @include breakpoint($lg-tablet down) {
                    margin-bottom: 41px;
                }
    
                @include breakpoint(small down) {
                    width: 178px;
                    margin: 50px 0 40px 0;
                }
            }
    
            h1 {
                font: $font50-bold;
                color: #000;
                margin-bottom: 20px;
                text-align: center;
    
                @include breakpoint($lg-tablet down) {
                    font: $h9-bold;
                    margin-bottom: 23px;
                }
    
                @include breakpoint(small down) {
                    font: $h6-bold;
                }
            }
    
            p {
                text-align: center;
                font: $h12-reg;
                margin-bottom: 0;
                line-height: 32px;
    
                @include breakpoint(medium down) {
                    font: $p14-reg;
                    line-height: 30px;
                }
    
                @include breakpoint(small down) {
                    font: $h4-reg;
                    line-height: 28px;
                }
            }
        }
       
    }

    .beyond-zero-banner {
        margin-top: 78px;
        width: 100%;
        height: auto;
    }
}