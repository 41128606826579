@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.options-packages-v2-container {
  .horizontal-divider {
    border-bottom: 1px solid $border-grey;
    width: 100vw;
    position: absolute;
    left: 0px;
  }
  .no-items-package-options {
    display: flex;
    color: #EB0A1E;
    text-align: center;
    justify-content: center;
    padding-top: 41px;
    padding-bottom: 71px;
  }
  
  .description-section-options-packages {
    display: flex;
    flex-direction: row;
    padding: 31px 21px 0px 21px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 33px;
    align-items: stretch;
    width: 100%;
    @include breakpoint(medium only) {
      padding: 10px 0 0 0;
    }
    @include breakpoint(small only) {
      flex-direction: column;
      gap: 15px;
      padding: 0px
    }
  }
  
  .options-packages-image-container {
    flex: 1; /* Fill available space */
    min-width: 250px;
    background-color: $dark-black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the child element */
    overflow: hidden; /* Hide overflowing image */
    height: fit-content;
    aspect-ratio: 16/9;

    .options-packages-image {
      /* Maintain aspect ratio without resizing */
      height: 100%;
      position: relative;
      z-index: 1; /* Ensure image is on top of black borders */
    
      img {
        width: 100%;
        height: 100%;
        object-fit: contain; /* Cover the container without distortion */
      }
    
      /* Add black borders */
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $dark-black;
        z-index: -1; /* Place it behind the image */
      }
    }
  }  
  
  .options-packages-info-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-wrap: wrap;
    width: 45%;
    min-width: 150px;
  
    @include breakpoint(medium) {
      margin-left: 0;
    }
  
    @include breakpoint(small only) {
      width: 100%;
    }
  }
  
  
  .availability-section-options-packages {
    padding-top: 30px;
  }
  
  .availability-section-options-packages-title {
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    font: $h4-bold;
    padding: 6px;
  }
  
  .options-packages-code-title {
    padding-top: 18px;
    font: $h4-bold;
  }
  
  .options-packages-msrp-title {
    padding-top: 20px;
    font: $h4-bold;
  }
  
  .package-options-model-grid {
    padding: 36px 21px 20px 21px;
    display: grid;
    justify-items: center;
    justify-content: space-between;

    @include breakpoint(medium only) {
      padding-left: 0px;
      padding-right: 0px;
    }
  
    @include breakpoint(small only) {
      display: flex;
      padding: 15px 21px 20px 21px;
    }

    .modelCodeNameTiles {
      column-gap: 48px;

      .modelTile {
        width: 160px;

        @include breakpoint(small only) {
          width: 100%;
        }
      }
    }
  }
  .vehicle-page-accordion.optionsPackages-accordion {
    @include breakpoint(small only) {
      margin-top: 20px;
    }

  }
  .vehicle-page-accordion.optionsPackagesOtherTrims-accordion {
    margin-top: 50px;
    .accordion-rows .accordion-row .accordion-row-header {
      padding-left: 0px !important;
      .title span {
        font: $h3-bold !important;
      }
      .expand-collapse-icon {
        .icon {
            width: 16px;
            height: 8px;
            min-width: 16px;
        }
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
    .other-trim-options {
      .other-option {
        font: $h2-reg;
        padding: 32px 28px;
        border-bottom: 1px solid #CCCCCC;
        &:first-child {
          padding-top: 8px;
        }
        &:last-child {
          border-bottom: unset;
        }
      }
    }
    &.alwaysExpanded {
      .accordion-rows .accordion-row{
        .accordion-row-header {
          cursor: default;
        }
      }
  
      .expand-collapse-icon {
        display: none;
      }
    }
  }

  .options-packages-skeleton-container {
    @include breakpoint(small only) {
      padding-top: 30px;
    }
  }
}
