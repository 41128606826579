@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.vehicle-card-container {
  background-image: linear-gradient(150deg, $primary-white , #D6DAE2);
  max-width: 337px;
  height: 280px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  padding-bottom: 20px;

  @include breakpoint(small down) {
    max-width: 287px;
    min-height: 250px;
    height: auto;
  }

  .vehicle-card-info-wrapper {
    display: flex;
    flex-direction: column;
  }


  img {
    padding: 20px 28px 15px 28px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    @include breakpoint(small down) {
      padding: 15px 24px 10px 24px;
    }
  }
  span {
    padding: 0 28px;
    @include breakpoint(small down) {
      padding: 0 24px;
    }
    &.year {
      font: $p11-reg;
    }
    &.model {
      font: $font25-bold;
    }
  }

  a {
    padding: 0 28px;
    @include breakpoint(small down) {
      padding: 0 24px;
    }
    font: $p10-bold;
    margin: 15px 0px 15px 0px;
  }
}