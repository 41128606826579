@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 48px;

  @include breakpoint(small only) {
    padding: unset;
  }

  .title-section {
    > h1 {
      font: $h9-bold;
      max-width: 851px;
      margin-bottom: 0;
      text-align: center;
      line-height: 50px;

      @include breakpoint(small only) {
        font: $font25-bold;
      }
    }

    h1:last-child {
      margin-bottom: 10px;
    }
  }

  > span {
    font: $h4-book;
    margin-bottom: 48px;

    @include breakpoint(small only) {
      font: $font15-book;
      margin-bottom: 20px;
    }
  }

  > .choices-container {
    display: flex;
    margin-bottom: 69px;
    flex-wrap: wrap;
    gap: 7px;

    @include breakpoint(medium only) {
      max-width: 747px;
    }
    @include breakpoint(small only) {
      max-width: 365px;
      margin-bottom: 30px;
    }
  }

  > .button-area {
    display: flex;
    gap: 22px;

    > .button-style {
      border-width: 1px;
      font: $p5-bold;
      height: 58px;
      width: 131px;

      &.active {
        background-color: #0F0F0F;
      }

      &.red-button {
        background-color: $primary-red;
        border-color: $primary-red;
        width: 175px;

        &:hover {
          background-color: $primary-red-dark;
          border-color: $primary-red-dark;
        }
      }

      &.disabled {
        background-color: #B4B4B4;
        border-color: #B4B4B4;

        &:hover {
          background-color: #B4B4B4;
          border-color: #B4B4B4;
        }
      }

    }
  }
}