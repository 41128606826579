@import '../../../node_modules/foundation-sites/scss/foundation.scss';
@import './toyota-style-standards.scss';
@import 'base';

@media print {
    .accordion-legend {
        &.sop{
            justify-content: left;
            margin-bottom: -11px;
            gap: 32px;

            .standard-icon{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                width: 16px;
                height: 11px;
                margin-right: 5px;
                margin-bottom: 0px;
            }
            
            .option-icon{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                width: 13px;
                height: 13px;
                margin-right: 5px;
                margin-bottom: 0px;
            }

            .package-icon{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                width: 10px;
                height: 13px;
                margin-right: 5px;
                margin-bottom: 0px;
            }

            .item-label {
                font-size: 12px;
            }
        }

        &.availability {
            margin: -18px 0 0 0;
            gap: 28px;

            .accordion-legend-not-available-indication {
                    font: 15px $toyota-reg;
                    margin-right: 5px;
                    margin-bottom: 1px;
            
                    path {
                        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                    }
            }
    
            .accordion-legend-available-indication{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                font: 13px $toyota-semibold;
                margin-right: 5px;
                margin-top: 3px;
            }
    
            .standard-icon{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                width: 16px;
                height: 11px;
                margin-right: 5px;
                margin-bottom: 0px;
            }
    
            .standard-item{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
            }

            .accordion-legend-item-unavailable{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
            }

            .accordion-legend-item-available{
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
            }

            .item-label {
                font-size: 12px;
            }
        }

        &.advantage{
            gap: 5px;

            .advantage-diamond-icon, #advantage-diamond-icon-print {
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                width: 8px;
                height: 9px;
            }
            .advantage-text {
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);

                .title {
                    font: $p7-semibold;
                }
    
                .details {
                    font: $p7-reg;
                }
            }

            .item-label {
                font-size: 12px;
            }
        }
    }
}