/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.glossary-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px; }

.glossary-title {
  display: flex;
  justify-content: center;
  text-transform: normal; }
  @media print, screen and (max-width: 39.99875em) {
    .glossary-title {
      font: 24px "ToyotaType-Semibold", sans-serif;
      margin: 18px 0 10px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .glossary-title {
      font: 32px "ToyotaType-Semibold", sans-serif;
      margin: 16px 0 14px; } }
  @media print, screen and (min-width: 64em) {
    .glossary-title {
      font: 36px "ToyotaType-Semibold", sans-serif;
      margin: -25px 0 19px; } }

.increase-padding-title {
  margin: 36px 0 10px; }

.glossary-search-message {
  text-align: center;
  font: 20px "ToyotaType-Bold", sans-serif;
  border-top: 1px solid #707070;
  margin-top: 15px;
  padding-top: 30px;
  width: 1250px; }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .glossary-search-message {
      width: 670px; } }
  @media print, screen and (max-width: 39.99875em) {
    .glossary-search-message {
      font: 16px "ToyotaType-Bold", sans-serif;
      width: 315px; } }

.glossary-cta-message {
  text-align: center;
  color: #EB0A1E;
  margin-top: 30px;
  margin-bottom: 30px;
  font: 20px "ToyotaType-Bold", sans-serif; }
  @media print, screen and (max-width: 39.99875em) {
    .glossary-cta-message {
      font: 16px "ToyotaType-Bold", sans-serif; } }

.glossary-cta-message:hover {
  cursor: pointer; }

.AlphabetNav {
  margin-top: 23px;
  display: flex;
  justify-content: space-between; }
  @media print, screen and (max-width: 39.99875em) {
    .AlphabetNav {
      width: 315px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .AlphabetNav {
      width: 654px; } }
  @media print, screen and (min-width: 64em) {
    .AlphabetNav {
      width: 715px; } }
  .AlphabetNav a:hover {
    text-decoration: none; }
  .AlphabetNav .letterLink {
    font: 16px "ToyotaType-Regular", sans-serif;
    color: #252525; }
  .AlphabetNav .active {
    color: #EB0A1E;
    font: 16px "ToyotaType-Bold", sans-serif;
    background-color: #fefefe; }
  @media print, screen and (max-width: 39.99875em) {
    .AlphabetNav img {
      height: 11px;
      margin-top: -2px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .AlphabetNav img {
      height: 12px;
      margin-top: -2px; } }

.glossarySearchBar {
  margin: 35px auto 0; }
  @media print, screen and (max-width: 39.99875em) {
    .glossarySearchBar {
      margin: 35px auto 35px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .glossarySearchBar {
      margin: 35px auto 62px; } }

.glossaryBackButton {
  text-align: left;
  color: #EB0A1E; }
  @media print, screen and (max-width: 39.99875em) {
    .glossaryBackButton {
      font: 14px "ToyotaType-Semibold", sans-serif;
      margin: 26px 28px;
      margin-bottom: -47px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .glossaryBackButton {
      font: 16px "ToyotaType-Semibold", sans-serif;
      margin: 37px 55px;
      margin-bottom: -54px; } }
  @media print, screen and (min-width: 64em) {
    .glossaryBackButton {
      font: 16px "ToyotaType-Semibold", sans-serif;
      margin: 30px 100px; } }
  @media print, screen and (max-width: 39.99875em) {
    .glossaryBackButton img {
      height: 11px;
      margin-right: 4px;
      margin-top: -1px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .glossaryBackButton img {
      height: 12px;
      margin-right: 5px;
      margin-top: -2px; } }
  @media print, screen and (min-width: 64em) {
    .glossaryBackButton img {
      height: 12px;
      margin-right: 6px;
      margin-top: -2px; } }

@media print, screen and (max-width: 39.99875em) {
  .glossaryScroll {
    margin-right: 100px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .glossaryScroll {
    margin-right: 50px; } }

@media print, screen and (min-width: 64em) {
  .glossaryScroll {
    margin-right: 50px; } }

.glossaryScroll img {
  height: 74px; }

.tooltipTest {
  position: relative; }
