@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

$mobile-view: 930px;

.color-viewer-selector-container-v2 {
    display: grid;
    gap: 0 4px;        
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 50px 1fr;
    grid-template-areas:
        "exterior-image interior-image"
        "button button"
        "exterior-selector interior-selector";

    @include breakpoint($mobile-view down) {
        gap: 30px 0;
        justify-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "exterior-image"
            "exterior-selector"
            "interior-image"
            "interior-selector";
    }

    .exterior-image {
        grid-area: exterior-image;
        padding-right: 10px;

        @include breakpoint(medium only) {
            padding-right: 6px;
        }

        @include breakpoint($mobile-view down) {
            padding-right: 0px;
        }
    }

    .interior-image {
        grid-area: interior-image;
        display: flex;
        place-content: center;
        padding-left: 10px;

        @include breakpoint(medium only) {
            padding-left: 6px;
        }

        @include breakpoint($mobile-view down) {
            padding-left: 0px;
        }

        img {
            max-width: 80%;

            @include breakpoint($mobile-view  down) {
                max-width: 100%;
            }
        }
    }

    .view-large-image-button {
        grid-area: button;
        height: 40px;
        display: flex;
        place-content: center;
        font: $h4-semibold;
        color: $primary-red;
        line-height: 40px;

        @include breakpoint($mobile-view down) {
            display: none;
        }

        div[role=button] {
            cursor: pointer;
        }
    }

    .exterior-selector {
        grid-area: exterior-selector;
        min-width: 280px;
        width: 100%;
    }

    .interior-selector {
        grid-area: interior-selector;
        min-width: 280px;
        width: 100%;
    }
}

.colors-skeleton {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .colors-skeleton-image-section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @include breakpoint($mobile-view down) {
            flex-direction: column;
            align-items: center;
        }

        .skeleton-item {
            width: 405px;
            height: 225px;
            
            @include breakpoint(medium only) {
                width: 240px;
                height: 140px;
            }

            @include breakpoint($mobile-view down) {
                width: 340px;
                height: 310px;
            }

        }
    }

    .colors-skeleton-selector-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;

        @include breakpoint($mobile-view down) {
            flex-direction: column;
            align-items: center;
        }

        .skeleton-item {
            width: 500px;
            height: 400px;

            @include breakpoint(medium only) {
                width: 320px;
                height: 500px;
            }

            @include breakpoint($mobile-view down) {
                width: 340px;
                height: 310px;
            }
        }
    }
}