/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.compare-features-modal {
  max-width: 1034px !important;
  height: 90% !important; }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .compare-features-modal {
      max-width: 644px !important; } }
  @media print, screen and (max-width: 39.99875em) {
    .compare-features-modal {
      max-width: 314px !important; } }

.compare-features-content {
  width: 746px;
  margin-right: 73px;
  margin-left: 73px; }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .compare-features-content {
      width: 508px;
      margin-right: 18px;
      margin-left: 4px; } }
  @media print, screen and (max-width: 39.99875em) {
    .compare-features-content {
      width: 224px;
      margin-right: 17px;
      margin-left: 0; } }
  .compare-features-content .modal-title {
    font: 24px "ToyotaType-Regular", sans-serif;
    text-align: center; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .modal-title {
        font: 24px "ToyotaType-Regular", sans-serif;
        text-align: center; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .modal-title {
        font: 20px "ToyotaType-Regular", sans-serif;
        text-align: center; } }
  .compare-features-content .red-line-component {
    width: 57px;
    margin: 20px auto; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .red-line-component {
        margin: 15px auto; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .red-line-component {
        margin: 20px auto; } }
  .compare-features-content .systems {
    display: flex;
    flex-direction: row; }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .systems {
        flex-direction: column; } }
  @media print, screen and (max-width: 39.99875em) {
    .compare-features-content .standard-audio-system {
      align-self: flex-start; } }
  @media print, screen and (max-width: 39.99875em) {
    .compare-features-content .optional-audio-system {
      align-self: flex-end; } }
  .compare-features-content .standard-audio-header {
    border-bottom: 1.5px solid #707070;
    padding-bottom: 5px;
    width: 362px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .standard-audio-header {
        border-bottom: none;
        width: 245px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .standard-audio-header {
        border-bottom: none;
        width: 224px; } }
  .compare-features-content .optional-audio-header {
    border-bottom: 1.5px solid #707070;
    padding-bottom: 5px;
    width: 385px;
    padding-left: 23px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .optional-audio-header {
        border-bottom: none;
        width: 263px;
        padding-left: 18px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .optional-audio-header {
        border-bottom: none;
        padding-left: 0;
        margin-top: 75px;
        width: 224px; } }
  .compare-features-content .system-label {
    font: 15px "ToyotaType-Semibold", sans-serif;
    text-align: center; }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .system-label {
        font: 14px "ToyotaType-Semibold", sans-serif;
        text-align: center; } }
  .compare-features-content .system-title {
    font: 18px "ToyotaType-Bold", sans-serif;
    text-align: center; }
  .compare-features-content .image {
    width: 362px;
    height: 163px;
    margin-top: 10px;
    object-fit: cover; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .image {
        margin-top: 10px;
        width: 245px;
        height: 110px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .image {
        margin-top: 0;
        width: 224px;
        height: 101px; } }
  .compare-features-content .optional-image {
    margin-left: 23px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .optional-image {
        margin-left: 18px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .optional-image {
        margin-left: 0; } }
  .compare-features-content .tcop-link-container {
    width: 362px;
    height: 163px;
    margin-top: 10px;
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .tcop-link-container {
        margin-top: 10px;
        width: 245px;
        height: 110px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .tcop-link-container {
        margin-top: 0;
        width: 224px;
        height: 101px; } }
  .compare-features-content .tcop-link-container-optional {
    margin-left: 23px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .tcop-link-container-optional {
        margin-left: 18px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .tcop-link-container-optional {
        margin-left: 0; } }
  .compare-features-content .tcop-link {
    width: 60%;
    text-align: center;
    font-weight: bold; }
  .compare-features-content .standard-table {
    width: 362px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .standard-table {
        width: 245px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .standard-table {
        width: 224px; } }
  .compare-features-content .optional-table {
    width: 362px;
    margin-left: 23px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content .optional-table {
        width: 245px;
        margin-left: 18px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .optional-table {
        width: 224px;
        margin-left: 0; } }
  .compare-features-content .optional-column-label {
    visibility: hidden; }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content .optional-column-label {
        visibility: visible; } }
  .compare-features-content table {
    margin-top: 30px;
    width: 362px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .compare-features-content table {
        margin-top: 30px;
        width: 245px; } }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content table {
        margin-top: 20px;
        width: 224px; } }
  .compare-features-content th {
    border-bottom: 1.5px solid black;
    background-color: white;
    font: 18px "ToyotaType-Semibold", sans-serif;
    padding: 10px 20px 10px 20px;
    max-width: 100%;
    height: 46px; }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content th {
        padding: 7px 7px 12px;
        font: 16px "ToyotaType-Semibold", sans-serif; } }
  .compare-features-content td {
    border-bottom: 0.5px solid black;
    background-color: white;
    max-width: 100%;
    font: 15px "ToyotaType-Regular", sans-serif;
    height: 39.5px; }
    @media print, screen and (max-width: 39.99875em) {
      .compare-features-content td {
        font: 14px "ToyotaType-Regular", sans-serif; } }
