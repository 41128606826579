@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

@media print {

    .third-party-comparison-component {
        gap: 0px;

        .filter-container {
            margin-bottom: 0px;

            .jato-disclaimer {
                font-size: 10px;
            }
        }


        .mobile-accordion-header {
            display: none;
        }

        .accordion-data-row {
            padding-top: 8px;
      .year-make-model {
        margin-top: -10px;
      }

      .year-make-model-placeholder {
        display: none;
      }

            .item {
                padding: 0px 10px;
                font: $p12-reg;
            }

            >.item+.item {
                border-left: unset;
                position: relative;
                padding: 0px 10px;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 18px;
                    background-color: rgb(134, 134, 134);
                    print-color-adjust: exact;
                }
            }
        }

    }

    #third-party-comparison-filter-print-only {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .filter-card {
            flex: 1;
            width: 1px; // workaround to make all same width
            padding: 20px 10px;
            background-color: #F7F7F7 !important;
            print-color-adjust: exact;

            .info-section {
                .year-make-model {
                    line-height: 14px;
                    margin-bottom: 10px;

                    .year {
                        font-size: 14px;
                    }
                    .make-model {
                        font-size: 14px;
                    }
                }
                .trim {
                    font-size: 14px;
                    min-height: 0px;
                    padding: 0px;
                }
            }

            .vehicle-image {
                padding-bottom: 0px;
            }

            .year-make-model-placeholder {
                display: none;
            }

            .year-make-model-placeholder-print-only {
                display: block;
                font-size: 12px;
            }

            .dropdown-section {
                display: none;
            }
        }
    }
}