/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.smartpath-overview-intro {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  margin: auto;
  padding-bottom: 16px; }
  @media print, screen and (max-width: 39.99875em) {
    .smartpath-overview-intro {
      padding-bottom: 0px; }
      .smartpath-overview-intro .smartpath-overview-sectionHeader {
        font: 30px "ToyotaType-Black", sans-serif;
        line-height: 1;
        padding-bottom: 15px; }
      .smartpath-overview-intro .smartpath-overview-quick-links {
        display: flex;
        width: 314px;
        margin: auto;
        flex-wrap: wrap;
        justify-content: space-between; }
        .smartpath-overview-intro .smartpath-overview-quick-links .smartpath-overview-intro-buttons {
          width: 151px;
          height: 68px;
          font: 16px "ToyotaType-Regular", sans-serif;
          background: #FFFFFF;
          border: 2px solid #707070;
          border-radius: 48px;
          margin-top: 17px;
          padding: 0px 20px 6px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: black; }
        .smartpath-overview-intro .smartpath-overview-quick-links .smartpath-overview-intro-buttons:active {
          background-color: #000000;
          border: 2px solid #000000;
          color: #FFFFFF;
          font: 16px "ToyotaType-Bold", sans-serif; }
      .smartpath-overview-intro .smartpath-overview-hero-video {
        padding-top: 28px;
        padding-bottom: 31px;
        margin: auto;
        width: 314px; }
        .smartpath-overview-intro .smartpath-overview-hero-video .kv-info-panel-inner {
          display: none; }
      .smartpath-overview-intro .smartpath-overview-cards {
        display: flex;
        flex-direction: column;
        width: 315px;
        height: auto;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px #00000034;
        border-radius: 12px;
        padding: 22px 30px 30px;
        margin-bottom: 20px; }
        .smartpath-overview-intro .smartpath-overview-cards .smartpath-overview-cardIcon {
          height: 31px; }
          .smartpath-overview-intro .smartpath-overview-cards .smartpath-overview-cardIcon img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .smartpath-overview-intro .smartpath-overview-cards .smartpath-overview-cardTitle {
          padding-top: 10px;
          font: 18px "ToyotaType-Black", sans-serif;
          margin-bottom: 4px; }
        .smartpath-overview-intro .smartpath-overview-cards .smartpath-overview-cardContent {
          font: 16px "ToyotaType-Regular", sans-serif; }
      .smartpath-overview-intro .smartpath-overview-cards:last-child {
        margin-bottom: 0px; }
      .smartpath-overview-intro .smartpath-overview-hero-video {
        padding-top: 30px;
        padding-bottom: 30px;
        margin: auto;
        width: 314px; }
        .smartpath-overview-intro .smartpath-overview-hero-video .kv-info-panel-inner {
          margin: 0; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .smartpath-overview-intro {
      padding-bottom: 75px; }
      .smartpath-overview-intro .smartpath-overview-sectionHeader {
        font: 60px "ToyotaType-Black", sans-serif;
        line-height: 1;
        height: auto;
        padding-bottom: 40px; }
      .smartpath-overview-intro .smartpath-overview-quick-links {
        width: 644px;
        display: flex;
        justify-content: space-between;
        margin: auto; }
        .smartpath-overview-intro .smartpath-overview-quick-links .smartpath-overview-intro-buttons {
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 6px;
          height: 70px;
          width: 154px;
          font: 16px "ToyotaType-Regular", sans-serif;
          background: #FFFFFF;
          border: 2px solid #707070;
          border-radius: 48px;
          margin-top: 17px; }
      .smartpath-overview-intro .smartpath-overview-hero-video {
        padding-top: 38px;
        padding-bottom: 62px;
        margin: auto;
        width: 644px; }
        .smartpath-overview-intro .smartpath-overview-hero-video .kv-info-panel-inner {
          display: none; }
      .smartpath-overview-intro .smartpath-overview-card-section {
        display: flex;
        justify-content: space-between;
        width: 644px;
        margin: auto; }
        .smartpath-overview-intro .smartpath-overview-card-section .smartpath-overview-cards {
          width: 202px;
          padding-left: 16px;
          padding-right: 16px;
          background: #FFFFFF;
          box-shadow: 0px 2px 8px #00000034;
          border-radius: 12px;
          padding-bottom: 30px; }
          .smartpath-overview-intro .smartpath-overview-card-section .smartpath-overview-cards .smartpath-overview-cardIcon {
            height: 65px;
            padding-top: 34px; }
            .smartpath-overview-intro .smartpath-overview-card-section .smartpath-overview-cards .smartpath-overview-cardIcon img {
              width: 100%;
              height: 100%;
              object-fit: contain; }
          .smartpath-overview-intro .smartpath-overview-card-section .smartpath-overview-cards .smartpath-overview-cardTitle {
            height: auto;
            padding-top: 10px;
            font: 18px "ToyotaType-Black", sans-serif;
            margin-bottom: 4px; }
          .smartpath-overview-intro .smartpath-overview-card-section .smartpath-overview-cards .smartpath-overview-cardContent {
            margin-top: 10px; } }
  @media print, screen and (min-width: 64em) {
    .smartpath-overview-intro .smartpath-overview-sectionHeader {
      font: 80px "ToyotaType-Black", sans-serif;
      line-height: 1; }
    .smartpath-overview-intro .smartpath-overview-intro-buttons {
      width: 198px;
      height: 40px;
      font: 16px "ToyotaType-Regular", sans-serif;
      background: #FFFFFF;
      border: 2px solid #707070;
      border-radius: 19px;
      margin-right: 20px;
      padding-bottom: 11px;
      padding-top: 6px;
      margin-top: 27px;
      color: black; }
    .smartpath-overview-intro .smartpath-overview-intro-buttons:last-child {
      margin-right: 0px; }
    .smartpath-overview-intro .smartpath-overview-intro-buttons:hover {
      background-color: #000000;
      border: 2px solid #000000;
      color: #FFFFFF;
      font: 16px "ToyotaType-Bold", sans-serif; }
    .smartpath-overview-intro .smartpath-overview-hero-video {
      padding-top: 41px;
      padding-bottom: 100px;
      margin: auto;
      width: 1040px; }
      .smartpath-overview-intro .smartpath-overview-hero-video .kv-info-panel-inner {
        margin: 0; }
    .smartpath-overview-intro .smartpath-overview-card-section {
      display: flex;
      justify-content: space-between;
      width: 1040px;
      margin: auto; }
    .smartpath-overview-intro .smartpath-overview-cards {
      display: inline-block;
      width: 327px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px #00000034;
      border-radius: 12px;
      padding-bottom: 30px;
      margin-bottom: 80px; }
      .smartpath-overview-intro .smartpath-overview-cards .smartpath-overview-cardIcon {
        padding-top: 54px;
        padding-bottom: 16px; }
      .smartpath-overview-intro .smartpath-overview-cards .smartpath-overview-cardTitle {
        height: 52px;
        font: 20px "ToyotaType-Black", sans-serif;
        margin-bottom: 14px; }
      .smartpath-overview-intro .smartpath-overview-cards .smartpath-overview-cardContent {
        font: 16px "ToyotaType-Regular", sans-serif;
        width: 250px;
        height: auto;
        margin: auto; }
    .smartpath-overview-intro .smartpath-overview-cardAlign {
      margin-left: 30px; } }
