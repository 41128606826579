/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.tabFilterCard .divider-line {
  border-bottom: 0.5px solid #707070;
  height: 1px;
  margin: 20px 0;
  width: 100%; }

.tabFilterCard .filter-tabs {
  height: 31px;
  width: fit-content;
  font-size: 14px;
  display: flex;
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  font-weight: bold;
  cursor: pointer; }
  .tabFilterCard .filter-tabs .active {
    padding: 5px 24px 0px 24px;
    min-width: 110px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px -1px 4px #00000029;
    border-radius: 12px 12px 0px 0px;
    opacity: 1;
    z-index: 11 !important; }
  .tabFilterCard .filter-tabs .inactive {
    min-width: 110px;
    padding: 5px 24px 0px 24px;
    background: #F5F7F8 0% 0% no-repeat padding-box;
    box-shadow: 2px -1px 4px #00000029;
    border-radius: 12px 12px 0px 0px;
    opacity: 1; }
  @media print, screen and (max-width: 39.99875em) {
    .tabFilterCard .filter-tabs {
      display: none; } }
  @media print, screen and (min-width: 40em) {
    .tabFilterCard .filter-tabs {
      margin-top: 59px; } }
  @media print, screen and (min-width: 64em) {
    .tabFilterCard .filter-tabs {
      margin-top: 60px; } }

.tabFilterCard .filter-tabs-mobile {
  margin-right: -15px;
  width: 330px;
  -webkit-mask-image: linear-gradient(to right, black 75%, transparent 100%);
  mask-image: linear-gradient(to right, black 75%, transparent 100%);
  padding-left: 2px;
  display: flex;
  gap: 36px;
  overflow: scroll;
  text-transform: uppercase;
  align-items: center; }
  .tabFilterCard .filter-tabs-mobile ::-webkit-scrollbar {
    background: transparent;
    height: 0; }
  .tabFilterCard .filter-tabs-mobile span {
    white-space: nowrap; }
  .tabFilterCard .filter-tabs-mobile span:last-child {
    margin-right: 100px; }
  .tabFilterCard .filter-tabs-mobile .active {
    font: 16px "ToyotaType-Bold", sans-serif;
    background: none; }
  .tabFilterCard .filter-tabs-mobile .inactive {
    font: 15px "ToyotaType-Semibold", sans-serif; }
  @media print, screen and (min-width: 40em) {
    .tabFilterCard .filter-tabs-mobile {
      display: none; } }

@media print, screen and (max-width: 39.99875em) {
  .tabFilterCard .filter-tabs-mobile.displayBadges, .tabFilterCard .filter-tabs.displayBadges {
    height: 40px;
    align-items: flex-end; } }

.tabFilterCard .filter-tabs-mobile.displayBadges span, .tabFilterCard .filter-tabs.displayBadges span {
  position: relative; }

.tabFilterCard .filter-tabs-mobile.displayBadges .menu-item-badge, .tabFilterCard .filter-tabs.displayBadges .menu-item-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #EB0A1E;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px; }
  @media print, screen and (max-width: 39.99875em) {
    .tabFilterCard .filter-tabs-mobile.displayBadges .menu-item-badge, .tabFilterCard .filter-tabs.displayBadges .menu-item-badge {
      top: -16px;
      right: -16px; } }

.tabFilterCard .filter-tabs-mobile::-webkit-scrollbar {
  display: none; }

@media print, screen and (max-width: 39.99875em) {
  .tabFilterCard {
    display: flex;
    flex-direction: column;
    align-items: center; } }

.tabCardContainer {
  width: 1034px;
  box-shadow: 0px 5px 7px #00000029;
  border-radius: 0px 0px 12px 12px;
  opacity: 1;
  height: auto;
  padding: 20px 80px;
  z-index: 13;
  border-top: 2px solid #FEFEFE;
  position: relative; }
  @media print, screen and (max-width: 39.99875em) {
    .tabCardContainer {
      width: 315px;
      box-shadow: none;
      border-radius: none;
      padding: 0px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .tabCardContainer {
      width: 648px;
      padding: 20px 55px; } }
