/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.recent-activity-list-wrapper {
  background: #FFFFFF;
  padding: 30px 40px;
  width: 50%;
  height: 100%;
  border-radius: 10px; }
  @media screen and (max-width: 56.5625em) {
    .recent-activity-list-wrapper {
      width: 100%; } }
  .recent-activity-list-wrapper h1 {
    font: 26px "ToyotaType-Bold", sans-serif;
    color: #000000; }
  .recent-activity-list-wrapper .list-item-loading {
    height: 90px;
    margin-top: 10px; }
  .recent-activity-list-wrapper .list-no-items {
    display: block;
    margin-top: 30px;
    font: 16px "ToyotaType-Regular", sans-serif;
    color: #000000; }
  .recent-activity-list-wrapper ul {
    margin: 0;
    padding: 0; }
    .recent-activity-list-wrapper ul li {
      display: flex;
      list-style: none;
      padding: 14px 0px 10px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      justify-content: space-between;
      align-items: center; }
      .recent-activity-list-wrapper ul li:last-child {
        border-bottom: none; }
      .recent-activity-list-wrapper ul li h4 {
        font: 16px "ToyotaType-Regular", sans-serif;
        color: #000000;
        margin: 0; }
      .recent-activity-list-wrapper ul li span {
        font: 14px "ToyotaType-Regular", sans-serif;
        color: #000000; }
      .recent-activity-list-wrapper ul li img {
        height: 62px;
        width: 62px;
        margin-left: 20px; }
