/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
@media print, screen and (max-width: 39.99875em) {
  .smartpath-overview-qualify {
    background-color: #F7F8F8;
    width: 100%;
    padding-top: 30px; }
    .smartpath-overview-qualify .smartpath-overview-qualify-header {
      font: 25px "ToyotaType-Regular", sans-serif;
      text-align: center;
      margin-bottom: 30px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-header strong {
        font: 25px "ToyotaType-Black", sans-serif; }
    .smartpath-overview-qualify .smartpath-overview-qualify-content {
      display: flex;
      flex-direction: column;
      width: 315px;
      margin: auto; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualify-content-icon {
        margin-top: 14px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualify-content-label {
        font: 20px "ToyotaType-Bold", sans-serif;
        width: 150px;
        margin: 8px auto 12px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent {
        flex-grow: 1; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 36px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-number {
            background-color: #707070;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: #FFFFFF;
            font: 24px "ToyotaType-Bold", sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 18px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-number span {
            margin-top: -5px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-title {
            font: 20px "ToyotaType-Bold", sans-serif;
            margin-right: 20px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description {
            width: 260px;
            text-align: initial;
            margin-left: 58px;
            font: 16px "ToyotaType-Regular", sans-serif; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description strong {
              font: 16px "ToyotaType-Bold", sans-serif; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description span strong:nth-child(2) {
              color: #EB0A1E; }
    .smartpath-overview-qualify .smartpath-overview-integrations-header {
      font: 25px "ToyotaType-Semibold", sans-serif;
      text-align: center;
      margin-bottom: 21px;
      margin-top: 7px; }
    .smartpath-overview-qualify .smartpath-overview-integrations-content {
      margin-right: 30px;
      margin-left: 30px; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column {
        padding-bottom: 25px;
        margin-bottom: 42px; }
        .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column .smartpath-overview-integration-column-title {
          font: 20px "ToyotaType-Bold", sans-serif;
          margin-bottom: 19px; }
        .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column .smartpath-overview-integration-tile {
          width: 227px;
          height: 72px;
          background-color: #FFFFFF;
          border-radius: 12px;
          box-shadow: 0px 3px 6px #00000029;
          margin: 0px auto 15px;
          display: flex;
          justify-content: center;
          align-items: center; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column:not(:last-child) {
        border-bottom: 1px #707070 solid; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .smartpath-overview-qualify {
    background-color: #F7F8F8;
    width: 100%;
    padding: 60px 62px 0px; }
    .smartpath-overview-qualify .smartpath-overview-qualify-header {
      font: 36px "ToyotaType-Regular", sans-serif;
      text-align: center;
      margin-bottom: 60px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-header strong {
        font: 36px "ToyotaType-Black", sans-serif; }
    .smartpath-overview-qualify .smartpath-overview-qualify-content {
      display: flex;
      flex-direction: row;
      width: 1068px;
      margin: auto; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualify-content-icon {
        margin-top: 14px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualify-content-label {
        font: 20px "ToyotaType-Bold", sans-serif;
        width: 200px;
        margin: 5px auto 0px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent {
        flex-grow: 1; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems {
          display: flex;
          align-items: baseline;
          flex-direction: column;
          height: 72px;
          flex-wrap: wrap;
          width: 300px;
          justify-content: center;
          align-content: flex-start;
          margin-bottom: 21px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design {
            display: none; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline {
              display: none; }
              .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline .smartpath-overview-qualifyContentItems-redline-top {
                margin-bottom: 8px; }
              .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline .smartpath-overview-qualifyContentItems-redline-bottom {
                border-bottom: 3px solid #EB0A1E; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline-circle {
              width: 8px;
              height: 8px;
              background-color: #EB0A1E;
              border: solid 3px #EB0A1E;
              border-radius: 50%;
              left: -5px;
              margin-top: 8px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-number {
            background-color: #707070;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: #FFFFFF;
            font: 30px "ToyotaType-Bold", sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 18px;
            margin-top: 25px;
            margin-bottom: 25px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-number span {
            margin-top: -5px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-title {
            font: 20px "ToyotaType-Bold", sans-serif;
            margin-right: 20px;
            text-align: left; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description {
            font: 15px "ToyotaType-Book", sans-serif;
            text-align: left; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description strong {
              font: 15px "ToyotaType-Regular", sans-serif; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description span strong:nth-child(2) {
              color: #EB0A1E; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(1) {
          margin-left: 14px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(2) {
          margin-left: -7px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(3) {
          margin-left: -23px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(4) {
          margin-left: -42px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(5) {
          margin-left: -58px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(6) {
          margin-left: -74px; }
    .smartpath-overview-qualify .smartpath-overview-integrations-header {
      font: 30px "ToyotaType-Semibold", sans-serif;
      text-align: center;
      margin-bottom: 52px;
      margin-top: 76px; }
    .smartpath-overview-qualify .smartpath-overview-integrations-content {
      width: 644px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      text-align: center; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column {
        width: 644;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 81px;
        padding-bottom: 36px; }
        .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column .smartpath-overview-integration-column-title {
          margin: auto;
          font: 20px "ToyotaType-Bold", sans-serif;
          margin-bottom: 18px;
          width: 100%; }
        .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column .smartpath-overview-integration-tile {
          width: 227px;
          height: 72px;
          background-color: #FFFFFF;
          margin-bottom: 23px;
          border-radius: 12px;
          box-shadow: 0px 3px 6px #00000029;
          display: flex;
          justify-content: center;
          align-items: center; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column:not(:first-child) {
        border-top: 1px #707070 solid;
        padding-top: 57px; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column:nth-child(n) {
        border-bottom: 0px; } }

@media print, screen and (min-width: 64em) {
  .smartpath-overview-qualify {
    background-color: #F7F8F8;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 115px; }
    .smartpath-overview-qualify .smartpath-overview-qualify-header {
      font: 48px "ToyotaType-Regular", sans-serif;
      text-align: center;
      margin-bottom: 77px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-header strong {
        font: 48px "ToyotaType-Bold", sans-serif; }
    .smartpath-overview-qualify .smartpath-overview-qualify-content {
      display: flex;
      flex-direction: row;
      width: 1068px;
      margin: auto; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualify-content-icon {
        margin-top: 14px; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualify-content-label {
        font: 20px "ToyotaType-Bold", sans-serif; }
      .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent {
        flex-grow: 1; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems {
          display: flex;
          align-items: center;
          height: 70px;
          margin-bottom: 12px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design {
            display: flex;
            flex-grow: 1;
            align-items: center; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline {
              display: flex;
              flex-grow: 1;
              flex-direction: column; }
              .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline .smartpath-overview-qualifyContentItems-redline-top {
                margin-bottom: 8px; }
              .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline .smartpath-overview-qualifyContentItems-redline-bottom {
                border-bottom: 3px solid #EB0A1E; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-design .smartpath-overview-qualifyContentItems-redline-circle {
              width: 8px;
              height: 8px;
              background-color: #EB0A1E;
              border: solid 3px #EB0A1E;
              border-radius: 50%;
              left: -5px;
              margin-top: 8px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-number {
            background-color: #707070;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: #FFFFFF;
            font: 30px "ToyotaType-Bold", sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 18px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-number span {
            margin-top: -5px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-title {
            font: 24px "ToyotaType-Bold", sans-serif;
            margin-right: 20px; }
          .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description {
            width: 260px;
            text-align: initial;
            margin-left: 20px;
            font: 16px "ToyotaType-Regular", sans-serif; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description strong {
              font: 16px "ToyotaType-Bold", sans-serif; }
            .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems .smartpath-overview-qualifyContentItems-description span strong:nth-child(2) {
              color: #EB0A1E; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(1) {
          margin-left: 10px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(2) {
          margin-left: -9px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(3) {
          margin-left: -25px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(4) {
          margin-left: -42px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(5) {
          margin-left: -59px; }
        .smartpath-overview-qualify .smartpath-overview-qualify-content .smartpath-overview-qualifyContent .smartpath-overview-qualifyContentItems:nth-child(6) {
          margin-left: -75px; }
    .smartpath-overview-qualify .smartpath-overview-integrations-header {
      font: 30px "ToyotaType-Semibold", sans-serif;
      text-align: center;
      margin-bottom: 52px;
      margin-top: 76px; }
    .smartpath-overview-qualify .smartpath-overview-integrations-content {
      width: 1100px;
      display: flex;
      justify-content: center;
      margin: auto;
      text-align: center; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column {
        width: calc(100% / 3); }
        .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column .smartpath-overview-integration-column-title {
          padding-top: inherit;
          font: 20px "ToyotaType-Bold", sans-serif;
          margin-bottom: 14px; }
        .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column .smartpath-overview-integration-tile {
          width: 227px;
          height: 72px;
          background-color: #FFFFFF;
          margin-bottom: 11px;
          border-radius: 12px;
          box-shadow: 0px 3px 6px #00000029;
          margin-left: 69px;
          margin-right: 56px;
          display: flex;
          justify-content: center;
          align-items: center; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column:not(:first-child) {
        border-left: 1px #707070 solid; }
      .smartpath-overview-qualify .smartpath-overview-integrations-content .smartpath-overview-integration-column:nth-child(n) {
        border-bottom: 0px; } }
