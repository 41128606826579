@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

@media print {

  .head-to-head-component {

    #head-to-head-header-print-only {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .competitor-filter {
        align-items: start;

        #competitor-filter-button-print-only {
          height: 26px !important; // need to use !importante here
          margin-left: -6px;
        }
      }

      .competitor-selection-v2 {
        gap: 50px;

        .comparison-vehicle-container {
          width: 180px;
        }
      }
    }

    .video-row {
      border-top: 1px solid rgb(128, 128, 128);
    }

    .video-row-header {
      padding: 5px 15px;

      .video-row-title {
        span {
          font: $p11-bold;
        }
      }

      .video-row-expand-collapse-icon {
        visibility: hidden;
      }
    }

    .combined-accordion {
      margin-top: 0px;
    } 

    .competitor-filter {
      padding-top: 15px;
      .filter-label {
        padding-right: 5px;
        font: $p11-bold;
      }

      .roundedButton:not(.active) {
        display: none;
      }

      .roundedButton.active {
        border: none;
        font: $p11-reg;
        margin-left: 0;
        padding: 0 0 3px 0;
      }
    }

    .accordion-body {
      &.h2h-cost-features{
        .advantage-diamond-icon{
          filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
          width: 10px;
          height: 11px;
        }
      }
      
      &.h2h-quick-specs-features{
        .advantage-diamond-icon{
          filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
          width: 10px;
          height: 11px;
        }
      }
    }

    .overview-video-section {
      display: none;
    }

    .video-row-expand-collapse-icon {
      display: none;
    }
  }
}