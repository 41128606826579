/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.engageXPOverview {
  width: 1336px;
  margin: auto;
  margin-bottom: 80px; }
  @media screen and (max-width: 83.625em) {
    .engageXPOverview {
      width: 897px; } }
  @media screen and (max-width: 59.375em) {
    .engageXPOverview {
      width: 359px;
      margin-bottom: 100px; } }
  .engageXPOverview .dynamic-landing-title-alternative {
    font: 28px "ToyotaType-Regular", sans-serif; }
    @media print, screen and (max-width: 39.99875em) {
      .engageXPOverview .dynamic-landing-title-alternative {
        text-align: center; } }
  .engageXPOverview .subpage-header {
    margin-top: 40px; }
    @media print, screen and (max-width: 39.99875em) {
      .engageXPOverview .subpage-header .description {
        flex-direction: column; } }
    @media print, screen and (max-width: 39.99875em) {
      .engageXPOverview .subpage-header .subpage-column-description {
        margin-bottom: 20px;
        font: 14px "ToyotaType-Regular", sans-serif; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .engageXPOverview .subpage-header .subpage-column-description {
        line-height: 22px; } }
    .engageXPOverview .subpage-header .subpage-column-question {
      font: 18px "ToyotaType-Bold", sans-serif !important; }
  .engageXPOverview .red-line-component {
    height: 2.5px; }
  .engageXPOverview .nitroHowToTabs {
    margin-top: 80px; }
    @media print, screen and (max-width: 39.99875em) {
      .engageXPOverview .nitroHowToTabs {
        margin-top: 70px; } }
    .engageXPOverview .nitroHowToTabs .title-text {
      font: 24px "ToyotaType-Regular", sans-serif;
      text-align: center; }
      @media print, screen and (max-width: 39.99875em) {
        .engageXPOverview .nitroHowToTabs .title-text {
          font: 20px "ToyotaType-Regular", sans-serif; } }
    .engageXPOverview .nitroHowToTabs .tab-menu-subSection {
      margin-bottom: 25px; }
      @media print, screen and (max-width: 63.99875em) {
        .engageXPOverview .nitroHowToTabs .tab-menu-subSection {
          margin: 52px 0px 25px 0px; } }

.welcome-to-engage-xp-overview-container {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 59.375em) {
    .welcome-to-engage-xp-overview-container {
      align-items: center; } }
  .welcome-to-engage-xp-overview-container .welcome-to-xp-message {
    display: flex;
    font: 40px "ToyotaType-Bold", sans-serif;
    justify-content: center;
    align-items: center;
    margin-top: 52px;
    margin-bottom: 36px;
    text-align: center;
    position: relative; }
    .welcome-to-engage-xp-overview-container .welcome-to-xp-message .global-back-button {
      position: absolute;
      left: 0px; }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .welcome-to-engage-xp-overview-container .welcome-to-xp-message .global-back-button {
          left: -10px; } }
      @media print, screen and (max-width: 39.99875em) {
        .welcome-to-engage-xp-overview-container .welcome-to-xp-message .global-back-button {
          left: 10px; }
          .welcome-to-engage-xp-overview-container .welcome-to-xp-message .global-back-button .left-arrow {
            width: 10px; } }
    @media screen and (max-width: 59.375em) {
      .welcome-to-engage-xp-overview-container .welcome-to-xp-message {
        max-width: 428px; } }
  .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 67px;
    gap: 64px; }
    @media screen and (max-width: 83.625em) {
      .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area {
        gap: 47px; } }
    @media screen and (max-width: 59.375em) {
      .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area {
        gap: 30px;
        flex-direction: column;
        margin-bottom: 0; } }
    .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area .what-is-xp-container {
      max-width: 591px; }
      @media screen and (max-width: 83.625em) {
        .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area .what-is-xp-container {
          max-width: 425px; } }
      @media screen and (max-width: 59.375em) {
        .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area .what-is-xp-container {
          max-width: 360px; } }
    .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area .why-play-container {
      max-width: 591px; }
      @media screen and (max-width: 83.625em) {
        .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area .why-play-container {
          max-width: 425px; } }
      @media screen and (max-width: 59.375em) {
        .welcome-to-engage-xp-overview-container .welcome-to-xp-info-area .why-play-container {
          max-width: 360px; } }
  .welcome-to-engage-xp-overview-container .what-is-xp-title {
    display: flex;
    font: 30px "ToyotaType-Bold", sans-serif;
    justify-content: center;
    margin-bottom: 13px; }
  .welcome-to-engage-xp-overview-container .why-play-title {
    display: flex;
    font: 30px "ToyotaType-Bold", sans-serif;
    margin-bottom: 13px;
    justify-content: center; }
  .welcome-to-engage-xp-overview-container .what-is-xp-description {
    display: flex;
    font: 16px "ToyotaType-Regular", sans-serif;
    max-width: 591px; }
    @media screen and (max-width: 83.625em) {
      .welcome-to-engage-xp-overview-container .what-is-xp-description {
        max-width: 425px; } }
    @media screen and (max-width: 59.375em) {
      .welcome-to-engage-xp-overview-container .what-is-xp-description {
        max-width: 360px;
        margin-bottom: 0;
        margin-right: 0; } }
  .welcome-to-engage-xp-overview-container .why-play-description {
    display: flex;
    font: 16px "ToyotaType-Regular", sans-serif;
    max-width: 591px; }
    @media screen and (max-width: 83.625em) {
      .welcome-to-engage-xp-overview-container .why-play-description {
        max-width: 425px; } }
    @media screen and (max-width: 59.375em) {
      .welcome-to-engage-xp-overview-container .why-play-description {
        max-width: 360px; } }

.engage-xp-button-container {
  display: flex;
  justify-content: center; }
  .engage-xp-button-container .xp-play-now-button {
    width: 204px;
    height: 35px;
    font: 18px "ToyotaType-Regular", sans-serif; }

.engage-xp-tabs-container {
  margin-bottom: 80px; }
  .engage-xp-tabs-container .engage-xp-tabs-items-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 100%;
    margin-left: 0; }
    @media screen and (max-width: 59.375em) {
      .engage-xp-tabs-container .engage-xp-tabs-items-container {
        flex-direction: column;
        max-width: 360px;
        margin: 0 auto; } }
    .engage-xp-tabs-container .engage-xp-tabs-items-container .engage-xp-tabs-card {
      width: 423px; }
      @media screen and (max-width: 83.625em) {
        .engage-xp-tabs-container .engage-xp-tabs-items-container .engage-xp-tabs-card {
          width: 283px; } }
      @media screen and (max-width: 59.375em) {
        .engage-xp-tabs-container .engage-xp-tabs-items-container .engage-xp-tabs-card {
          width: 360px; } }
      .engage-xp-tabs-container .engage-xp-tabs-items-container .engage-xp-tabs-card img {
        width: 100%;
        height: 212px;
        margin-bottom: 10px; }
        @media screen and (max-width: 83.625em) {
          .engage-xp-tabs-container .engage-xp-tabs-items-container .engage-xp-tabs-card img {
            height: 142px; } }
        @media screen and (max-width: 59.375em) {
          .engage-xp-tabs-container .engage-xp-tabs-items-container .engage-xp-tabs-card img {
            height: 180px; } }
      .engage-xp-tabs-container .engage-xp-tabs-items-container .engage-xp-tabs-card p {
        font: 16px "ToyotaType-Regular", sans-serif;
        line-height: 1.7; }

.engageXPHowDo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .engageXPHowDo .headerContainer {
    display: flex;
    align-items: baseline;
    position: relative;
    width: 1328px; }
    .engageXPHowDo .headerContainer .titleTextContainer {
      margin: 0px auto;
      margin-top: 52px; }
      @media screen and (max-width: 48.75em) {
        .engageXPHowDo .headerContainer .titleTextContainer {
          margin-top: 30px;
          text-align: center; } }
      .engageXPHowDo .headerContainer .titleTextContainer .titleText {
        font: 40px "ToyotaType-Bold", sans-serif; }
        @media screen and (max-width: 48.75em) {
          .engageXPHowDo .headerContainer .titleTextContainer .titleText {
            font: 35px "ToyotaType-Bold", sans-serif; } }
    @media screen and (max-width: 87.5em) {
      .engageXPHowDo .headerContainer {
        width: 720px; } }
    .engageXPHowDo .headerContainer .global-back-button {
      position: absolute;
      top: 74px; }
    @media screen and (min-width: 103.125em) {
      .engageXPHowDo .headerContainer .global-back-button {
        position: absolute;
        left: -150px;
        top: 78px; } }
    @media screen and (max-width: 48.75em) {
      .engageXPHowDo .headerContainer {
        width: 360px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .engageXPHowDo .headerContainer {
        padding: 20px 30px 80px 30px; }
        .engageXPHowDo .headerContainer .global-back-button {
          position: absolute;
          left: -24px;
          top: 92px;
          padding-top: 0px;
          align-items: center; } }
    @media print, screen and (max-width: 39.99875em) {
      .engageXPHowDo .headerContainer {
        padding-bottom: 50px; }
        .engageXPHowDo .headerContainer .global-back-button {
          align-items: flex-start;
          padding-top: 15px; }
          .engageXPHowDo .headerContainer .global-back-button .left-arrow {
            width: 10px; } }
  .engageXPHowDo .cardsContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 1328px;
    margin: 59px auto 25px auto; }
    @media screen and (max-width: 87.5em) {
      .engageXPHowDo .cardsContainer {
        width: 720px;
        gap: 34px; } }
    @media screen and (max-width: 48.75em) {
      .engageXPHowDo .cardsContainer {
        width: 360px;
        gap: 60px; } }
  .engageXPHowDo .checkMyBalanceContainer {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .engageXPHowDo .checkMyBalanceButton {
    top: 839px;
    left: 781px;
    width: 342px;
    height: 54px;
    background: #000000;
    border-radius: 27px;
    opacity: 1;
    font: 20px "ToyotaType-Bold", sans-serif;
    color: #ffffff;
    text-align: center;
    padding-top: 11px;
    text-decoration: none; }

.cardContainer {
  width: 302px; }
  @media screen and (max-width: 87.5em) {
    .cardContainer {
      width: 340px; } }
  @media screen and (max-width: 48.75em) {
    .cardContainer {
      width: 360px; } }
  .cardContainer .mainImage {
    position: relative;
    display: inline-block;
    width: 100%; }
    .cardContainer .mainImage img {
      width: 100%; }
    .cardContainer .mainImage .imageIndex {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #383838;
      width: 57px;
      height: 49px;
      border-radius: 5px;
      color: white;
      text-align: center;
      font: 30px "ToyotaType-Bold", sans-serif; }
  .cardContainer .cardTitle {
    font: 24px "ToyotaType-Bold", sans-serif;
    width: 100%;
    margin-top: 7px; }
  .cardContainer .description {
    width: 100%;
    height: auto;
    font: 16px "ToyotaType-Regular", sans-serif;
    margin-top: 18px; }
