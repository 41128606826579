@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.modelCodeNameTiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include breakpoint(small only) {
    flex-direction: column;
  }

  row-gap: 25px;
  column-gap: 67px;

  .modelTile {
    display: flex;
    width: 188px;
    flex-direction: column;
    gap: 7px;
    @include breakpoint(medium only) {
      width: 167px;
    }
    @include breakpoint(small only) {
      max-width: 271px;
      width: 100%;
    }

    .modelCode {
      font: $h4-bold;
    }
    .modelName {
      font: $font15-book;
    }
  }
}

