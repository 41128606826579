/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content, .TCOP {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content, .TCOP {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title, .TCOP .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title, .TCOP .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title, .TCOP .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p, .TCOP p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3, .TCOP h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.TCOP ::-webkit-scrollbar {
  display: none;
  /* remove scrollbar space */ }

.TCOP img {
  margin-left: auto;
  margin-right: auto;
  display: block; }

@media print, screen and (max-width: 39.99875em) {
  .TCOP {
    margin-right: 0;
    margin-left: 0; }
    .TCOP .margin-container {
      margin-right: 10px;
      margin-left: 10px; } }
    @media screen and (max-width: 39.99875em) and (max-width: 0em), screen and (max-width: 39.99875em) and (min-width: 40em) {
      .TCOP .margin-container {
        display: none !important; } }

.TCOP .align-left {
  justify-content: flex-start; }

.TCOP .align-right {
  justify-content: flex-end; }

.TCOP .align-center {
  justify-content: center; }

.TCOP .align-justify {
  justify-content: space-between; }

.TCOP .align-spaced {
  justify-content: space-around; }

.TCOP .align-left.vertical.menu > li > a {
  justify-content: flex-start; }

.TCOP .align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.TCOP .align-center.vertical.menu > li > a {
  justify-content: center; }

.TCOP .align-top {
  align-items: flex-start; }

.TCOP .align-self-top {
  align-self: flex-start; }

.TCOP .align-bottom {
  align-items: flex-end; }

.TCOP .align-self-bottom {
  align-self: flex-end; }

.TCOP .align-middle {
  align-items: center; }

.TCOP .align-self-middle {
  align-self: center; }

.TCOP .align-stretch {
  align-items: stretch; }

.TCOP .align-self-stretch {
  align-self: stretch; }

.TCOP .align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.TCOP .small-order-1 {
  order: 1; }

.TCOP .small-order-2 {
  order: 2; }

.TCOP .small-order-3 {
  order: 3; }

.TCOP .small-order-4 {
  order: 4; }

.TCOP .small-order-5 {
  order: 5; }

.TCOP .small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .TCOP .medium-order-1 {
    order: 1; }
  .TCOP .medium-order-2 {
    order: 2; }
  .TCOP .medium-order-3 {
    order: 3; }
  .TCOP .medium-order-4 {
    order: 4; }
  .TCOP .medium-order-5 {
    order: 5; }
  .TCOP .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .TCOP .large-order-1 {
    order: 1; }
  .TCOP .large-order-2 {
    order: 2; }
  .TCOP .large-order-3 {
    order: 3; }
  .TCOP .large-order-4 {
    order: 4; }
  .TCOP .large-order-5 {
    order: 5; }
  .TCOP .large-order-6 {
    order: 6; } }

.TCOP .flex-container {
  display: flex; }

.TCOP .flex-child-auto {
  flex: 1 1 auto; }

.TCOP .flex-child-grow {
  flex: 1 0 auto; }

.TCOP .flex-child-shrink {
  flex: 0 1 auto; }

.TCOP .flex-dir-row {
  flex-direction: row; }

.TCOP .flex-dir-row-reverse {
  flex-direction: row-reverse; }

.TCOP .flex-dir-column {
  flex-direction: column; }

.TCOP .flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .TCOP .medium-flex-container {
    display: flex; }
  .TCOP .medium-flex-child-auto {
    flex: 1 1 auto; }
  .TCOP .medium-flex-child-grow {
    flex: 1 0 auto; }
  .TCOP .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .TCOP .medium-flex-dir-row {
    flex-direction: row; }
  .TCOP .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .TCOP .medium-flex-dir-column {
    flex-direction: column; }
  .TCOP .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .TCOP .large-flex-container {
    display: flex; }
  .TCOP .large-flex-child-auto {
    flex: 1 1 auto; }
  .TCOP .large-flex-child-grow {
    flex: 1 0 auto; }
  .TCOP .large-flex-child-shrink {
    flex: 0 1 auto; }
  .TCOP .large-flex-dir-row {
    flex-direction: row; }
  .TCOP .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .TCOP .large-flex-dir-column {
    flex-direction: column; }
  .TCOP .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.TCOP .row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .TCOP .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .TCOP .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .TCOP .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .TCOP .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .TCOP .row.expanded {
    max-width: none; }
    .TCOP .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .TCOP .row:not(.expanded) .row {
    max-width: none; }
  .TCOP .row.collapse > .column, .TCOP .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .TCOP .row.is-collapse-child,
  .TCOP .row.collapse > .column > .row,
  .TCOP .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.TCOP .column, .TCOP .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0; }
  @media print, screen and (min-width: 40em) {
    .TCOP .column, .TCOP .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.TCOP .column.row.row, .TCOP .row.row.columns {
  float: none;
  display: block; }

.TCOP .row .column.row.row, .TCOP .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.TCOP .small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.TCOP .small-offset-0 {
  margin-left: 0%; }

.TCOP .small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.TCOP .small-offset-1 {
  margin-left: 8.33333%; }

.TCOP .small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.TCOP .small-offset-2 {
  margin-left: 16.66667%; }

.TCOP .small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.TCOP .small-offset-3 {
  margin-left: 25%; }

.TCOP .small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.TCOP .small-offset-4 {
  margin-left: 33.33333%; }

.TCOP .small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.TCOP .small-offset-5 {
  margin-left: 41.66667%; }

.TCOP .small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.TCOP .small-offset-6 {
  margin-left: 50%; }

.TCOP .small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.TCOP .small-offset-7 {
  margin-left: 58.33333%; }

.TCOP .small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.TCOP .small-offset-8 {
  margin-left: 66.66667%; }

.TCOP .small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.TCOP .small-offset-9 {
  margin-left: 75%; }

.TCOP .small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.TCOP .small-offset-10 {
  margin-left: 83.33333%; }

.TCOP .small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.TCOP .small-offset-11 {
  margin-left: 91.66667%; }

.TCOP .small-up-1 {
  flex-wrap: wrap; }
  .TCOP .small-up-1 > .column, .TCOP .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.TCOP .small-up-2 {
  flex-wrap: wrap; }
  .TCOP .small-up-2 > .column, .TCOP .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.TCOP .small-up-3 {
  flex-wrap: wrap; }
  .TCOP .small-up-3 > .column, .TCOP .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.TCOP .small-up-4 {
  flex-wrap: wrap; }
  .TCOP .small-up-4 > .column, .TCOP .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.TCOP .small-up-5 {
  flex-wrap: wrap; }
  .TCOP .small-up-5 > .column, .TCOP .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.TCOP .small-up-6 {
  flex-wrap: wrap; }
  .TCOP .small-up-6 > .column, .TCOP .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.TCOP .small-up-7 {
  flex-wrap: wrap; }
  .TCOP .small-up-7 > .column, .TCOP .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.TCOP .small-up-8 {
  flex-wrap: wrap; }
  .TCOP .small-up-8 > .column, .TCOP .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.TCOP .small-collapse > .column, .TCOP .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.TCOP .small-uncollapse > .column, .TCOP .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) {
  .TCOP .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .TCOP .medium-offset-0 {
    margin-left: 0%; }
  .TCOP .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .TCOP .medium-offset-1 {
    margin-left: 8.33333%; }
  .TCOP .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .TCOP .medium-offset-2 {
    margin-left: 16.66667%; }
  .TCOP .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .TCOP .medium-offset-3 {
    margin-left: 25%; }
  .TCOP .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .TCOP .medium-offset-4 {
    margin-left: 33.33333%; }
  .TCOP .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .TCOP .medium-offset-5 {
    margin-left: 41.66667%; }
  .TCOP .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .TCOP .medium-offset-6 {
    margin-left: 50%; }
  .TCOP .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .TCOP .medium-offset-7 {
    margin-left: 58.33333%; }
  .TCOP .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .TCOP .medium-offset-8 {
    margin-left: 66.66667%; }
  .TCOP .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .TCOP .medium-offset-9 {
    margin-left: 75%; }
  .TCOP .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .TCOP .medium-offset-10 {
    margin-left: 83.33333%; }
  .TCOP .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .TCOP .medium-offset-11 {
    margin-left: 91.66667%; }
  .TCOP .medium-up-1 {
    flex-wrap: wrap; }
    .TCOP .medium-up-1 > .column, .TCOP .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .TCOP .medium-up-2 {
    flex-wrap: wrap; }
    .TCOP .medium-up-2 > .column, .TCOP .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .TCOP .medium-up-3 {
    flex-wrap: wrap; }
    .TCOP .medium-up-3 > .column, .TCOP .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .TCOP .medium-up-4 {
    flex-wrap: wrap; }
    .TCOP .medium-up-4 > .column, .TCOP .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .TCOP .medium-up-5 {
    flex-wrap: wrap; }
    .TCOP .medium-up-5 > .column, .TCOP .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .TCOP .medium-up-6 {
    flex-wrap: wrap; }
    .TCOP .medium-up-6 > .column, .TCOP .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .TCOP .medium-up-7 {
    flex-wrap: wrap; }
    .TCOP .medium-up-7 > .column, .TCOP .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .TCOP .medium-up-8 {
    flex-wrap: wrap; }
    .TCOP .medium-up-8 > .column, .TCOP .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .TCOP .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .TCOP .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .TCOP .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .TCOP .medium-collapse > .column, .TCOP .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .TCOP .medium-uncollapse > .column, .TCOP .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .TCOP .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .TCOP .large-offset-0 {
    margin-left: 0%; }
  .TCOP .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .TCOP .large-offset-1 {
    margin-left: 8.33333%; }
  .TCOP .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .TCOP .large-offset-2 {
    margin-left: 16.66667%; }
  .TCOP .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .TCOP .large-offset-3 {
    margin-left: 25%; }
  .TCOP .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .TCOP .large-offset-4 {
    margin-left: 33.33333%; }
  .TCOP .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .TCOP .large-offset-5 {
    margin-left: 41.66667%; }
  .TCOP .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .TCOP .large-offset-6 {
    margin-left: 50%; }
  .TCOP .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .TCOP .large-offset-7 {
    margin-left: 58.33333%; }
  .TCOP .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .TCOP .large-offset-8 {
    margin-left: 66.66667%; }
  .TCOP .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .TCOP .large-offset-9 {
    margin-left: 75%; }
  .TCOP .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .TCOP .large-offset-10 {
    margin-left: 83.33333%; }
  .TCOP .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .TCOP .large-offset-11 {
    margin-left: 91.66667%; }
  .TCOP .large-up-1 {
    flex-wrap: wrap; }
    .TCOP .large-up-1 > .column, .TCOP .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .TCOP .large-up-2 {
    flex-wrap: wrap; }
    .TCOP .large-up-2 > .column, .TCOP .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .TCOP .large-up-3 {
    flex-wrap: wrap; }
    .TCOP .large-up-3 > .column, .TCOP .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .TCOP .large-up-4 {
    flex-wrap: wrap; }
    .TCOP .large-up-4 > .column, .TCOP .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .TCOP .large-up-5 {
    flex-wrap: wrap; }
    .TCOP .large-up-5 > .column, .TCOP .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .TCOP .large-up-6 {
    flex-wrap: wrap; }
    .TCOP .large-up-6 > .column, .TCOP .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .TCOP .large-up-7 {
    flex-wrap: wrap; }
    .TCOP .large-up-7 > .column, .TCOP .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .TCOP .large-up-8 {
    flex-wrap: wrap; }
    .TCOP .large-up-8 > .column, .TCOP .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .TCOP .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .TCOP .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .TCOP .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .TCOP .large-collapse > .column, .TCOP .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .TCOP .large-uncollapse > .column, .TCOP .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.TCOP .shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.TCOP .column-block {
  margin-bottom: 1.25rem; }
  .TCOP .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .TCOP .column-block {
      margin-bottom: 1.875rem; }
      .TCOP .column-block > :last-child {
        margin-bottom: 0; } }

.TCOP .trim-selector-checkboxes {
  background-color: #E6E6E6;
  padding: 2.5%;
  margin-bottom: 10px; }
  @media print, screen and (max-width: 39.99875em) {
    .TCOP .trim-selector-checkboxes {
      display: none !important; } }
  .TCOP .trim-selector-checkboxes .trims-available {
    display: flex;
    justify-content: center;
    width: 15%; }
    .TCOP .trim-selector-checkboxes .trims-available .verticle-center-container {
      align-self: center; }
      .TCOP .trim-selector-checkboxes .trims-available .verticle-center-container div {
        display: inline-block;
        vertical-align: middle; }
      .TCOP .trim-selector-checkboxes .trims-available .verticle-center-container .number-of-trims {
        font: 54px "ToyotaType-Semibold", sans-serif;
        margin-right: 5px; }
      .TCOP .trim-selector-checkboxes .trims-available .verticle-center-container .trims-available-words {
        font: 18px "ToyotaType-Semibold", sans-serif; }
        .TCOP .trim-selector-checkboxes .trims-available .verticle-center-container .trims-available-words div {
          display: block;
          line-height: 100%; }
  .TCOP .trim-selector-checkboxes .trim-checkboxes {
    border-left: 0.5px solid #000;
    border-right: 1px dotted #000;
    padding: 1%;
    width: 70%; }
    .TCOP .trim-selector-checkboxes .trim-checkboxes .compare-trims-words {
      font-family: "ToyotaType-Bold", sans-serif;
      max-width: 75rem;
      margin-right: auto;
      margin-left: auto;
      padding-left: 5px; }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .compare-trims-words::before, .TCOP .trim-selector-checkboxes .trim-checkboxes .compare-trims-words::after {
        display: table;
        content: ' ';
        flex-basis: 0;
        order: 1; }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .compare-trims-words::after {
        clear: both; }
    .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox {
      /* Base for label styling */
      /* checkbox aspect */
      /* checked mark aspect changes */ }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:not(:checked),
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:checked {
        position: absolute;
        left: -9999px; }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:not(:checked) + label,
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 1.95em;
        cursor: pointer; }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox .checkbox-box, .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:not(:checked) + label:before, .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:checked + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.3em;
        width: 1em;
        height: 1em;
        border: 1px solid #252525; }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:not(:checked) + label:before {
        background: #fafafa; }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:checked + label:before {
        background: #0099FF; }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0); }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox [type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1); }
      .TCOP .trim-selector-checkboxes .trim-checkboxes .checkbox label {
        font-family: "ToyotaType-Bold", sans-serif; }
  .TCOP .trim-selector-checkboxes .toggle-all-trims {
    display: flex;
    justify-content: center;
    width: 15%;
    font-family: "ToyotaType-Bold", sans-serif;
    font-size: 14px;
    color: #808080; }
    .TCOP .trim-selector-checkboxes .toggle-all-trims .deselect:hover,
    .TCOP .trim-selector-checkboxes .toggle-all-trims .select-all:hover {
      color: #A60000;
      cursor: pointer; }
    .TCOP .trim-selector-checkboxes .toggle-all-trims .toggle-all-buttons {
      align-self: center; }

.TCOP .trims-carousel {
  margin-bottom: 40px; }
  .TCOP .trims-carousel .carousel-title {
    font-family: "ToyotaType-Bold", sans-serif;
    font-size: 24px; }
  .TCOP .trims-carousel .trim-carousel-item {
    outline: none; }
    .TCOP .trims-carousel .trim-carousel-item .trim-name {
      font-family: "ToyotaType-Bold", sans-serif;
      font-size: 24px;
      line-height: 1.8;
      display: table-cell;
      position: relative;
      margin-bottom: 5px; }
      @media only screen and (max-width: 40em) {
        .TCOP .trims-carousel .trim-carousel-item .trim-name {
          font-size: 20px; } }
      .TCOP .trims-carousel .trim-carousel-item .trim-name:after {
        content: "";
        background: #EB0A1E;
        position: absolute;
        bottom: -11px;
        left: 12px;
        height: 23px;
        width: 2px;
        -ms-transform: rotate(90deg);
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        /* Chrome, Safari, Opera */
        transform: rotate(90deg); }
    .TCOP .trims-carousel .trim-carousel-item .msrpHeader {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 5px;
      font: 15px "ToyotaType-Bold", sans-serif; }
      .TCOP .trims-carousel .trim-carousel-item .msrpHeader sup {
        top: 0; }
    .TCOP .trims-carousel .trim-carousel-item .modelListSection {
      margin: 15px 0px; }
      .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px; }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem {
            margin-bottom: 25px;
            flex-direction: column; } }
        .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem .modelListItemCode {
          font: 15px "ToyotaType-Bold", sans-serif;
          width: 43px;
          margin-right: 17px; }
          @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
            .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem .modelListItemCode {
              margin-bottom: 2px; } }
        .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem .modelListItemName {
          font: 15px "ToyotaType-Book", sans-serif;
          margin-right: 5px; }
          @media print, screen and (max-width: 39.99875em) {
            .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem .modelListItemName {
              margin-right: 15px; } }
          @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
            .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem .modelListItemName {
              margin-right: 20px;
              margin-bottom: 5px;
              line-height: 19px;
              width: 79%; } }
        .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem .msrpSection {
          margin-left: auto; }
          .TCOP .trims-carousel .trim-carousel-item .modelListSection .modelListItem .msrpSection .modelListItemMSRP {
            font: 15px "ToyotaType-Book", sans-serif;
            width: 70px;
            padding-right: 6px;
            margin-left: auto; }
    .TCOP .trims-carousel .trim-carousel-item .msrp-info {
      margin-top: 5px; }
      .TCOP .trims-carousel .trim-carousel-item .msrp-info .msrp {
        font-family: "ToyotaType-Bold", sans-serif;
        display: inline-block;
        font-size: 24px;
        line-height: 100%; }
        @media only screen and (max-width: 40em) {
          .TCOP .trims-carousel .trim-carousel-item .msrp-info .msrp {
            font-size: 20px; } }
        .TCOP .trims-carousel .trim-carousel-item .msrp-info .msrp .starting-msrp {
          font-family: "ToyotaType-Light", sans-serif;
          display: block;
          font-size: 11px;
          line-height: 1.5; }
      .TCOP .trims-carousel .trim-carousel-item .msrp-info .comparison-badge {
        float: right; }
        .TCOP .trims-carousel .trim-carousel-item .msrp-info .comparison-badge .comparison {
          vertical-align: top;
          background-color: #EB0A1E;
          color: #FFFFFF;
          font-family: "ToyotaType-Bold", sans-serif;
          display: inline-block;
          position: relative; }
          .TCOP .trims-carousel .trim-carousel-item .msrp-info .comparison-badge .comparison .comparison-number {
            font-size: 20px;
            display: inline-block;
            padding-left: 5px; }
          .TCOP .trims-carousel .trim-carousel-item .msrp-info .comparison-badge .comparison .for-trim {
            display: inline-block;
            font-size: 8px;
            line-height: 100%;
            margin: 0px 3px; }
        .TCOP .trims-carousel .trim-carousel-item .msrp-info .comparison-badge .triangle-right {
          vertical-align: top;
          position: relative;
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 15px solid #EB0A1E; }
    .TCOP .trims-carousel .trim-carousel-item .leadIn {
      padding-top: 5px;
      padding-bottom: 0px;
      font-family: "ToyotaType-Light", sans-serif; }
    .TCOP .trims-carousel .trim-carousel-item .key-features {
      margin: 0;
      font-family: "ToyotaType-Light", sans-serif;
      font-size: 14px;
      padding: 1% 0; }
      .TCOP .trims-carousel .trim-carousel-item .key-features .key-features-title {
        font: 16px "ToyotaType-Bold", sans-serif; }
      .TCOP .trims-carousel .trim-carousel-item .key-features li {
        line-height: 110%;
        padding-top: 4px;
        padding-bottom: 4px; }
    .TCOP .trims-carousel .trim-carousel-item .trim-story {
      font-family: "ToyotaType-Light", sans-serif;
      line-height: 100%;
      font-size: 14px;
      margin-bottom: 2%; }
      .TCOP .trims-carousel .trim-carousel-item .trim-story .trim-story-title {
        font-family: "ToyotaType-Bold", sans-serif;
        margin-bottom: 3px;
        font-size: 16px; }
  .TCOP .trims-carousel .slick-dots .slick-active button:before {
    color: #EB0A1E; }
  .TCOP .trims-carousel .slick-prev {
    left: -15px;
    height: 20px;
    width: 20px; }
  .TCOP .trims-carousel .slick-next {
    right: -15px;
    height: 20px;
    width: 20px; }

.TCOP .empty-trims-carousel {
  text-align: center;
  font-family: "ToyotaType-Bold", sans-serif;
  font-size: 22px; }

.TCOP .COP-trim-selector {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #252525;
  color: #FFFFFF;
  font: 16px bold "ToyotaType-Regular", sans-serif;
  padding: 8px 4px;
  text-align: center;
  margin-bottom: 10px; }
  .TCOP .COP-trim-selector::before, .TCOP .COP-trim-selector::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .TCOP .COP-trim-selector::after {
    clear: both; }
  .TCOP .COP-trim-selector.mobile {
    text-align: left; }
  .TCOP .COP-trim-selector .cop-select-trim {
    display: inline-block;
    font-family: "ToyotaType-Light", sans-serif; }
    .TCOP .COP-trim-selector .cop-select-trim .cop-select-trim-name {
      display: inline-block;
      color: #FFFFFF;
      font-weight: bold;
      margin: 0 2px; }
      .TCOP .COP-trim-selector .cop-select-trim .cop-select-trim-name:hover {
        color: #EB0A1E;
        cursor: pointer; }
      .TCOP .COP-trim-selector .cop-select-trim .cop-select-trim-name.selected {
        color: #EB0A1E; }
    .TCOP .COP-trim-selector .cop-select-trim .delimiter {
      display: inline-block;
      margin: 0 5px; }
  .TCOP .COP-trim-selector .swipeable-container {
    overflow: hidden;
    width: 100%;
    display: inline-block; }
    .TCOP .COP-trim-selector .swipeable-container .swipeable-content {
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      box-sizing: border-box;
      -webkit-box-sizing: border-box; }

.TCOP .section-title {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  font-size: 24px;
  line-height: 100%;
  font-family: "ToyotaType-Bold", sans-serif;
  margin-bottom: 10px; }
  .TCOP .section-title::before, .TCOP .section-title::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .TCOP .section-title::after {
    clear: both; }
  .TCOP .section-title span {
    color: #EB0A1E; }

.TCOP .color-section {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .TCOP .color-section::before, .TCOP .color-section::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .TCOP .color-section::after {
    clear: both; }
  .TCOP .color-section .int-ext-title {
    font-size: 22px;
    line-height: 100%;
    font-family: "ToyotaType-Bold", sans-serif;
    margin-bottom: 10px;
    position: relative; }
    .TCOP .color-section .int-ext-title:after {
      content: "";
      background: #EB0A1E;
      position: absolute;
      bottom: -16px;
      left: 9px;
      height: 20px;
      width: 2px;
      -ms-transform: rotate(90deg);
      /* IE 9 */
      -webkit-transform: rotate(90deg);
      /* Chrome, Safari, Opera */
      transform: rotate(90deg); }
  .TCOP .color-section .exterior {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    width: 100%;
    float: left;
    padding-left: 0;
    font-family: "ToyotaType-Bold", sans-serif; }
    @media print, screen and (min-width: 40em) {
      .TCOP .color-section .exterior {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .TCOP .color-section .exterior, .TCOP .color-section .exterior:last-child:not(:first-child) {
      float: left;
      clear: none; }
    .TCOP .color-section .exterior:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .TCOP .color-section .exterior {
        width: 75%;
        border-right: 1px dotted #000;
        padding-left: 0; } }
    .TCOP .color-section .exterior .ext-color-name {
      font-family: "ToyotaType-Light", sans-serif;
      font-size: 12px; }
    .TCOP .color-section .exterior .color-swatches {
      text-align: center; }
      .TCOP .color-section .exterior .color-swatches .swatch:hover {
        cursor: pointer; }
      .TCOP .color-section .exterior .color-swatches .swatch {
        position: relative;
        display: inline-block;
        margin: 1% 1%;
        width: 35px;
        height: 35px; }
        .TCOP .color-section .exterior .color-swatches .swatch.dark-accents.color-available {
          border: 1px solid #B3B3B3; }
          .TCOP .color-section .exterior .color-swatches .swatch.dark-accents.color-available .color-selected {
            position: absolute;
            border: none;
            left: 0; }
            .TCOP .color-section .exterior .color-swatches .swatch.dark-accents.color-available .color-selected:after {
              /*Add another block-level blank space*/
              content: "";
              display: block;
              /*Make it a small rectangle so the border will create an L-shape*/
              width: 11px;
              height: 20px;
              /*Add a white border on the bottom and left, creating that 'L' */
              border: solid #B3B3B3;
              border-width: 0 1px 1px 0;
              margin: 5px 12px;
              /*Rotate the L 45 degrees to turn it into a checkmark*/
              transform: rotate(45deg); }
        .TCOP .color-section .exterior .color-swatches .swatch.dark-accents .color-unavailable {
          position: absolute;
          display: inline-block;
          width: 34px;
          height: 34px;
          overflow: hidden;
          margin: 0;
          left: 0;
          border: 1px solid #B3B3B3; }
          .TCOP .color-section .exterior .color-swatches .swatch.dark-accents .color-unavailable::before, .TCOP .color-section .exterior .color-swatches .swatch.dark-accents .color-unavailable::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 49px;
            top: 50%;
            left: -8px;
            background: #B3B3B3; }
          .TCOP .color-section .exterior .color-swatches .swatch.dark-accents .color-unavailable::before {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg); }
          .TCOP .color-section .exterior .color-swatches .swatch.dark-accents .color-unavailable::after {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg); }
        .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) {
          border: 1px solid #FFFFFF; }
          .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) .color-selected {
            position: absolute;
            border: none;
            left: 0; }
            .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) .color-selected:after {
              /*Add another block-level blank space*/
              content: "";
              display: block;
              /*Make it a small rectangle so the border will create an L-shape*/
              width: 11px;
              height: 20px;
              /*Add a white border on the bottom and left, creating that 'L' */
              border: solid #eee;
              border-width: 0 1px 1px 0;
              margin: 5px 12px;
              /*Rotate the L 45 degrees to turn it into a checkmark*/
              transform: rotate(45deg); }
          .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) .color-unavailable {
            position: absolute;
            display: inline-block;
            width: 34px;
            height: 34px;
            overflow: hidden;
            margin: 0;
            left: 0;
            border: 1px solid #eee; }
            .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) .color-unavailable::before, .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) .color-unavailable::after {
              content: "";
              position: absolute;
              height: 1px;
              width: 49px;
              top: 50%;
              left: -8px;
              background: #eee; }
            .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) .color-unavailable::before {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg); }
            .TCOP .color-section .exterior .color-swatches .swatch:not(.dark-accents) .color-unavailable::after {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .TCOP .color-section .interior {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    width: 100%;
    float: left;
    margin: 10px;
    font-size: 14px; }
    @media print, screen and (min-width: 40em) {
      .TCOP .color-section .interior {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .TCOP .color-section .interior, .TCOP .color-section .interior:last-child:not(:first-child) {
      float: left;
      clear: none; }
    .TCOP .color-section .interior:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .TCOP .color-section .interior {
        width: 25%;
        margin: 0; } }
    .TCOP .color-section .interior .unavailable {
      color: #B3B3B3; }

.TCOP .options-packages-list {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px solid #252525;
  padding-top: 10px;
  margin: 15px auto; }
  .TCOP .options-packages-list::before, .TCOP .options-packages-list::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .TCOP .options-packages-list::after {
    clear: both; }
  .TCOP .options-packages-list .list-item {
    cursor: default;
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    margin: 10px 0;
    color: #252525; }
    .TCOP .options-packages-list .list-item::before, .TCOP .options-packages-list .list-item::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .TCOP .options-packages-list .list-item::after {
      clear: both; }
    .TCOP .options-packages-list .list-item .item-image {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      width: 100%;
      float: left;
      padding-left: 0; }
      @media print, screen and (min-width: 40em) {
        .TCOP .options-packages-list .list-item .item-image {
          padding-right: 0.9375rem;
          padding-left: 0.9375rem; } }
      .TCOP .options-packages-list .list-item .item-image, .TCOP .options-packages-list .list-item .item-image:last-child:not(:first-child) {
        float: left;
        clear: none; }
      .TCOP .options-packages-list .list-item .item-image:last-child:not(:first-child) {
        float: right; }
      @media print, screen and (min-width: 40em) {
        .TCOP .options-packages-list .list-item .item-image {
          width: 25%;
          padding-left: 0; } }
    .TCOP .options-packages-list .list-item .item-info {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      width: 100%;
      float: left;
      padding-left: 0; }
      @media print, screen and (min-width: 40em) {
        .TCOP .options-packages-list .list-item .item-info {
          padding-right: 0.9375rem;
          padding-left: 0.9375rem; } }
      .TCOP .options-packages-list .list-item .item-info, .TCOP .options-packages-list .list-item .item-info:last-child:not(:first-child) {
        float: left;
        clear: none; }
      .TCOP .options-packages-list .list-item .item-info:last-child:not(:first-child) {
        float: right; }
      @media print, screen and (min-width: 40em) {
        .TCOP .options-packages-list .list-item .item-info {
          width: 75%;
          padding-left: 0; } }
      .TCOP .options-packages-list .list-item .item-info p {
        margin-top: 10px;
        font-size: 14px; }
