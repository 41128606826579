@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.countdown-container {
    display: flex;
    flex-direction: column;
    color: $primary-white;
    position: absolute;
    left: 10%;
    bottom: 5%;

    @include breakpoint(917px down) {
        left: 50%;
        transform: translateX(-50%);
    }

    p {
        font: $h2-bold;
        z-index: 1;
        margin-bottom: 0;
        margin-left: 42px;

        @include breakpoint(1504px down) {
            font: $h4-semibold;
            margin-left: 20px;
        }
    }

    .rings-section {
        display: flex;
        z-index: 1;
        gap: 0px;
    }
}
