@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.tourn-dashboard {
    max-width: 1504px;
    margin: auto;
    display: flex;
    padding: 0 10px 0 10px;

    @include breakpoint(1504px down) {
        max-width: 917px;
        margin: auto;
        padding: 0 10px 0 10px;
    }

    @include breakpoint(917px down) {
        max-width: 380px;
        margin: auto;
        padding: 0 10px 0 10px;
    }

    .tourn-dashboard-content-container {
        display: grid;
        grid-template-columns: 982px 1fr;
        grid-template-rows: 260px 130px;

        @include breakpoint(1504px down) {
            grid-template-columns: 396px 480px;
            grid-template-rows: 260px 1fr;
        }

        @include breakpoint(917px down) {
            grid-template-columns: 100%;
            grid-template-rows: 195px 1fr max-content;
            gap: 12px;
        }

        gap: 22px 22px;
        width: 100%;

        .tourn-progress-container {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            min-height: 260px;
            border-radius: 10px;
            padding: 35px 73px 15px 65px;
            background: #fff;
            display: flex;
            flex-direction: column;
            gap: 41px;

            @include breakpoint(1504px down) {
                grid-column: 1 / 3;
            }

            @include breakpoint(917px down) {
                grid-column: 1 / 2;
                min-height: 195px;
                padding: 35px 23px 40px 33px;
                gap: 28px;
            }

            .progress-title {
                font: $h3-bold;

                @include breakpoint(917px down) {
                    font: $h12-bold;
                    padding-left: 12px;
                }
            }

            .round-diagram-container {
                align-content: normal;
                align-items: normal;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: normal;
                height: 100%;
                max-height: 56px;
                width: auto;
                position: relative;

                .round-node-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 11px;

                    @include breakpoint(917px down) {
                        width: 45px;
                        gap: 8px;
                    }

                    .round-circle-outer {
                        max-width: 56px;
                        width: 100%;
                        position: relative;

                        @include breakpoint(917px down) {
                            max-width: 25px;
                        }

                        .round-circle-inner {
                            border-radius: 100%;
                            position: relative;
                            width: 100%;
                            padding-top: 100%;
                        }

                        .round-check-container {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            padding: 25%;

                            .round-check {
                                max-height: 17px;
                                max-width: 20px;
                                width: 100%;
                                height: 100%;

                                @include breakpoint(917px down) {
                                    max-height: 6px;
                                    max-width: 9px;
                                }
                            }
                        }
                    }

                    .round-name {
                        font: $h4-semibold;

                        @include breakpoint(917px down) {
                            font: $font15-semibold;
                            text-align: center;
                            line-height: 1;
                        }
                    }
                }

                .round-rectangle {
                    margin: 0 -20px 0 -10px;
                    max-height: 18px;
                    width: 100%;
                    height: 100%;
                    align-self: center;
                    flex: 1;

                    @include breakpoint(917px down) {
                        align-self: unset;
                        max-height: 8px;
                        margin: 8.3px -12px 0px -12px;
                    }
                }
            }
        }

        .stats-cards {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            display: flex;
            gap: 22px;

            @include breakpoint(1504px down) {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                flex-direction: column;
            }

            @include breakpoint(917px down) {
                grid-row: 3 / 4;
                gap: 12px;
            }

            .stats-card-pair {
                display: flex;
                gap: 22px;

                @include breakpoint(917px down) {
                    gap: 12px;
                    width: 100%;
                }
            }

            .stat-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 10px;
                padding: 15px;
                background: #fff;
                flex-grow: 1;
                height: 130px;
                min-width: 196px;

                @include breakpoint(1504px down) {
                    min-width: 187px;
                }

                @include breakpoint(917px down) {
                    min-width: unset;
                    height: 104px;
                }

                .stat-title {
                    font: $font15-bold;
                }

                .stat-value {
                    font: $font50-bold;
                    color: $primary-blue-nitro;

                    @include breakpoint(917px down) {
                        font: $h9-bold;
                    }
                }
            }
        }
    }

    .round-checklist-container {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        border-radius: 10px;
        padding: 35px 41px 46px 57px;
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;

        @include breakpoint(1504px down) {
            grid-column: 2 / 3;
            grid-row: 2 / 5;
            max-height: 434px;
        }

        @include breakpoint(917px down) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            padding: 30px 37px 40px 40px;
            max-height: unset;
        }

        .checklist-title {
            font: $h3-bold;

            @include breakpoint(917px down) {
                font: $h12-bold;
            }
        }

        .checklist-content {
            display: flex;
            flex-direction: column;
            gap: 29px;
            justify-content: space-between;
            overflow-y: auto;

            @include breakpoint(917px down) {
                overflow-y: unset;
            }

            .checklist-line-item {
                display: flex;
                align-items: center;
                position: relative;

                .checklist-circle {
                    display: flex;
                    flex-grow: 0;
                    flex-shrink: 0;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    border: 1px solid #c9c9c9;
                    &.complete {
                        border: none;
                        background: $primary-blue-nitro;
                    }
                }

                .check {
                    display: flex;
                    height: 8px;
                    width: 12px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    position: absolute;
                    margin-left: 9px;
                }

                .line-item-text {
                    margin-left: 18px;
                    text-wrap: wrap;

                    .mission-number {
                        white-space: pre;
                        font: $p5-bold;
                    }

                    .mission-title {
                        font: $p5-reg;
                    }
                }
            }
        }
    }
}
