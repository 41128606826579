@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.ev-matchmaker-container {
  .beyond-zero-ev-matchmaker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    header {
      width: 100%;
      height: 425px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: var(--desktop-banner);
  
      @include breakpoint($lg-tablet down) {
        background-image: var(--tablet-banner);
      }
  
      @include breakpoint($xl-mobile down) {
        background-image: var(--mobile-banner);
        height: 245px;
      }
    }
  
    .beyond-zero-ev-matchmaker-container {
      max-width: 576px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 50px;
      margin-bottom: 200px;
  
      h1 {
        font: $font50-bold;
        color: $dark-black;
        margin-top: 76px;
        margin-bottom: 20px;
  
        @include breakpoint($xl-mobile down) {
          font: $font40-bold;
          margin-bottom: 23px;
        }
      }
  
      p {
        font: $h12-reg;
        color: $dark-black;
        margin-bottom: 14px;
  
        @include breakpoint($xl-mobile down) {
          font: $p14-reg;
        }
      }
  
      small {
        font: $p5-reg;
        color: $dark-black;
        margin-bottom: 65px;
      }
      
      .beyond-zero-ev-matchmaker-find-match-button {
        font: $h4-bold;
      }
    }
  }
}

.ev-matchmaker-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
