/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content, .features-and-specs-page {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content, .features-and-specs-page {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title, .features-and-specs-page .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title, .features-and-specs-page .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title, .features-and-specs-page .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p, .features-and-specs-page p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3, .features-and-specs-page h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

@media print, screen and (min-width: 40em) {
  .comparison-border {
    border-right: 1px dotted #808080;
    margin-right: 5.0%;
    padding-right: 40px; }
    .comparison-border .item-description {
      border-right: 1px dotted #808080; } }

.features-and-specs-page .features-and-specs {
  position: relative;
  margin-bottom: 3%; }
  .features-and-specs-page .features-and-specs .align-left {
    justify-content: flex-start; }
  .features-and-specs-page .features-and-specs .align-right {
    justify-content: flex-end; }
  .features-and-specs-page .features-and-specs .align-center {
    justify-content: center; }
  .features-and-specs-page .features-and-specs .align-justify {
    justify-content: space-between; }
  .features-and-specs-page .features-and-specs .align-spaced {
    justify-content: space-around; }
  .features-and-specs-page .features-and-specs .align-left.vertical.menu > li > a {
    justify-content: flex-start; }
  .features-and-specs-page .features-and-specs .align-right.vertical.menu > li > a {
    justify-content: flex-end; }
  .features-and-specs-page .features-and-specs .align-center.vertical.menu > li > a {
    justify-content: center; }
  .features-and-specs-page .features-and-specs .align-top {
    align-items: flex-start; }
  .features-and-specs-page .features-and-specs .align-self-top {
    align-self: flex-start; }
  .features-and-specs-page .features-and-specs .align-bottom {
    align-items: flex-end; }
  .features-and-specs-page .features-and-specs .align-self-bottom {
    align-self: flex-end; }
  .features-and-specs-page .features-and-specs .align-middle {
    align-items: center; }
  .features-and-specs-page .features-and-specs .align-self-middle {
    align-self: center; }
  .features-and-specs-page .features-and-specs .align-stretch {
    align-items: stretch; }
  .features-and-specs-page .features-and-specs .align-self-stretch {
    align-self: stretch; }
  .features-and-specs-page .features-and-specs .align-center-middle {
    justify-content: center;
    align-items: center;
    align-content: center; }
  .features-and-specs-page .features-and-specs .small-order-1 {
    order: 1; }
  .features-and-specs-page .features-and-specs .small-order-2 {
    order: 2; }
  .features-and-specs-page .features-and-specs .small-order-3 {
    order: 3; }
  .features-and-specs-page .features-and-specs .small-order-4 {
    order: 4; }
  .features-and-specs-page .features-and-specs .small-order-5 {
    order: 5; }
  .features-and-specs-page .features-and-specs .small-order-6 {
    order: 6; }
  @media print, screen and (min-width: 40em) {
    .features-and-specs-page .features-and-specs .medium-order-1 {
      order: 1; }
    .features-and-specs-page .features-and-specs .medium-order-2 {
      order: 2; }
    .features-and-specs-page .features-and-specs .medium-order-3 {
      order: 3; }
    .features-and-specs-page .features-and-specs .medium-order-4 {
      order: 4; }
    .features-and-specs-page .features-and-specs .medium-order-5 {
      order: 5; }
    .features-and-specs-page .features-and-specs .medium-order-6 {
      order: 6; } }
  @media print, screen and (min-width: 64em) {
    .features-and-specs-page .features-and-specs .large-order-1 {
      order: 1; }
    .features-and-specs-page .features-and-specs .large-order-2 {
      order: 2; }
    .features-and-specs-page .features-and-specs .large-order-3 {
      order: 3; }
    .features-and-specs-page .features-and-specs .large-order-4 {
      order: 4; }
    .features-and-specs-page .features-and-specs .large-order-5 {
      order: 5; }
    .features-and-specs-page .features-and-specs .large-order-6 {
      order: 6; } }
  .features-and-specs-page .features-and-specs .flex-container {
    display: flex; }
  .features-and-specs-page .features-and-specs .flex-child-auto {
    flex: 1 1 auto; }
  .features-and-specs-page .features-and-specs .flex-child-grow {
    flex: 1 0 auto; }
  .features-and-specs-page .features-and-specs .flex-child-shrink {
    flex: 0 1 auto; }
  .features-and-specs-page .features-and-specs .flex-dir-row {
    flex-direction: row; }
  .features-and-specs-page .features-and-specs .flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .features-and-specs-page .features-and-specs .flex-dir-column {
    flex-direction: column; }
  .features-and-specs-page .features-and-specs .flex-dir-column-reverse {
    flex-direction: column-reverse; }
  @media print, screen and (min-width: 40em) {
    .features-and-specs-page .features-and-specs .medium-flex-container {
      display: flex; }
    .features-and-specs-page .features-and-specs .medium-flex-child-auto {
      flex: 1 1 auto; }
    .features-and-specs-page .features-and-specs .medium-flex-child-grow {
      flex: 1 0 auto; }
    .features-and-specs-page .features-and-specs .medium-flex-child-shrink {
      flex: 0 1 auto; }
    .features-and-specs-page .features-and-specs .medium-flex-dir-row {
      flex-direction: row; }
    .features-and-specs-page .features-and-specs .medium-flex-dir-row-reverse {
      flex-direction: row-reverse; }
    .features-and-specs-page .features-and-specs .medium-flex-dir-column {
      flex-direction: column; }
    .features-and-specs-page .features-and-specs .medium-flex-dir-column-reverse {
      flex-direction: column-reverse; } }
  @media print, screen and (min-width: 64em) {
    .features-and-specs-page .features-and-specs .large-flex-container {
      display: flex; }
    .features-and-specs-page .features-and-specs .large-flex-child-auto {
      flex: 1 1 auto; }
    .features-and-specs-page .features-and-specs .large-flex-child-grow {
      flex: 1 0 auto; }
    .features-and-specs-page .features-and-specs .large-flex-child-shrink {
      flex: 0 1 auto; }
    .features-and-specs-page .features-and-specs .large-flex-dir-row {
      flex-direction: row; }
    .features-and-specs-page .features-and-specs .large-flex-dir-row-reverse {
      flex-direction: row-reverse; }
    .features-and-specs-page .features-and-specs .large-flex-dir-column {
      flex-direction: column; }
    .features-and-specs-page .features-and-specs .large-flex-dir-column-reverse {
      flex-direction: column-reverse; } }
  .features-and-specs-page .features-and-specs .row {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap; }
    .features-and-specs-page .features-and-specs .row .row {
      margin-right: -0.625rem;
      margin-left: -0.625rem; }
      @media print, screen and (min-width: 40em) {
        .features-and-specs-page .features-and-specs .row .row {
          margin-right: -0.9375rem;
          margin-left: -0.9375rem; } }
      @media print, screen and (min-width: 64em) {
        .features-and-specs-page .features-and-specs .row .row {
          margin-right: -0.9375rem;
          margin-left: -0.9375rem; } }
      .features-and-specs-page .features-and-specs .row .row.collapse {
        margin-right: 0;
        margin-left: 0; }
    .features-and-specs-page .features-and-specs .row.expanded {
      max-width: none; }
      .features-and-specs-page .features-and-specs .row.expanded .row {
        margin-right: auto;
        margin-left: auto; }
    .features-and-specs-page .features-and-specs .row:not(.expanded) .row {
      max-width: none; }
    .features-and-specs-page .features-and-specs .row.collapse > .column, .features-and-specs-page .features-and-specs .row.collapse > .columns {
      padding-right: 0;
      padding-left: 0; }
    .features-and-specs-page .features-and-specs .row.is-collapse-child,
    .features-and-specs-page .features-and-specs .row.collapse > .column > .row,
    .features-and-specs-page .features-and-specs .row.collapse > .columns > .row {
      margin-right: 0;
      margin-left: 0; }
  .features-and-specs-page .features-and-specs .column, .features-and-specs-page .features-and-specs .columns {
    flex: 1 1 0px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    min-width: 0; }
    @media print, screen and (min-width: 40em) {
      .features-and-specs-page .features-and-specs .column, .features-and-specs-page .features-and-specs .columns {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .features-and-specs-page .features-and-specs .column.row.row, .features-and-specs-page .features-and-specs .row.row.columns {
    float: none;
    display: block; }
  .features-and-specs-page .features-and-specs .row .column.row.row, .features-and-specs-page .features-and-specs .row .row.row.columns {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0; }
  .features-and-specs-page .features-and-specs .small-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .features-and-specs-page .features-and-specs .small-offset-0 {
    margin-left: 0%; }
  .features-and-specs-page .features-and-specs .small-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .features-and-specs-page .features-and-specs .small-offset-1 {
    margin-left: 8.33333%; }
  .features-and-specs-page .features-and-specs .small-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .features-and-specs-page .features-and-specs .small-offset-2 {
    margin-left: 16.66667%; }
  .features-and-specs-page .features-and-specs .small-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .features-and-specs-page .features-and-specs .small-offset-3 {
    margin-left: 25%; }
  .features-and-specs-page .features-and-specs .small-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .features-and-specs-page .features-and-specs .small-offset-4 {
    margin-left: 33.33333%; }
  .features-and-specs-page .features-and-specs .small-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .features-and-specs-page .features-and-specs .small-offset-5 {
    margin-left: 41.66667%; }
  .features-and-specs-page .features-and-specs .small-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .features-and-specs-page .features-and-specs .small-offset-6 {
    margin-left: 50%; }
  .features-and-specs-page .features-and-specs .small-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .features-and-specs-page .features-and-specs .small-offset-7 {
    margin-left: 58.33333%; }
  .features-and-specs-page .features-and-specs .small-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .features-and-specs-page .features-and-specs .small-offset-8 {
    margin-left: 66.66667%; }
  .features-and-specs-page .features-and-specs .small-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .features-and-specs-page .features-and-specs .small-offset-9 {
    margin-left: 75%; }
  .features-and-specs-page .features-and-specs .small-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .features-and-specs-page .features-and-specs .small-offset-10 {
    margin-left: 83.33333%; }
  .features-and-specs-page .features-and-specs .small-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .features-and-specs-page .features-and-specs .small-offset-11 {
    margin-left: 91.66667%; }
  .features-and-specs-page .features-and-specs .small-up-1 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-1 > .column, .features-and-specs-page .features-and-specs .small-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .features-and-specs-page .features-and-specs .small-up-2 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-2 > .column, .features-and-specs-page .features-and-specs .small-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .features-and-specs-page .features-and-specs .small-up-3 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-3 > .column, .features-and-specs-page .features-and-specs .small-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .features-and-specs-page .features-and-specs .small-up-4 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-4 > .column, .features-and-specs-page .features-and-specs .small-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .features-and-specs-page .features-and-specs .small-up-5 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-5 > .column, .features-and-specs-page .features-and-specs .small-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .features-and-specs-page .features-and-specs .small-up-6 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-6 > .column, .features-and-specs-page .features-and-specs .small-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .features-and-specs-page .features-and-specs .small-up-7 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-7 > .column, .features-and-specs-page .features-and-specs .small-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .features-and-specs-page .features-and-specs .small-up-8 {
    flex-wrap: wrap; }
    .features-and-specs-page .features-and-specs .small-up-8 > .column, .features-and-specs-page .features-and-specs .small-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; }
  .features-and-specs-page .features-and-specs .small-collapse > .column, .features-and-specs-page .features-and-specs .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .features-and-specs-page .features-and-specs .small-uncollapse > .column, .features-and-specs-page .features-and-specs .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .features-and-specs-page .features-and-specs .medium-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .features-and-specs-page .features-and-specs .medium-offset-0 {
      margin-left: 0%; }
    .features-and-specs-page .features-and-specs .medium-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .features-and-specs-page .features-and-specs .medium-offset-1 {
      margin-left: 8.33333%; }
    .features-and-specs-page .features-and-specs .medium-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .features-and-specs-page .features-and-specs .medium-offset-2 {
      margin-left: 16.66667%; }
    .features-and-specs-page .features-and-specs .medium-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .features-and-specs-page .features-and-specs .medium-offset-3 {
      margin-left: 25%; }
    .features-and-specs-page .features-and-specs .medium-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .features-and-specs-page .features-and-specs .medium-offset-4 {
      margin-left: 33.33333%; }
    .features-and-specs-page .features-and-specs .medium-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .features-and-specs-page .features-and-specs .medium-offset-5 {
      margin-left: 41.66667%; }
    .features-and-specs-page .features-and-specs .medium-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .features-and-specs-page .features-and-specs .medium-offset-6 {
      margin-left: 50%; }
    .features-and-specs-page .features-and-specs .medium-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .features-and-specs-page .features-and-specs .medium-offset-7 {
      margin-left: 58.33333%; }
    .features-and-specs-page .features-and-specs .medium-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .features-and-specs-page .features-and-specs .medium-offset-8 {
      margin-left: 66.66667%; }
    .features-and-specs-page .features-and-specs .medium-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .features-and-specs-page .features-and-specs .medium-offset-9 {
      margin-left: 75%; }
    .features-and-specs-page .features-and-specs .medium-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .features-and-specs-page .features-and-specs .medium-offset-10 {
      margin-left: 83.33333%; }
    .features-and-specs-page .features-and-specs .medium-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .features-and-specs-page .features-and-specs .medium-offset-11 {
      margin-left: 91.66667%; }
    .features-and-specs-page .features-and-specs .medium-up-1 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-1 > .column, .features-and-specs-page .features-and-specs .medium-up-1 > .columns {
        flex: 0 0 100%;
        max-width: 100%; }
    .features-and-specs-page .features-and-specs .medium-up-2 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-2 > .column, .features-and-specs-page .features-and-specs .medium-up-2 > .columns {
        flex: 0 0 50%;
        max-width: 50%; }
    .features-and-specs-page .features-and-specs .medium-up-3 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-3 > .column, .features-and-specs-page .features-and-specs .medium-up-3 > .columns {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .features-and-specs-page .features-and-specs .medium-up-4 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-4 > .column, .features-and-specs-page .features-and-specs .medium-up-4 > .columns {
        flex: 0 0 25%;
        max-width: 25%; }
    .features-and-specs-page .features-and-specs .medium-up-5 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-5 > .column, .features-and-specs-page .features-and-specs .medium-up-5 > .columns {
        flex: 0 0 20%;
        max-width: 20%; }
    .features-and-specs-page .features-and-specs .medium-up-6 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-6 > .column, .features-and-specs-page .features-and-specs .medium-up-6 > .columns {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .features-and-specs-page .features-and-specs .medium-up-7 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-7 > .column, .features-and-specs-page .features-and-specs .medium-up-7 > .columns {
        flex: 0 0 14.28571%;
        max-width: 14.28571%; }
    .features-and-specs-page .features-and-specs .medium-up-8 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .medium-up-8 > .column, .features-and-specs-page .features-and-specs .medium-up-8 > .columns {
        flex: 0 0 12.5%;
        max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .features-and-specs-page .features-and-specs .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .features-and-specs-page .features-and-specs .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .features-and-specs-page .features-and-specs .row.medium-unstack > .columns {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 40em) {
    .features-and-specs-page .features-and-specs .medium-collapse > .column, .features-and-specs-page .features-and-specs .medium-collapse > .columns {
      padding-right: 0;
      padding-left: 0; }
    .features-and-specs-page .features-and-specs .medium-uncollapse > .column, .features-and-specs-page .features-and-specs .medium-uncollapse > .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 64em) {
    .features-and-specs-page .features-and-specs .large-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .features-and-specs-page .features-and-specs .large-offset-0 {
      margin-left: 0%; }
    .features-and-specs-page .features-and-specs .large-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .features-and-specs-page .features-and-specs .large-offset-1 {
      margin-left: 8.33333%; }
    .features-and-specs-page .features-and-specs .large-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .features-and-specs-page .features-and-specs .large-offset-2 {
      margin-left: 16.66667%; }
    .features-and-specs-page .features-and-specs .large-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .features-and-specs-page .features-and-specs .large-offset-3 {
      margin-left: 25%; }
    .features-and-specs-page .features-and-specs .large-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .features-and-specs-page .features-and-specs .large-offset-4 {
      margin-left: 33.33333%; }
    .features-and-specs-page .features-and-specs .large-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .features-and-specs-page .features-and-specs .large-offset-5 {
      margin-left: 41.66667%; }
    .features-and-specs-page .features-and-specs .large-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .features-and-specs-page .features-and-specs .large-offset-6 {
      margin-left: 50%; }
    .features-and-specs-page .features-and-specs .large-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .features-and-specs-page .features-and-specs .large-offset-7 {
      margin-left: 58.33333%; }
    .features-and-specs-page .features-and-specs .large-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .features-and-specs-page .features-and-specs .large-offset-8 {
      margin-left: 66.66667%; }
    .features-and-specs-page .features-and-specs .large-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .features-and-specs-page .features-and-specs .large-offset-9 {
      margin-left: 75%; }
    .features-and-specs-page .features-and-specs .large-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .features-and-specs-page .features-and-specs .large-offset-10 {
      margin-left: 83.33333%; }
    .features-and-specs-page .features-and-specs .large-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .features-and-specs-page .features-and-specs .large-offset-11 {
      margin-left: 91.66667%; }
    .features-and-specs-page .features-and-specs .large-up-1 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-1 > .column, .features-and-specs-page .features-and-specs .large-up-1 > .columns {
        flex: 0 0 100%;
        max-width: 100%; }
    .features-and-specs-page .features-and-specs .large-up-2 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-2 > .column, .features-and-specs-page .features-and-specs .large-up-2 > .columns {
        flex: 0 0 50%;
        max-width: 50%; }
    .features-and-specs-page .features-and-specs .large-up-3 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-3 > .column, .features-and-specs-page .features-and-specs .large-up-3 > .columns {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .features-and-specs-page .features-and-specs .large-up-4 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-4 > .column, .features-and-specs-page .features-and-specs .large-up-4 > .columns {
        flex: 0 0 25%;
        max-width: 25%; }
    .features-and-specs-page .features-and-specs .large-up-5 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-5 > .column, .features-and-specs-page .features-and-specs .large-up-5 > .columns {
        flex: 0 0 20%;
        max-width: 20%; }
    .features-and-specs-page .features-and-specs .large-up-6 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-6 > .column, .features-and-specs-page .features-and-specs .large-up-6 > .columns {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .features-and-specs-page .features-and-specs .large-up-7 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-7 > .column, .features-and-specs-page .features-and-specs .large-up-7 > .columns {
        flex: 0 0 14.28571%;
        max-width: 14.28571%; }
    .features-and-specs-page .features-and-specs .large-up-8 {
      flex-wrap: wrap; }
      .features-and-specs-page .features-and-specs .large-up-8 > .column, .features-and-specs-page .features-and-specs .large-up-8 > .columns {
        flex: 0 0 12.5%;
        max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .features-and-specs-page .features-and-specs .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .features-and-specs-page .features-and-specs .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .features-and-specs-page .features-and-specs .row.large-unstack > .columns {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .features-and-specs-page .features-and-specs .large-collapse > .column, .features-and-specs-page .features-and-specs .large-collapse > .columns {
      padding-right: 0;
      padding-left: 0; }
    .features-and-specs-page .features-and-specs .large-uncollapse > .column, .features-and-specs-page .features-and-specs .large-uncollapse > .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .features-and-specs-page .features-and-specs .shrink {
    flex: 0 0 auto;
    max-width: 100%; }
  .features-and-specs-page .features-and-specs .column-block {
    margin-bottom: 1.25rem; }
    .features-and-specs-page .features-and-specs .column-block > :last-child {
      margin-bottom: 0; }
    @media print, screen and (min-width: 40em) {
      .features-and-specs-page .features-and-specs .column-block {
        margin-bottom: 1.875rem; }
        .features-and-specs-page .features-and-specs .column-block > :last-child {
          margin-bottom: 0; } }

.vehicle-selection-skeleton-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20%;
  margin-top: 40px; }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .vehicle-selection-skeleton-container {
      padding-right: 10%; } }
  @media print, screen and (max-width: 39.99875em) {
    .vehicle-selection-skeleton-container {
      justify-content: center;
      padding-right: 0%; } }
  .vehicle-selection-skeleton-container .skeleton-blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 50%; }
    @media print, screen and (max-width: 39.99875em) {
      .vehicle-selection-skeleton-container .skeleton-blocks {
        margin: auto; } }
  .vehicle-selection-skeleton-container .image-placeholder {
    height: 170px;
    width: 400px; }

.accordion-skeleton-container {
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  gap: 5px; }
