@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.competitor-selection-v2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 70px;
  
  @include breakpoint(medium down) {
    justify-content: space-between;
    gap: unset;
  }

  .comparison-vehicle-container {
    display: flex;
    flex-direction: column;
    width: 323px;
    height: auto;

    @include breakpoint(medium down){
      width: 50%;
    }

    .comparison-image-container {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;

      img {
        max-height: 138px;
        width: auto;
      }

      @include breakpoint(medium) {
        margin-bottom: 15px;
      }

      @include breakpoint(small only) {
        margin-bottom: 9px;
      }
    }
    .comparison-title {
      display: flex;
      padding-bottom: 10px;
      text-wrap: wrap;
      justify-content: center;
      align-items: baseline;
      flex-wrap: wrap;

      @media print {
        padding-bottom: 5px;
      }

      .comparison-year {
        font: $h3-book;
        text-align: center;
        width: 100%;
        @include breakpoint(medium) {
          font: $font25-book;
        }

        @include breakpoint(small only) {
          font: $h2-book;
        }
        padding-right: 5px;

        @media print {
          font: $p10-book;
        }
      }

      .comparison-model {
        font: $h3-bold;
        text-align: center;

        @include breakpoint(medium) {
          font: $font25-bold;
        }

        @include breakpoint(small only) {
          font: $h2-bold;
        }
        
        @media print {
          font: $p10-semibold;
        }
      }
    }
    .comparison-trim {
      justify-content: center;
      text-align: center;

      font: $h2-semibold;

      @include breakpoint(medium) {
        font: $h2-semibold;
      }

      @include breakpoint(small only) {
        font: $font15-semibold;
      }

      @media print {
        font: $p10-semibold;
      }
    }
  }
}