@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.power-of-choice-section {
  background: transparent linear-gradient(180deg, #001E5A 0%, #001E46 100%) 0% 0% no-repeat padding-box;
  max-width: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;

  @include breakpoint(large up) {
    padding: 80px 62px 91px 62px;
  }

  @include breakpoint(medium only) {
    padding: 70px 62px 80px 62px;
  }

  @include breakpoint (small only) {
    padding: 50px 34px 80px 34px;
  }

  & > span {
    font: $font35-semibold;
    color: $primary-white;
    width: 100%;
    max-width: 1421px;
    margin-bottom: 56px;

    @include breakpoint(medium down) {
      font: $h6-semibold;
      margin-bottom: 60px;
    }

    @include breakpoint(small down) {
      font: $font25-semibold;
      margin-bottom: 50px;
    }
  }

  & > .tabs-section {
    width: 100%;
    max-width: 900px;
  }

  & > section.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: transparent;
    gap: 70px;
    padding-top: 84px;
    max-width: 1421px;

    @include breakpoint(medium down) {
      flex-direction: column;
      padding-top: 42px;
      gap: 40px;
    }

    @include breakpoint(small down) {
      padding-top: 36px;
      gap: 30px;
    }

    & > img {
      display: block;
      width: 59%;
      height: auto;
      object-fit: contain;

      @include breakpoint(medium down) {
        width: 100%;
      }
    }

    & > section {
      display: flex;
      flex-direction: column;
      color: $primary-white;

      & > img {
        margin-bottom: 15px;
        margin-top: 15px;
        width: 80px;
        height: 80px;

        @include breakpoint(medium down) {
          width: 70px;
          height: 70px;
          margin-top: 0px;
        }

        @include breakpoint(small down) {
          width: 53px;
          height: 53px;
          margin-bottom: 28px;
          margin-top: 0px;
        }
      }

      & > h2 {
        font: $font40-bold;

        @include breakpoint(medium down) {
          font: $font35-bold;
          margin-bottom: 12px;
        }

        @include breakpoint(small down) {
          font: $h6-bold;
          margin-bottom: 13px;
        }
      }

      & > p {
        font: $h12-reg;
        line-height: 30px;
        margin-bottom: unset;

        @include breakpoint(medium down) {
          font: $h2-reg;
          line-height: 32px;
        }

        @include breakpoint(small down) {
          font: $font15-reg;
          line-height: 27px;
        }
      }
    }
  }
}
