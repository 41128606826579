/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.engage-xp-missions .header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 0 50px 0;
  row-gap: 5px;
  max-width: 1484px;
  margin: auto; }
  @media screen and (max-width: 99.6875em) {
    .engage-xp-missions .header-section {
      max-width: 897px; } }
  @media screen and (max-width: 56.5625em) {
    .engage-xp-missions .header-section {
      max-width: 352px;
      padding: 47px 0 52px 0; } }
  .engage-xp-missions .header-section .header-title {
    font: 50px "ToyotaType-Bold", sans-serif; }
    @media screen and (max-width: 56.5625em) {
      .engage-xp-missions .header-section .header-title {
        font: 35px "ToyotaType-Bold", sans-serif; } }
  .engage-xp-missions .header-section .header-subtitle {
    font: 24px "ToyotaType-Semibold", sans-serif; }
  .engage-xp-missions .header-section .header-description {
    font: 20px "ToyotaType-Regular", sans-serif; }

.engage-xp-missions .missions-section {
  background-color: #f4f4f4;
  margin: auto; }
  .engage-xp-missions .missions-section .missions-top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 63px 50px 63px;
    max-width: 1596px;
    margin: auto;
    gap: 119px; }
    @media screen and (max-width: 99.6875em) {
      .engage-xp-missions .missions-section .missions-top-section {
        max-width: 1024px;
        gap: 28px; } }
    @media screen and (max-width: 56.5625em) {
      .engage-xp-missions .missions-section .missions-top-section {
        max-width: 428px;
        flex-direction: column;
        padding: 34px 34px 50px 34px; } }
    .engage-xp-missions .missions-section .missions-top-section .info-container {
      display: flex;
      flex-direction: column;
      gap: 15px; }
      @media screen and (max-width: 56.5625em) {
        .engage-xp-missions .missions-section .missions-top-section .info-container {
          width: 100%; } }
      .engage-xp-missions .missions-section .missions-top-section .info-container .missions-title {
        font: 40px "ToyotaType-Bold", sans-serif; }
        @media screen and (max-width: 56.5625em) {
          .engage-xp-missions .missions-section .missions-top-section .info-container .missions-title {
            font: 30px "ToyotaType-Bold", sans-serif; } }
      .engage-xp-missions .missions-section .missions-top-section .info-container .missions-subtitle {
        font: 18px "ToyotaType-Regular", sans-serif; }
    .engage-xp-missions .missions-section .missions-top-section .dropdown-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 29px; }
      @media screen and (max-width: 99.6875em) {
        .engage-xp-missions .missions-section .missions-top-section .dropdown-section {
          gap: 20px; } }
      @media screen and (max-width: 56.5625em) {
        .engage-xp-missions .missions-section .missions-top-section .dropdown-section {
          width: 100%;
          justify-content: center;
          gap: 19px; } }
      .engage-xp-missions .missions-section .missions-top-section .dropdown-section .dropdown-container {
        width: 225px; }
        @media screen and (max-width: 99.6875em) {
          .engage-xp-missions .missions-section .missions-top-section .dropdown-section .dropdown-container {
            width: 170px; } }
        .engage-xp-missions .missions-section .missions-top-section .dropdown-section .dropdown-container .dropdown-label {
          font: 17px "ToyotaType-Semibold", sans-serif;
          text-align: center;
          padding-bottom: 11px; }
  .engage-xp-missions .missions-section .missions-cards-section {
    margin: auto;
    height: auto;
    max-width: 1596px; }
    @media screen and (max-width: 99.6875em) {
      .engage-xp-missions .missions-section .missions-cards-section {
        max-width: 1024px; } }
    @media screen and (max-width: 56.5625em) {
      .engage-xp-missions .missions-section .missions-cards-section {
        max-width: 428px; } }

.engage-xp-missions .dashboard-section {
  padding: 60px 60px 30px 60px;
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 56.5625em) {
    .engage-xp-missions .dashboard-section {
      padding: 0; } }
  .engage-xp-missions .dashboard-section .top-section {
    width: 100%;
    max-width: 1484px;
    display: flex;
    flex-direction: column;
    gap: 15px; }
    @media screen and (max-width: 99.6875em) {
      .engage-xp-missions .dashboard-section .top-section {
        max-width: 897px; } }
    @media screen and (max-width: 56.5625em) {
      .engage-xp-missions .dashboard-section .top-section {
        max-width: 428px; } }
    .engage-xp-missions .dashboard-section .top-section .title {
      font: 40px "ToyotaType-Bold", sans-serif; }
      @media screen and (max-width: 56.5625em) {
        .engage-xp-missions .dashboard-section .top-section .title {
          font: 30px "ToyotaType-Bold", sans-serif;
          padding: 50px 38px 0px 38px; } }
    .engage-xp-missions .dashboard-section .top-section .description {
      font: 18px "ToyotaType-Regular", sans-serif; }
      @media screen and (max-width: 56.5625em) {
        .engage-xp-missions .dashboard-section .top-section .description {
          padding: 0px 38px 44px 38px; } }
    @media print, screen and (max-width: 39.99875em) {
      .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper {
        margin: auto;
        width: 100%;
        background-color: #F4F4F4; } }
    .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      max-width: 1484px;
      padding: 44px;
      background-color: #F4F4F4;
      margin-top: 25px; }
      @media screen and (max-width: 99.6875em) {
        .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area {
          max-width: 897px;
          padding: 37px; } }
      @media screen and (max-width: 56.5625em) {
        .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area {
          width: 428px;
          padding: 34px;
          margin-top: 0; } }
      @media print, screen and (max-width: 39.99875em) {
        .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area {
          margin: auto;
          width: 360px;
          padding-left: 0;
          padding-right: 0; } }
      .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .grid-dashboard-level-points {
        background-color: white;
        border-radius: 10px;
        width: 100%; }
      .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .dashboard-row-2 {
        display: flex;
        gap: 15px;
        width: 100%;
        height: 438px; }
        @media screen and (max-width: 99.6875em) {
          .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .dashboard-row-2 {
            flex-direction: column-reverse;
            height: auto; } }
        .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .dashboard-row-2 .row-grouping-1 {
          display: flex;
          gap: 15px;
          flex-grow: 1; }
          @media screen and (max-width: 56.5625em) {
            .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .dashboard-row-2 .row-grouping-1 {
              flex-direction: column; } }
        .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .dashboard-row-2 .row-grouping-2 {
          width: 302px; }
          @media screen and (max-width: 99.6875em) {
            .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .dashboard-row-2 .row-grouping-2 {
              width: 100%; } }
          @media screen and (max-width: 56.5625em) {
            .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .dashboard-row-2 .row-grouping-2 {
              height: 376px; } }
      .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .grid-dashboard-banner {
        background-color: white;
        border-radius: 10px;
        width: 303px; }
      .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .grid-dashboard-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        margin: auto; }
        .engage-xp-missions .dashboard-section .top-section .dashboard-wrapper .dashboard-grey-area .grid-dashboard-button button {
          width: 196px;
          height: 44px;
          border-radius: 22px;
          background-color: black;
          font: 16px "ToyotaType-Bold", sans-serif;
          color: white;
          cursor: pointer;
          padding-bottom: 3px; }

.engage-xp-missions .dashboard-info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff; }
  @media screen and (max-width: 56.5625em) {
    .engage-xp-missions .dashboard-info-card {
      flex-direction: column;
      height: 247px; } }
  .engage-xp-missions .dashboard-info-card .bar-section {
    width: 50%;
    padding: 12px 40px; }
    @media screen and (max-width: 99.6875em) {
      .engage-xp-missions .dashboard-info-card .bar-section {
        padding: 19px 10px 19px 28px; } }
    @media screen and (max-width: 56.5625em) {
      .engage-xp-missions .dashboard-info-card .bar-section {
        padding: 12px 20px;
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
  .engage-xp-missions .dashboard-info-card .points-section {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid #a3a3a380;
    padding: 12px 40px;
    gap: 10px; }
    @media screen and (max-width: 99.6875em) {
      .engage-xp-missions .dashboard-info-card .points-section {
        width: 100%;
        padding: 20px;
        justify-content: space-around; } }
    @media screen and (max-width: 56.5625em) {
      .engage-xp-missions .dashboard-info-card .points-section {
        padding: 12px 20px;
        flex-direction: column;
        align-items: flex-start;
        border-left: none;
        border-top: 1px solid #a3a3a380;
        width: 100%;
        height: 50%;
        justify-content: center;
        gap: 0px; } }
    .engage-xp-missions .dashboard-info-card .points-section .xp-points {
      font: 38px "ToyotaType-Bold", sans-serif;
      padding-bottom: 3px;
      line-height: 0.9; }
      @media screen and (max-width: 99.6875em) {
        .engage-xp-missions .dashboard-info-card .points-section .xp-points {
          font: 25px "ToyotaType-Bold", sans-serif; } }
    .engage-xp-missions .dashboard-info-card .points-section .points-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px; }
      .engage-xp-missions .dashboard-info-card .points-section .points-link .link-icon {
        width: 22px;
        height: 22px; }
      .engage-xp-missions .dashboard-info-card .points-section .points-link .link-text {
        font: 16px "ToyotaType-Semibold", sans-serif;
        color: #eb0a1e;
        padding-bottom: 3px;
        cursor: pointer; }
        @media screen and (max-width: 99.6875em) {
          .engage-xp-missions .dashboard-info-card .points-section .points-link .link-text {
            font: 15px "ToyotaType-Semibold", sans-serif;
            width: 95px; } }
        @media screen and (max-width: 56.5625em) {
          .engage-xp-missions .dashboard-info-card .points-section .points-link .link-text {
            font: 16px "ToyotaType-Semibold", sans-serif;
            width: auto; } }

.dashboard-image-card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-color: #d8d8d8;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 32px 28px 56px 28px; }
  @media screen and (max-width: 99.6875em) {
    .dashboard-image-card {
      flex-direction: row;
      padding: 14px 28px;
      align-items: center;
      height: 146px;
      background-position-x: left; } }
  @media screen and (max-width: 56.5625em) {
    .dashboard-image-card {
      flex-direction: column;
      padding-bottom: 30px;
      margin: auto;
      height: 100%;
      background-position-x: center; } }
  .dashboard-image-card .title-group {
    display: flex;
    flex-direction: column;
    gap: 10px; }
    .dashboard-image-card .title-group .image-title {
      font: 17px "ToyotaType-Regular", sans-serif; }
    .dashboard-image-card .title-group .image-large-text {
      font: 32px "ToyotaType-Bold", sans-serif;
      line-height: 0.94em;
      max-width: 250px; }
  @media screen and (max-width: 99.6875em) {
    .dashboard-image-card .image-link-text {
      width: 196px; } }
