/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.model-overview-page {
  background-repeat: no-repeat;
  width: 100%; }
  .model-overview-page .row {
    width: 100%;
    margin: 0; }
  @media print {
    .model-overview-page div {
      break-inside: avoid; } }

@media print {
  .model-overview-web-content {
    display: none; } }

@media not print {
  .model-overview-print-content {
    display: none; } }

@media print {
  .model-overview-print-content {
    margin: 10px 25px; } }

@media print {
  .model-overview-print-content.redline {
    margin-left: 0px !important;
    padding-bottom: 2px; } }

@media not print {
  .model-overview-web-content.redline {
    margin: 0px !important; } }

@media screen and (min-width: 19.375em) {
  .model-overview-web-content.redline {
    padding-bottom: 16px; } }

@media screen and (min-width: 48em) {
  .model-overview-web-content.redline {
    padding-bottom: 18px; } }

@media screen and (min-width: 64em) {
  .model-overview-web-content.redline {
    padding-bottom: 20px; } }

@media not print {
  .model-overview-print-divider {
    display: none; } }

@media print {
  .model-overview-print-divider {
    display: block;
    height: 2px;
    width: 100%;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px 0px;
    margin-bottom: 5px; } }

.row.hero-image-background {
  background-color: #252525;
  height: fit-content;
  top: 15px;
  margin-bottom: 0px;
  padding-bottom: 0px; }
  @media screen and (min-width: 64em) {
    .row.hero-image-background {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-attachment: unset; } }

@media screen and (min-width: 19.375em) {
  .hero-image-background-mobile {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center; } }

@media screen and (min-width: 48em) {
  .hero-image-background-mobile {
    min-height: 400px; } }

@media screen and (min-width: 64em) {
  .hero-image-background-mobile {
    overflow: visible;
    min-height: 0px; } }

.model-overview-title-section {
  color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0; }
  @media print {
    .model-overview-title-section {
      color: #252525;
      display: block;
      margin-bottom: 0px; } }
  @media print {
    .model-overview-title-section .lower {
      background-color: unset;
      max-width: 50%;
      display: flex;
      flex-direction: row; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .lower {
      background-color: #252525;
      height: inherit;
      width: 100%; } }
  @media screen and (min-width: 64em) {
    .model-overview-title-section .lower {
      background-color: unset; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section {
      min-height: 200px;
      flex-direction: column; } }
  @media screen and (min-width: 40em) {
    .model-overview-title-section {
      flex-direction: row;
      min-height: 275px; } }
  @media screen and (min-width: 48em) {
    .model-overview-title-section {
      min-height: 300px; } }
  @media screen and (min-width: 64em) {
    .model-overview-title-section {
      height: 350px; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section {
      height: 455px; } }
  .model-overview-title-section .headline, .model-overview-title-section .msrp, .model-overview-title-section .subheadline, .model-overview-title-section .description {
    width: 100%;
    text-align: left;
    overflow: visible;
    overflow-wrap: normal;
    position: relative;
    display: inline-flex; }
    @media screen and (min-width: 19.375em) {
      .model-overview-title-section .headline, .model-overview-title-section .msrp, .model-overview-title-section .subheadline, .model-overview-title-section .description {
        margin: 0px 25px; } }
    @media screen and (min-width: 48em) {
      .model-overview-title-section .headline, .model-overview-title-section .msrp, .model-overview-title-section .subheadline, .model-overview-title-section .description {
        margin: 0px 40px; } }
    @media screen and (min-width: 80em) {
      .model-overview-title-section .headline, .model-overview-title-section .msrp, .model-overview-title-section .subheadline, .model-overview-title-section .description {
        margin: 0px 65px; } }
    @media print {
      .model-overview-title-section .headline, .model-overview-title-section .msrp, .model-overview-title-section .subheadline, .model-overview-title-section .description {
        margin: 0px; } }
  .model-overview-title-section .headline {
    line-height: 2;
    vertical-align: top; }
    @media print {
      .model-overview-title-section .headline {
        font: 20px "ToyotaType-Regular", sans-serif; } }
    @media screen and (min-width: 19.375em) {
      .model-overview-title-section .headline {
        font: 16px "ToyotaType-Regular", sans-serif; } }
    @media screen and (min-width: 40em) {
      .model-overview-title-section .headline {
        font: 20px "ToyotaType-Regular", sans-serif; } }
    @media screen and (min-width: 48em) {
      .model-overview-title-section .headline {
        font: 24px "ToyotaType-Regular", sans-serif; } }
    @media screen and (min-width: 90em) {
      .model-overview-title-section .headline {
        font: 30px "ToyotaType-Regular", sans-serif; } }
    @media print {
      .model-overview-title-section .headline {
        font: 16px "ToyotaType-Regular", sans-serif; } }
    @media screen and (min-width: 19.375em) {
      .model-overview-title-section .headline {
        margin-top: 20px;
        flex-direction: column; } }
    @media screen and (min-width: 64em) {
      .model-overview-title-section .headline {
        height: fit-content;
        margin-top: 46px; } }
    @media screen and (min-width: 80em) {
      .model-overview-title-section .headline {
        font: 36px "ToyotaType-Light", sans-serif; } }
  @media print {
    .model-overview-title-section .title-section-model-name {
      font: 16px "ToyotaType-Semibold", sans-serif;
      width: 100%;
      position: absolute;
      top: 105px;
      left: 50px;
      z-index: 2000; }
      .model-overview-title-section .title-section-model-name span {
        width: 100%;
        color: #FFFFFF !important; }
    .model-overview-title-section .title-text.headline {
      font: 24px "ToyotaType-Light", sans-serif !important;
      position: absolute;
      z-index: 2000;
      top: 25px; }
      .model-overview-title-section .title-text.headline span {
        width: 100%;
        position: inherit;
        color: #FFFFFF !important;
        z-index: 2001;
        top: 0px;
        left: 25px; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .msrp {
      font: 16px "ToyotaType-Book", sans-serif;
      top: -65px; } }
  @media screen and (min-width: 40em) {
    .model-overview-title-section .msrp {
      top: -50px; } }
  @media screen and (min-width: 48em) {
    .model-overview-title-section .msrp {
      font: 18px "ToyotaType-Book", sans-serif;
      margin-top: 0px;
      top: unset; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section .msrp {
      font: 30px "ToyotaType-Light", sans-serif;
      margin-bottom: 84px;
      padding-top: 18px;
      top: unset; } }
  @media print {
    .model-overview-title-section .msrp .msrp-print-caption {
      color: #FFFFFF !important;
      font: 12px "ToyotaType-Light", sans-serif;
      position: absolute;
      top: -110px;
      left: 25px;
      z-index: 2000; }
      .model-overview-title-section .msrp .msrp-print-caption span {
        width: 100%;
        position: inherit;
        z-index: 2001; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .msrp-caption {
      margin: 0px 25px; } }
  @media screen and (min-width: 48em) {
    .model-overview-title-section .msrp-caption {
      margin: 0px 40px; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section .msrp-caption {
      margin: 0px 65px; } }
  @media print {
    .model-overview-title-section .msrp-caption {
      margin: 0px; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .msrp-caption {
      font: 8px "ToyotaType-Bold", sans-serif;
      margin-top: -135px; } }
  @media screen and (min-width: 40em) {
    .model-overview-title-section .msrp-caption {
      margin-top: -100px; } }
  @media screen and (min-width: 48em) {
    .model-overview-title-section .msrp-caption {
      margin-top: -5px; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section .msrp-caption {
      font: 11px "ToyotaType-Bold", sans-serif;
      height: fit-content;
      margin-top: -80px; } }
  @media print {
    .model-overview-title-section .subheadline {
      font: 20px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .subheadline {
      font: 16px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 40em) {
    .model-overview-title-section .subheadline {
      font: 20px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 48em) {
    .model-overview-title-section .subheadline {
      font: 24px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 90em) {
    .model-overview-title-section .subheadline {
      font: 30px "ToyotaType-Regular", sans-serif; } }
  @media print {
    .model-overview-title-section .subheadline {
      font: 16px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .subheadline {
      margin-top: 25px; } }
  @media screen and (min-width: 40em) {
    .model-overview-title-section .subheadline {
      margin-right: 30px; } }
  @media screen and (min-width: 64em) {
    .model-overview-title-section .subheadline {
      margin-top: 52px; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section .subheadline {
      line-height: 2;
      margin-left: 65px; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .description {
      font: 12px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 40em) {
    .model-overview-title-section .description {
      font-size: 15px; } }
  @media screen and (min-width: 48em) {
    .model-overview-title-section .description {
      font: 16px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section .description {
      font: 24px "ToyotaType-Regular", sans-serif; } }
  @media print {
    .model-overview-title-section .description {
      font: 11px "ToyotaType-Regular", sans-serif !important; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section .description {
      font: 20px "ToyotaType-Regular", sans-serif !important; } }
  .model-overview-title-section .description ul {
    list-style: none; }
    @media screen and (min-width: 19.375em) {
      .model-overview-title-section .description ul {
        margin-left: 0; } }
    @media screen and (min-width: 25.6875em) {
      .model-overview-title-section .description ul {
        margin-left: 0.4rem; } }
    @media screen and (min-width: 48em) {
      .model-overview-title-section .description ul {
        margin-left: 0.1rem; } }
    @media screen and (min-width: 64em) {
      .model-overview-title-section .description ul {
        margin-left: 0; } }
    .model-overview-title-section .description ul li {
      line-height: 0.9; }
      @media screen and (min-width: 19.375em) {
        .model-overview-title-section .description ul li {
          margin-bottom: 15px;
          line-height: 1.3; } }
      @media screen and (min-width: 80em) {
        .model-overview-title-section .description ul li {
          margin-bottom: 25px; } }
    .model-overview-title-section .description ul li::before {
      content: "\2022";
      color: #EB0A1E;
      display: block; }
      @media screen and (min-width: 19.375em) {
        .model-overview-title-section .description ul li::before {
          margin-left: -18px;
          font-size: 18px;
          height: 4px; } }
      @media screen and (min-width: 48em) {
        .model-overview-title-section .description ul li::before {
          font-size: 26px;
          margin-left: -17px;
          height: 8px; } }
      @media screen and (min-width: 64em) {
        .model-overview-title-section .description ul li::before {
          margin-left: -15px; } }
      @media screen and (min-width: 90em) {
        .model-overview-title-section .description ul li::before {
          margin-left: -35px;
          width: 25px;
          font-size: 175%;
          height: 0px;
          line-height: 0.5; } }
  @media screen and (min-width: 19.375em) {
    .model-overview-title-section .description {
      padding-left: 17px;
      padding-bottom: 25px;
      padding-top: 10px;
      width: 85%; } }
  @media screen and (min-width: 25.6875em) {
    .model-overview-title-section .description {
      padding-bottom: 40px; } }
  @media screen and (min-width: 40em) {
    .model-overview-title-section .description {
      margin-top: 5px;
      padding-bottom: 20px;
      width: 90%; } }
  @media screen and (min-width: 48em) {
    .model-overview-title-section .description {
      padding-bottom: 25px;
      padding-left: 15px; } }
  @media screen and (min-width: 64em) {
    .model-overview-title-section .description {
      width: 85%;
      margin-top: 10px; } }
  @media screen and (min-width: 80em) {
    .model-overview-title-section .description {
      margin-left: 85px;
      padding-right: 75px; } }
  .model-overview-title-section .video {
    min-height: 0; }
    @media screen and (min-width: 19.375em) {
      .model-overview-title-section .video {
        margin: 0px 25px; } }
    @media screen and (min-width: 48em) {
      .model-overview-title-section .video {
        margin: 0px 40px; } }
    @media screen and (min-width: 80em) {
      .model-overview-title-section .video {
        margin: 0px 65px; } }
    @media print {
      .model-overview-title-section .video {
        margin: 0px; } }
    @media screen and (min-width: 19.375em) {
      .model-overview-title-section .video {
        height: fit-content;
        margin-bottom: 15px;
        width: 75px; } }
    @media screen and (min-width: 40em) {
      .model-overview-title-section .video {
        margin-bottom: 10px; } }
    @media screen and (min-width: 48em) {
      .model-overview-title-section .video {
        margin-top: 140px; } }
    @media screen and (min-width: 64em) {
      .model-overview-title-section .video {
        margin-top: 180px;
        display: block;
        width: 100px; } }
    @media screen and (min-width: 90em) {
      .model-overview-title-section .video {
        margin-top: 249px; } }
  .model-overview-title-section .overview-video-title {
    position: absolute; }
    @media screen and (min-width: 19.375em) {
      .model-overview-title-section .overview-video-title {
        font: 12px "ToyotaType-Regular", sans-serif;
        position: absolute;
        top: 320px;
        left: 100px;
        width: 50%; } }
    @media screen and (min-width: 25.6875em) {
      .model-overview-title-section .overview-video-title {
        top: 335px;
        left: 105px; } }
    @media screen and (min-width: 48em) {
      .model-overview-title-section .overview-video-title {
        font-size: 15px;
        position: absolute;
        top: 440px;
        left: 120px; } }
    @media screen and (min-width: 64em) {
      .model-overview-title-section .overview-video-title {
        top: 495px;
        left: 150px; } }
    @media screen and (min-width: 80em) {
      .model-overview-title-section .overview-video-title {
        font: 16px "ToyotaType-Regular", sans-serif;
        top: 580px;
        left: 200px; } }
    @media screen and (min-width: 90em) {
      .model-overview-title-section .overview-video-title {
        font: 20px "ToyotaType-Regular", sans-serif;
        left: 195px; } }
