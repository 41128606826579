@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.tournament-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $grey-area;
    .tournament-content {
        padding: 42px 0px 80px 0px;
        .tournament-title {
            font: $h9-bold;
            margin-bottom: 50px;
            @include breakpoint(1504px down) {
                font: $font40-bold;
                // margin-left: 64px;
            }
            @include breakpoint(917px down) {
                font: $h6-bold;
                margin-left: 34px;
            }
        }
    }
}
