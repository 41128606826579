@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.engageXPMissionDetail {
    max-width: 1582px;
    margin: auto;
    padding: 50px;
    width: 100%;

    @include breakpoint(medium only) {
        max-width: 1024px;
        padding: 50px 76px;
    }

    @include breakpoint(small only) {
        padding: 34px;
        max-width: 500px;
    }

    .engageXPMissionDetail-header {
        display: flex;
        padding: 20px 0 80px 0;

        @include breakpoint(medium only) {
            padding: 20px 30px 80px 30px;

            .global-back-button {
                position: absolute;
                left: 25px;
                margin-top: 7px;
                padding-top: 0px;
                align-items: center;
            }    
        }

        @include breakpoint(small only) {
            padding-bottom: 50px;

            .global-back-button {
                align-items: flex-start;
                padding-top: 15px;
            } 
        }

        h1 {
            font: $h9-bold;
            color: #000;
            margin: 0 auto;
            line-height: 1;
            text-align: center;
        }
    }

    .engageXPMissionDetail-content {
        display: flex;

        @include breakpoint(1024px down) {
            flex-direction: column;
        }

        .engageXPMissionDetail-content-side {
            width: 100%;

            &:first-child {
                padding-right: 50px;

                @include breakpoint(1024px down) {
                    padding-right: 0px;
                }

                .mission-card-body {
                    display: flex;
                    gap: 20px;

                    @include breakpoint(small only) {
                        flex-direction: column;
                    }

                    .mission-card-hero {
                        min-width: 347px;
                        width: 347px;
                        height: 174px;
                        border-radius: 5px;
                        background-color: #dedede;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        padding: 10px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        aspect-ratio: 16/9;
                
                        @include breakpoint(small only) {
                            min-width: none;
                            width: 100%;
                            height: 180px;
                        }
                    }

                    .mission-card-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 100%;

                        .mission-infos {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            align-items: center;
                
                            p {
                                font: $h4-reg;
                                display: inline-block;
                                margin-bottom: 0;
                            }
                        }
                
                        h1 {
                            font: $h3-bold;
                            color: #2DAEFF;
                            margin-bottom: 0;
                            line-height: 1.25;
                            padding-bottom: 12px;

                            @include breakpoint(1024px down) {
                                margin-bottom: 20px;
                            }
                        }

                        .tournament-eligible-pro-points{
                            font: $font34-bold;
                            color: #0C56C9;
                            margin-bottom: 0;
                            line-height: 1.25;
                        }
                
                        .tournament-eligible-xp-points{
                            font: $h3-bold;
                            color: #2DAEFF;
                        }
                
                        .mission-repeatable {
                            font: $h4;
                            display: flex;
                            gap: 5px;
                            align-items: flex-start;
                
                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }

                .mission-description {
                    font: $h12-reg;
                    margin: 20px 0 50px 0;
                }

                .rewards-out-of-stock {
                    display: flex;
                    column-gap: 10px;
                    align-items: center;

                    @include breakpoint(medium down) {
                        padding-bottom: 15px;
                    }

                }
            }
    
            &:last-child {
                border-left: 1px solid rgba(0,0,0,0.2);
                padding-left: 50px;

                h4 {
                    font: $h12-bold;
                    margin-bottom: 30px;
                }

                @include breakpoint(1024px down) {
                    padding-left: 0px;
                    border-left: none;
                    padding-top: 50px;
                    border-top: 1px solid rgba(0,0,0,0.2);
                }
    
                @include breakpoint(small only) {
                    padding-top: 34px;
                }
            }

            .mission-details-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 40px 0 20px 0;
                gap: 20px;

                @include breakpoint(small down) {
                    flex-direction: column;
                    align-items: flex-end;
                    text-align: right;
                }

                .mission-details-completed-text {
                    font: $h2-bold;
                }

                .mission-details-faqs {
                    color: #E10A1D;
                    font: $font15-reg;
                    
                    .mission-details-faqs-link {
                        font: $font15-semibold;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
