@import "../../../node_modules/foundation-sites/scss/foundation.scss";
@import "./toyota-style-standards.scss";

.feature-data-rows {
    .feature-data-row {
        display: flex;
        flex-direction: row;
        padding: 14px 28px;
        gap: 70px;
    
        &.shaded-row {
            background-color: #f7f7f7;
            @media print {
                background-color: #F7F7F7 !important;
                print-color-adjust: exact;
           }
        }

        &.availability-icons {
            .features-data {
                .main-feature-data, .comparison-feature-data {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &.stacked-rows {

            @media print {
                display: flex;
                flex-direction: row;
                gap: 70px;
            }

            flex-direction: column;
            background-color: unset;
            padding: unset;
            gap: unset;

            .title-feature-name {
                background-color: #f7f7f7;
                @media print {
                    max-width: 200px;
                    width: 100%;
                }
                max-width: unset;
                padding: 14px 28px;
            }
            .features-data {
                padding: 14px 28px;
            }
        }

        &.single-feature {
            justify-content: space-between;
            gap: 30px;
            .title-feature-name {
                max-width: 742px;
            }
            .features-data {
                justify-content: flex-end;
                width: unset;
                .main-feature-data {
                    justify-content: flex-end;
                    width: unset;
                    max-width: unset;
                    min-width: unset;
                }
            }
        }
    
        .title-feature-name {
            max-width: 200px;
            width: 100%;
            font: $p14-reg;
            @include breakpoint(medium down) {
                font: $font15-reg;
            }
        }

        .features-data {
            display: flex;
            flex-direction: row;
            width: 100%;

            @media print {
                align-items: center;
            }

            .main-feature-data, .comparison-feature-data {
                max-width: 320px;
                width: 100%;
                min-width: 112px;
                word-break: break-word;
                font: $p14-reg;
                @include breakpoint(medium down) {
                    font: $font15-reg;
                }
                .standard-icon {
                    width: 20px;
                    height: 15px;
                    margin-right: 10px;
                    margin-bottom: 3px;
                    margin-top: 6px;
            
                    @include breakpoint(small only) {
                        width: 17px;
                        height: 14px;
                        margin-bottom: 2px;
                    }
                }
            
                .option-icon {
                    width: 17px;
                    height: 17px;
                    margin-right: 10px;
                    margin-bottom: 3px;
                    margin-top: 6px;
            
                    @include breakpoint(small only) {
                        width: 14px;
                        height: 14px;
                        margin-bottom: 1px;
                    }
                }
            
                .package-icon {
                    width: 12px;
                    height: 16px;
                    margin-right: 10px;
                    margin-bottom: 3px;
                    margin-top: 6px;
            
                    @include breakpoint(small only) {
                        width: 10px;
                        height: 14px;
                        margin-bottom: 2px;
                    }
                }

                .h2h-availablity-indication {
                    color: #15AEFF;
                    font: 22px $toyota-reg;
                    width: 12px;
                    height: 16px;
                    margin-right: 17px;

                    @include breakpoint(medium) {
                        margin-top: -6px;
                        @media print { 
                            margin-top: -15px;
                        }
                    }

                    @include breakpoint(small only) {
                        margin-bottom: 0;
                        margin-top: -6px;
                        @media print { 
                            margin-top: -15px;
                        }
                    }

                }

                .h2h-not-availablity-indication {
                    color: #15AEFF;
                    font: 22px $toyota-reg;
                    width: 12px;
                    height: 16px;
                    margin-right: 24px;
                    margin-top: -6px;
                }
            }
            .vertical-divider {
                @media print {
                    height: 15px;
                }
                height: 27px;
                border-left: 1px solid black;
                margin: 0 47px 0 25px;
            }
        }
    }
}
