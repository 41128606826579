@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.bz-chart-page {
  display: flex;
  justify-content: center;
  width: 100%;

  .bz-chart-container {
    max-width: 1444px;
    width: 1444px;
    border: 1px solid #b4b4b480;
    border-radius: 20px;
    margin: 30px 50px;
    overflow: hidden;

    @include breakpoint($xl-mobile down) {
      margin: 35px;
      border-radius: 10px;
    }

    .bz-chart-header-wrapper {
      background: transparent linear-gradient(180deg, #001e5a 0%, #001e46 100%)
        0% 0% no-repeat padding-box;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 20px calc(58px + 25px);

      .Dropdown-root {
        width: 100%;

        .Dropdown-control {
          height: 46px;

          .Dropdown-placeholder {
            @include breakpoint($xl-mobile down) {
              font-size: 15px;
            }
          }
        }

        .Dropdown-menu {
          .Dropdown-option {
            img {
              display: none;
            }
          }
        }
      }

      @include breakpoint($lg-tablet down) {
        padding: 32px calc(40px + 15px);
      }

      @include breakpoint($xl-mobile down) {
        padding: 38px 29px;
      }

      .bz-chart-header-item-title {
        width: 250px;
        min-width: 110px;

        @include breakpoint($lg-tablet down) {
          width: 150px;
        }
      }

      .bz-chart-header-item {
        font: $h7-reg;
        color: $primary-white;
        width: 190px;
        min-width: 190px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include breakpoint($lg-tablet down) {
          font: $font17-reg;
          width: 150px;
          min-width: auto;
        }

        img {
          width: 42px;
          height: 42px;
          margin-bottom: 15px;

          @include breakpoint($lg-tablet down) {
            width: 30px;
            height: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .bz-chart-data-wrapper {
      padding: 10px 58px;
      background: #fff;

      @include breakpoint($lg-tablet down) {
        padding: 10px 40px;
      }

      @include breakpoint($xl-mobile down) {
        padding: 10px 29px;
      }

      .bz-chart-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #c1c8d080;
        padding: 25px;

        @include breakpoint($lg-tablet down) {
          padding: 25px 15px;
        }

        @include breakpoint($xl-mobile down) {
          padding-right: 0;
          padding-top: 39px;
          padding-bottom: 39px;
        }

        &:last-child {
          border-bottom: none;
        }

        .bz-chart-row-title {
          font: $h2-bold;
          color: $dark-black;
          width: 250px;
          min-width: 110px;

          @include breakpoint($lg-tablet down) {
            font: $font15-bold;
            width: 150px;
          }

          @include breakpoint($xl-mobile down) {
            width: 145px;
          }
        }

        .bz-chart-row-value {
          font: $h4-reg;
          color: $dark-black;
          width: 190px;
          min-width: 190px;
          display: flex;
          justify-content: center;
          text-align: center;

          @include breakpoint($lg-tablet down) {
            width: 140px;
            min-width: auto;
            padding: 0 5px;
            font: $font15-reg;
          }

          @include breakpoint($xl-mobile down) {
            padding: 0;
          }

          img {
            width: 54px;
            height: 54px;

            @include breakpoint($lg-tablet down) {
              width: 41px;
              height: 41px;
              min-width: 41px;
              min-height: 41px;
            }
          }
        }
      }
    }
  }
}
