/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.non-vehicle-search-result-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding-top: 25px;
  padding-bottom: 20px;
  min-height: 154px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  width: 307px;
  margin-top: 23px; }
  @media print, screen and (min-width: 40em) {
    .non-vehicle-search-result-pane {
      width: 324px;
      margin-top: 38px; } }
  @media print, screen and (min-width: 64em) {
    .non-vehicle-search-result-pane {
      min-width: 411px; } }
  .non-vehicle-search-result-pane .header-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media print, screen and (max-width: 39.99875em) {
      .non-vehicle-search-result-pane .header-content {
        padding-left: 30px;
        padding-right: 30px;
        width: 324px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .non-vehicle-search-result-pane .header-content {
        padding-left: 30px;
        padding-right: 30px;
        width: 324px; } }
    @media print, screen and (min-width: 64em) {
      .non-vehicle-search-result-pane .header-content {
        padding-left: 30px;
        padding-right: 30px;
        width: 411px; } }
    .non-vehicle-search-result-pane .header-content .header-text {
      text-align: center;
      margin: 5px 0px 12px 0px;
      font: 22px "ToyotaType-Bold", sans-serif;
      width: fit-content; }
      @media print, screen and (min-width: 40em) {
        .non-vehicle-search-result-pane .header-content .header-text {
          margin: 5px 0px 17px 0px; } }
      @media print, screen and (min-width: 64em) {
        .non-vehicle-search-result-pane .header-content .header-text {
          margin: 31px 0px 17px 0px;
          font: 28px "ToyotaType-Bold", sans-serif; } }
  .non-vehicle-search-result-pane .body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 10px 0px; }
    @media print, screen and (min-width: 40em) {
      .non-vehicle-search-result-pane .body-content {
        margin: 0px 0px 10px 0px; } }
    @media print, screen and (min-width: 64em) {
      .non-vehicle-search-result-pane .body-content {
        margin: 0px 0px 40px 0px; } }
    .non-vehicle-search-result-pane .body-content .red-border {
      border-bottom: 2px solid #EB0A1E;
      width: 43px;
      margin: 8px 0px 20px 0px; }
      @media print, screen and (min-width: 64em) {
        .non-vehicle-search-result-pane .body-content .red-border {
          width: 48px;
          margin: 8px 0px 23px 0px; } }
    .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list {
      margin: 0px;
      list-style: none; }
      @media print, screen and (max-width: 39.99875em) {
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list {
          margin-top: -20px;
          width: 255px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list {
          margin-top: -20px;
          width: 268px; } }
      @media print, screen and (min-width: 64em) {
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list {
          margin-top: -20px;
          width: 310px; } }
      .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab {
        border-bottom: 1px solid #E6E6E6;
        line-height: 20px; }
        @media print, screen and (max-width: 39.99875em) {
          .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab {
            width: 255px;
            font-size: 13px;
            padding: 19px 0px 19px 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab {
            width: 268px;
            font-size: 15px;
            padding: 19px 0px 19px 0px; } }
        @media print, screen and (min-width: 64em) {
          .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab {
            width: 310px;
            font-size: 15px;
            padding: 15px 0px 15px 0px; } }
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab .tabName,
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab .tabName:hover,
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab .tabName:active,
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab .tabName:visited {
          color: #eb0a1e;
          font-weight: bold;
          cursor: pointer; }
      .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .nonVehicle-tab:last-child {
        border-bottom: none;
        padding-bottom: 0px; }
      .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .tab-filters-list {
        padding-top: 3px; }
        .non-vehicle-search-result-pane .body-content .nonVehicle-tabs-list .tab-filters-list .tab-filter {
          color: gray;
          font-size: 13px; }
    .non-vehicle-search-result-pane .body-content .single-page-button {
      border: 2px solid #707070;
      border-radius: 19px;
      font: 16px "ToyotaType-Regular", sans-serif;
      height: 32px;
      padding-top: 1px;
      text-align: center;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */ }
      .non-vehicle-search-result-pane .body-content .single-page-button a {
        color: black; }
      @media print, screen and (max-width: 39.99875em) {
        .non-vehicle-search-result-pane .body-content .single-page-button {
          margin-bottom: 21px;
          width: 145px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .non-vehicle-search-result-pane .body-content .single-page-button {
          margin-bottom: 19px;
          width: 210px; } }
      @media print, screen and (min-width: 64em) {
        .non-vehicle-search-result-pane .body-content .single-page-button {
          margin-bottom: 14px;
          width: 190px; } }
    @media print, screen and (min-width: 64em) {
      .non-vehicle-search-result-pane .body-content .single-page-button:hover {
        cursor: pointer; } }
    .non-vehicle-search-result-pane .body-content .single-page-button:hover a {
      text-decoration: none; }
  .non-vehicle-search-result-pane .arrowButton {
    display: flex;
    justify-content: center; }
    .non-vehicle-search-result-pane .arrowButton .arrow-image {
      margin-top: 20px;
      margin-bottom: 26px;
      width: 16px; }
