/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

@media screen and (min-width: 19.375em) {
  .vertical-media-text-tile-redline {
    margin: 6px 0px 6px 0px; } }

@media screen and (min-width: 48em) {
  .vertical-media-text-tile-redline {
    margin: 10px 0px 14px 0px; } }

@media screen and (min-width: 80em) {
  .vertical-media-text-tile-redline {
    margin: 5px 0px 13px 0px;
    width: 42px; } }

.vertical-media-text-tile-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0px; }
  @media screen and (min-width: 19.375em) {
    .vertical-media-text-tile-column {
      margin: 20px 0px 12px 0px; } }
  @media screen and (min-width: 48em) {
    .vertical-media-text-tile-column {
      flex: 1 0 0;
      margin: 16px 0px 20px 0px; } }
  @media screen and (min-width: 90em) {
    .vertical-media-text-tile-column {
      flex: 1 0 0;
      margin: 16px 0px 0px 0px; } }

.vertical-media-text-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: 16px "ToyotaType-Regular", sans-serif; }
  .vertical-media-text-space-between p {
    margin: 0px;
    line-height: 1.3; }

.image-wrapper {
  min-height: 155px; }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .image-wrapper {
      min-height: 100px; } }

.item-img {
  width: 100%; }

.vertical-media-text-title {
  color: #0a0a0a; }
  @media screen and (min-width: 19.375em) {
    .vertical-media-text-title {
      font: 22px "ToyotaType-Light", sans-serif;
      margin-top: 6px;
      line-height: 1.2; } }
  @media screen and (min-width: 48em) {
    .vertical-media-text-title {
      font: 20px "ToyotaType-Light", sans-serif;
      margin-top: 8px; } }
  @media screen and (min-width: 90em) {
    .vertical-media-text-title {
      font: 22px "ToyotaType-Light", sans-serif; } }

.vertical-media-text-contentType {
  color: #acacac;
  font: 16px "ToyotaType-Bold", sans-serif; }
  @media screen and (min-width: 19.375em) {
    .vertical-media-text-contentType {
      margin-top: 7px;
      margin-bottom: 4px; } }
  @media screen and (min-width: 90em) {
    .vertical-media-text-contentType {
      margin-top: 5px; } }

.vertical-media-text-subDescription {
  font: 16px "ToyotaType-Semibold", sans-serif; }

a.image-link-overlay:hover {
  text-decoration: none; }

@media screen and (min-width: 19.375em) {
  .vertical.image-link-overlay p {
    font: 16px "ToyotaType-Regular", sans-serif; } }

@media screen and (min-width: 48em) {
  .vertical.image-link-overlay p {
    font-size: 16px; } }

.red-button-link {
  margin-top: 10px;
  font-weight: bold;
  color: #EB0A1E; }
  @media print, screen and (max-width: 39.99875em) {
    .red-button-link {
      margin-top: 1px; } }
  @media screen and (min-width: 23.4375em) {
    .red-button-link {
      font-size: 16px; } }

@media screen and (min-width: 48em) {
  .vertical-media-text-tile-column.two-column-layout {
    min-width: 324px; }
    .vertical-media-text-tile-column.two-column-layout:first-child {
      margin-right: 40px; } }

@media screen and (min-width: 90em) {
  .vertical-media-text-tile-column.two-column-layout {
    min-width: 580px; }
    .vertical-media-text-tile-column.two-column-layout:first-child {
      margin-right: 88px; } }

@media screen and (min-width: 48em) {
  .vertical-media-text-tile-column.three-column-layout {
    min-width: 216px; }
    .vertical-media-text-tile-column.three-column-layout:not(:last-child) {
      margin-right: 20px; } }

@media screen and (min-width: 90em) {
  .vertical-media-text-tile-column.three-column-layout {
    min-width: 398px; }
    .vertical-media-text-tile-column.three-column-layout:not(:last-child) {
      margin-right: 27px; } }

@media screen and (min-width: 48em) {
  .vertical-media-text-tile-column.four-column-layout,
  .vertical-media-text-tile-column.four-column-layout-wrap {
    min-width: 44%;
    max-width: 47%; } }

@media screen and (min-width: 64em) {
  .vertical-media-text-tile-column.four-column-layout,
  .vertical-media-text-tile-column.four-column-layout-wrap {
    min-width: 22%;
    max-width: 24%; }
    .vertical-media-text-tile-column.four-column-layout:not(:nth-child(4)),
    .vertical-media-text-tile-column.four-column-layout-wrap:not(:nth-child(4)) {
      margin-right: 27px; } }

@media screen and (min-width: 453.75em) {
  .vertical-media-text-tile-column.four-column-layout:not(:nth-child(4)),
  .vertical-media-text-tile-column.four-column-layout-wrap:not(:nth-child(4)) {
    margin-right: 30px; } }

@media screen and (min-width: 111.625em) {
  .vertical-media-text-tile-column.four-column-layout,
  .vertical-media-text-tile-column.four-column-layout-wrap {
    min-width: 21%;
    max-width: 22%; }
    .vertical-media-text-tile-column.four-column-layout:not(:nth-child(4)),
    .vertical-media-text-tile-column.four-column-layout-wrap:not(:nth-child(4)) {
      margin-right: 66px; } }

@media screen and (min-width: 48em) {
  .vertical-media-text-tile-column.four-column-layout-wrap {
    margin-bottom: 65px; } }
