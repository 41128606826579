@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

// XP Tournament breakpoints
$tablet: 1504px;
$mobile: 917px;

.jump-buttons {
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    gap: 102px;
    height: 106px;
    @include breakpoint($tablet down) {
        height: 80px;
    }
    @include breakpoint($mobile down) {
        gap: 50px;
        height: 85px;
        padding: 0 53px;
    }
    button {
        font: $p5-semibold;
        color: #000;
        text-decoration: underline;
        @include breakpoint($mobile down) {
            font: $font15-semibold;
        }
    }
    button:hover {
        cursor: pointer;
        color: #0c56c9;
    }
}
