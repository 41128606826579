@import '../../../../node_modules/foundation-sites/scss/foundation.scss';
@import '../toyota-style-standards.scss';

.incentives-button {
    display: flex;
    flex-direction: row;
    min-width: 218px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    font-family: $toyota-reg;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    margin-right: auto;
    cursor: pointer;
    padding: 8px 20px 8px 17px;
    gap: 12px;

    @include breakpoint(medium only) {
        min-width: 194px;
        font-size: 17px;
    }
    @include breakpoint(790px down) {
        min-width: 150px;
        align-items: center;
    }
    @include breakpoint(small only) {
        width: 280px;
        justify-content: space-between;
        font-size: 17px;
        margin: auto;
        margin-top: 35px;
        align-items: unset;
        gap: unset;
        padding: 8px 25px 8px 17px;
    }
}

.location-icon {
    width: 16px;
    height: 23px;
}

.incentives-text {
    line-height: 18px;
    @include breakpoint(790px down) {
        padding-bottom: 2px;;
    }
    @include breakpoint(small only) {
        padding-bottom: 0px;
    }
}