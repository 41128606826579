@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.disclaimer-overlay {

    position: fixed;
    bottom: 0;
    left: 396px;
    right: 26px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.6s ease;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    max-width: 1129px;
    z-index: 2160;

    @media (min-width: 1920px){
        left: calc(396px + ((100vw - 1920px) * 0.5));
        right: auto;
        width: 100%;
    }

    @include breakpoint(medium down){
        left: 296px;
        right: 22px;
    }
    @include breakpoint(small down){
        left: 0;
        right: 0;
    }

    &.show {
        opacity: 0.9;
        visibility: visible;
        transform: translateY(0);
    }

    .disclaimer-content {
        width: 100%;
        height: 108px;
        background: #141414 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 23px 23px 0px 0px;
        display: flex;
        justify-content: flex-end;
        z-index: 2160;
        animation: slide-up 1s ease;
        transition: bottom 1s ease;

        .disclaimer-text {
            width: 100%;
            font: $font15-reg;
            word-wrap: break-word;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin-left: 43px;
            overflow-y: scroll;
            margin-top: 26px;
            padding: 0px 55px 0px 0px;

                &::-webkit-scrollbar {
                    background-color: transparent;
                    width: 6px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #666;
                    border-radius: 10px;
                }
        }

        .disclaimer-exit-name {
            position: absolute;
            color: #FFFFFF;
            padding-bottom: 4px;
            font-size: 18px;
            font-weight: 300;
            font-family: sans-serif;
        }

        .disclaimer-exit {
            border: 1px solid #FFFFFF;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            margin: 15px 20px;
            animation: auto;
            transition: transform 0.3s ease-out;
        }
        .disclaimer-exit:hover {
            width: 36px;
            height: 36px;
            min-width: 36px;
            min-height: 36px;
            border-width: 2px;
            transform: scale(1.10);
            margin: 12px 17px 0px 0px;
        }
    }
}