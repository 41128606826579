@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.resources-recommended-articles {
  .recommended-title {
    font: $h3-bold
  }

  .recommended-articles {
    grid-template-columns: 1fr 1fr 1fr;

    @include breakpoint(medium only) {
        grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(735px down) {
        grid-template-columns: 1fr;
    }
    .contentCard.resources-recommended {    
      .contentCardTitle {
          text-align: left;
          font: $h4-bold;
      }
    }
  }
}

.resources-recommended-articles {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include breakpoint(small only) {
    max-width: 360px;
    margin: auto;
    width: 100%;
  }
  .recommended-articles {
    display: grid;
    column-gap: 12px;
    row-gap: 25px;
    max-width: 1035px;
    @include breakpoint(735px down) {
      max-width: 360px;
    }
    .contentCard {
      width: 100%;
      height: fit-content;
      .contentCardTitle {
        padding: 0px;
        width: 95%;
      }
      .contentCardOverlayImage {
        padding: 2px 2px 2px 5px;
        display: flex;
        justify-content: center;
      }
    }
  }
}