@import "./toyota-style-standards.scss";
@import "../../../node_modules/foundation-sites/scss/foundation.scss";
@import "base";

/////////////////////////////////////////////////////////////////////
//// CLASSES THAT ARE CREATED/USED BY THE react-dropdown PACKAGE ////
/////////////////////////////////////////////////////////////////////
.Dropdown-menu {
  background-color: $primary-white;
  border-radius: 4px;
  box-shadow: 0px 3px 6px $primary-black-boxshadow;
  margin-top: -15px;
  overflow-y: scroll;
  position: relative;
  z-index: 1;
  >div.Dropdown-group>div.Dropdown-title {
      background-color: #F5F6F7;
      color: #313131;
      font: $h2-bold;
      padding: 3px 0 4px 26px;
      margin-top: 11px;
      pointer-events: none;
    }
}

.Dropdown-menu:hover {
  cursor: pointer;
}

.Dropdown-option {
  padding: 5px 0;
  text-align: center;
}

.Dropdown-option:hover {
  background-color: $dark-black;
  color: $primary-white;
}

// -----------------------------
// --- STANDARD COLOR STYLES ---
// -----------------------------

// shared standard styles
.Dropdown-root.default,
.Dropdown-root.grey,
.Dropdown-root.black-red,
.Dropdown-root.black,
.Dropdown-root.dark-grey,
.Dropdown-root.white,
.Dropdown-root.dark-translucent,
.Dropdown-root.white-translucent,
.Dropdown-root.disabled {
  cursor: pointer;
  position: relative;

  .Dropdown-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 9000px;
    height: 38px;
    align-items: center;
    padding: 0px 15px 4px 24px;
    margin-bottom: 2px;
  }
  .Dropdown-placeholder {
    font: $h2-reg;
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    color: $tertiary-light-grey;
  }
  .Dropdown-placeholder.is-selected {
    font: $h2-semibold;
    color: $primary-black;
  }
  .Dropdown-arrow-wrapper {
    display: flex;
    align-items: center;
  }
  .dropdown-arrow {
    max-width: 17px;
    margin-left: 5px;
    margin-top: 5px;
  }
  .dropdown-arrow.upside-down {
    transform: rotate(180deg);
  }
  .Dropdown-menu {
    background-color: $primary-white;
    margin: 0 4% 0 4%;
    margin-top: 0px;
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    box-shadow: 0px 3px 6px $primary-black-boxshadow;
    width: 92%;
    min-width: 100px;
    max-height: 500px;
  }
  .Dropdown-option {
    padding: 5px 20px 5px 24px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
  }
}

// "grey" (grey dropdown background and menu hover)
.Dropdown-root.grey {
  .Dropdown-control {
    background-color: $grey-hover;
  }
  .Dropdown-option:hover {
    background-color: $grey-hover;
    color: $primary-black;
  }
}

// "black" (black dropdown outline and menu hover, black arrow)
.Dropdown-root.black {
  .Dropdown-control {
    border: 1px solid $mid-dark-grey;
  }
  .Dropdown-option:hover {
    background-color: $dark-black;
    color: $primary-white;
  }
}

// "dark-grey" (dark-grey dropdown background, black menu hover, black arrow)
.Dropdown-root.dark-grey {
  .Dropdown-control {
    background-color: #edeeef;
  }
  .Dropdown-option:hover {
    background-color: $dark-black;
    color: $primary-white;
  }
}

// "dark-translucent" (dark-translucent dropdown background, black menu hover, black arrow)
.Dropdown-root.dark-translucent {
  .Dropdown-control {
    background: rgba(255, 255, 255, 0.7);
  }
  .Dropdown-option:hover {
    background-color: $dark-black;
    color: $primary-white;
  }
}

// "white-translucent" (white-translucent dropdown background, black menu hover, black arrow)
.Dropdown-root.white-translucent {
  .Dropdown-control {
    background: transparent;
    border: 1px solid #fff;
  }
  .Dropdown-option:hover {
    background-color: $dark-black;
    color: $primary-white;
  }
  .Dropdown-placeholder {
    color: $primary-white;
  }
  .Dropdown-placeholder.is-selected {
    color: $primary-white;
  }
}

// "dark-grey" (dark-grey dropdown background, black menu hover, black arrow)
.Dropdown-root.white {
  .Dropdown-control {
    background-color: #FFFFFF;
    border: 1px solid $mid-dark-grey;
  }
  .Dropdown-option:hover {
    background-color: $dark-black;
    color: $primary-white;
  }
}

// "default" / "black-red" (black dropdown outline and menu hover, red arrow)
.Dropdown-root.default,
.Dropdown-root.black-red {
  .Dropdown-control {
    border: 1px solid $mid-dark-grey;
  }
  .Dropdown-option:hover {
    background-color: $dark-black;
    color: $primary-white;
  }
}

// "disabled" (grey dropdown background, no menu hover, black arrow)
.Dropdown-root.disabled {
  cursor: not-allowed !important;
  .Dropdown-control {
    background-color: #edeeef;
  }
  .Dropdown-menu {
    display: none;
  }
}

.dropdown-overlay {
  background-color: black;
  opacity: 0.4;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 4997;
  left: 0;
  top: 0;
}

.fullBackground-dropdown {
  position: relative;
  z-index: 4998;

  .value-section {
      display: flex;
      flex-direction: row;
      color: $primary-white;
      font-size: 18px;
      align-items: center;
      padding: 5px 18px 5px 18px;
      border-radius: 5px 5px 0px 0px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      white-space: nowrap;
      justify-content: space-between;
      
      .arrow {
          width: 16px;
          height: 8px;
          margin-top: 3px;
          margin-left: 10px;
      }
  
  }
  
  .value-section.open {
      background-color: white;
      color: $navigation-text;
  }
  
  .menu {
      position: absolute;
      z-index: 1;
      width: 100%;
      border-radius: 0px 0px 5px 5px;
      max-height: 200px;
      overflow-y: overlay;
      box-shadow: 4px 6px 6px #00000029;
      background-color: white;
  }

  .option:last-child {
    margin-bottom: 20px;
}
  
  .option {
      background-color: white;
      color: $navigation-text;
      text-align: center;
      padding: 5px;
      cursor: pointer;
  }

  .option:hover {
    background-color: black;
    color: white;
  }
  
  .menu::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
      border-radius: 0px 0px 5px 0px;
    }
    
  .menu::-webkit-scrollbar-thumb {
      background-color: #B7B7B7;
      border: 3px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
  }
}
