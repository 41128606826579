/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.item-is-visible {
  border-radius: 12px 12px 0 0; }

.item-is-not-visible {
  border-radius: 12px; }

.raceTrack-item-body-content-description {
  position: absolute; }

.pointer-cursor {
  cursor: pointer; }

@media print, screen and (max-width: 39.99875em) {
  .smartpath-roadmap {
    background-color: #F7F8F8;
    padding: 34px 25px 43px; }
    .smartpath-roadmap .roadmap-header {
      width: 300px;
      font: 25px "ToyotaType-Regular", sans-serif;
      margin: auto;
      padding-bottom: 30px; }
      .smartpath-roadmap .roadmap-header strong {
        font: 25px "ToyotaType-Black", sans-serif; }
    .smartpath-roadmap .roadmap-key {
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px; }
      .smartpath-roadmap .roadmap-key .roadmap-key-item {
        width: 30%;
        display: flex;
        justify-content: left;
        align-items: flex-start; }
        .smartpath-roadmap .roadmap-key .roadmap-key-item .roadmap-key-item-icon {
          display: flex;
          align-items: flex-start;
          padding-top: 5px;
          padding-right: 3px; }
    .smartpath-roadmap .roadmap-key-item-description {
      font: 12px "ToyotaType-Regular", sans-serif;
      text-align: left; }
  .roadmap-raceTrack {
    height: 1858px;
    width: 315px;
    margin: 0px auto;
    background-repeat: no-repeat; }
    .roadmap-raceTrack .roadmap-raceTrack-items {
      display: flex;
      flex-direction: column;
      padding-top: 100px;
      justify-content: flex-start;
      margin-left: -14px;
      height: 1858px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item {
        display: flex;
        align-items: flex-start; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-image {
          padding-right: 3px; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body {
          margin-top: 18px;
          margin-bottom: auto;
          height: auto;
          width: 225px;
          background: #FFFFFF;
          box-shadow: 0px 3px 6px #00000029;
          text-align: initial;
          font: 15px "ToyotaType-Bold", sans-serif; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content {
            display: flex;
            justify-content: space-between; }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content .raceTrack-item-body-content-title {
              padding-top: 16px;
              padding-left: 18px;
              padding-bottom: 18px;
              padding-right: 5px; }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content .raceTrack-item-body-icon-container .raceTrack-item-body-content-icon {
              display: block;
              position: relative;
              width: 32px;
              height: 20px;
              margin-right: 17px;
              margin-top: 17px; }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content .raceTrack-item-body-icon-container .raceTrack-item-body-content-icon:before, .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content .raceTrack-item-body-icon-container .raceTrack-item-body-content-icon:after {
              position: absolute;
              left: 15px;
              content: ' ';
              height: 20px;
              width: 3px;
              background-color: #EB0A1E; }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content .raceTrack-item-body-icon-container .raceTrack-item-body-content-icon:after {
              transform: rotate(90deg); }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content .raceTrack-item-body-icon-container .raceTrack-item-body-content-inactiveIcon {
              position: relative;
              width: 32px;
              height: 20px;
              margin-right: 17px;
              margin-top: 17px; }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content .raceTrack-item-body-icon-container .raceTrack-item-body-content-inactiveIcon::after {
              position: absolute;
              left: 15px;
              content: ' ';
              height: 20px;
              width: 3px;
              background-color: #EB0A1E;
              transform: rotate(90deg); }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-description {
            display: none;
            width: 225px;
            font: 13px "ToyotaType-Regular", sans-serif;
            padding-right: 17px;
            padding-left: 18px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 3px #00000029;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            padding-bottom: 20px;
            line-height: 1.2; }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-description p {
              line-height: 1.2; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:first-child strong {
        color: #EB0A1E; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:last-child {
        align-self: flex-end;
        margin-top: 84px;
        margin-bottom: 129px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .smartpath-roadmap {
    background-color: #F7F8F8;
    padding-top: 60px;
    padding-bottom: 80px; }
    .smartpath-roadmap .roadmap-header {
      height: auto;
      font: 36px "ToyotaType-Regular", sans-serif;
      margin-bottom: 36px; }
      .smartpath-roadmap .roadmap-header strong {
        font: 36px "ToyotaType-Black", sans-serif; }
    .smartpath-roadmap .roadmap-key {
      display: flex;
      justify-content: center;
      padding-bottom: 87px; }
    .smartpath-roadmap .roadmap-key-item {
      display: inherit; }
    .smartpath-roadmap .roadmap-key-item-icon {
      margin-left: 35px;
      margin-right: 15px; }
    .smartpath-roadmap .roadmap-key-item-description {
      font: 13px "ToyotaType-Regular", sans-serif;
      align-self: center; }
  .roadmap-raceTrack {
    height: 1823px;
    width: 644px;
    margin: auto;
    margin-bottom: 130px;
    background-repeat: no-repeat; }
    .roadmap-raceTrack .roadmap-raceTrack-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: baseline;
      min-height: 100px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(1) {
        margin: auto;
        margin-top: -60px;
        margin-left: 80px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(2) {
        margin: auto;
        margin-top: -60px;
        margin-left: -60px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(3) {
        margin: auto;
        margin-top: 120px;
        margin-right: 75px;
        order: 4; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(4) {
        margin: auto;
        margin-top: 122px;
        margin-left: 84px;
        order: 3; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(5) {
        margin: auto;
        margin-top: 114px;
        margin-left: 0px;
        order: 5; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(6) {
        margin: auto;
        margin-top: 115px;
        margin-right: 100px;
        order: 6; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(7) {
        margin: auto;
        margin-top: 95px;
        margin-right: 21px;
        order: 8; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(8) {
        margin: auto;
        margin-top: 100px;
        margin-left: 95px;
        order: 7; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(9) {
        margin: auto;
        margin-top: 100px;
        margin-left: 20px;
        order: 9; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(10) {
        margin: auto;
        margin-top: 100px;
        margin-right: 110px;
        order: 10; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(11) {
        margin: auto;
        margin-top: 115px;
        margin-right: 0px;
        order: 12; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(12) {
        margin: auto;
        margin-top: 115px;
        margin-left: 100px;
        order: 11; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(13) {
        margin: auto;
        margin-top: 120px;
        margin-left: 270px;
        order: 13; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:first-child .raceTrack-item-body-content-description {
        padding-bottom: 2px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:first-child strong {
        color: #EB0A1E; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item {
        width: 225px; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body {
          background: #FFFFFF;
          box-shadow: 0px 3px 6px #00000029;
          text-align: initial;
          font: 15px "ToyotaType-Black", sans-serif; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content {
            display: flex;
            justify-content: space-between; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-title {
            padding-left: 18px;
            padding-top: 16px;
            padding-bottom: 20px;
            padding-right: 5px; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-icon {
            display: block;
            position: relative;
            width: 32px;
            height: 20px;
            margin-right: 17px;
            margin-top: 17px; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-icon:before, .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-icon:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 20px;
            width: 3px;
            background-color: #EB0A1E; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-icon:after {
            transform: rotate(90deg); }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-inactiveIcon {
            position: relative;
            width: 32px;
            height: 20px;
            margin-right: 17px;
            margin-top: 17px; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-inactiveIcon::after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 20px;
            width: 3px;
            background-color: #EB0A1E;
            transform: rotate(90deg); }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-description {
            display: none;
            z-index: 10;
            width: 225px;
            font: 13px "ToyotaType-Regular", sans-serif;
            padding-right: 17px;
            padding-left: 18px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 3px #00000029;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            padding-bottom: 20px;
            line-height: 1.2; }
            .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item .raceTrack-item-body .raceTrack-item-body-content-description p {
              line-height: 1.2; } }

@media print, screen and (min-width: 64em) {
  .smartpath-roadmap {
    background-color: #F7F8F8;
    padding-top: 100px;
    padding-bottom: 200px; }
    .smartpath-roadmap .roadmap-header {
      font: 42px "ToyotaType-Regular", sans-serif;
      margin-bottom: 56px; }
      .smartpath-roadmap .roadmap-header strong {
        font: 42px "ToyotaType-Bold", sans-serif; }
    .smartpath-roadmap .roadmap-key {
      display: flex;
      justify-content: center;
      padding-bottom: 87px; }
    .smartpath-roadmap .roadmap-key-item {
      display: inherit; }
    .smartpath-roadmap .roadmap-key-item-icon {
      margin-left: 35px;
      margin-right: 15px; }
    .smartpath-roadmap .roadmap-key-item-description {
      font: 13px "ToyotaType-Regular", sans-serif;
      align-self: center; }
  .roadmap-raceTrack {
    height: 1342px;
    width: 1070px;
    margin: auto;
    margin-bottom: 130px; }
    .roadmap-raceTrack .roadmap-raceTrack-items {
      display: flex;
      flex-wrap: wrap; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item {
        min-height: 316px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(3n-2) {
        margin-right: 70px;
        margin-left: 70px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(3) {
        order: 5; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(4) {
        order: 4; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(5) {
        order: 3; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(6) {
        order: 6; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(7) {
        order: 7; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(8) {
        order: 8; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(9) {
        order: 11; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(10) {
        order: 10; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(11) {
        order: 9; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(12) {
        order: 12; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-child(13) {
        order: 13; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:first-child {
        margin-left: 185px;
        margin-right: 73px; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:first-child .raceTrack-item-body-content-description {
          padding-bottom: 2px; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:first-child strong {
          color: #EB0A1E; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:first-child .raceTrack-item-image {
          margin-top: -57px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:nth-last-child(2) {
        margin-left: 185px;
        margin-right: 32px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item:last-child .raceTrack-item-image {
        margin-top: -57px; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-image {
        margin-top: -58px;
        margin-left: auto; }
      .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body {
        width: 300px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        text-align: initial;
        font: 20px "ToyotaType-Bold", sans-serif;
        margin-top: auto;
        margin-left: auto; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content {
          display: flex;
          justify-content: space-between; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-title {
          padding-left: 18px;
          padding-top: 13px;
          padding-bottom: 13px; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-icon {
          display: block;
          position: relative;
          width: 32px;
          height: 20px;
          margin-right: 17px;
          margin-top: 17px; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-icon:before, .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-icon:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 20px;
          width: 3px;
          background-color: #EB0A1E; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-icon:after {
          transform: rotate(90deg); }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-inactiveIcon {
          position: relative;
          width: 32px;
          height: 20px;
          margin-right: 17px;
          margin-top: 17px; }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-inactiveIcon::after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 20px;
          width: 3px;
          background-color: #EB0A1E;
          transform: rotate(90deg); }
        .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-description {
          display: none;
          width: 300px;
          font: 13px "ToyotaType-Regular", sans-serif;
          padding-right: 17px;
          padding-left: 18px;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 3px #00000029;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          padding-bottom: 20px;
          line-height: 1.2; }
          .roadmap-raceTrack .roadmap-raceTrack-items .raceTrack-item-body .raceTrack-item-body-content-description p {
            line-height: 1.2; } }
