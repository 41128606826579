/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.knowledge-pane {
  width: 307px;
  margin-top: 23px;
  min-height: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1; }
  .knowledge-pane .card-collapsed {
    width: 324px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0px 26px 0px; }
    .knowledge-pane .card-collapsed .header-text {
      font-size: 22px;
      margin-bottom: 7px;
      margin-top: 5px; }
    .knowledge-pane .card-collapsed .model {
      font: 22px "ToyotaType-Bold", sans-serif; }
    .knowledge-pane .card-collapsed .body-title {
      font: 14px "ToyotaType-Semibold", sans-serif;
      text-transform: uppercase; }
    .knowledge-pane .card-collapsed .down-arrow {
      margin-top: 20px;
      width: 16px; }
  .knowledge-pane .display-desktop {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .knowledge-pane .display-desktop {
        display: inline; } }
  .knowledge-pane .display-tablet-mobile {
    display: inline; }
    @media print, screen and (min-width: 64em) {
      .knowledge-pane .display-tablet-mobile {
        display: none; } }
  @media print, screen and (min-width: 40em) {
    .knowledge-pane {
      width: 324px;
      margin-top: 38px; } }
  @media print, screen and (min-width: 64em) {
    .knowledge-pane {
      min-width: 411px; } }
  .knowledge-pane .header-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .knowledge-pane .header-content .header-text {
      margin: 25px 0px 12px 0px;
      font: 22px "ToyotaType-Regular", sans-serif;
      width: fit-content; }
      .knowledge-pane .header-content .header-text .model {
        font: 22px "ToyotaType-Bold", sans-serif; }
      @media print, screen and (min-width: 40em) {
        .knowledge-pane .header-content .header-text {
          margin: 25px 0px 17px 0px; } }
      @media print, screen and (min-width: 64em) {
        .knowledge-pane .header-content .header-text {
          margin: 31px 0px 17px 0px;
          font: 28px "ToyotaType-Regular", sans-serif; }
          .knowledge-pane .header-content .header-text .model {
            font: 28px "ToyotaType-Bold", sans-serif; } }
    .knowledge-pane .header-content .header-image {
      width: 264px;
      height: 120px;
      margin-bottom: 10px;
      object-fit: contain; }
      @media print, screen and (min-width: 40em) {
        .knowledge-pane .header-content .header-image {
          width: 274px;
          height: 124px; } }
      @media print, screen and (min-width: 64em) {
        .knowledge-pane .header-content .header-image {
          width: 361px;
          height: 164px; } }
  .knowledge-pane .body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 26px 0px; }
    @media print, screen and (min-width: 40em) {
      .knowledge-pane .body-content {
        margin: 0px 0px 26px 0px; } }
    @media print, screen and (min-width: 64em) {
      .knowledge-pane .body-content {
        margin: 0px 0px 54px 0px; } }
    .knowledge-pane .body-content .quick-specs-title {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .knowledge-pane .body-content .quick-specs-title .body-title {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0px; }
        @media print, screen and (min-width: 64em) {
          .knowledge-pane .body-content .quick-specs-title .body-title {
            font-size: 22px; } }
      .knowledge-pane .body-content .quick-specs-title .red-border {
        border-bottom: 2px solid #EB0A1E;
        width: 43px;
        margin: 15px 0px 0px 0px; }
        @media print, screen and (min-width: 64em) {
          .knowledge-pane .body-content .quick-specs-title .red-border {
            width: 48px;
            margin: 15px 0px 3px 0px; } }
    .knowledge-pane .body-content .quick-specs {
      width: 255px; }
      @media print, screen and (min-width: 40em) {
        .knowledge-pane .body-content .quick-specs {
          width: 268px; } }
      @media print, screen and (min-width: 64em) {
        .knowledge-pane .body-content .quick-specs {
          width: 310px; } }
      .knowledge-pane .body-content .quick-specs ul {
        width: 255px;
        margin: 0px;
        list-style: none; }
        @media print, screen and (min-width: 40em) {
          .knowledge-pane .body-content .quick-specs ul {
            width: 268px; } }
        @media print, screen and (min-width: 64em) {
          .knowledge-pane .body-content .quick-specs ul {
            width: 310px; } }
      .knowledge-pane .body-content .quick-specs li {
        width: 255px;
        padding: 16.73px 0px 17.27px 0px;
        border-bottom: 1px solid #E6E6E6;
        line-height: 20px; }
        @media print, screen and (min-width: 40em) {
          .knowledge-pane .body-content .quick-specs li {
            width: 268px; } }
        @media print, screen and (min-width: 64em) {
          .knowledge-pane .body-content .quick-specs li {
            width: 310px; } }
      .knowledge-pane .body-content .quick-specs li:first-child {
        margin-top: 20px;
        padding-top: 0px; }
      .knowledge-pane .body-content .quick-specs li:last-child {
        border-bottom: none;
        padding-bottom: 30px; }
      .knowledge-pane .body-content .quick-specs p {
        margin-top: 20px 0px 34px 0px;
        line-height: 20px; }
      .knowledge-pane .body-content .quick-specs p:first-child {
        margin: 20px 0px 17px 0px; }
      .knowledge-pane .body-content .quick-specs p:last-child {
        margin: 20px 0px 34px 0px; }
      .knowledge-pane .body-content .quick-specs .quick-spec-item {
        padding: 16.73px 0px 17.27px 0px;
        border-bottom: 1px solid #E6E6E6; }
      .knowledge-pane .body-content .quick-specs .quick-spec-item:last-child {
        border-bottom: none; }
    .knowledge-pane .body-content .explore-button {
      margin: 0px 0px 0px 0px;
      border: 2px solid #000000;
      border-radius: 200px;
      padding: 1px 20px 5px 20px;
      color: black;
      cursor: pointer;
      font: 16px "ToyotaType-Semibold", sans-serif;
      width: 244px; }
      @media print, screen and (min-width: 64em) {
        .knowledge-pane .body-content .explore-button {
          width: 200px; } }
    .knowledge-pane .body-content .arrowButtonSpacing {
      margin-top: 20px; }
