/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.column-title-results {
  display: flex;
  margin: 25px 0px 29px 0px;
  flex-direction: column;
  justify-content: flex-start; }
  @media print, screen and (min-width: 40em) {
    .column-title-results {
      margin: 32px 0px 0px 0px;
      flex-direction: row;
      justify-content: space-between; } }
  @media print, screen and (min-width: 64em) {
    .column-title-results {
      margin-top: 29px; } }
  .column-title-results .column-title-results-left {
    text-align: left;
    color: #252525;
    letter-spacing: 0px;
    margin-bottom: 15px;
    font: normal normal normal 18px/26px "ToyotaType-Regular", sans-serif; }
    @media print, screen and (min-width: 40em) {
      .column-title-results .column-title-results-left {
        margin-bottom: 18px;
        font: normal normal normal 22px/32px "ToyotaType-Regular", sans-serif; } }
  .column-title-results .column-title-results-right {
    color: #808080;
    letter-spacing: 0px;
    font: normal normal 600 14px/20px "ToyotaType-Regular", sans-serif;
    text-align: left; }
    @media print, screen and (min-width: 40em) {
      .column-title-results .column-title-results-right {
        text-align: right;
        padding-right: 14px;
        margin-top: 12px; } }
    @media print, screen and (min-width: 64em) {
      .column-title-results .column-title-results-right {
        padding-right: 0px; } }
  .column-title-results .suggestedProduct {
    float: right; }
    .column-title-results .suggestedProduct a {
      color: #EB0A1E; }
      @media print, screen and (max-width: 39.99875em) {
        .column-title-results .suggestedProduct a {
          font-size: 10px;
          line-height: 16px;
          vertical-align: middle; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .column-title-results .suggestedProduct a {
          font-size: 19px;
          line-height: 24px;
          vertical-align: middle; } }
    @media print, screen and (max-width: 39.99875em) {
      .column-title-results .suggestedProduct {
        padding-right: 20px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .column-title-results .suggestedProduct {
        padding-right: 25px; } }
    @media print, screen and (min-width: 64em) {
      .column-title-results .suggestedProduct {
        padding-right: 50px; } }
