@import "../../../node_modules/foundation-sites/scss/foundation.scss";
@import "./toyota-style-standards.scss";

.tabs-selector-container {
    .tabs-header-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;

        @include breakpoint(950px down) {
            display: none;
        }
        
        li {
            list-style: none;
            padding: 15px 24px;
            border-bottom: 3px solid #F0F0F0;
            display: flex;
            text-align: center;
            align-items: flex-end;
            cursor: pointer;
            font: $font17-reg;

            @include breakpoint(1338px down) {
                font: $h4-reg;
            }

            &.selected {
                border-color: $dark-black;
                font-weight: bold;
            }
        }
    }

    .tabs-header-dropdown {
        display: none;
        max-width: 360px;
        margin: 70px auto 30px auto;

        @include breakpoint(950px down) {
            display: block;
        }
    }

    .tabs-items {
        > div {
            display: none;
            
            &.selected {
                display: flex !important;
            }
        }
    }
}
