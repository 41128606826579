@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.resources-browse-articles {
  .browse-article-header-wrapper {
    display: flex;
    align-items: center;

    .browse-title {
      font-family: $toyota-bold;
      font-size: 24px;
      margin-bottom: 5px;
    }

    .browse-article-filters-fade {
      display: none;
    }
    .browse-article-filters-fade {
      @include breakpoint(medium down) {
          display: block;
          pointer-events: none;
          width: 40px;
          height: 40px;
          position: absolute;
          z-index: 999;
          left: 140px;
          background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      }
      @include breakpoint(small only) {
        left: 35%;
      }
    }

    .browse-article-filters {
      z-index: 99;
      -webkit-column-gap: 5px;
      column-gap: 5px;
      display: flex;
      flex-direction: row;
      margin-left: 20px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
      }

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;

      @include breakpoint(medium only) {
        position: absolute;
        margin-left: 140px;
        width: calc(100% - 140px);
        overflow-x: auto;
        padding: 0 20px;
        left: 0px;
      }

      @include breakpoint(small only) {
        position: absolute;
        margin-left: 35%;
        width: 65%;
        overflow-x: auto;
        padding: 0 20px;
        left: 0px;
      }
    }
  }

  .browse-articles {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include breakpoint(large only) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(medium only) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(735px down) {
      grid-template-columns: 1fr;
    }

  }
}

.resources-browse-articles {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include breakpoint(small only) {
    max-width: 360px;
    margin: auto;
    width: 100%;
  }
  .browse-articles {
    display: grid;
    column-gap: 12px;
    row-gap: 25px;
    max-width: 1035px;
    @include breakpoint(735px down) {
      max-width: 360px;
    }
    .contentCard {
      width: 100%;
      .contentCardTitle {
        padding: 0px;
        width: 95%;
      }
      .contentCardOverlayImage {
        padding: 2px 2px 2px 5px;
        display: flex;
        justify-content: center;
      }
    }
  }
}