/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.bz-vc-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px 50px;
  width: 100%; }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .bz-vc-page {
      padding: 0px 50px; } }
  @media print, screen and (max-width: 39.99875em) {
    .bz-vc-page {
      padding: 0px 35px; } }
  .bz-vc-page .bz-vc-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;
    max-width: 1440px;
    background: transparent linear-gradient(180deg, #001E5A 0%, #001E46 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    row-gap: 42px;
    padding: 60px 91px 67px 91px; }
    @media print, screen and (max-width: 39.99875em) {
      .bz-vc-page .bz-vc-container {
        padding: 0px;
        justify-content: unset;
        padding-top: 53px;
        padding-bottom: 148px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .bz-vc-page .bz-vc-container {
        justify-content: unset;
        padding: 60px 20px 67px 20px; } }
    .bz-vc-page .bz-vc-container .bz-vc-tab-container {
      max-width: 851px;
      width: 100%;
      height: auto; }
      @media print, screen and (max-width: 39.99875em) {
        .bz-vc-page .bz-vc-container .bz-vc-tab-container {
          max-width: 287px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .bz-vc-page .bz-vc-container .bz-vc-tab-container {
          max-width: 695px; } }
    .bz-vc-page .bz-vc-container .bz-vc-carousel-container {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      padding: 0px 100px; }
      @media print, screen and (max-width: 39.99875em) {
        .bz-vc-page .bz-vc-container .bz-vc-carousel-container {
          padding: 0 36px; } }
      .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 {
        position: static; }
        .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 .carouselV2-arrow {
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer; }
          @media print, screen and (max-width: 39.99875em) {
            .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 .carouselV2-arrow {
              top: unset;
              bottom: -85px;
              transform: unset; } }
          .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 .carouselV2-arrow.prev {
            transform: translateY(-50%) rotate(180deg); }
            @media print, screen and (max-width: 39.99875em) {
              .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 .carouselV2-arrow.prev {
                right: 50%;
                left: unset;
                transform: translateX(-13px) rotate(180deg); } }
          @media print, screen and (max-width: 39.99875em) {
            .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 .carouselV2-arrow.next {
              left: 50%;
              right: unset;
              transform: translateX(13px); } }
          .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 .carouselV2-arrow.swiper-button-disabled {
            opacity: 0.5;
            pointer-events: none; }
          .bz-vc-page .bz-vc-container .bz-vc-carousel-container .carouselV2 .carouselV2-arrow .circleArrow {
            cursor: pointer;
            height: 63px;
            width: 63px; }
