@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.round-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $grey-area;

    .round-details-content {
        max-width: 1484px;
        padding: 49px 0 92px 0;

        @include breakpoint(1504px down) {
            padding: 49px 64px 92px;
        }

        @include breakpoint(917px down) {
            padding: 49px 34px 70px;
        }

        span {
            display: block;

            &.title {
                font: $h9-bold;
                margin-bottom: 50px;
            }

            &.back button span {
                font: $p5-semibold;
                cursor: pointer;
            }

            @include breakpoint(1504px down) {
                &.title {
                    font: $font40-bold;
                }

                &.back button span {
                    font: $h4-semibold;
                }
            }

            @include breakpoint(917px down) {
                &.title {
                    font: $h6-bold;
                }
            }
        }

        img.banner {
            margin-top: 30px;
            content: var(--desktop-banner);

            @include breakpoint(1504px down) {
                content: var(--tablet-banner);
            }

            @include breakpoint(917px down) {
                content: var(--mobile-banner);
                max-width: 360px;
            }
        }

        div.missions-grid {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            margin-top: 22px;
            gap: 13px;

            @include breakpoint(1504px down) {
                margin-top: 20px;
                gap: 20px;
                grid-template-columns: repeat(3, 1fr);
            }

            @include breakpoint(917px down) {
                margin-top: 13px;
                gap: 12px;
                grid-template-columns: repeat(1, 1fr);
            }

            .mission-container {
                width: 286px;
                height: 420px;

                @include breakpoint(917px down) {
                    width: 360px;
                    height: 465px;
                }
            }
        }
    }
}
