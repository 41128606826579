@import "./toyota-style-standards.scss";
@import "../../../node_modules/foundation-sites/scss/foundation.scss";
@import "base";

.hero-badge {
    text-align: center;
    width: fit-content;
    padding: 4px 5px 5px 8px;
    margin-bottom: 30px;
    opacity: 0.38;

    @include breakpoint(small, medium) {
        font: $font15-book;
        letter-spacing: 4.5px;
    }

    @include breakpoint($med-laptop){
        font: $p5-book;
        letter-spacing: 4.8px;
    }

    @media only screen and (min-width: 1367px){
        font: $p14-book;
        letter-spacing: 5.4px;
    }
}

.hero-small-title {
    text-align: left;
    margin-top: -15px;
    
    @include breakpoint(small, medium) {
        font: $h13 $toyota-reg;
    }

    @include breakpoint($med-laptop){
        font: $h1-reg;
    }

    @media only screen and (min-width: 1367px){
        font: $font42 $toyota-reg;
    }
}

.hero-large-title {
    text-align: left;
    letter-spacing: 0px;
    margin-top: -10px;
    font-family: $toyota-bold;
    overflow-wrap: break-word;

    @include breakpoint(small, medium) {
        font-size: 40px;
        margin-top: -6px;
        line-height: 40px;
        max-width: 330px;
    }

    @include breakpoint(medium)
    {
        margin-bottom: 20px;
    }

    @include breakpoint(small)
    {
        margin-bottom: 15px;
    }

    @include breakpoint($med-laptop){
        font-size: 56px;
        line-height: 52px;
        max-width: 408px;
        margin-bottom: 25px;
    }

    @media only screen and (min-width: 1367px){
        font-size: 72px;
        line-height: 66px;
        max-width: 525px;
        margin-bottom: 35px;
    }
}

.hero-description {
    text-align: left;
    letter-spacing: 0px;

    @include breakpoint(small) {
        font: $h4 $toyota-reg;
        font-size: $h4;
        line-height: 24px;
        max-width: 95%;
        margin-bottom: 35px;
    }

    @include breakpoint(medium) {
        font: $h4 $toyota-reg;
        line-height: 24px;
        max-width: 300px;
        margin-bottom: 25px;
    }

    @include breakpoint($med-laptop){
        font: $h7-reg;
        line-height: 28px;
        max-width: 410px;
        margin-bottom: 40px;
    }

    @media only screen and (min-width: 1367px){
        font: $h13 $toyota-reg;
        line-height: 32px;
        max-width: 525px;
        margin-bottom: 70px;
    }
}

.homepage-hero-container {
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    padding: 5%;
    margin-bottom: 65px;

    @media only screen and (min-width: 1367px){
        min-height: 778px;
        justify-content: center;
    }

    @include breakpoint($med-laptop down){
        min-height: 564px;
        justify-content: center;
    }

    @include breakpoint(medium down) {
        min-height: 425px;
        justify-content: center;
    }

    @include breakpoint(small down) {
        min-height: 605px;
        justify-content: flex-start;
        padding: 15% 8%;
        margin-bottom: 50px;
        background-position: bottom;
    }

    .roundedButton{
        border: 1px solid;

        @include breakpoint($med-laptop){
            font: $h2-reg;
            width: 180px;
        }

        @include breakpoint(medium down){
            font: $h4-semibold;
            width: 145px;
        }

        @media only screen and (min-width: 1367px){
            font: $h12-semibold;
            width: 255px;
        }
    }
}

.homepage-hero-container.homepage-hero-no-text {
    position: relative;
    height: 100%;
    padding: unset;
    min-height: unset;

    img {
        width: -webkit-fill-available;
    }
}

.homepage-hero-container.homepage-hero-cursor-pointer {
    cursor: pointer;
}

#no-text-homepage-button {
    position: absolute;
    left: 4%;
    bottom: 19%;

    @include breakpoint(small only) {
        left: 6%;
        bottom: 8%;
    }
}