/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.select {
  padding: 0px 35px 0px 10px;
  margin: 5px 0px;
  height: 36px;
  font: 18px "ToyotaType-Semibold", sans-serif;
  font-weight: bold;
  background: url(../../resources/images/red_arrow_down.png) no-repeat #FFF;
  background-size: 25px;
  background-position: right 7px top 7px; }
  @media print, screen and (max-width: 39.99875em) {
    .select {
      padding: 0px 30px 0px 10px;
      font: 12px "ToyotaType-Regular", sans-serif;
      font-weight: bold;
      height: 30px;
      background-position: right 5px top 5px; } }

.rounded {
  border-radius: 24px;
  background: url(../../resources/images/arrowDown_DarkGray.png) no-repeat #FFF;
  background-size: 15px;
  background-position: right 12px top 14px; }

.select-wrapper {
  width: 250px;
  margin: 20px; }
  @media print, screen and (max-width: 39.99875em) {
    .select-wrapper {
      width: 220px;
      margin: 5px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .select-wrapper {
      margin: 5px;
      width: 200px; } }

select:disabled {
  color: #E6E6E6;
  background: #B3B3B3 !important; }
  @media print {
    select:disabled {
      display: none; } }
