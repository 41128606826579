@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.banner-container {
    display: flex;
    position: relative;
    height: 100%;
    img {
        width: -webkit-fill-available;
    }
}
