@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1222px;
  margin: 100px 109px 35px;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;

  @include breakpoint(1264px down) {
    flex-direction: column;
    gap: 20px;
  }

  @include breakpoint(medium down) {
    max-width: 680px;
  }

  @include breakpoint(small down) {
    margin: 50px 34px 30px;
    max-width: 360px;
  }

  .button-style {
    width: 175px;
    height: 58px;
    position: absolute;
    left: 0;
    border-radius: 29px;
    background-color: #0F0F0F;
    font: $h4-bold;
    color: $primary-white;

    @include breakpoint(1264px down) {
      position: relative;
      left: unset;
      align-self: flex-start;
    }
  }

  > span {
    font: $h9-bold;
    text-align: center;

    @include breakpoint(small down) {
      font: $font35-bold;
    }
  }
}