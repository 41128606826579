/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.react-responsive-modal-modal.minicardFeatureModal {
  max-width: 1034px !important; }
  @media print, screen and (max-width: 39.99875em) {
    .react-responsive-modal-modal.minicardFeatureModal {
      max-width: 315px !important; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .react-responsive-modal-modal.minicardFeatureModal {
      max-width: 650px !important; } }

.ammModalContent {
  margin: 0px 75px; }
  @media print, screen and (max-width: 39.99875em) {
    .ammModalContent {
      margin: 0px 5px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .ammModalContent {
      margin: 0px 15px; } }
  .ammModalContent .description {
    font: 15px "ToyotaType-Regular", sans-serif;
    margin-top: 25px; }
  .ammModalContent table {
    margin-top: 26px;
    width: 747px; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent table {
        width: 224px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .ammModalContent table {
        width: 508px; } }
  .ammModalContent .tag {
    font: 9px "ToyotaType-Semibold", sans-serif;
    background-color: #EB0A1E;
    border-radius: 10px;
    color: #FFFFFF;
    height: 17px;
    padding: 1px 10px;
    margin-left: 10px;
    margin-top: 3px; }
  .ammModalContent tr {
    width: 747px;
    border-collapse: collapse; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent tr {
        width: 224px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .ammModalContent tr {
        width: 508px; } }
  .ammModalContent td {
    border-bottom: 1px solid #58595B;
    padding: 7px 15px;
    width: 372px; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent td {
        padding: 7px 7px 12px; } }
  .ammModalContent th {
    border-bottom: 1px solid #58595B;
    border-top: 2px solid #58595B;
    padding: 7px 15px;
    width: 50%; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent th {
        padding: 7px 7px 12px;
        width: 100%; } }
  .ammModalContent .rowPrice {
    font: 15px "ToyotaType-Regular", sans-serif;
    text-align: right; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent .rowPrice {
        border-top: none;
        font: 14px "ToyotaType-Regular", sans-serif;
        text-align: center; } }
  .ammModalContent .rowTitle {
    font: 18px "ToyotaType-Semibold", sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 445px; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent .rowTitle {
        font: 16px "ToyotaType-Semibold", sans-serif;
        justify-content: center;
        width: 100%; } }
  .ammModalContent .rowOption {
    font: 15px "ToyotaType-Regular", sans-serif; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent .rowOption {
        font: 14px "ToyotaType-Regular", sans-serif; } }
  .ammModalContent .rowAvailability {
    font: 15px "ToyotaType-Semibold", sans-serif;
    text-align: right; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent .rowAvailability {
        font: 14px "ToyotaType-Semibold", sans-serif; } }
  .ammModalContent .rowHeaders {
    display: flex;
    flex-direction: row;
    width: 747px; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent .rowHeaders {
        flex-direction: column;
        width: 224px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .ammModalContent .rowHeaders {
        width: 508px; } }
  .ammModalContent .tableRows {
    display: flex;
    width: 747px;
    flex-direction: column; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent .tableRows {
        width: 224px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .ammModalContent .tableRows {
        width: 508px; } }
  .ammModalContent .ammModalHeader {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .ammModalContent .ammModalImage img {
    width: auto;
    max-height: 85px; }
  .ammModalContent .ammModalTitle {
    font: 24px "ToyotaType-Bold", sans-serif; }
    @media print, screen and (max-width: 39.99875em) {
      .ammModalContent .ammModalTitle {
        text-align: center;
        margin-bottom: 13px; } }
    @media print, screen and (min-width: 64em) {
      .ammModalContent .ammModalTitle {
        margin-bottom: 12px; } }
