@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";


$tablet-view: 1570px;
$mobile-view: 930px;

.color-selector-wrapper-v2 {
    background: #F6F6F6;
    border-radius: 5px;
    display: flex;
    height: 100%;
    flex-direction: column;

    .color-selector-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 74px;
        padding: 25px 47px 25px 47px;
        border-bottom: 1px solid #CBCBCB;

        @include breakpoint($tablet-view up) {
            justify-content: space-between;
            align-items: start;
        }

        @include breakpoint($tablet-view down) {
            flex-direction: column;
            gap: 5px;
        }

        h3 {
            font: $h3-bold;
            color: $toyota-black;
            margin-bottom: 0;
            text-transform: capitalize;

            @include breakpoint($tablet-view down) {
                font: $h7-bold;
            }
        }

        .color-selector-header-text {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @include breakpoint($tablet-view up) {
                text-align: right;
                margin-top: 7px;
            }

            @include breakpoint($tablet-view down) {
                text-wrap: balance;
            }

            span {
                font: $font17-reg;
                color: $toyota-black;
                max-width: 100%;

                @include breakpoint($mobile-view up) {
                    height: auto;
                }

                @include breakpoint($tablet-view down) {
                    font: $h4-reg;
                }
            }

            .paint-code-charge {
                cursor: pointer;
                font: $font17-reg;
                color: #EB0A1E;
            }
        }
    }

    .color-selector-body {
        column-gap: 34px;
        margin: 20px 46px 8px 53px;
        column-count: 2;

        @include breakpoint($tablet-view down) {
            margin: 30px 41px;
            column-count: 1;
            margin-bottom: 20px;
        }


        li {
            word-break: break-word;
            break-inside: avoid;
            height: auto;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            flex-direction: row;
            column-gap: 17px;
            min-height: 47px;
            padding-top: 10px;
            padding-bottom: 20px;
            position: relative;

            div {
                height: 48px;
                width: 48px;
                min-width: 48px;
                min-height: 48px;
                border: 1px solid #CBCBCB;
                border-radius: 100%;
                display: flex;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    .color-item-tooltip {
                        display: block;
                    }
                }
            }

            .color-item {
                width: 100%;
                text-wrap: wrap;
                text-align: left;

                .color-item-name {
                    font: $h4-semibold;
                }

                .color-item-id {
                    font: $font15-book;
                    max-width: 125px;
                    display: flex;
                    justify-content: space-between;

                    .paint-code-charge {
                        color: #EB0A1E;
                    }

                }
            }

            &.selected {
                border-color: #e7e7e7;

                div {
                    &::before {
                        content: var(--check-icon);
                        width: 20px;
                        height: 20px;
                        margin-top: 10px;
                        margin-left: 13px;
                        display: block;
                    }

                    &::after {
                        content: '';
                        width: 60px;
                        height: 60px;
                        display: block;
                        position: absolute;
                        border: 1px solid #707070;
                        border-radius: 100%;
                        margin-top: -7px;
                        margin-left: -7px;
                    }
                }
            }

            &:not(.available) {
                cursor: not-allowed;

                div:before {
                    content: '';
                    width: 47px;
                    height: 47px;
                    border-bottom: 2px solid #CBCBCB;
                    -webkit-transform: translateY(22px) translateX(8px) rotate(320deg);
                    margin-top: -40px;
                    margin-left: -23px;
                    opacity: 0.6;
                }

                .color-item-name {
                    opacity: 0.3;
                    font: $h4-semibold;
                }

                .color-item-id {
                    opacity: 0.3;
                    font: $font15-book;
                    display: flex;
                    justify-content: space-between;

                    .paint-code-charge {
                        color: #EB0A1E;
                    }

                }
            }
        }
    }
}