@import "./toyota-style-standards.scss";
@import "../../../node_modules/foundation-sites/scss/foundation.scss";

.contentCard{
    width: 360px;

    &.compact {
        width: 100%;

        .contentCardTitle {
            text-align: left;
            font: $h4-bold;
        }
    }

    a:hover{
        text-decoration: none;
    }
    
    .contentCardImageContainer{
        position: relative;

        .contentCardImage{
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: $dark-black;
            overflow: hidden;
            aspect-ratio: 16/9;

            .react-freezeframe {
                height: 100%;
                .ff-container {
                    height: 100%;
                    width: unset;
                }
            }
            
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                &:not(.aspect-ratio) {
                    object-fit: cover;
                }
            }
        }

        .contentCardCornerIcon{
            width: 38px;
            background-color: $dark-black;
            padding: 2px;
            display: flex;
            justify-content: center;
            z-index: 10;
            position: absolute;
            top: 0;
            right: 0;
        }

        .contentCardOverlayImage {
            width: 40px;
            height: 40px;
            z-index: 10;
            position: absolute;
            right: calc(50% - 20px);
            top: calc(50% - 20px);
        }
    }

    .contentCardTitle{
        font: $h12-bold;
        color: $dark-black;
        text-align: center;
        margin-top: 10px;
        padding: 0 5px 5px;
        line-height: 22px;
    }

    &.skeleton-card {
        width: 100%;
        .react-loading-skeleton.image {
            aspect-ratio: 16/9;
        }
    }
}