@charset "UTF-8";
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
@media print {
  .article-page {
    margin-top: 5mm; } }

@media print {
  .article-page .tools-container {
    display: none; } }

@media only screen and (min-width: 450px) {
  .article-page .tools-container {
    border-bottom: 1px solid #58595B;
    height: 60px; } }

.article-page .tools-container .smaller-tool-set {
  padding-left: 20px;
  padding-right: 2vw;
  padding-top: .5vh;
  padding-bottom: .5vh;
  float: right;
  height: 60px; }
  @media only screen and (min-width: 450px) {
    .article-page .tools-container .smaller-tool-set {
      border-left: 1px solid #58595B; } }
  .article-page .tools-container .smaller-tool-set .share-button-container {
    margin-top: 1.4vh; }
  .article-page .tools-container .smaller-tool-set .printer-image-container {
    margin-top: 1.5vh; }
    .article-page .tools-container .smaller-tool-set .printer-image-container .printer-image {
      width: 30px; }
  .article-page .tools-container .smaller-tool-set .email-image-container {
    margin-top: 2vh; }
    .article-page .tools-container .smaller-tool-set .email-image-container .email-image {
      width: 30px; }
  .article-page .tools-container .smaller-tool-set img {
    cursor: pointer;
    opacity: 0.8;
    width: 25px; }
  .article-page .tools-container .smaller-tool-set div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 10px;
    float: right;
    margin-top: 1vh; }

.article-page .article {
  padding: 0px 5% 5% 5%; }
  .article-page .article .ql-indent-1 {
    margin-left: 15px;
    list-style-type: circle; }
  .article-page .article .ql-indent-2 {
    margin-left: 30px;
    list-style-type: square; }
  .article-page .article p {
    line-height: 1.5;
    margin-bottom: 0 !important; }
  .article-page .article .article-date {
    color: #808080; }
  .article-page .article .article-header .article-type {
    color: #EB0A1E;
    font-weight: bold;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-header .article-type {
        font-size: 20px; } }
  .article-page .article .article-header .red-border {
    border-bottom: 2px solid #EB0A1E;
    width: 30px; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-header .red-border {
        width: 50px; } }
  .article-page .article .article-header .overrideText {
    text-align: center;
    font-weight: bold;
    padding-top: 10px; }
  .article-page .article .article-header .noResponse {
    text-align: center;
    font-weight: bold;
    padding: 50px;
    font-size: 1.8em;
    background: rgba(100, 100, 100, 0.3);
    margin: 30px;
    border-radius: 5px; }
  .article-page .article .video-player {
    height: 500px;
    width: 100%; }
    @media print {
      .article-page .article .video-player {
        display: none; } }
  .article-page .article .article-link {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #E6E6E6;
    width: fit-content;
    height: fit-content;
    font-size: 30px;
    border-radius: 25px;
    padding: 15px; }
    .article-page .article .article-link .article-link-text {
      display: inline; }
      .article-page .article .article-link .article-link-text .article-link-arrow {
        width: auto;
        padding: 5px; }
        @media print {
          .article-page .article .article-link .article-link-text .article-link-arrow {
            display: none; } }
    .article-page .article .article-link .article-link-tool-list {
      display: inline;
      list-style: none; }
      @media print {
        .article-page .article .article-link .article-link-tool-list {
          display: none; } }
      .article-page .article .article-link .article-link-tool-list .article-link-tool {
        float: right; }
  .article-page .article .uppercase {
    text-transform: uppercase; }
  @media print {
    .article-page .article .article-body .body-left {
      width: 100%; } }
  .article-page .article .article-body .body-left a {
    color: #EB0A1E; }
  .article-page .article .article-body .body-left .title {
    font-weight: bold;
    font-size: 20px; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-body .body-left .title {
        font-size: 30px; } }
  .article-page .article .article-body .body-left .short-description {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 95%; }
  .article-page .article .article-body .body-left .image {
    margin-top: 15px; }
    @media print, screen and (max-width: 39.99875em) {
      .article-page .article .article-body .body-left .image {
        margin-top: 10px; } }
  .article-page .article .article-body .body-left .article-body {
    margin-top: 20px; }
    @media print {
      .article-page .article .article-body .body-left .article-body {
        font-size: 14px; } }
    .article-page .article .article-body .body-left .article-body .sub-title {
      display: inline-block;
      font-weight: bold; }
    .article-page .article .article-body .body-left .article-body .article-body {
      display: inline; }
  @media print, screen and (min-width: 40em) {
    .article-page .article .article-body .body-left .article-tags {
      margin-top: 0px; } }
  .article-page .article .article-body .body-left .article-tags .article-tag {
    margin-top: 10px;
    white-space: initial;
    margin-right: 10px;
    padding: 7px 7px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #808080;
    color: #808080;
    border-radius: 25px;
    display: inline-block; }
  @media print {
    .article-page .article .article-body .body-left .article-tags {
      font-size: 12px; } }
  .article-page .article .article-body .body-left .promoted-comment-section {
    margin-right: 20%;
    width: 80%;
    display: inline-block; }
    .article-page .article .article-body .body-left .promoted-comment-section .promoted-comment-title {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 24px; }
      @media print {
        .article-page .article .article-body .body-left .promoted-comment-section .promoted-comment-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0px; } }
    .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments {
      font-style: italic; }
      .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container {
        width: 100%;
        margin-top: 15px;
        overflow: auto;
        background-color: #fafafa;
        padding: 10px; }
        @media print {
          .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container {
            margin-top: 0px; } }
        .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container blockquote {
          background: #f9f9f9;
          margin: 0px;
          padding: 0.5em 10px;
          quotes: "“" "”" "‘" "’";
          border-left: 0; }
          @media print {
            .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container blockquote {
              font-size: 12px;
              border: 0; } }
        .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container blockquote:before {
          padding-top: 5px;
          color: #CCCCCC;
          content: open-quote;
          font-size: 3em;
          line-height: 0.1em;
          margin-right: 0.25em;
          vertical-align: -0.4em; }
        .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container blockquote:after {
          padding-top: 5px;
          color: #CCCCCC;
          content: close-quote;
          font-size: 3em;
          line-height: 0.1em;
          margin-left: 0.25em;
          vertical-align: -0.4em; }
        .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container .promoted-comment-text {
          line-height: 24px;
          display: inline;
          margin: 0px;
          white-space: pre-line;
          word-wrap: break-word; }
        .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container .promoted-comment-profile {
          text-align: right;
          margin-top: 25px;
          font-size: 14px;
          float: right; }
          @media print {
            .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container .promoted-comment-profile {
              font-size: 12px;
              margin-top: 10px; } }
          .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container .promoted-comment-profile .promoted-comment-user {
            display: inline;
            font-weight: bold;
            color: #EB0A1E; }
          .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container .promoted-comment-profile .promoted-comment-role {
            padding-left: 10px;
            display: inline; }
          .article-page .article .article-body .body-left .promoted-comment-section .promoted-comments .promoted-comment-container .promoted-comment-profile .promoted-comment-dealership {
            float: right;
            margin-bottom: 0px; }
  .article-page .article .article-body .body-left .comment-section {
    margin-right: 20%;
    margin-top: 30px; }
    @media print, screen and (max-width: 39.99875em) {
      .article-page .article .article-body .body-left .comment-section {
        margin-right: 0; } }
    @media print {
      .article-page .article .article-body .body-left .comment-section {
        display: none; } }
    .article-page .article .article-body .body-left .comment-section .comment-title {
      line-height: 100%;
      font-weight: bold;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px; }
    .article-page .article .article-body .body-left .comment-section .comment-error {
      padding: 30px;
      text-align: center;
      font-weight: bold; }
    .article-page .article .article-body .body-left .comment-section .add-comment {
      height: auto;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column; }
      .article-page .article .article-body .body-left .comment-section .add-comment .add-comment-container {
        display: flex; }
      .article-page .article .article-body .body-left .comment-section .add-comment .user-profile {
        height: 70px;
        width: 70px;
        float: left; }
        .article-page .article .article-body .body-left .comment-section .add-comment .user-profile .user-img {
          border-radius: 100%;
          object-fit: cover;
          float: left;
          height: 60px;
          width: 60px;
          background-size: contain;
          background-color: #E6E6E6;
          object-position: 50% 0; }
      .article-page .article .article-body .body-left .comment-section .add-comment .text-box-container {
        height: auto;
        width: 100%;
        padding-left: 15px;
        float: left; }
        .article-page .article .article-body .body-left .comment-section .add-comment .text-box-container .text-box {
          width: 100%;
          height: 100px;
          resize: none;
          margin-bottom: 0;
          border-radius: 10px; }
      .article-page .article .article-body .body-left .comment-section .add-comment .error-text {
        float: right;
        color: #EB0A1E;
        margin-right: 20px;
        margin-bottom: 0px;
        font-size: 14px;
        padding-top: 5px; }
      .article-page .article .article-body .body-left .comment-section .add-comment .add-button {
        float: right;
        width: 100px;
        height: 30px;
        font: 12px "ToyotaType-Semibold", sans-serif;
        color: #EB0A1E;
        background-color: #E6E6E6;
        padding: 5px;
        cursor: pointer; }
    .article-page .article .article-body .body-left .comment-section .comments {
      display: inline-block;
      width: 100%; }
      .article-page .article .article-body .body-left .comment-section .comments .reply-container {
        display: inline-block;
        margin-left: 55px;
        width: 100%; }
        .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-profile {
          width: 70px;
          float: left; }
          .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-profile .reply-img {
            border-radius: 50%;
            float: right;
            width: 60px;
            height: 60px;
            object-fit: cover;
            background-size: contain;
            background-color: #E6E6E6;
            object-position: 50% 0; }
        .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body {
          width: 87%;
          float: left;
          padding-left: 15px; }
          .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-header {
            height: 25px;
            width: 100%; }
            .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-header .reply-user-header {
              height: 100%;
              width: 85%;
              float: left;
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding-right: 10px; }
              .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-header .reply-user-header .reply-name {
                display: inline;
                color: #EB0A1E; }
              .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-header .reply-user-header .reply-role {
                display: inline;
                margin-left: 10px; }
              .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-header .reply-user-header .reply-dealership {
                display: inline; }
            .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-header .reply-date-container {
              height: 100%;
              width: 15%;
              float: left;
              margin-bottom: 0px;
              padding-right: 10px; }
              .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-header .reply-date-container .reply-date {
                color: #E6E6E6;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                float: right; }
          .article-page .article .article-body .body-left .comment-section .comments .reply-container .reply-body .reply-text {
            padding-right: 15px;
            display: inline-block;
            margin-bottom: 0px; }
    .article-page .article .article-body .body-left .comment-section .comments-more {
      display: flex;
      justify-content: center; }
      .article-page .article .article-body .body-left .comment-section .comments-more .show-button {
        width: 100px;
        height: 30px;
        font: 12px "ToyotaType-Semibold", sans-serif;
        color: #EB0A1E;
        background-color: #E6E6E6;
        padding: 5px;
        cursor: pointer; }
  @media print {
    .article-page .article .article-body .body-right {
      display: none; } }
  .article-page .article .article-body .body-right .usage .usage-title {
    line-height: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-body .body-right .usage .usage-title {
        font-size: 20px;
        margin-top: 0; } }
  .article-page .article .article-body .body-right .usage .red-border-right {
    margin: 7px 0px 7px;
    border-bottom: 2px solid #EB0A1E;
    width: 20px; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-body .body-right .usage .red-border-right {
        width: 35px; } }
  .article-page .article .article-body .body-right .usage .usage-body {
    line-height: 100%;
    font-size: 16px; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-body .body-right .usage .usage-body {
        font-size: 20px; } }
  .article-page .article .article-body .body-right .related-content .related-title {
    line-height: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-body .body-right .related-content .related-title {
        font-size: 20px;
        margin-top: 30px; } }
  .article-page .article .article-body .body-right .related-content .red-border-right {
    margin: 7px 0px 7px;
    border-bottom: 2px solid #EB0A1E;
    width: 20px; }
    @media print, screen and (min-width: 40em) {
      .article-page .article .article-body .body-right .related-content .red-border-right {
        width: 35px; } }
  .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article {
    margin-bottom: 10px; }
    .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article .related-content-type {
      font-size: 10px;
      color: #EB0A1E; }
      @media print, screen and (min-width: 40em) {
        .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article .related-content-type {
          font-size: 14px; } }
    .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article .related-body-bold {
      line-height: 1.3em;
      font-weight: bold;
      font-size: 12px;
      color: #252525; }
      @media print, screen and (min-width: 40em) {
        .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article .related-body-bold {
          font-size: 16px; } }
    .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article .related-body-regular {
      margin-top: 3px;
      font-size: 12px;
      line-height: 1.3em;
      max-height: 3.9em;
      overflow: hidden; }
      @media print, screen and (min-width: 40em) {
        .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article .related-body-regular {
          font-size: 16px; } }
    .article-page .article .article-body .body-right .related-content .related-content-articles .related-content-article a {
      color: #EB0A1E; }

.comment-button-container {
  margin-right: 15px;
  float: right;
  margin-bottom: 0px; }
  .comment-button-container :hover {
    text-decoration: underline; }
  .comment-button-container .comment-button {
    color: #EB0A1E;
    float: right; }

.active-comment-button-container {
  width: 100%;
  justify-content: right;
  float: right;
  display: flex;
  margin-top: 15px !important; }
  .active-comment-button-container .active-button-prompt span {
    font-size: 13px; }
  .active-comment-button-container .active-button-options {
    display: flex; }
    .active-comment-button-container .active-button-options .view-button {
      margin-top: 0; }

.navattic-container .raw-html-embed {
  height: 33vw; }

.navattic-container .nv-scale-dialog-center {
  width: 31.3vw;
  height: auto; }
