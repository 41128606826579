@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

@keyframes rollDown1 {
    0% {
        transform: translate(-50%, 0%);
        visibility: visible;
    }
    100% {
        transform: translate(-50%, 100%);
        visibility: visible;
    }
}

@keyframes rollDown2 {
    0% {
        transform: translate(-50%, -100%);
        visibility: visible;
    }
    100% {
        transform: translate(-50%, 0%);
        visibility: visible;
    }
}

.ring-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $primary-white;
    position: relative;

    .ring-number {
        font: $font74-bold;
        padding: 0 32px;
        min-width: 120px;
        width: 100%;
        height: 72px;
        position: relative;
        overflow: hidden;

        @include breakpoint(1504px down) {
            font: $CTAs-2-5x;
            min-width: 80px;
            height: 45px;
        }

        div {
            position: absolute;
            display: inline-block;
            line-height: 1;
            left: 50%;
            transform: translateX(-50%);

            &.ring-number-1 {
                visibility: hidden;
                animation: rollDown1 1s linear;
            }

            &.ring-number-2 {
                animation: rollDown2 1s linear;
            }
        }
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        right: 0;
        width: 1px;
        height: calc(100% - 12px);
        background-color: $primary-white;
    }

    &:last-child::after {
        display: none;
    }

    .ring-label {
        padding-top: 8px;
        font: $h4-semibold;
    }
}
