@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.navigation-bar {
  display: flex;
  gap: 35px;
  text-align: center;
  font: $h4-reg;
  justify-content: center;
  margin-bottom: 35px;

  @include breakpoint(small only) {
    font: $font15-reg;
  }
}

.tab.active {
  text-decoration: underline;
  text-underline-offset: 2px;
  font: $h4-bold;
  
  @include breakpoint(small only) {
    font: $font15-bold;
  }
}