// profile-page-recent-activities.scss
@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.recent-activities-container {
  .desktop-content {
    display: flex;
    gap: 10%;
  }

  @include breakpoint(small only) {
    max-width: 450px;
    width: 100%;
    margin: auto;
  }
}
