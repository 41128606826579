/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content, .comparison-container {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content, .comparison-container {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title, .comparison-container .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title, .comparison-container .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title, .comparison-container .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p, .comparison-container p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3, .comparison-container h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.sticky-vehicles {
  max-width: 100%;
  margin-left: 0; }
  .sticky-vehicles .vehicle {
    background-color: #252525;
    padding: 10px;
    display: inline-block;
    border-bottom: solid 1px #FFFFFF;
    border-left: solid 1px #FFFFFF;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    min-height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .sticky-vehicles .vehicle:first-child {
      border-left: none; }

.comparison-container .no-response {
  text-align: center;
  font-weight: bold;
  padding: 50px;
  font-size: 1.8em;
  background: rgba(100, 100, 100, 0.3);
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 5px; }
  @media print, screen and (max-width: 39.99875em) {
    .comparison-container .no-response {
      margin-left: 10px;
      margin-right: 10px; } }

.comparison-container .jato-disclaimer-container {
  width: 100%;
  text-align: center; }
  .comparison-container .jato-disclaimer-container .jato-disclaimer {
    font-weight: bold;
    color: #EB0A1E; }

@media print {
  .comparison-container {
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center; } }

.comparison-container .vehicles {
  max-width: 100%; }
  @media print {
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(1),
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(1) ~ .vehicle {
      width: 100%; }
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(2),
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(2) ~ .vehicle {
      width: 50%; }
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(3),
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(3) ~ .vehicle {
      width: 33.33%; }
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(4),
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(4) ~ .vehicle {
      width: 25%; }
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(5),
    .comparison-container .vehicles .vehicle:first-child:nth-last-child(5) ~ .vehicle {
      width: 20%; } }
  .comparison-container .vehicles .loading-message {
    font-weight: bold;
    padding: 10px;
    text-align: center; }
  .comparison-container .vehicles .vehicle {
    display: inline-block;
    font-weight: 600;
    padding: 0 15px;
    text-align: center;
    border-left: #CACACA solid 1px; }
    .comparison-container .vehicles .vehicle:first-child {
      border: none; }
    @media screen and (min-width: 40em) {
      .comparison-container .vehicles .vehicle {
        border: none; } }
  .comparison-container .vehicles .img-vehicle {
    margin: 20px auto; }
  .comparison-container .vehicles .primary-vehicle {
    padding: 0px 35px 0px 10px;
    border: 1px solid #CACACA;
    margin: 5px 0px;
    text-align: left;
    font: 18px "ToyotaType-Semibold", sans-serif;
    height: 36px;
    overflow: hidden;
    background-color: #FFFFFF;
    align-items: center; }
    .comparison-container .vehicles .primary-vehicle span {
      vertical-align: middle;
      font: 16px "ToyotaType-Bold", sans-serif, sans-serif; }
    @media print, screen and (max-width: 39.99875em) {
      .comparison-container .vehicles .primary-vehicle {
        padding: 0px 30px 0px 10px;
        font: 12px "ToyotaType-Regular", sans-serif;
        font-weight: bold;
        height: 30px; } }
  @media print {
    .comparison-container .vehicles .vehicle-dropdown, .comparison-container .vehicles .primary-vehicle {
      padding-right: 0;
      font-size: 80%; } }
  .comparison-container .vehicles .vehicle-dropdown select, .comparison-container .vehicles .primary-vehicle select {
    font: 16px "ToyotaType-Bold", sans-serif; }

.comparison-container .data-table {
  max-width: 100%;
  margin: 0;
  text-align: center; }
  @media print {
    .comparison-container .data-table {
      width: 100%;
      margin-left: 0px;
      text-align: center; } }
  .comparison-container .data-table .clearfix {
    margin-top: 15px; }
  .comparison-container .data-table .loading-message {
    font-weight: bold;
    padding: 10px;
    height: 600px; }
  .comparison-container .data-table .legend {
    margin: 10px 0;
    font-size: 14px;
    height: 24px;
    line-height: 24px; }
    @media print {
      .comparison-container .data-table .legend {
        display: none; } }
    .comparison-container .data-table .legend:first-child {
      margin-right: 10px; }
    .comparison-container .data-table .legend .flag {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 6px; }
      .comparison-container .data-table .legend .flag.best {
        background-color: #0099FF !important; }
  .comparison-container .data-table .jato-data-badge {
    margin: 10px 5px 0px 0; }
    @media print {
      .comparison-container .data-table .jato-data-badge {
        padding-right: 10px; } }
    .comparison-container .data-table .jato-data-badge img {
      height: 16px; }
  .comparison-container .data-table .spec-category {
    display: inline-block;
    width: 100%; }
    @media print {
      .comparison-container .data-table .spec-category {
        width: 100%;
        display: inline; } }
    .comparison-container .data-table .spec-category .spec-category-title {
      background-color: #252525 !important;
      color: #FFFFFF !important;
      font-weight: bold;
      padding: 10px; }
    .comparison-container .data-table .spec-category .spec-data {
      display: inline-block;
      width: 100%; }
      .comparison-container .data-table .spec-category .spec-data .spec-data-title {
        font-weight: bold;
        background-color: #CACACA !important;
        padding: 5px 10px; }
      @media print {
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(1),
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(1) ~ .spec-data-vehicle {
          margin-top: -7px;
          width: 100%; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(2),
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(2) ~ .spec-data-vehicle {
          margin-top: -7px;
          width: 50%; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(3),
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(3) ~ .spec-data-vehicle {
          margin-top: -7px;
          width: 33.33%; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(4),
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(4) ~ .spec-data-vehicle {
          margin-top: -7px;
          width: 25%; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(5),
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child:nth-last-child(5) ~ .spec-data-vehicle {
          margin-top: -7px;
          width: 20%; } }
      .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle {
        display: inline-block;
        font-weight: normal;
        padding: 5px;
        margin: 10px auto;
        border-left: 1px dotted #252525; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle .spec-data-vehicle-content {
          display: inline-block;
          height: 24px; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle .flag {
          margin-left: -16px; }
          .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle .flag:before {
            content: '';
            display: inline-block;
            height: 10px;
            width: 10px;
            margin: 0 6px 3px 0;
            border-radius: 50%;
            vertical-align: middle; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle .best::before {
          background-color: #0099FF !important; }
        .comparison-container .data-table .spec-category .spec-data .spec-data-vehicle:first-child {
          border: none; }

.comparison-container .vehicle-span-1 {
  width: 100%; }

.comparison-container .vehicle-span-2 {
  width: 50%;
  margin-left: 0;
  margin-right: 0; }

.comparison-container .vehicle-span-3 {
  width: 50%; }
  @media screen and (min-width: 40em) {
    .comparison-container .vehicle-span-3 {
      width: 33.33%;
      width: calc(100% / 3);
      margin-left: 0;
      margin-right: 0; } }

.comparison-container .vehicle-span-4 {
  width: 50%; }
  @media screen and (min-width: 40em) {
    .comparison-container .vehicle-span-4 {
      width: 25%;
      margin-left: 0;
      margin-right: 0; } }

.comparison-container .vehicle-span-5 {
  width: 50%; }
  @media screen and (min-width: 40em) {
    .comparison-container .vehicle-span-5 {
      width: 20%;
      margin-left: 0;
      margin-right: 0; } }

.comparison-container .head-to-head-legal-text p {
  margin-bottom: 0px;
  font: 10px "ToyotaType-Regular", sans-serif; }
