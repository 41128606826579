/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.chipGroupComponent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }
  @media print, screen and (max-width: 39.99875em) {
    .chipGroupComponent {
      gap: 32px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .chipGroupComponent {
      gap: 24px; } }
  @media print, screen and (min-width: 64em) {
    .chipGroupComponent {
      gap: 16px; } }
  .chipGroupComponent .chipComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
    cursor: default; }
    @media print, screen and (max-width: 39.99875em) {
      .chipGroupComponent .chipComponent {
        height: 40px;
        width: 315px; }
        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
          .chipGroupComponent .chipComponent:not(:last-child) {
            margin-right: 32px; } } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .chipGroupComponent .chipComponent {
        height: 37px;
        width: 198px; }
        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
          .chipGroupComponent .chipComponent:not(:last-child) {
            margin-right: 24px; } } }
    @media print, screen and (min-width: 64em) {
      .chipGroupComponent .chipComponent {
        height: 37px;
        width: 220px; }
        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
          .chipGroupComponent .chipComponent:not(:last-child) {
            margin-right: 16px; } } }
    .chipGroupComponent .chipComponent .chipContent {
      display: flex;
      width: inherit;
      flex-direction: row;
      justify-content: center; }
      @media print, screen and (max-width: 39.99875em) {
        .chipGroupComponent .chipComponent .chipContent {
          padding: 0px 28px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .chipGroupComponent .chipComponent .chipContent {
          padding: 0px 19px; } }
      @media print, screen and (min-width: 64em) {
        .chipGroupComponent .chipComponent .chipContent {
          padding: 0px 20px; } }
      .chipGroupComponent .chipComponent .chipContent .chipLabel {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-bottom: 5px; }
        @media print, screen and (max-width: 39.99875em) {
          .chipGroupComponent .chipComponent .chipContent .chipLabel {
            font: 15px "ToyotaType-Semibold", sans-serif; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .chipGroupComponent .chipComponent .chipContent .chipLabel {
            font: 16px "ToyotaType-Semibold", sans-serif; } }
        @media print, screen and (min-width: 64em) {
          .chipGroupComponent .chipComponent .chipContent .chipLabel {
            font: 16px "ToyotaType-Semibold", sans-serif; } }
  .chipGroupComponent .chipComponent.active {
    color: #FFFFFF;
    border: 2px solid #252525;
    background-color: #252525; }
  .chipGroupComponent .chipComponent.placeholder {
    color: #CDCDCE;
    border: 2px solid #CDCDCE; }
  .chipGroupComponent .chipComponent.deletableChip .chipContent {
    justify-content: space-between;
    align-items: center; }
    @media print, screen and (max-width: 39.99875em) {
      .chipGroupComponent .chipComponent.deletableChip .chipContent {
        padding: 0px 0px 0px 28px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .chipGroupComponent .chipComponent.deletableChip .chipContent {
        padding: 0px 0px 0px 19px; } }
    @media print, screen and (min-width: 64em) {
      .chipGroupComponent .chipComponent.deletableChip .chipContent {
        padding: 0px 0px 0px 20px; } }
    @media print, screen and (max-width: 39.99875em) {
      .chipGroupComponent .chipComponent.deletableChip .chipContent .chipLabel {
        margin-left: 4px;
        width: 205px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .chipGroupComponent .chipComponent.deletableChip .chipContent .chipLabel {
        margin-left: 8px;
        width: 130px; } }
    @media print, screen and (min-width: 64em) {
      .chipGroupComponent .chipComponent.deletableChip .chipContent .chipLabel {
        margin-left: 7px;
        width: 148px; } }
    .chipGroupComponent .chipComponent.deletableChip .chipContent .chipIcon {
      cursor: pointer; }
      @media print, screen and (max-width: 39.99875em) {
        .chipGroupComponent .chipComponent.deletableChip .chipContent .chipIcon {
          padding: 15px 30px 15px 30px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .chipGroupComponent .chipComponent.deletableChip .chipContent .chipIcon {
          padding: 13px 20px 13px 10px; } }
      @media print, screen and (min-width: 64em) {
        .chipGroupComponent .chipComponent.deletableChip .chipContent .chipIcon {
          padding: 13px 18px 13px 13px; } }
      .chipGroupComponent .chipComponent.deletableChip .chipContent .chipIcon .deleteIcon {
        display: block;
        box-sizing: border-box;
        width: 12px; }
  .chipGroupComponent .chipComponent.clickableChip {
    cursor: pointer; }
  .chipGroupComponent .chipComponent.placeholder.clickableChip:hover {
    color: #252525;
    border: 2px solid #252525; }
