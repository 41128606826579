@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.trim-selector-container {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    gap: 20px 10px;
    flex-wrap: wrap;

    &.has-powertrain-category {
        flex-direction: column;
        gap: 15px;

        .trim-item-container{
            display: flex;
            justify-content: flex-start;
            gap: 16px 10px;
            flex-wrap: wrap;
            margin-bottom: 10px;

            @include breakpoint(small only) {
                margin-bottom: 25px;
            }
        }
    
        .trim-item-header {
            font: $h12-semibold ;
        }
    }

    .trim-item {
        font: $p14-reg;
        cursor: pointer;
        text-decoration: none;
        line-height: 1.5;;
        border: 1px solid $mid-dark-grey;
        border-radius: 50px;
        padding: 0 16px;
        height: 32px;

        .selected {
            font: $p14-bold;
            text-decoration: none;
            text-underline-offset: 0px;
        }

        &.trim-item-selected {
            color: $primary-white;
            background-color: $dark-black;
        }
    }

    .trim-selector-skeleton-container span {
        display: flex;
        flex-direction: row;
        gap: 20px;

        @include breakpoint(medium){
            flex-wrap: wrap;
        }

        @include breakpoint(small only) {
            flex-wrap: wrap;
        }
    }
}
