/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.featuredCarousel {
  margin-bottom: 110px; }
  @media only screen and (max-width: 641px) {
    .featuredCarousel {
      margin-bottom: 50px; } }
  @media only screen and (min-width: 640px) and (max-width: 1250px) {
    .featuredCarousel {
      margin-bottom: 90px; } }
  .featuredCarousel .carouselV2.spotlight {
    width: 100vw;
    max-width: 2864px; }
    .featuredCarousel .carouselV2.spotlight .carouselV2-slides {
      width: 2864px; }
      @media only screen and (min-width: 640px) and (max-width: 1250px) {
        .featuredCarousel .carouselV2.spotlight .carouselV2-slides {
          width: 1898px; } }
  .featuredCarousel .carouselV2-arrow {
    top: 40% !important; }
    .featuredCarousel .carouselV2-arrow .circleArrow {
      cursor: pointer;
      height: 74px;
      width: 74px;
      background-color: #000000ad;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media only screen and (max-width: 1250px) {
        .featuredCarousel .carouselV2-arrow .circleArrow {
          height: 48px;
          width: 48px; } }
      .featuredCarousel .carouselV2-arrow .circleArrow .arrowIcon {
        height: 18px;
        width: 10px; }
      .featuredCarousel .carouselV2-arrow .circleArrow.prev .arrowIcon {
        margin-right: 3px; }
      .featuredCarousel .carouselV2-arrow .circleArrow.next .arrowIcon {
        margin-left: 3px; }
  .featuredCarousel .swiper .swiper-wrapper .swiper-slide.swiper-slide-prev {
    pointer-events: none;
    filter: brightness(38%);
    transition: filter 0.5s; }
  .featuredCarousel .swiper .swiper-wrapper .swiper-slide.swiper-slide-next {
    pointer-events: none;
    filter: brightness(38%);
    transition: filter 0.5s; }
  .featuredCarousel .swiper .swiper-pagination {
    height: 42px;
    margin-top: -4px; }
    .featuredCarousel .swiper .swiper-pagination .swiper-pagination-bullet {
      margin: 0 8px; }
  @media print, screen and (min-width: 64em) {
    .featuredCarousel .resizeSpinner {
      padding: 240px 0px 239px 0px; } }
  @media only screen and (min-width: 640px) and (max-width: 1250px) {
    .featuredCarousel .resizeSpinner {
      padding: 154px 0px 153px 0px; } }

.featuredItemsMobile {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center; }

.featuredItemsMobileButton {
  margin: 34px auto 0;
  font: 20px "ToyotaType-Bold", sans-serif;
  color: #EB0A1E;
  text-align: center;
  cursor: pointer; }

@media only screen and (max-width: 640px) {
  .featuredCarouselItem {
    width: 429px;
    height: 231px; } }

@media only screen and (min-width: 640px) and (max-width: 1250px) {
  .featuredCarouselItem {
    width: 622px;
    height: 328px; } }

@media only screen and (min-width: 1251px) {
  .featuredCarouselItem {
    width: 945px;
    height: 500px; } }

.featuredCarouselItem a:hover {
  text-decoration: none; }

.featuredCarouselItem .featuredCarouselImageContainer {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  background-size: cover; }
  @media only screen and (max-width: 640px) {
    .featuredCarouselItem .featuredCarouselImageContainer {
      width: 429px;
      height: 231px; } }
  @media only screen and (min-width: 640px) and (max-width: 1250px) {
    .featuredCarouselItem .featuredCarouselImageContainer {
      width: 622px;
      height: 328px; } }
  @media only screen and (min-width: 1251px) {
    .featuredCarouselItem .featuredCarouselImageContainer {
      width: 945px;
      height: 500px; } }
  .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: auto 30px 0; }
    @media only screen and (max-width: 640px) {
      .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer {
        width: 429px;
        height: 231px; } }
    @media only screen and (min-width: 640px) and (max-width: 1250px) {
      .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer {
        width: 622px;
        height: 328px; } }
    @media only screen and (min-width: 1251px) {
      .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer {
        width: 945px;
        height: 500px; } }
    .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemTitle {
      color: #fefefe;
      text-align: left;
      letter-spacing: 0px;
      opacity: 1; }
      @media only screen and (max-width: 640px) {
        .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemTitle {
          width: 372px;
          font: 20px "ToyotaType-Bold", sans-serif; } }
      @media only screen and (min-width: 640px) and (max-width: 1250px) {
        .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemTitle {
          width: 562px;
          font: 20px "ToyotaType-Bold", sans-serif; } }
      @media only screen and (min-width: 1251px) {
        .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemTitle {
          width: 881px;
          font: 24px "ToyotaType-Bold", sans-serif; } }
    .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemDescription {
      color: #fefefe;
      text-align: left;
      letter-spacing: 0px;
      opacity: 1; }
      @media only screen and (max-width: 640px) {
        .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemDescription {
          font: 15px "ToyotaType-Regular", sans-serif;
          width: 372px;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 640px) and (max-width: 1250px) {
        .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemDescription {
          font: 16px "ToyotaType-Regular", sans-serif;
          width: 562px;
          margin-bottom: 25px; } }
      @media only screen and (min-width: 1251px) {
        .featuredCarouselItem .featuredCarouselImageContainer .featuredCarouselTextContainer .featuredCarouselItemDescription {
          font: 20px "ToyotaType-Regular", sans-serif;
          width: 881px;
          margin-bottom: 25px; } }
