/*
    TOYOTA PRIMARY COLOR PALATTE
*/

$primary-red: #EB0A1E;
$primary-red-dark: #A60000;
$primary-blue: #0099FF;
$primary-blue-light: #00AEEF;
$primary-blue-dark: #007AEC;
$primary-blue-nitro: #2DAEFF;
$primary-blue-nitro-opacity: rgba(45, 174, 255, 0.1);
$dark-black: #000000;
$primary-black: #252525;
$primary-black-background: rgba(37, 37, 37, 0.749);
$primary-black-boxshadow: #00000029;
$primary-black-hover-boxshadow: #0000002E;
$border-light-grey: #D8D8D8;
$border-grey: #BCBEC0;
$grey-area: #F4F4F4;
$dark-grey: #58595B;
$mid-dark-grey: #707070;
$mid-grey: #808080;
$light-grey: #E6E6E6;
$grey-hover: #F5F6F7;
$secondary-light-grey: #B3B3B3;
$tertiary-light-grey: #acacac;
$secondary-grey: #CCCCCC;
$primary-white: #FFFFFF;
$soft-white: #fafafa;
$primary-background-grey: #F5F5F5;
$mobile-header-grey: #1B1B1B;
$navigation-text: #333333;
$clear: rgba(0, 0, 0, 0);
/*
    TOYOTA ACCENT COLOR PALATTE
*/

$accent-yellow: #FFD200;
$accent-yellow-dark: #ECBD00;
$accent-orange: #FF8E14;
$accent-orange-dark: #E67700;
$accent-green: #3CD808;
$accent-green-dark: #30C300;

/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/

@font-face {
    font-family: "ToyotaType-Regular";
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf') format('truetype');
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff') format('woff');
}

@font-face {
    font-family: "ToyotaType-Light";
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf') format('truetype'); //support for Chrome & Edge
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Light.woff') format('woff'); //support for IE
}

@font-face {
    font-family: "ToyotaType-Bold";
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf') format('truetype');
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff') format('woff');
}

@font-face {
    font-family: "ToyotaType-Semibold";
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf') format('truetype');
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff') format('woff');
}

@font-face {
    font-family: "ToyotaType-Black";
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf') format('truetype');
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Black.woff') format('woff');
}

@font-face {
    font-family: "ToyotaType-Book";
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf') format('truetype');
    src: url('https://engage.toyota.com/static/fonts/ToyotaType-Book.woff') format('woff');
}

/*
    PRINT CONFIGUTARIONS
*/
@page {
    size: portrait;
    margin: 33px;
}

/*
    VARIABLE FONT DEFINITIONS
*/
$toyota-reg: "ToyotaType-Regular", sans-serif;
$toyota-light: "ToyotaType-Light", sans-serif;
$toyota-bold: "ToyotaType-Bold", sans-serif;
$toyota-semibold: "ToyotaType-Semibold", sans-serif;
$toyota-black: "ToyotaType-Black", sans-serif;
$toyota-book: "ToyotaType-Book", sans-serif;

$h1: 36px;
$h1-book: $h1 $toyota-light;
$h1-reg: $h1 $toyota-reg;
$h1-bold: $h1 $toyota-bold;
$h1-semibold: $h1 $toyota-semibold;
$h1-black: $h1 $toyota-black;

$h2: 18px;
$h2-book: $h2 $toyota-book;
$h2-reg: $h2 $toyota-reg;
$h2-bold: $h2 $toyota-bold;
$h2-semibold: $h2 $toyota-semibold;
$h2-black: $h2 $toyota-black;

$h3: 24px;
$h3-book: $h3 $toyota-light;
$h3-reg: $h3 $toyota-reg;
$h3-bold: $h3 $toyota-bold;
$h3-semibold: $h3 $toyota-semibold;
$h3-black: $h3 $toyota-black;

$h4: 16px;
$h4-book: $h4 $toyota-book;
$h4-reg: $h4 $toyota-reg;
$h4-bold: $h4 $toyota-bold;
$h4-semibold: $h4 $toyota-semibold;
$h4-black: $h4 $toyota-black;

$h6: 30px;
$h6-book: $h6 $toyota-light;
$h6-reg: $h6 $toyota-reg;
$h6-bold: $h6 $toyota-bold;
$h6-semibold: $h6 $toyota-semibold;
$h6-black: $h6 $toyota-black;

$h7: 22px;
$h7-book: $h7 $toyota-light;
$h7-reg: $h7 $toyota-reg;
$h7-bold: $h7 $toyota-bold;
$h7-semibold: $h7 $toyota-semibold;
$h7-black: $h7 $toyota-black;

$h8: 80px;
$h8-book: $h8 $toyota-book;
$h8-reg: $h8 $toyota-reg;
$h8-bold: $h8 $toyota-bold;
$h8-semibold: $h8 $toyota-semibold;
$h8-black: $h8 $toyota-black;


$h9: 40px;
$h9-book: $h9 $toyota-light;
$h9-reg: $h9 $toyota-reg;
$h9-bold: $h9 $toyota-bold;
$h9-semibold: $h9 $toyota-semibold;
$h9-black: $h9 $toyota-black;

$h10: 60px;
$h10-book: $h10 $toyota-book;
$h10-reg: $h10 $toyota-reg;
$h10-bold: $h10 $toyota-bold;
$h10-semibold: $h10 $toyota-semibold;
$h10-black: $h10 $toyota-black;

$h11: 42px;
$h11-book: $h11 $toyota-book;
$h11-reg: $h11 $toyota-reg;
$h11-bold: $h11 $toyota-bold;
$h11-semibold: $h11 $toyota-semibold;
$h11-black: $h11 $toyota-black;

$h12: 20px;
$h12-book: $h12 $toyota-light;
$h12-reg: $h12 $toyota-reg;
$h12-bold: $h12 $toyota-bold;
$h12-semibold: $h12 $toyota-semibold;
$h12-black: $h12 $toyota-black;

$h13: 28px;
$h13-book: $h13 $toyota-book;
$h13-reg: $h13 $toyota-reg;
$h13-bold: $h13 $toyota-bold;
$h13-semibold: $h13 $toyota-semibold;
$h13-black: $h13 $toyota-black;

$p1: 20px;
$p1-book: $p1 $toyota-light;
$p1-reg: $p1 $toyota-reg;
$p1-bold: $p1 $toyota-bold;
$p1-semibold: $p1 $toyota-semibold;
$p1-black: $p1 $toyota-black;

$p5: 16px;
$p5-book: $p5 $toyota-light;
$p5-reg: $p5 $toyota-reg;
$p5-bold: $p5 $toyota-bold;
$p5-semibold: $p5 $toyota-semibold;
$p5-black: $p5 $toyota-black;

$p6: 11px;
$p6-book: $p6 $toyota-light;
$p6-reg: $p6 $toyota-reg;
$p6-bold: $p6 $toyota-bold;
$p6-semibold: $p6 $toyota-semibold;
$p6-black: $p6 $toyota-black;

$p7: 12px;
$p7-book: $p7 $toyota-light;
$p7-reg: $p7 $toyota-reg;
$p7-bold: $p7 $toyota-bold;
$p7-semibold: $p7 $toyota-semibold;
$p7-black: $p7 $toyota-black;

$p8: 8px;
$p8-book: $p8 $toyota-light;
$p8-reg: $p8 $toyota-reg;
$p8-bold: $p8 $toyota-bold;
$p8-semibold: $p8 $toyota-semibold;
$p8-black: $p8 $toyota-black;

$p9: 6px;
$p9-book: $p9 $toyota-book;
$p9-reg: $p9 $toyota-reg;
$p9-bold: $p9 $toyota-bold;
$p9-semibold: $p9 $toyota-semibold;
$p9-black: $p9 $toyota-black;

$p10: 13px;
$p10-book: $p10 $toyota-book;
$p10-reg: $p10 $toyota-reg;
$p10-bold: $p10 $toyota-bold;
$p10-semibold: $p10 $toyota-semibold;
$p10-black: $p10 $toyota-black;

$p11: 14px;
$p11-book: $p11 $toyota-light;
$p11-reg: $p11 $toyota-reg;
$p11-bold: $p11 $toyota-bold;
$p11-semibold: $p11 $toyota-semibold;
$p11-black: $p11 $toyota-black;

$p12: 10px;
$p12-book: $p12 $toyota-book;
$p12-reg: $p12 $toyota-reg;
$p12-bold: $p12 $toyota-bold;
$p12-semibold: $p12 $toyota-semibold;
$p12-black: $p12 $toyota-black;

$p13: 9px;
$p13-book: $p13 $toyota-light;
$p13-reg: $p13 $toyota-reg;
$p13-bold: $p13 $toyota-bold;
$p13-semibold: $p13 $toyota-semibold;
$p13-black: $p13 $toyota-black;

$p14: 18px;
$p14-book: $p14 $toyota-light;
$p14-reg: $p14 $toyota-reg;
$p14-bold: $p14 $toyota-bold;
$p14-semibold: $p14 $toyota-semibold;
$p14-black: $p14 $toyota-black;


$font15: 15px;
$font15-book: $font15 $toyota-book;
$font15-reg: $font15 $toyota-reg;
$font15-bold: $font15 $toyota-bold;
$font15-semibold: $font15 $toyota-semibold;
$font15-black: $font15 $toyota-black;

$font17: 17px;
$font17-book: $font17 $toyota-light;
$font17-reg: $font17 $toyota-reg;
$font17-bold: $font17 $toyota-bold;
$font17-semibold: $font17 $toyota-semibold;
$font17-black: $font17 $toyota-black;

$font19: 19px;
$font19-book: $font19 $toyota-book;
$font19-reg: $font19 $toyota-reg;
$font19-bold: $font19 $toyota-bold;
$font19-semibold: $font19 $toyota-semibold;
$font19-black: $font19 $toyota-black;

$font25: 25px;
$font25-book: $font25 $toyota-light;
$font25-reg: $font25 $toyota-reg;
$font25-bold: $font25 $toyota-bold;
$font25-semibold: $font25 $toyota-semibold;
$font25-black: $font25 $toyota-black;

$font26: 26px;
$font26-book: $font26 $toyota-light;
$font26-reg: $font26 $toyota-reg;
$font26-bold: $font26 $toyota-bold;
$font26-semibold: $font26 $toyota-semibold;
$font26-black: $font26 $toyota-black;

$font34: 34px;
$font34-book: $font34 $toyota-light;
$font34-reg: $font34 $toyota-reg;
$font34-bold: $font34 $toyota-bold;
$font34-semibold: $font34 $toyota-semibold;
$font34-black: $font34 $toyota-black;

$font32: 32px;
$font32-book: $font32 $toyota-book;
$font32-reg: $font32 $toyota-reg;
$font32-bold: $font32 $toyota-bold;
$font32-semibold: $font32 $toyota-semibold;
$font32-black: $font32 $toyota-black;

$font35: 35px;
$font35-book: $font35 $toyota-book;
$font35-reg: $font35 $toyota-reg;
$font35-bold: $font35 $toyota-bold;
$font35-semibold: $font35 $toyota-semibold;
$font35-black: $font35 $toyota-black;

$font38: 38px;
$font38-book: $font38 $toyota-book;
$font38-reg: $font38 $toyota-reg;
$font38-bold: $font38 $toyota-bold;
$font38-semibold: $font38 $toyota-semibold;
$font38-black: $font38 $toyota-black;

$font40: 40px;
$font40-book: $font40 $toyota-book;
$font40-reg: $font40 $toyota-reg;
$font40-bold: $font40 $toyota-bold;
$font40-semibold: $font40 $toyota-semibold;
$font40-black: $font40 $toyota-black;

$font42: 42px;
$font42-book: $font42 $toyota-book;
$font42-reg: $font42 $toyota-reg;
$font42-bold: $font42 $toyota-bold;
$font42-semibold: $font42 $toyota-semibold;
$font42-black: $font42 $toyota-black;

$font45: 45px;
$font45-book: $font45 $toyota-book;
$font45-reg: $font45 $toyota-reg;
$font45-bold: $font45 $toyota-bold;
$font45-semibold: $font45 $toyota-semibold;
$font45-black: $font45 $toyota-black;

$font46: 46px;
$font46-book: $font46 $toyota-book;
$font46-reg: $font46 $toyota-reg;
$font46-bold: $font46 $toyota-bold;
$font46-semibold: $font46 $toyota-semibold;
$font46-black: $font46 $toyota-black;

$font50: 50px;
$font50-book: $font50 $toyota-book;
$font50-reg: $font50 $toyota-reg;
$font50-bold: $font50 $toyota-bold;
$font50-semibold: $font50 $toyota-semibold;
$font50-black: $font50 $toyota-black;

$font74: 74px;
$font74-book: $font74 $toyota-book;
$font74-reg: $font74 $toyota-reg;
$font74-bold: $font74 $toyota-bold;
$font74-semibold: $font74 $toyota-semibold;
$font74-black: $font74 $toyota-black;

$headline2-2x: 48px $toyota-light;
$CTAs-3x: 54px $toyota-semibold;
$CTAs-2-5x: 45px $toyota-semibold;
$body-p2: 14px $toyota-reg;
$body-1x: 12px $toyota-reg;
$text-link-1x: 12px $toyota-semibold;
$legal-copy-1x: 10px $toyota-reg;

/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
$sm-mobile: 310px; // iPhone 4/5
$med-mobile: 375px; // iPhone 6/7/8, iPhone X
$lg-mobile: 411px; // Pixel 1/2, iPhone 6/7/8 Plus
$xl-mobile: 640px; // Foundation's medium column breakpoint
$sm-tablet: 768px; // iPad/iPad mini
$lg-tablet: 1024px; // iPad Pro, Microsoft Surface
$sm-laptop: 1280px; // Kindle/Kindle Fire
$med-laptop: 1366px; // Yoga/ThinkPad
$lg-laptop: 1440px;

/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/

/*
    CSS ANIMATION & TRANSITIONS
*/
$top-nav-transition-all: all 0.5s;
