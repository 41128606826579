@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.trim-walk-component {
    @media print {
        .trim-walk-selection-wrapper {
            background-color: #F7F7F7 !important;
            print-color-adjust: exact;
            border: unset;
        }
        .trim-walk-selection-wrapper.vehicle0{
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
        }
        .trim-walk-selection-wrapper.vehicle1{
            border-radius: unset;
        }
        .trim-walk-selection-wrapper.vehicle2{
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
        }

        .vehicle-choice-sections {
            flex-direction: row;
            gap: 5px;
            margin-bottom: 25px;
        }
        .vehicle-title-text {
            color: black;
            padding: 2px 0px 10px 0px;
            font-size: 12px;
        }

        .vehicle-subtitle-text {
            display: none;
        }

        .trim-walk-selection-container {
            flex-direction: column-reverse;
            padding: 10px 15px 20px 15px;

            .trim-walk-selection-content {
                width: unset;
                margin-top: -20px;

                .trim-walk-trim-name {
                    font: .8em $toyota-bold;
                    text-wrap: balance;
                }

                .trim-walk-show-more-less {
                    display: none;
                }

                .model-number {
                    font: .8em $toyota-bold;
                }

                .msrp {
                    .base-msrp {
                        font: .8em $toyota-bold;
                    }
                    .additional-msrp {
                        white-space: nowrap;
                        font: .8em $toyota-book;
                        padding: 0px;
                    }
                }

                .trim-walk-selection-footer {
                    margin-top: -20px;
                    .footer-title {
                        color: black;
                        font: .8em $toyota-bold;
                    }

                    .footer-subtitle {
                        font: .8em $toyota-book;
                    }
                }
            }

            .trim-walk-selection-image {
                max-width: unset;
                width: 100%;
                justify-content: center;

                .image-container {
                    padding-top: 5px;
                    width: 70%
                }
            }
        }

        .trim-walk-selection-bottom{
            .trim-walk-selection-footer{
                .footer-title{
                    color: black;
                }
            }
        }
    }
}
