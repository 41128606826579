/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.my-stats-page {
  background-color: #F4F4F4;
  width: 100%;
  padding: 26px 0 108px 0;
  height: auto;
  display: grid;
  gap: 20px;
  justify-content: center;
  grid-template-columns: 290px 582px 582px;
  grid-template-rows: 88px 473px 394px;
  grid-template-areas: "stats-header stats-header stats-header" "your-progress full-leaderboard stats-badges" "stats-banner full-leaderboard stats-badges"; }
  @media screen and (max-width: 99.6875em) {
    .my-stats-page {
      padding: 39px 24px 81px 24px;
      gap: 25px;
      grid-template-columns: 290px 582px;
      grid-template-rows: 91px 473px 394px 631px;
      grid-template-areas: "stats-header stats-header" "your-progress full-leaderboard" "stats-banner full-leaderboard" "stats-badges stats-badges"; } }
  @media screen and (max-width: 59.375em) {
    .my-stats-page {
      padding: 0px 10% 116px 10%;
      gap: 10px;
      grid-template-columns: 360px;
      grid-template-rows: 91px 473px 376px 1fr auto;
      grid-template-areas: "stats-header" "your-progress" "stats-banner" "full-leaderboard" "stats-badges"; } }
  .my-stats-page .grid-stats-header {
    position: relative;
    grid-area: stats-header;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .my-stats-page .grid-stats-header .global-back-button {
      position: absolute;
      left: 0; }
      @media print, screen and (max-width: 39.99875em) {
        .my-stats-page .grid-stats-header .global-back-button .left-arrow {
          width: 10px; } }
    .my-stats-page .grid-stats-header > span {
      font: 40px "ToyotaType-Bold", sans-serif; }
      @media print, screen and (max-width: 39.99875em) {
        .my-stats-page .grid-stats-header > span {
          font: 35px "ToyotaType-Bold", sans-serif; } }
  .my-stats-page .grid-stats-progress {
    grid-area: your-progress;
    background-color: white;
    border-radius: 5px; }
  .my-stats-page .grid-stats-banner {
    grid-area: stats-banner;
    background-color: white;
    border-radius: 5px; }
    .my-stats-page .grid-stats-banner .dashboard-image-card {
      width: 100%;
      height: 100%;
      background-position-x: center;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center; }
      @media screen and (max-width: 99.6875em) {
        .my-stats-page .grid-stats-banner .dashboard-image-card {
          padding: 32px 28px 56px 28px; } }
      @media screen and (max-width: 56.5625em) {
        .my-stats-page .grid-stats-banner .dashboard-image-card {
          padding: 14px 28px 30px 28px; } }
  .my-stats-page .grid-stats-leaderboard {
    grid-area: full-leaderboard;
    background-color: white;
    border-radius: 5px; }
  .my-stats-page .grid-stats-badges {
    grid-area: stats-badges;
    background-color: white;
    border-radius: 5px; }
