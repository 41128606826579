/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.announcements-container .announcements-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0 5px 0;
  gap: 16px; }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .announcements-container .announcements-filters {
      margin-bottom: 12px; } }
  @media print, screen and (max-width: 39.99875em) {
    .announcements-container .announcements-filters {
      flex-direction: column;
      margin-bottom: 30px; } }
  .announcements-container .announcements-filters .dropdown-layout {
    display: flex;
    flex-direction: row;
    width: 66%;
    gap: 16px; }
    @media print, screen and (max-width: 39.99875em) {
      .announcements-container .announcements-filters .dropdown-layout {
        flex-direction: column;
        width: 100%; } }
    .announcements-container .announcements-filters .dropdown-layout .announcementsDropdown {
      width: 100%;
      font: 15px "ToyotaType-Semibold", sans-serif; }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .announcements-container .announcements-filters .dropdown-layout .announcementsDropdown {
          width: 197px; } }
      @media print, screen and (max-width: 39.99875em) {
        .announcements-container .announcements-filters .dropdown-layout .announcementsDropdown {
          width: auto; } }
    .announcements-container .announcements-filters .dropdown-layout .announcementsDropdown > div.Dropdown-menu {
      overflow-y: hidden; }
  .announcements-container .announcements-filters .mark-all-read-button {
    font: 15px "ToyotaType-Semibold", sans-serif;
    color: #EB0A1E;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-left: auto; }
    .announcements-container .announcements-filters .mark-all-read-button span {
      cursor: pointer; }

.announcements-container .announcementCardV2-container {
  margin: 20px 0 0 0; }
  .announcements-container .announcementCardV2-container .announcementCard-V2 {
    position: relative;
    border-radius: 12px;
    padding: 20px 24px 20px 19px;
    margin-top: 15px; }
    .announcements-container .announcementCardV2-container .announcementCard-V2 .layout {
      display: grid;
      grid-template-columns: [first] minmax(30px, 0.04fr) [line2] 1fr;
      grid-template-rows: [first] auto [line2] 1fr;
      grid-template-areas: "icon title" ". text-content";
      column-gap: 24px; }
      .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .icon {
        grid-area: icon;
        align-self: center;
        aspect-ratio: 1; }
      .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .title {
        width: 100%;
        justify-content: space-between;
        font: 17px "ToyotaType-Semibold", sans-serif;
        white-space: pre-wrap;
        grid-area: title;
        align-self: center; }
      .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .text-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        grid-area: text-content; }
        .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .text-content .description {
          font: 16px "ToyotaType-Regular", sans-serif;
          word-break: break-word;
          white-space: pre-wrap;
          min-height: 35px;
          padding-bottom: 15px; }
        @media only screen and (max-width: 400px) {
          .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .text-content .description {
            padding-bottom: 50px; } }
        .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .text-content .announcement-footer-V2 {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center; }
          .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .text-content .announcement-footer-V2 .time {
            font: 15px "ToyotaType-Regular", sans-serif;
            letter-spacing: -0.23px;
            color: #000000; }
          .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .text-content .announcement-footer-V2 .redirect-button {
            font: 15px "ToyotaType-Semibold", sans-serif;
            width: 137px;
            height: 28px;
            border: 1px solid #707070; }
          @media print, screen and (max-width: 39.99875em) {
            .announcements-container .announcementCardV2-container .announcementCard-V2 .layout .text-content .announcement-footer-V2 .redirect-button {
              right: 25px; } }
  .announcements-container .announcementCardV2-container .announcementCard-V2.read {
    background-color: #f6f6f6; }
  .announcements-container .announcementCardV2-container .announcementCard-V2.unread {
    border: .5px solid #707070;
    cursor: pointer; }

.announcements-container .no-results {
  font: 18px "ToyotaType-Bold", sans-serif;
  color: #EB0A1E;
  margin: 38px auto 45px auto;
  text-align: center; }

.announcements-container .announcements-skeleton-loading .react-loading-skeleton {
  border-radius: 12px;
  margin-top: 20px; }
