@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.profile-page {
  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 750px;
    margin: 0px auto 0px auto;
    gap: 40px;

    @include breakpoint(small only) {
      max-width: 450px;
    }

    .profile-top {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      gap: 70px;

      @include breakpoint(medium only) {
        gap: 40px;
      }

      @include breakpoint(small only) {
        flex-direction: column;
        gap: 40px;
      }

      .profile-info-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include breakpoint(small only) {
          width: 100%;
        }

        .profile-field-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          border-bottom: 1px solid #ACACAC;
          padding-bottom: 11px;

          .profile-field-top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .profile-field-label {
              font: $p1-semibold;
            }
          }

          .profile-field-item {
            font: $p5-reg;
            opacity: 0.4;
            word-wrap: break-word;
          }
        }
      }
    }

    .profile-bottom {
      width: 100%;
      max-width: 750px;
      margin: 0px auto 0px auto;

      .profile-disclaimer {
          font: $font15-reg;
          display: flex;
        }
        .profile-privacy {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          align-items: flex-start;
          a {
            display: flex;
            column-gap: 5px;
            color: rgb(40, 40, 40);
            align-items: center;
            font: $p11-semibold;
            .external-icon {
              max-height: 16px;
            }
          }
        .privacy-choice {
          font: $p11-semibold;
          flex-direction: row;

          a {
            display: flex;
            align-items: center;
            column-gap: 5px;
            color: #ca1f00;
            white-space: nowrap;

            span {
              padding-bottom: 2px;
            }
            .privacy-icon {
              max-width: 40px;
              max-height: 20px;
            }
          }

          a:hover {
            color: #ca1f00;
          }

          @include breakpoint(1350px) {
            margin: auto;
            width: 1254px;
          }
        }
      }
    }
  }
}