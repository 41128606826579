@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.beyond-zero-card-layout-wrapper {
    display: flex;
    min-height: 500px;
    gap: 30px;

    &.flipped {
        flex-direction: row-reverse;
    }

    @include breakpoint($xl-mobile down) {
        flex-direction: column !important;
        gap: 16px;
    }

    .card-one {
        width: 100%;
        height: 885px;
        border-radius: 0px 15px 15px 0px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: var(--desktop-banner);

        &.flipped {
            border-radius: 15px 0px 0px 15px;
            
        }

        @include breakpoint($lg-tablet down) {
            background-image: var(--tablet-banner);
        }

        @include breakpoint($xl-mobile down) {
            background-image: var(--mobile-banner);
            height: 328px;
        }
    }

    .card-two {
        width: 100%;
        border-radius: 15px 0 0 15px;
        height: 644px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: var(--desktop-banner);

        &.flipped {
            border-radius: 0px 15px 15px 0px;
        }

        @include breakpoint($lg-tablet down) {
            height: 412px;
            background-image: var(--tablet-banner);
        }

        @include breakpoint($xl-mobile down) {
            background-image: var(--mobile-banner);
            height: 218px;
        }
    }

    .card-text {
        border-radius: 15px;
        background-color: #B4BAC3;
        padding: 90px;
        max-width: 678px;

        p {
            font: $h6-bold;
            line-height: 40px;
            color: $primary-white;
            margin-bottom: 40px;

            @include breakpoint($lg-tablet down) {
                font: $font25-bold;
                line-height: 35px;
            }
        }

        .learn-more-btn {
            border: none;
            color: $dark-black;
            font: $h4-bold;

            &:hover {
                color: $primary-white;
            }
        }

        @include breakpoint($lg-tablet down) {
            max-width: 451px;
            height: auto;
            padding: 70px;
        }

        @include breakpoint($xl-mobile down) {
            border-radius: 0px;
            max-width: 100%;
            width: 100%;
            padding: 70px 56px;
            min-height: auto;
        }
    }

    @include breakpoint($lg-tablet down) {
        gap: 26px;
    }

    @include breakpoint($xl-mobile down) {
        gap: 16px;
    }

    .card-layout-column {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;

        &:last-child {
            &.flipped {
                align-items: flex-end;
            }
        }
        

        @include breakpoint($xl-mobile down) {
            &:last-child {
                gap: 16px;
                flex-direction: row;

                &.flipped {
                    flex-direction: row-reverse;
                    align-items: flex-start !important;
                }
            }
        }
    }
}