@import './toyota-style-standards.scss';
@import '../../../node_modules/foundation-sites/scss/foundation.scss';
@import 'base';

section.comment-card{
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  border-radius: 12px;
  border: 1px solid $tertiary-light-grey;
  background-color: $primary-white;
  transition: height 0.3s ease-in-out;
  margin-bottom: 15px;

  & > .comment-card-photo-name {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 16px;

    & > img {
      width: 37px;
      height: 37px;
      border-radius: 50%;
    }

    span{
      color: $mid-dark-grey;
      font: $h2-reg;
    }
  }

  & > .comment-card-date {
    position: absolute;
    top: 15px;
    right: 18px;
    color: $mid-dark-grey;
    font: $p7-reg;
  }

  & > .comment-card-title {
    color: $dark-black;
    font: $h2-semibold;
    margin-bottom: 9px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  & > .comment-card-comment {
    color: $dark-black;
    font: $font15-reg;

    &.collapsed {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2; 
      -webkit-box-orient: vertical;
    }
  }

  & > .comment-card-expand-icon {
    width: 13px;
    align-self: center;
    cursor: pointer;
    padding: 16px;
    margin-bottom: -16px;
    box-sizing: content-box;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  &.comment-card-reply {
    width: 80%;
    align-self: flex-end;

    &.comment-card-reply-unread {
      box-shadow: 0px 3px 6px $border-grey;
      cursor: pointer;
    }
  }
}