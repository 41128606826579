@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

// XP Tournament breakpoints
$tablet: 1504px;
$mobile: 917px;

.tournament-rounds {
    display: grid;
    grid-template-columns: repeat(2, 480px) 480px;
    grid-template-rows: 465px 465px;
    grid-gap: 22px;
    justify-content: center;
    padding: 49px 0 92px 0;
    @include breakpoint($tablet down) {
        grid-template-columns: repeat(2, 438px);
        grid-template-rows: 425px 425px 425px;
        grid-gap: 21px;
    }
    @include breakpoint($mobile down) {
        grid-template-columns: 360px;
        grid-template-rows: repeat(auto, 425px);
        grid-gap: 12px;
        padding-bottom: 70px;
    }
    .round-tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 480px;
        height: 465px;
        padding: 25px 25px 25px 45px;
        color: white;
        border-radius: 10px;
        background-size: contain;
        @include breakpoint($tablet down) {
            width: 438px;
            height: 425px;
            padding-left: 40px;
        }
        @include breakpoint($mobile down) {
            width: 360px;
        }
        &.tall {
            grid-column: 3;
            grid-row: 1 / span 2;
            height: 952px;
            @include breakpoint($tablet down) {
                grid-column: 1;
                grid-row: 3 / span 2;
                width: 897px;
                height: 425px;
            }
            @include breakpoint($mobile down) {
                grid-column: 1;
                grid-row: 5 / span 1;
                width: 360px;
                height: 425px;
            }
        }
        .round-overlay {
            background-color: rgba(88, 89, 91, 0.7);
            background-blend-mode: multiply;
            position: absolute;
            z-index: 1;
            margin: -25px -25px -25px -45px;
            width: 480px;
            height: 465px;
            border-radius: 10px;
            @include breakpoint($tablet down) {
                width: 438px;
                height: 425px;
                margin-left: -40px;
            }
            @include breakpoint($mobile down) {
                width: 360px;
            }
            &.tall {
                height: 952px;
                @include breakpoint($tablet down) {
                    width: 897px;
                    height: 425px;
                }
                @include breakpoint($mobile down) {
                    width: 360px;
                    height: 425px;
                }
            }
        }
        .top-section {
            display: flex;
            justify-content: space-between;
            padding: 12px 5px 0 0;
            .title-area {
                .title {
                    font: $h6-bold;
                }
                .description {
                    font: $p14-semibold;
                    position: absolute;
                    z-index: 0;
                    max-width: 390px;
                    @include breakpoint($tablet down) {
                        max-width: 356px;
                    }
                    @include breakpoint($mobile down) {
                        max-width: 280px;
                    }
                }
            }
            .cta-area {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 156px;
                height: 44px;
                padding-bottom: 2px;
                @include breakpoint($mobile down) {
                    width: 124px;
                }
                &.open {
                    font: $p5-bold;
                    color: black;
                    border-radius: 22px;
                    background-color: white;
                    &:hover {
                        cursor: pointer;
                        background-color: black;
                        color: white;
                    }
                }
                &.closed {
                    font: $p5-reg;
                    border-radius: 5px;
                    background-color: rgba(255, 255, 255, 0.2);
                    backdrop-filter: blur(6px);
                    -webkit-backdrop-filter: blur(6px);
                }
                &.locked {
                    position: relative;
                    z-index: 2;
                    width: auto;
                    height: auto;
                }
            }
        }
        .bottom-section {
            .footer-area {
                &.locked {
                    text-align: center;
                    font: $font15-reg;
                    position: relative;
                    z-index: 2;
                }
                &.open {
                    .point-value {
                        font: $h6-bold;
                    }
                }
                &.closed {
                    display: flex;
                    .round-stat:first-child {
                        padding-right: 53px;
                        @include breakpoint($tablet down) {
                            padding-right: 35px;
                        }
                    }
                    .stat-value {
                        font: $h6-bold;
                    }
                }
            }
        }
    }
}
