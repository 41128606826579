@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.profile-page {
    display: flex;

    .profile-page-content {
        display: flex;
        flex-direction: column;
        width: calc(100% - 335px);
        padding: 90px;
        position: relative;
        max-width: 1215px;
        margin-left: 20px;
        gap: 35px;

        @media only screen and (min-width: 1920px) {
            margin-left: calc((100% - 1880px) / 2);
        }

        @include breakpoint(medium only) {
            padding: 80px 50px 50px 50px;
            width: calc(100% - 275px);
            margin-left: 0px;
        }

        @include breakpoint(small only) {
            padding: 30px 20px 20px 20px;
            width: 100%;
            margin-left: 0px;
        }
    }
}
