/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.rank-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 28px 8px 18px; }
  @media screen and (max-width: 99.6875em) {
    .rank-item {
      padding: 7px 28px 7px 18px; } }
  @media screen and (max-width: 56.5625em) {
    .rank-item {
      padding: 8px 12px 8px 12px; } }
  .rank-item .rank-item-position {
    font: 40px "ToyotaType-Bold", sans-serif;
    min-width: 44px;
    text-align: center;
    padding-bottom: 5px; }
  .rank-item .rank-item-name-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 10px; }
    @media screen and (max-width: 56.5625em) {
      .rank-item .rank-item-name-section {
        margin-left: 16px; } }
    .rank-item .rank-item-name-section .rank-item-name {
      font: 17px "ToyotaType-Bold", sans-serif;
      line-height: 16px;
      text-wrap: pretty; }
    .rank-item .rank-item-name-section .rank-item-location {
      font: 15px "ToyotaType-Regular", sans-serif;
      padding-top: 6px;
      line-height: 16px;
      text-wrap: pretty; }
  .rank-item .rank-item-points {
    font: 17px "ToyotaType-Regular", sans-serif;
    white-space: nowrap; }

.leaderboard-container {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .leaderboard-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .leaderboard-container .header .header-title {
      font: 26px "ToyotaType-Bold", sans-serif; }
    .leaderboard-container .header .header-dropdown {
      width: 280px;
      margin-left: 24px;
      height: 38px;
      text-align: center; }
      .leaderboard-container .header .header-dropdown .Dropdown-root .Dropdown-control .Dropdown-placeholder {
        font: 15px "ToyotaType-Regular", sans-serif; }
      @media screen and (max-width: 26.75em) {
        .leaderboard-container .header .header-dropdown {
          margin-left: 0; } }
  .leaderboard-container .ranking {
    padding-top: 10px;
    flex-grow: 1; }
    @media screen and (max-width: 56.5625em) {
      .leaderboard-container .ranking {
        padding: 22px 0 8px 0; } }
    .leaderboard-container .ranking.display-grid {
      display: grid; }
      .leaderboard-container .ranking.display-grid .no-leaders-message {
        display: flex;
        justify-self: center; }
  .leaderboard-container .my-rank {
    display: flex;
    flex-direction: column; }
    .leaderboard-container .my-rank .my-rank-title {
      text-align: center;
      background-color: #2DAEFF;
      color: #FFFFFF;
      font: 16px "ToyotaType-Bold", sans-serif;
      border-radius: 5px 5px 0px 0px;
      height: 29px;
      padding-top: 1px; }
    .leaderboard-container .my-rank .rank-item {
      background-color: rgba(45, 174, 255, 0.1);
      border-radius: 0px 0px 5px 5px; }
      .leaderboard-container .my-rank .rank-item .rank-item-position {
        min-width: 44px;
        font: 35px "ToyotaType-Bold", sans-serif;
        color: #2DAEFF;
        padding-bottom: 5px; }
      .leaderboard-container .my-rank .rank-item .rank-item-points {
        font: 24px "ToyotaType-Regular", sans-serif; }
  .leaderboard-container.full {
    padding: 23px 38px 18px 38px;
    justify-content: flex-start; }
    @media screen and (max-width: 99.6875em) {
      .leaderboard-container.full {
        padding: 28px 28px 18px 28px; } }
    @media screen and (max-width: 59.375em) {
      .leaderboard-container.full {
        padding: 0px 12px 16px 12px;
        min-height: 981px; } }
    .leaderboard-container.full .header {
      padding: 6px 4px 0 0px; }
      @media screen and (max-width: 59.375em) {
        .leaderboard-container.full .header {
          padding: 28px 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: 17px; } }
      .leaderboard-container.full .header .header-title {
        font: 26px "ToyotaType-Bold", sans-serif; }
    .leaderboard-container.full .ranking {
      padding-top: 22px; }
      @media screen and (max-width: 59.375em) {
        .leaderboard-container.full .ranking {
          padding-top: 0;
          padding-bottom: 22px; } }
    .leaderboard-container.full .rank-item-position {
      font: 30px "ToyotaType-Bold", sans-serif;
      padding-bottom: 5px; }
    .leaderboard-container.full .my-rank {
      margin: initial; }
  .leaderboard-container.partial {
    width: 50%;
    padding: 22px;
    justify-content: flex-start; }
    @media screen and (max-width: 56.5625em) {
      .leaderboard-container.partial {
        padding: 10px;
        width: 100%;
        min-height: 496px; } }
    .leaderboard-container.partial .header {
      padding: 6px 4px 0 18px; }
      @media screen and (max-width: 99.6875em) {
        .leaderboard-container.partial .header {
          flex-direction: column;
          align-items: flex-start;
          gap: 17px; } }
      @media screen and (max-width: 56.5625em) {
        .leaderboard-container.partial .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px 12px 0 12px; } }
      @media screen and (max-width: 56.5625em) {
        .leaderboard-container.partial .header .header-title {
          align-self: flex-start; } }
