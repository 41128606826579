@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.related-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .see-more-button {
    font: $font15-bold;
    color: $primary-red;
    cursor: pointer;
    margin: auto;
    text-align: center;
  }
}