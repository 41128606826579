@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.trim-walk-component {
  .vehicle-choice-sections {
    display: flex;
    flex-direction: row;
    gap: 6px;
    width: 100%;
    margin-bottom: 50px;

    @include breakpoint(medium down) {
      flex-direction: column;
      gap: 27px;
    }

    .trim-selection-v2 {
      display: flex;
      flex-direction: column;
      width: 49%;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      @include breakpoint(medium down) {
        width: 100%;
        max-width: unset;
      }
    }
  }

  .trim-walk-selection-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    height: 100%;
  }

  .trim-walk-show-more-less {
    text-align: end;
    color: $primary-red;
    font: $p10-semibold;

    &:hover {
      cursor: pointer;
    }
  }

  .trim-walk-selection-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-content: center;
    font-weight: bold;
    width: 100%; /* Set the width to 100% */
    padding: 20px 20px 15px 20px;

    @include breakpoint(medium only) {
      flex-direction: row-reverse;
      padding: 20px 20px 28px 20px;
    }

        .trim-walk-selection-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 14px;
          
          @include breakpoint(medium only) {
            width: 50%;
          }

          @include breakpoint(small only) {
            padding-top: 30px;
          }
        }

        .trim-walk-selection-content-m-c {
          gap: 0;

          @include breakpoint(small only) {
            padding-top: 0px;
            padding-left: 30px;
          }
        }

    .trim-walk-selection-image {
      display: flex;
      flex-direction: row;

      @include breakpoint(medium only) {
        max-width: 50%;
        flex-basis: 45%;
      }


      .image-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .image-container-m-e {
        width: 70%;
      }
    }

    .trim-walk-selection-image-m-e {
      justify-content: center;
    }

    .trim-walk-selection-image-m-c {
      max-width: 60%;
    }
  }

  .trim-walk-selection-container-m-c {
    flex-direction: row-reverse;
    padding: 18px 21px;
  }

  .trim-walk-dropdowns {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    gap: 15px;

    .trim-walk-dropdown-trim {
        .Dropdown-root {
    
          .Dropdown-placeholder {
            .model-code-label {
              display: none;
            }
          }
    
          .Dropdown-menu {
            margin: 0px;
            min-width: 250px;
            max-height: 287px;
    
            transform: translate(-50%);
            left: 50%;
    
            .Dropdown-option {
              span {
                display: flex;
                justify-content: space-between;
                gap: 5px;
    
                .engine-label {
                  font-weight: normal;
                  float: left;
                  white-space: break-spaces;
                }
    
                .model-code-label {
                  font-weight: bold;
                  float: right;
                }
              }
            }
          }
        }
      }
  }
  .trim-walk-trim-name {
    color: #000000;
    font: $font15-reg;

    @include breakpoint (small only) {
      padding-top: 5px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .trim-walk-selection-bottom {
    width: 100%;
    display: flex;
    align-items: baseline;
    font-weight: bold;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    @include breakpoint(small only) {
      justify-content: space-between;
    }
    
    .model-msrp {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      /* To vertically align the content */
      @include breakpoint(small only) {
        gap: 20px;
      }

      .model-number {
        font: $p14-bold;
        padding-right: 5px;
  
        @include breakpoint(medium down) {
          font: $h4-bold;
        }

        @include breakpoint(small only) {
          text-align: end;
          padding-right: 0;
        }
      }

      .msrp {
        display: flex;
        flex-direction: column;

        .base-msrp {
          flex: 1;
          font: $p14-semibold;
          text-align: end;
          // Negative margin is bad practice. Making an exception here for the
          // lack of support for the hanging-punctuation css property across browsers
          margin-right: -0.4em;

          @include breakpoint(medium down) {
            font: $h4-semibold;
          }
        }

        .base-msrp-m-c {
          padding-top: 8px;
          font: $p10-semibold;
        }

        .base-msrp-value-m-c{
          font: $font15-semibold;
        }

        .additional-msrp {
          font: $h2-book;
          text-align: end;
          padding-top: 5px;

          @include breakpoint(medium down) {
            font: $h4-book;
          }
        }
      }
    }

    .model-msrp-m-c {
      flex-direction: column;
    }
  }

  .trim-walk-selection-footer {
    .footer-title {
      color: #15AEFF;
      font: $font15-bold;

      @include breakpoint (small only) {
        padding-top:20px;
      }
    }
    .footer-subtitle {
      color: #313131;
      font: $font15-reg;
    }
  }

  .vehicle-title-text {
    color: #15AEFF;
    font: $p14-semibold;
    padding-bottom: 5px;
    

    @include breakpoint(medium only) {
      font-size: 15px;
    }

    @include breakpoint(small only) {
      font-size: 15px;
    }
  }

  .vehicle-subtitle-text {
    color: #000000;
    font: $h4-reg;
    padding-bottom: 16px;
  }

  .feature-data-rows {
    .feature-data-row {
      .title-feature-name {
        font: $p5-reg;

        @include breakpoint(small only) {
          font: $font15-reg;
        }
      }

      .features-data {
        align-items: center;
      }

      .package-row {
        .package-title {
          font: $p14-semibold;

          @include breakpoint(small only) {
            font: $p5-semibold
          }
        }

        .package-description {
          font: $p14-reg;

          @include breakpoint(small only) {
            font: $p5-reg;
          }
        }
      }
    }
  }

  .trim-walk-no-results {
    font: $h4-reg;
    padding-left: 28px;

    @include breakpoint(small only) {
      font: $font15-reg;
    }
  }

  .trim-walk-accordion {
    .accordion-rows {
      .accordion-row {

        .accordion-row-header {
          padding-left: 22px;
        }

        &.expanded {
          .accordion-row-header {
              .title {
                  font: $h12-bold;
              }
          }
        }
      }
    }
  }

  .disclaimers-footnote {
    display: none;
  }
}