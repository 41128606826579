@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.accessories-important-message {
  font-size: 17px;
  font-family: $toyota-reg;
  color: #EB0A1E;
  padding: 0px 0px 50px 20px;

  // Nest the styles here
  .important-message-bold {
    font-family: $toyota-bold;
  }

  .important-message-body {
    margin: 0;
  }
}

.vehicle-page-no-accessories-section{
  text-align: center;
  font-family: $toyota-bold;
  padding: 50px;
  font-size: 1.8em;
  background: transparent;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 5px;

  @include breakpoint(medium) {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  @include breakpoint(small only) {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.accessories-v2-accordion-container {
    display: flex;
    flex-direction: row;
    column-gap: 33px;
    padding: 0px 22px 0px 22px;

    @include breakpoint(medium only) {
      flex-direction: column;
    }

    @include breakpoint(small only) {
      flex-direction: column;
    }


  .img-container{
    justify-content: flex-start;
    flex-basis: 50%;
  }

  .img-background{
    background-color: black;
    display: flex;
    justify-content: center;
  }

  .img {
    display: flex;
    justify-content: center;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

  }

  .font-bold {
    margin: 0;
    font: $h4-bold
  }

  .m-b-16 {
    margin-bottom: 16px;
  }

  .m-b-16 ul {
    margin-bottom: 0px;
  }

  .li-padding li {
    padding-bottom: 16px;
  }

  .slim-border {
    border-bottom: 1px solid lightgray;
  }
  
}

.accessories-msg-skeleton {
  margin-bottom: 50px;
}