/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.title-text.desktop {
  text-align: center;
  width: 1040px;
  margin: 100px auto 0;
  font: 42px "ToyotaType-Regular", sans-serif; }

.title-text.tablet {
  text-align: center;
  width: 644px;
  margin: 75px auto 0;
  font: 40px "ToyotaType-Regular", sans-serif; }

.title-text.mobile {
  text-align: center;
  width: 314px;
  margin: 30px auto 0;
  font: 25px "ToyotaType-Regular", sans-serif; }

@media print, screen and (max-width: 39.99875em) {
  .tab-menu-container .tab-menu-container-alignment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    width: 320px; }
  .tab-menu-container .tab-menu {
    padding-top: 30px;
    height: auto;
    text-align: -webkit-center;
    width: 105px; }
    .tab-menu-container .tab-menu .tab-menu-header-icon-container {
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
    .tab-menu-container .tab-menu .tab-menu-imageActive {
      display: inline-block;
      margin: auto;
      height: 33px; }
      .tab-menu-container .tab-menu .tab-menu-imageActive img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 30px; }
    .tab-menu-container .tab-menu .tab-menu-imageInactive {
      display: none;
      height: 33px; }
      .tab-menu-container .tab-menu .tab-menu-imageInactive img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 30px; }
    .tab-menu-container .tab-menu .tab-menu-lineInactive {
      align-self: center;
      width: 95px;
      background: #DBDBDB;
      border: 2px solid #DBDBDB;
      margin-top: 16px;
      margin-bottom: 9px; }
    .tab-menu-container .tab-menu .tab-menu-lineActive {
      align-self: center;
      width: 95px;
      background: #EB0A1E;
      border: 2px solid #EB0A1E;
      margin-top: 16px;
      margin-bottom: 9px; }
    .tab-menu-container .tab-menu .tab-menu-imageTitleInactive {
      font: 15px "ToyotaType-Semibold", sans-serif;
      color: #000000;
      line-height: 1; }
    .tab-menu-container .tab-menu .tab-menu-imageTitleActive {
      font: 15px "ToyotaType-Bold", sans-serif;
      color: #000000;
      line-height: 1; }
  .tab-menu-container .tab-menu:last-child {
    justify-self: flex-start;
    margin-right: auto; }
  .tab-menu-container .tab-menu-subSection {
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 12px;
    margin: 30px 30px 43px;
    padding: 40px 36px 30px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-subSection-title {
      text-align: left;
      font: 25px "ToyotaType-Bold", sans-serif;
      line-height: 1.1; }
    .tab-menu-container .tab-menu-subSection .tab-menu-subSection-description {
      text-align: left;
      padding-top: 12px;
      font: 16px "ToyotaType-Regular", sans-serif;
      margin-bottom: 20px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-footer {
      display: flex;
      flex-direction: column; }
    .tab-menu-container .tab-menu-subSection .tab-menu-cards {
      display: flex;
      flex-direction: row;
      margin-top: 35px;
      margin-bottom: 20px;
      flex-wrap: wrap; }
      .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card {
        width: 234px;
        margin-left: 6px;
        margin-bottom: 25px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card .tab-menu-card-image {
          width: 231px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card .tab-menu-card-description {
          font: 16px "ToyotaType-Book", sans-serif;
          margin-left: 10px;
          margin-top: 5px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats {
      flex: 0 0 calc(100% / 1);
      margin-bottom: 10px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-iconStats {
        display: flex;
        align-items: center;
        padding-bottom: 10px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-icon {
        padding-left: 3px;
        height: 20px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-icon img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-width: 20px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-text {
        width: 100%;
        text-align: left;
        font: 24px "ToyotaType-Bold", sans-serif;
        padding: 0 14px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-blackLine {
        align-self: center;
        background: #111;
        border: 2px solid #111;
        margin-bottom: 9px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-description {
        text-align: left;
        font: 16px "ToyotaType-Regular", sans-serif; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet {
      width: 100%; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet-text {
      text-align: left; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet-title {
      font: 18px "ToyotaType-Semibold", sans-serif; }
    .tab-menu-container .tab-menu-subSection ul {
      list-style: none; }
      .tab-menu-container .tab-menu-subSection ul li::before {
        content: "\2022";
        color: #EB0A1E;
        display: block;
        height: 11px;
        font-size: 28px;
        margin-left: -20px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .tab-menu-container .tab-menu-container-alignment {
    display: flex;
    margin: auto;
    height: auto;
    width: 644px;
    justify-content: space-between;
    padding-top: 72px; }
  .tab-menu-container .tab-menu {
    width: 129px;
    height: auto;
    text-align: -webkit-center; }
    .tab-menu-container .tab-menu .tab-menu-header-icon-container {
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
    .tab-menu-container .tab-menu .tab-menu-imageActive {
      display: inline-block;
      height: 41px; }
      .tab-menu-container .tab-menu .tab-menu-imageActive img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 40px; }
    .tab-menu-container .tab-menu .tab-menu-imageInactive {
      display: none;
      height: 41px; }
      .tab-menu-container .tab-menu .tab-menu-imageInactive img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 40px; }
    .tab-menu-container .tab-menu .tab-menu-lineInactive {
      align-self: center;
      width: 114px;
      background: #DBDBDB;
      border: 2px solid #DBDBDB;
      margin-top: 21px;
      margin-bottom: 11px; }
    .tab-menu-container .tab-menu .tab-menu-lineActive {
      align-self: center;
      width: 114px;
      background: #EB0A1E;
      border: 2px solid #EB0A1E;
      margin-top: 21px;
      margin-bottom: 11px; }
    .tab-menu-container .tab-menu .tab-menu-imageTitleInactive {
      font: 20px "ToyotaType-Semibold", sans-serif;
      color: #000000; }
    .tab-menu-container .tab-menu .tab-menu-imageTitleActive {
      font: 20px "ToyotaType-Bold", sans-serif;
      color: #000000; }
  .tab-menu-container .tab-menu-subSection {
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 12px;
    margin: 59px 62px 0;
    padding: 52px 60px 32px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-subSection-title {
      height: auto;
      text-align: left;
      font: 36px "ToyotaType-Bold", sans-serif; }
    .tab-menu-container .tab-menu-subSection .tab-menu-subSection-description {
      text-align: left;
      padding-top: 12px;
      font: 18px "ToyotaType-Regular", sans-serif;
      margin-bottom: 30px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
    .tab-menu-container .tab-menu-subSection .tab-menu-cards {
      display: flex;
      flex-direction: row;
      margin-top: 35px;
      margin-bottom: 20px;
      flex-wrap: wrap; }
      .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card {
        width: 234px;
        margin-right: 50px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card .tab-menu-card-image {
          width: 231px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card .tab-menu-card-description {
          font: 18px "ToyotaType-Book", sans-serif;
          margin-left: 10px;
          margin-top: 8px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card:nth-child(2n) {
        margin-right: 0px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats {
      flex: 0 0 calc(100% / 2);
      padding-bottom: 30px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-iconStats {
        display: flex;
        align-items: center;
        padding-bottom: 15px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-icon {
        padding-left: 3px;
        margin-top: 7px;
        height: 30px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-icon img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: 30px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-text {
        width: auto;
        text-align: left;
        font: 40px "ToyotaType-Bold", sans-serif;
        padding: 0 14px;
        line-height: 1; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-blackLine {
        align-self: center;
        background: #111;
        border: 2.5px solid #111;
        margin-bottom: 16px;
        margin-right: 30px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-description {
        text-align: left;
        font: 18px "ToyotaType-Regular", sans-serif;
        margin-right: 25px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet {
      width: 100%; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet-text {
      text-align: left; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet-title {
      font: 18px "ToyotaType-Semibold", sans-serif; }
    .tab-menu-container .tab-menu-subSection ul {
      list-style: none; }
      .tab-menu-container .tab-menu-subSection ul li::before {
        content: "\2022";
        color: #EB0A1E;
        display: block;
        height: 11px;
        font-size: 28px;
        margin-left: -20px; }
  .tab-menu-container .tab-menu:nth-child(2n) {
    margin-right: 14px;
    margin-left: 14px; } }

@media print, screen and (min-width: 64em) {
  .tab-menu-container .tab-menu-container-alignment {
    display: flex;
    width: 1040px;
    margin: auto;
    justify-content: space-between; }
  .tab-menu-container .tab-menu {
    margin-top: 50px;
    display: inline-block;
    width: 200px;
    height: 200px;
    text-align: -webkit-center; }
    .tab-menu-container .tab-menu .tab-menu-header {
      height: 60px; }
    .tab-menu-container .tab-menu .tab-menu-header-icon-container {
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
    .tab-menu-container .tab-menu .tab-menu-imageActive {
      display: inline-block; }
      .tab-menu-container .tab-menu .tab-menu-imageActive img {
        max-height: 65px; }
    .tab-menu-container .tab-menu .tab-menu-imageInactive {
      display: none; }
      .tab-menu-container .tab-menu .tab-menu-imageInactive img {
        max-height: 65px; }
    .tab-menu-container .tab-menu .tab-menu-lineInactive {
      align-self: center;
      width: 179px;
      background: #DBDBDB;
      border: 3px solid #DBDBDB;
      margin-top: 30px;
      margin-bottom: 18px; }
    .tab-menu-container .tab-menu .tab-menu-lineActive {
      align-self: center;
      width: 179px;
      background: #EB0A1E;
      border: 3px solid #EB0A1E;
      margin-top: 30px;
      margin-bottom: 18px; }
    .tab-menu-container .tab-menu .tab-menu-imageTitleInactive {
      width: 155px;
      height: 53px;
      text-align: center;
      font: 20px "ToyotaType-Semibold", sans-serif;
      color: #000000; }
    .tab-menu-container .tab-menu .tab-menu-imageTitleActive {
      width: 155px;
      height: 53px;
      text-align: center;
      font: 20px "ToyotaType-Bold", sans-serif;
      color: #000000; }
  .tab-menu-container .tab-menu:hover {
    cursor: pointer; }
  .tab-menu-container .tab-menu-subSection {
    height: auto;
    width: 1040px;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 12px;
    padding-top: 52px;
    padding-left: 90px;
    padding-bottom: 60px;
    padding-right: 90px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 115px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-subSection-title {
      height: auto;
      text-align: left;
      font: 36px "ToyotaType-Bold", sans-serif; }
    .tab-menu-container .tab-menu-subSection .tab-menu-subSection-description {
      text-align: left;
      padding-top: 14px;
      margin-bottom: 46px;
      height: auto;
      font: 18px "ToyotaType-Regular", sans-serif;
      width: 720px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap; }
    .tab-menu-container .tab-menu-subSection .tab-menu-cards {
      display: flex;
      flex-direction: row;
      margin-top: 35px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card {
        width: 273px;
        margin-left: 25px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card .tab-menu-card-image {
          width: 258px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card .tab-menu-card-description {
          font: 18px "ToyotaType-Book", sans-serif;
          margin-left: 10px;
          margin-top: 8px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-cards .tab-menu-card:first-child {
        margin-left: 0px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats {
      flex: 0 0 calc(100% / 3)-13.33333px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-iconStats {
        display: flex;
        align-items: center;
        padding-bottom: 15px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-icon {
        padding-left: 3px; }
        .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-icon img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: 35px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-text {
        width: 258px;
        text-align: left;
        font: 40px "ToyotaType-Bold", sans-serif;
        padding: 0 14px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-blackLine {
        align-self: center;
        background: #111;
        border: 3px solid #111;
        margin-bottom: 16px;
        margin-right: 40px; }
      .tab-menu-container .tab-menu-subSection .tab-menu-stats .tab-menu-stats-description {
        text-align: left;
        font: 18px "ToyotaType-Regular", sans-serif;
        margin-right: 35px; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet {
      width: 100%; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet-text {
      text-align: left; }
    .tab-menu-container .tab-menu-subSection .tab-menu-stats-bullet-title {
      font: 18px "ToyotaType-Semibold", sans-serif; }
    .tab-menu-container .tab-menu-subSection ul {
      list-style: none; }
      .tab-menu-container .tab-menu-subSection ul li::before {
        content: "\2022";
        color: #EB0A1E;
        display: block;
        height: 11px;
        font-size: 28px;
        margin-left: -20px; } }
