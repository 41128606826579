/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.smartpath-support {
  margin: auto;
  max-width: 1440px; }
  @media print, screen and (max-width: 39.99875em) {
    .smartpath-support {
      padding-left: 26px;
      padding-right: 26px;
      padding-bottom: 45px; }
      .smartpath-support .smartpath-support-header {
        height: auto;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        width: 315px;
        margin: 35px auto 7px;
        padding-bottom: 22px; }
      .smartpath-support .smartpath-support-header-title {
        font: 25px "ToyotaType-Regular", sans-serif;
        padding-top: 24px;
        padding-bottom: 24px; }
        .smartpath-support .smartpath-support-header-title strong {
          font: 25px "ToyotaType-Bold", sans-serif; }
      .smartpath-support .smartpath-support-header-description {
        font: 18px "ToyotaType-Regular", sans-serif;
        width: 235px;
        margin: auto;
        margin-bottom: 11px; }
      .smartpath-support .smartpath-support-header-content {
        display: flex;
        line-height: 1.4;
        flex-direction: column;
        width: 235px;
        margin: auto; }
      .smartpath-support .smartpath-support-header-content-left, .smartpath-support .smartpath-support-header-content-right {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 29px; }
        .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-icon, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-icon {
          width: 24px;
          margin-right: 5px; }
          .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-icon img, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-icon img {
            width: 100%;
            height: 19px;
            object-fit: contain; }
        .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-description, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-description {
          width: 215px;
          text-align: initial;
          margin-bottom: 20px;
          font: 15px "ToyotaType-Regular", sans-serif; }
          .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-description strong, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-description strong {
            font: 15px "ToyotaType-Bold", sans-serif; }
      .smartpath-support .smartpath-support-divider {
        border-top: 0.75px solid #707070; }
      .smartpath-support .smartpath-support-bigCards {
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 20px; }
      .smartpath-support .smartpath-support-bigCard {
        margin: auto;
        margin-top: 29px;
        width: 315px;
        height: 543px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px; }
      .smartpath-support .smartpath-support-bigCard:last-child {
        height: 593px; }
      .smartpath-support .kv-widget {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px; }
      .smartpath-support .smartpath-support-bigCard-title {
        font: 20px "ToyotaType-Bold", sans-serif;
        margin-top: 31px;
        margin-bottom: 19px; }
      .smartpath-support .smartpath-support-bigCard-description {
        font: 16px "ToyotaType-Regular", sans-serif;
        margin: auto;
        width: 240px;
        text-align: initial;
        padding-bottom: 16px; }
      .smartpath-support .smartpath-support-links {
        padding-top: 19px; }
      .smartpath-support .smartpath-support-content {
        margin: auto;
        text-align: left;
        display: flex;
        flex-direction: column;
        width: 315px; }
      .smartpath-support .smartpath-support-cards {
        margin: auto;
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px #00000034;
        border-radius: 12px;
        margin-top: 30px;
        width: 315px;
        height: 384px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 40px 31px 40px; }
        .smartpath-support .smartpath-support-cards .smartpath-support-cardIcon {
          height: 40px;
          margin-bottom: 13px; }
          .smartpath-support .smartpath-support-cards .smartpath-support-cardIcon img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .smartpath-support .smartpath-support-cards .smartpath-support-cardContent {
          text-align: initial;
          font: 16px "ToyotaType-Regular", sans-serif; }
        .smartpath-support .smartpath-support-cards .smartpath-support-cardTitle {
          font: 18px "ToyotaType-Bold", sans-serif;
          margin-bottom: 19px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .smartpath-support .smartpath-support-header {
      width: 644px;
      margin: auto;
      padding: 40px 70px 10px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      margin-bottom: 40px;
      margin-top: 57px; }
    .smartpath-support .smartpath-support-header-title {
      font: 36px "ToyotaType-Regular", sans-serif;
      padding-bottom: 24px; }
      .smartpath-support .smartpath-support-header-title strong {
        font: 36px "ToyotaType-Bold", sans-serif; }
    .smartpath-support .smartpath-support-header-description {
      font: 20px "ToyotaType-Regular", sans-serif;
      margin: auto; }
      .smartpath-support .smartpath-support-header-description strong {
        font: 20px "ToyotaType-Bold", sans-serif; }
    .smartpath-support .smartpath-support-header-content {
      display: flex;
      line-height: 1.4;
      flex-direction: column;
      margin: auto; }
      .smartpath-support .smartpath-support-header-content .smartpath-support-divider {
        border-top: 0.75px solid #707070; }
    .smartpath-support .smartpath-support-header-content-left, .smartpath-support .smartpath-support-header-content-right {
      display: flex;
      justify-content: space-between;
      padding-top: 42px;
      padding-bottom: 42px; }
      .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-icon, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-icon {
        width: 47px; }
        .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-icon img, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-icon img {
          width: 100%;
          height: 38px;
          object-fit: contain; }
      .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-description, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-description {
        text-align: initial;
        width: 445px;
        font: 16px "ToyotaType-Regular", sans-serif; }
        .smartpath-support .smartpath-support-header-content-left .smartpath-support-header-content-description strong, .smartpath-support .smartpath-support-header-content-right .smartpath-support-header-content-description strong {
          font: 16px "ToyotaType-Bold", sans-serif; }
    .smartpath-support .smartpath-support-bigCards {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 646px; }
      .smartpath-support .smartpath-support-bigCards .smartpath-support-bigCard {
        margin: 0px auto 40px;
        width: 646px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px; }
        .smartpath-support .smartpath-support-bigCards .smartpath-support-bigCard .kv-info-panel {
          display: none; }
        .smartpath-support .smartpath-support-bigCards .smartpath-support-bigCard .smartpath-support-bigCard-title {
          font: 20px "ToyotaType-Bold", sans-serif;
          margin-top: 42px;
          margin-bottom: 19px; }
        .smartpath-support .smartpath-support-bigCards .smartpath-support-bigCard .smartpath-support-bigCard-description {
          font: 16px "ToyotaType-Regular", sans-serif;
          margin: auto;
          text-align: initial;
          padding: 0px 45px 48px; }
      .smartpath-support .smartpath-support-bigCards .kv-widget {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px; }
    .smartpath-support .smartpath-support-links {
      padding-top: 22px;
      justify-self: end; }
    .smartpath-support .smartpath-support-content {
      margin: auto;
      text-align: left;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 646px; }
      .smartpath-support .smartpath-support-content .smartpath-support-cards {
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px #00000034;
        border-radius: 12px;
        margin-bottom: 44px;
        width: 308px;
        min-height: 376px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 40px 31px 40px; }
        .smartpath-support .smartpath-support-content .smartpath-support-cards .smartpath-support-cardIcon {
          height: 40px;
          margin-bottom: 13px; }
          .smartpath-support .smartpath-support-content .smartpath-support-cards .smartpath-support-cardIcon img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .smartpath-support .smartpath-support-content .smartpath-support-cards .smartpath-support-cardContent {
          text-align: initial;
          font: 16px "ToyotaType-Regular", sans-serif; }
        .smartpath-support .smartpath-support-content .smartpath-support-cards .smartpath-support-cardTitle {
          font: 18px "ToyotaType-Black", sans-serif;
          margin-bottom: 19px; } }
  @media print, screen and (min-width: 64em) {
    .smartpath-support {
      padding-left: 200px;
      padding-right: 200px;
      padding-bottom: 82px; }
      .smartpath-support .smartpath-support-header {
        height: auto;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        margin-top: 100px;
        padding: 0px 80px 75px; }
      .smartpath-support .smartpath-support-header-title {
        font: 36px "ToyotaType-Regular", sans-serif;
        padding-top: 30px;
        padding-bottom: 24px; }
        .smartpath-support .smartpath-support-header-title strong {
          font: 36px "ToyotaType-Bold", sans-serif; }
      .smartpath-support .smartpath-support-header-description {
        font: 20px "ToyotaType-Regular", sans-serif;
        width: 876px;
        margin: auto; }
      .smartpath-support .smartpath-support-header-content {
        display: flex;
        justify-content: space-evenly;
        margin-top: 68px;
        line-height: 1.2;
        flex-direction: row;
        width: auto; }
      .smartpath-support .smartpath-support-divider {
        border-right: 0.75px solid #707070; }
      .smartpath-support .smartpath-support-header-content-left {
        display: flex;
        width: 50%;
        padding-right: 70px; }
      .smartpath-support .smartpath-support-header-content-right {
        display: flex;
        width: 50%;
        padding-left: 45px; }
      .smartpath-support .smartpath-support-header-content-description {
        text-align: initial;
        padding-left: 18px;
        line-height: 1.2;
        width: 335px; }
      .smartpath-support .smartpath-support-bigCard {
        margin-top: 50px;
        width: 501px;
        height: 612px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px; }
      .smartpath-support .smartpath-support-bigCards {
        display: flex;
        flex-direction: row;
        margin-bottom: 50px; }
      .smartpath-support .kv-widget {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px; }
      .smartpath-support .smartpath-support-bigCard:first-child {
        margin-right: 38px; }
        .smartpath-support .smartpath-support-bigCard:first-child .smartpath-support-bigCard-title {
          font: 20px "ToyotaType-Bold", sans-serif;
          margin-top: 31px;
          margin-bottom: 18px; }
      .smartpath-support .smartpath-support-bigCard:last-child {
        height: 612px; }
      .smartpath-support .smartpath-support-bigCard-title {
        font: 20px "ToyotaType-Bold", sans-serif;
        margin-top: 31px;
        margin-bottom: 16px; }
      .smartpath-support .smartpath-support-bigCard-description {
        font: 16px "ToyotaType-Regular", sans-serif;
        margin: auto;
        width: 430px;
        text-align: initial;
        padding-bottom: 16px; }
      .smartpath-support .smartpath-support-links {
        padding-top: 12px; }
      .smartpath-support .smartpath-support-content {
        text-align: left;
        display: flex;
        flex-wrap: wrap; }
      .smartpath-support .smartpath-support-cards {
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px #00000034;
        border-radius: 12px;
        margin-bottom: 52px;
        width: 328px;
        height: 390px; }
        .smartpath-support .smartpath-support-cards .smartpath-support-cardIcon {
          padding-top: 52px;
          padding-bottom: 30px; }
        .smartpath-support .smartpath-support-cards .smartpath-support-cardContent {
          text-align: initial;
          font: 16px "ToyotaType-Regular", sans-serif;
          margin-left: 32px;
          margin-right: 31px; }
        .smartpath-support .smartpath-support-cards .smartpath-support-cardTitle {
          font: 18px "ToyotaType-Semibold", sans-serif;
          margin-bottom: 19px; }
      .smartpath-support .smartpath-support-cards:nth-child(3n-1) {
        margin-right: 28px;
        margin-left: 28px; } }
