@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.third-party-comparison-component {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .filter-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 45px;

        .third-party-comparison-filter {
            display: flex;
            flex-direction: row;
            gap: 5px;
    
            @include breakpoint(small only) {
                flex-direction: column;
                align-items: center;
            }
    
            .filter-card {
                background-color: #F7F7F7;
                border-radius: 9px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 30px 18px 40px 18px;
                flex: 1 1 0;
                width: 0;
                height: auto;
    
                @include breakpoint(small only) {
                    width: 100%;
                    max-width: 390px;
                }
    
                .info-section {
                    .image-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 120px;
    
                        @include breakpoint(medium only) {
                            height: 90px;
                        }
    
                        @include breakpoint(small only) {
                            height: 85px;
                        }
    
                        .image-placeholder {
                            width: 70%;
                            padding-bottom: 10px;

                            @include breakpoint(small only) {
                                width: auto;
                                height: 55%;
                            }
                        }
            
                        .vehicle-image {
                            width: 67%;
                            padding-bottom: 10px;

                            @include breakpoint(small only) {
                                width: auto;
                                height: 100%;
                            }
                        }
                    }
                    
    
                    .year-make-model {
                        text-align: center;
                        .year {
                            font: $font17-book;
                            padding: 2px;
                        }
    
                        .make-model {
                            font: $font17-bold;
                            padding: 2px;
                        }
                    }
    
                    .year-make-model-placeholder {
                        color: #C8C8C9;
                        font: $h2-bold;
                        text-align: center;
                    }

                    .year-make-model-placeholder-print-only {
                        display: none;
                        color: #313131;
                        font: $h2-book;
                        text-align: center;
                    }
    
                    .trim {
                        font: $p11-semibold;
                        text-align: center;
                        padding: 10px 12px;
                        min-height: 60px;
    
                        @include breakpoint(medium only) {
                            font: $h4-semibold;
                            min-height: 70px;
                        }
                    }
                }
    
                .dropdown-section {
                    display: flex;
                    flex-direction: column;
                    padding-top: 20px;
                    gap: 18px;
                    width: 100%;
    
                    @include breakpoint(small only) {
                        padding-top: 0px;
                        width: 90%;
                    }
    
                    .Dropdown-control {
                        @include breakpoint(medium only) {
                            height: 31px;
                        }
    
                        @include breakpoint(small only) {
                            height: 30px;
                        }
                    }
    
                    .toyota-placeholder {
                        display: flex;
                        background-color: #FFFFFF;
                        border: 1px solid #707070;
                        border-radius: 9000px;
                        height: 38px;
                        align-items: center;
                        font: $font15-reg;
                        padding-left: 24px;
                        padding-bottom: 4px;
                        margin-bottom: 2px;
    
                        @include breakpoint(medium only) {
                            height: 31px;
                        }
    
                        @include breakpoint(small only) {
                            height: 30px;
                        }
                    }
    
                    .filter-dropdown {
                        width: 100%;
                    }
    
                    .Dropdown-placeholder {
                        color: black;
                        font: $font15-reg;
                    }
    
                    .Dropdown-disabled {
                        cursor: not-allowed !important;
                        .Dropdown-placeholder {
                            color: #C8C8C9;
                        }
                    }
                }
            }

            .skeleton-placeholder {
                display: flex;
                flex: 1 1 0;
                width: 0;
                height: 450px;

                @include breakpoint(medium only) {
                    height: 400px;
                }

                @include breakpoint(small only) {
                    width: 100%;
                    max-width: 390px;
                    height: 350px;
                    flex: 1 1 auto;
                }

                .flex-1 {
                    flex: 1;
                }
            }
        }


        .jato-disclaimer {
            font: $font15-reg;
            width: 90%;

            @include breakpoint(medium down) {
                width: 100%;
            }
        }
    }

    .accordion-data-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 30px;

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font: $h4-reg;
            flex: 1 1 0;
            width: 0;
            padding: 5px 10px;

            @include breakpoint(small only) {
                font: $font15-reg;
            }

            .data-placeholder {
                color: #969696;
            }
        }
    }
    .accordion-data-row > .item + .item {
        border-left: 1px solid #70707080;
    }

    .mobile-accordion-header {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font: $font15-semibold;
        color: #313131;

        .header-vehicle-label {
            width: 100%;
            text-align: center;
            padding: 5px 10px;
        }
        
    }
    .mobile-accordion-header > .header-vehicle-label + .header-vehicle-label {
        border-left: 1px solid #70707080;
    }

    .disclaimers-footnote {
        display: none;
    }
}