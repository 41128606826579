@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.profile-page-recently-visited-pages-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .recently-visited-pages-title {
        font: $h3-semibold;
        margin-bottom: 21px;
    }

    .day-section {
        font: $p11-reg;

        .day-section-title {
            font: $h12-bold;
            margin-bottom: 21px;
        }

        .day-page-section {
            margin-left: 10px;
            .day-page-visited-section {
                .day-page-section-title {
                    font: $p11-reg;
                    margin-bottom: 1px;
                    color: #707070;
                }

                .day-page-section-detail {
                    cursor: pointer;
                    font: $p14-semibold;
                    margin-bottom: 21px;
                    &:hover {
                        text-decoration: underline;
                      }
                }
            }
        }

    }

    .day-page-entry-divider {
        margin: 10px 0px 30px 0px;
        border: none;
        height: 1px;
        background-color: #ACACAC;
      }

    .history-skeleton-loading {
        margin-top: 12px;
        
        .skeleton-date {
            margin-bottom: 20px;
        }
        .skeleton-page {
            margin-bottom: 10px;
            margin-left: 10px;
        }
    }
}