/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.model-overview-page {
  background-repeat: no-repeat;
  width: 100%; }
  .model-overview-page .row {
    width: 100%;
    margin: 0; }
  @media print {
    .model-overview-page div {
      break-inside: avoid; } }

@media print {
  .model-overview-web-content {
    display: none; } }

@media not print {
  .model-overview-print-content {
    display: none; } }

@media print {
  .model-overview-print-content {
    margin: 10px 25px; } }

@media print {
  .model-overview-print-content.redline {
    margin-left: 0px !important;
    padding-bottom: 2px; } }

@media not print {
  .model-overview-web-content.redline {
    margin: 0px !important; } }

@media screen and (min-width: 19.375em) {
  .model-overview-web-content.redline {
    padding-bottom: 16px; } }

@media screen and (min-width: 48em) {
  .model-overview-web-content.redline {
    padding-bottom: 18px; } }

@media screen and (min-width: 64em) {
  .model-overview-web-content.redline {
    padding-bottom: 20px; } }

@media not print {
  .model-overview-print-divider {
    display: none; } }

@media print {
  .model-overview-print-divider {
    display: block;
    height: 2px;
    width: 100%;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px 0px;
    margin-bottom: 5px; } }

.quick-facts-container {
  width: auto;
  display: flex;
  flex-wrap: nowrap; }
  @media screen and (min-width: 19.375em) {
    .quick-facts-container {
      flex-direction: column;
      padding: 25px 0px; } }
  @media screen and (min-width: 48em) {
    .quick-facts-container {
      flex-direction: row; } }
  @media screen and (min-width: 80em) {
    .quick-facts-container {
      padding: 50px 0px; } }
  @media print {
    .quick-facts-container {
      width: 100%;
      flex-wrap: wrap;
      padding: 0px;
      margin: 0px;
      margin-top: -10px; } }
  @media screen and (min-width: 19.375em) {
    .quick-facts-container .quick-facts-section {
      margin: 0px 25px; } }
  @media screen and (min-width: 48em) {
    .quick-facts-container .quick-facts-section {
      margin: 0px 40px; } }
  @media screen and (min-width: 80em) {
    .quick-facts-container .quick-facts-section {
      margin: 0px 65px; } }
  @media print {
    .quick-facts-container .quick-facts-section {
      margin: 0px; } }
  @media print {
    .quick-facts-container .quick-facts-section .title-text {
      font: 20px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 19.375em) {
    .quick-facts-container .quick-facts-section .title-text {
      font: 16px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 40em) {
    .quick-facts-container .quick-facts-section .title-text {
      font: 20px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 48em) {
    .quick-facts-container .quick-facts-section .title-text {
      font: 24px "ToyotaType-Regular", sans-serif; } }
  @media screen and (min-width: 90em) {
    .quick-facts-container .quick-facts-section .title-text {
      font: 30px "ToyotaType-Regular", sans-serif; } }
  @media print {
    .quick-facts-container .quick-facts-section .title-text {
      font: 16px "ToyotaType-Regular", sans-serif; } }
  .quick-facts-container .quick-facts-section .quick-facts-text {
    width: 100%; }
    @media screen and (min-width: 19.375em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        font: 12px "ToyotaType-Regular", sans-serif; } }
    @media screen and (min-width: 40em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        font-size: 15px; } }
    @media screen and (min-width: 48em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        font: 16px "ToyotaType-Regular", sans-serif; } }
    @media screen and (min-width: 80em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        font: 24px "ToyotaType-Regular", sans-serif; } }
    @media print {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        font: 11px "ToyotaType-Regular", sans-serif !important; } }
    @media screen and (min-width: 80em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        font: 20px "ToyotaType-Regular", sans-serif !important; } }
    @media screen and (min-width: 19.375em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        padding-top: 16px; } }
    @media screen and (min-width: 48em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        padding: 18px 25px 25px 0px; } }
    @media screen and (min-width: 64em) {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        padding-top: 20px; } }
    @media print {
      .quick-facts-container .quick-facts-section .quick-facts-text {
        margin-top: 10px;
        height: fit-content;
        padding-bottom: 0px;
        margin-bottom: 0px; } }
    @media screen and (min-width: 19.375em) {
      .quick-facts-container .quick-facts-section .quick-facts-text ul {
        margin-left: 12px;
        margin-bottom: 0px; } }
    @media screen and (min-width: 48em) {
      .quick-facts-container .quick-facts-section .quick-facts-text ul {
        margin-left: 18px; } }
    @media screen and (min-width: 19.375em) {
      .quick-facts-container .quick-facts-section .quick-facts-text ul li {
        line-height: 1.5; }
        .quick-facts-container .quick-facts-section .quick-facts-text ul li:not(:last-child) {
          margin-bottom: 15px; } }
    @media print {
      .quick-facts-container .quick-facts-section .quick-facts-text ul {
        margin-right: 10px;
        padding-bottom: 0px;
        margin-bottom: 0px; } }
  @media screen and (min-width: 19.375em) {
    .quick-facts-container .quick-facts-section:first-child {
      border-bottom: 1px solid #E6E6E6 !important; } }
  @media screen and (min-width: 48em) {
    .quick-facts-container .quick-facts-section:first-child {
      border-bottom: none !important; } }
  @media screen and (min-width: 19.375em) {
    .quick-facts-container .quick-facts-section:not(:last-child) {
      border-bottom: 1px;
      padding-bottom: 25px; } }
  @media screen and (min-width: 48em) {
    .quick-facts-container .quick-facts-section:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid #E6E6E6 !important;
      padding-bottom: 0px; } }
  @media screen and (min-width: 90em) {
    .quick-facts-container .quick-facts-section:not(:last-child) {
      margin-right: 0px; } }
  @media screen and (min-width: 19.375em) {
    .quick-facts-container .quick-facts-section:not(:first-child) {
      padding-top: 25px; } }
  @media screen and (min-width: 48em) {
    .quick-facts-container .quick-facts-section:not(:first-child) {
      padding-top: 0px; } }
  @media print {
    .quick-facts-container .quick-facts-section {
      width: 50%;
      padding: 0px 0px 5px 0px; }
      .quick-facts-container .quick-facts-section:first-child {
        border-right: 1px solid #CCCCCC; }
      .quick-facts-container .quick-facts-section:last-child {
        border-left: 1px solid #CCCCCC; }
      .quick-facts-container .quick-facts-section:nth-last-child(2), .quick-facts-container .quick-facts-section:last-child {
        margin-top: 10px; }
      .quick-facts-container .quick-facts-section:first-child, .quick-facts-container .quick-facts-section:nth-child(even), .quick-facts-container .quick-facts-section:last-child {
        padding-left: 25px; }
      .quick-facts-container .quick-facts-section:nth-child(odd) {
        padding-right: 25px; } }
  @media screen and (min-width: 19.375em) {
    .quick-facts-container .quick-facts-section {
      margin: 0px 25px;
      width: inherit; } }
  @media screen and (min-width: 48em) {
    .quick-facts-container .quick-facts-section {
      margin: 0px 0px 0px 40px;
      width: 50%; } }
  @media screen and (min-width: 90em) {
    .quick-facts-container .quick-facts-section {
      width: 50%;
      min-height: 250px;
      margin: 0px 0px 0px 65px; }
      .quick-facts-container .quick-facts-section:not(:first-child) {
        padding-left: 5px; } }
