/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
@media print, screen and (max-width: 39.99875em) {
  .smartpath-overview-pricing {
    width: 100%;
    padding-top: 34px;
    padding-bottom: 30px; }
    .smartpath-overview-pricing .smartpath-overview-pricing-header {
      font: 25px "ToyotaType-Regular", sans-serif;
      text-align: center;
      margin-bottom: 30px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-header strong {
        font: 25px "ToyotaType-Bold", sans-serif; }
    .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card {
      flex-direction: column;
      width: 315px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: flex-end; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles {
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 73px;
        margin-top: 30px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-title {
          display: flex;
          align-items: center; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-title .smartpath-overview-pricing-title-text {
            text-transform: uppercase;
            font: 30px "ToyotaType-Bold", sans-serif;
            text-align: left;
            margin-left: 20px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-plus {
          padding-top: 23px;
          padding-bottom: 23px;
          display: flex;
          padding-left: 71px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles > div:first-child > div:first-child {
        display: none; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info {
        width: 241px;
        margin: auto;
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 35px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-description {
          padding-bottom: 12px;
          font: 16px "ToyotaType-Book", sans-serif;
          line-height: 1.4;
          text-align: initial; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-description strong {
            font: 16px "ToyotaType-Bold", sans-serif;
            line-height: 1.4; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-details {
          flex-grow: 2; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-details .smartpath-overview-pricing-detail {
            font: 16px "ToyotaType-Book", sans-serif;
            padding-top: 13px;
            padding-bottom: 13px;
            line-height: 1.2;
            text-align: left; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-detail:not(:first-child) {
          border-top: 0.5px solid #808080; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-link {
          font: 16px "ToyotaType-Semibold", sans-serif;
          text-align: center;
          padding: 35px 0px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices {
        padding-top: 20px;
        width: 315px;
        background-color: #F7F8F8; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item {
          padding-top: 10px;
          padding-bottom: 45px;
          display: flex;
          flex-direction: column; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price-title {
            font: 24px "ToyotaType-Bold", sans-serif; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price-description {
            font: 14px "ToyotaType-Regular", sans-serif;
            width: 150px;
            margin: auto; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price {
            font: 24px "ToyotaType-Bold", sans-serif;
            color: #EB0A1E; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .smartpath-overview-pricing {
    width: 100%;
    padding-top: 55px;
    padding-bottom: 50px; }
    .smartpath-overview-pricing .smartpath-overview-pricing-header {
      font: 36px "ToyotaType-Regular", sans-serif;
      text-align: center;
      margin-bottom: 30px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-header strong {
        font: 36px "ToyotaType-Black", sans-serif; }
    .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card {
      width: 644px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      margin: 0px auto 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles {
        width: 315px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 73px;
        margin-top: 30px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-title {
          display: flex;
          align-items: center; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-title .smartpath-overview-pricing-title-text {
            text-transform: uppercase;
            font: 30px "ToyotaType-Bold", sans-serif;
            text-align: left;
            margin-left: 20px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-plus {
          padding-top: 23px;
          padding-bottom: 23px;
          display: flex;
          padding-left: 71px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles > div:first-child > div:first-child {
        display: none; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info {
        width: 280px;
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 20px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-description {
          padding-bottom: 12px;
          font: 16px "ToyotaType-Book", sans-serif;
          line-height: 1.4;
          text-align: initial; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-description strong {
            font: 16px "ToyotaType-Bold", sans-serif;
            line-height: 1.4; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-details {
          flex-grow: 2; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-details .smartpath-overview-pricing-detail {
            font: 16px "ToyotaType-Book", sans-serif;
            padding-top: 13px;
            padding-bottom: 13px;
            line-height: 1.2;
            text-align: left; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-detail:not(:first-child) {
          border-top: 0.5px solid #808080; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-link {
          font: 16px "ToyotaType-Semibold", sans-serif;
          text-align: center;
          padding: 35px 0px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices {
        padding-top: 20px;
        width: 644px;
        background-color: #F7F8F8;
        display: flex;
        justify-content: space-evenly; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item {
          padding-top: 10px;
          padding-bottom: 55px;
          display: flex;
          flex-direction: column; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price-title {
            font: 24px "ToyotaType-Bold", sans-serif; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price-description {
            font: 14px "ToyotaType-Regular", sans-serif;
            width: 150px;
            margin: auto; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price {
            font: 24px "ToyotaType-Bold", sans-serif;
            color: #EB0A1E; } }

@media print, screen and (min-width: 64em) {
  .smartpath-overview-pricing {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 70px; }
    .smartpath-overview-pricing .smartpath-overview-pricing-header {
      font: "ToyotaType-Regular", sans-serif;
      font-size: 48px;
      text-align: center;
      margin-bottom: 65px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-header strong {
        font: 48px "ToyotaType-Bold", sans-serif; }
    .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card {
      width: 1040px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      margin-bottom: 45px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: flex-end;
      flex-direction: row; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles {
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 91px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-title {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-title .smartpath-overview-pricing-title-text {
            text-transform: uppercase;
            font: 30px "ToyotaType-Bold", sans-serif;
            width: 220px;
            text-align: left; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles .smartpath-overview-pricing-plus {
          padding-top: 35px;
          padding-bottom: 35px;
          display: flex;
          padding-left: 71px; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-titles > div:first-child > div:first-child {
        display: none; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info {
        width: 280px;
        text-align: left;
        margin-right: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-description {
          padding-bottom: 12px;
          font: 16px "ToyotaType-Book", sans-serif;
          line-height: 1.2; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-description strong {
            font: 16px "ToyotaType-Bold", sans-serif;
            line-height: 1.2; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-details {
          flex-grow: 2; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-details .smartpath-overview-pricing-detail {
            font: 16px "ToyotaType-Book", sans-serif;
            padding-top: 18px;
            padding-bottom: 18px;
            line-height: 1.2; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-detail:not(:first-child) {
          border-top: 0.5px solid #808080; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-info .smartpath-overview-pricing-link {
          font: 16px "ToyotaType-Semibold", sans-serif;
          text-align: center; }
      .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices {
        padding-top: 20px;
        width: 334px;
        background-color: #F7F8F8; }
        .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item {
          padding-top: 10px;
          padding-bottom: 30px; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price-title {
            font: 24px "ToyotaType-Bold", sans-serif; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price-description {
            font: 14px "ToyotaType-Regular", sans-serif; }
          .smartpath-overview-pricing .smartpath-overview-pricing-content .smartpath-overview-pricing-card .smartpath-overview-pricing-prices .smartpath-overview-pricing-price-item .smartpath-overview-pricing-price {
            font: 24px "ToyotaType-Bold", sans-serif;
            color: #EB0A1E; } }
