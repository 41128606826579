@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";


.overview-hero-container {
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  height: 566px;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $border-light-grey;

  @include breakpoint(small only) {
    height: 346px;
    align-items: center;
    justify-content: flex-start;
    background-position-y: bottom;
  }

  @include breakpoint(medium only) {
    height: 409px;
  }

  .overview-hero-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 566px;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    padding: 50px 60px 50px 60px;

    @include breakpoint(small only) {
      height: 346px;
      justify-content: flex-start;
      padding: 40px 35px;
      position: relative;
    }

    @include breakpoint(medium only) {
      height: 409px;
      padding: 35px 38px 35px 50px;
    }
  }
}

.year-model-container {
  .hero-year {
    font-family: $toyota-reg;
    font-size: 36px;
    line-height: 10px;
    text-align: right;
    margin-bottom: 10px;

    @include breakpoint(small only) {
      font-size: 26px;
    }

    @include breakpoint(medium only) {
      font-size: 26px;
    }
  }

  .hero-model {
    font-family: $toyota-bold;
    font-size: 82px;
    margin-bottom: 80px;
    max-width: 603px;
    text-align: right;
    line-height: 100%;
    white-space: pre-line;

    @include breakpoint(small only) {
      font-size: 44px;
      max-width: 350px;
    }

    @include breakpoint(medium only) {
      font-size: 60px;
    }
  }

  .hero-model-long {
    @include breakpoint(small only) {
      font-size: 44px;
    }
  }
}


.hero-bottom-bar {
  position: absolute;
  padding: 0px 60px 50px 60px;

  @include breakpoint(medium only) {
    padding: 35px 38px 35px 50px;
  }

  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .hero-msrp {
    font-family: $toyota-bold;
    font-size: 36px;
    text-align: right;
    width: 380px;
    line-height: 30px;
    padding-bottom: 8px;

    @include breakpoint(medium only) {
      font-size: 30px;
      width: 400px;
    }

    &.coming-soon {
      font-size: 30px;

      @include breakpoint(medium only) {
        font-size: 22px;
      }
    }
  }

  .dropdown-placeholder {
    display: flex;
    width: 290px;
    min-width: 200px;

    @include breakpoint(medium only) {
      width: 224px;
      min-width: 100px;
    }

    .Dropdown-root.hero-msrp-dropdown-filter {
      width: 100%;

      .Dropdown-control .Dropdown-placeholder .dropdown-model-code-option {
        span {
          white-space: nowrap;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          font: $h2-reg;
        }

        b {
          display: none;
          font: $h2-bold;
        }
      }

      .Dropdown-menu {
        max-height: 274px;

          &::-webkit-scrollbar {
              background-color: transparent;
              width: 4px;
          }

          &::-webkit-scrollbar-thumb {
              background: #B7B7B7 0% 0% no-repeat padding-box;
              border-radius: 4px;
          }

        .dropdown-model-code-option {
          display: flex;
          justify-content: space-between;

          span {
            width: 80%;
            white-space: break-spaces;
          }
        }
      }

      &.dropdown-menu-lg {
        position: unset;

        .Dropdown-menu {
          max-width: 350px;
          min-width: 276px;
          margin: 0 7px;
          width: unset;
        }
      }
    }
  }

  .skeleton-section span {
    display: flex;
    gap: 20px;
  }

}