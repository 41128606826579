@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.cer-container {
    background: linear-gradient(180deg, #0046D2 0%, 18%, #001E5A 58%, 88%, #000f2d 100%);
    color: white;
    .cer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 110px 62px 86px;
        width: 100%;
        max-width: 1506px;
        margin: auto;
        @include breakpoint(medium down) {
            padding: 71px 62px;
        }
        @include breakpoint(small down) {
            padding: 50px 34px 60px;
        }
        .cer-title {
            font: $font45-semibold;
            text-align: center;
            margin-bottom: 20px;
            line-height: 50px;
            width: 80%;
            @include breakpoint(medium down) {
                font: $font35-semibold;
                line-height: 50px;
            }
            @include breakpoint(small down) {
                font: $h13-semibold;
                line-height: 42px;
            }
        }
        .cer-description {
            width: 872px;
            text-align: center;
            font: $p1-reg;
            line-height: 32px;
            margin-bottom: 89px;
            @include breakpoint(medium down) {
                width: 74vw;
                font: $p14-reg;
                line-height: 32px;
                margin-bottom: 71px;
            }
            @include breakpoint(small down) {
                width: 84vw;
                margin-bottom: 45px;
            }
        }
        .cer-columns {
            display: flex;
            justify-content: space-between;
            margin-bottom: 165px;
            width: 100%;
            gap: 8.9%;
            @include breakpoint(medium down) {
                gap: 7.5%;
                margin-bottom: 144px;
            }
            @include breakpoint(small down) {
                flex-direction: column;
                margin-bottom: 40px;
            }
            .cer-column {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 33.3%;
                @include breakpoint(small down) {
                    width: 100%;
                    border-top: 1px solid #FFFFFF;
                    margin-bottom: 40px;
                }
                .cer-column-bevImg {
                    width: 100%;
                    aspect-ratio: 380 / 330;
                    margin-bottom: 86px;
                    @include breakpoint(medium down) {
                        margin-bottom: 58px;
                    }
                    @include breakpoint(small down) {
                        width: 70.8%;
                        margin-top: 40px;
                        aspect-ratio: unset;
                    }
                }
                .cer-column-icon {
                    width: 75px;
                    height: 75px;
                    margin-bottom: 30px;
                    @include breakpoint(medium down) {
                        width: 50px;
                        height: 50px;
                        margin-bottom: 11px;
                    }
                }
                .cer-column-tonsAmount {
                    font: $font35-bold;
                    margin-bottom: 13px;
                    @include breakpoint(medium down) {
                        font: $h6-bold;
                        margin-bottom: 6px;
                    }
                }
                .cer-column-details {
                    width: 70%;
                    font: $p14-reg;
                    line-height: 28px;
                    margin-bottom: 13px;
                    text-align: center;
                    @include breakpoint(medium down) {
                        width: 92%;
                        font: $p5-reg;
                        line-height: 26px;
                        margin-bottom: 8px;
                    }
                    @include breakpoint(small down) {
                        max-width: 248px;
                    }
                }
                .cer-column-vehicleAmount {
                    font: $font25-bold;
                    text-align: center;
                    @include breakpoint(medium down) {
                        font: $p1-bold;
                    }
                }
            }
        }
        .cer-footer {
            font: $font15-reg;
            line-height: 32px;
            text-align: center;
            @include breakpoint(medium down) {
                line-height: 27px;
                width: 73vw;
            }
            @include breakpoint(small down) {
                font: $p10-reg;
                line-height: 25px;
            }
            text-wrap: balance;
        }
    }
}
