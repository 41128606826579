@charset "UTF-8";
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
@media print {
  .ca-edge {
    -webkit-print-color-adjust: exact; }
    .ca-edge sup {
      color: #EB0A1E !important; } }

.ca-edge .edge-dropdown {
  width: 75%;
  margin: 15px 0px 15px 12%; }
  @media print {
    .ca-edge .edge-dropdown {
      padding-right: 0;
      margin-top: -5px;
      font-size: 85%; } }

.ca-edge .noResponse {
  text-align: center;
  font-weight: bold;
  padding: 50px;
  font-size: 1.8em;
  background: rgba(100, 100, 100, 0.3);
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 5px; }
  @media print, screen and (max-width: 39.99875em) {
    .ca-edge .noResponse {
      margin-left: 10px;
      margin-right: 10px; } }

.ca-edge .background {
  height: auto; }
  .ca-edge .background .background-image {
    background-size: cover;
    background-position: center center;
    position: relative;
    margin-bottom: 5px;
    margin-top: 15px; }
    @media print {
      .ca-edge .background .background-image {
        -webkit-print-color-adjust: exact; }
        .ca-edge .background .background-image img {
          display: inline; } }
    .ca-edge .background .background-image .dropdown-content {
      padding: 0 0.5%;
      margin: 15px;
      margin-top: 0; }
      .ca-edge .background .background-image .dropdown-content .disclaimer-box {
        padding-top: 10px;
        display: flex; }
        .ca-edge .background .background-image .dropdown-content .disclaimer-box .disclaimer-icon {
          margin-right: 5px;
          width: 30px;
          height: 30px; }
          .ca-edge .background .background-image .dropdown-content .disclaimer-box .disclaimer-icon img {
            width: 30px;
            height: 30px; }
          @media print {
            .ca-edge .background .background-image .dropdown-content .disclaimer-box .disclaimer-icon {
              display: none; } }
        .ca-edge .background .background-image .dropdown-content .disclaimer-box .disclaimer-text {
          color: #EB0A1E;
          text-transform: uppercase;
          font: 11px "ToyotaType-Bold", sans-serif; }
          @media print {
            .ca-edge .background .background-image .dropdown-content .disclaimer-box .disclaimer-text {
              margin-bottom: 30px;
              -webkit-print-color-adjust: exact;
              color: #EB0A1E !important; } }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .background .background-image .dropdown-content .disclaimer-box .disclaimer-text {
              font: 10px "ToyotaType-Bold", sans-serif; } }
      .ca-edge .background .background-image .dropdown-content .h2h-images {
        height: 100%;
        display: flex; }
      .ca-edge .background .background-image .dropdown-content .dropdowns .dropdown-image {
        width: 300px;
        height: auto;
        display: block;
        margin: auto;
        margin-bottom: -5px; }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .background .background-image .dropdown-content .dropdowns .dropdown-image {
            width: 150px; } }
        @media print {
          .ca-edge .background .background-image .dropdown-content .dropdowns .dropdown-image {
            margin-top: -40px;
            height: auto;
            width: 200px;
            margin-bottom: 5px; } }
      .ca-edge .background .background-image .dropdown-content .dropdowns .toyota-msrp, .ca-edge .background .background-image .dropdown-content .dropdowns .competitor-msrp {
        font: 20px "ToyotaType-Bold", sans-serif;
        font-weight: bold;
        font-style: italic;
        margin-left: 12%;
        margin-top: -10px;
        padding-right: 2px;
        padding-left: 2px; }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .background .background-image .dropdown-content .dropdowns .toyota-msrp, .ca-edge .background .background-image .dropdown-content .dropdowns .competitor-msrp {
            font-size: 14px;
            font-style: italic; } }
        @media print {
          .ca-edge .background .background-image .dropdown-content .dropdowns .toyota-msrp, .ca-edge .background .background-image .dropdown-content .dropdowns .competitor-msrp {
            font-size: 80%; } }
      .ca-edge .background .background-image .dropdown-content .dropdowns .toyota-footnote, .ca-edge .background .background-image .dropdown-content .dropdowns .competitor-footnote {
        margin-left: 12%;
        width: 75%;
        padding: 2px;
        font-style: italic; }
        @media print {
          .ca-edge .background .background-image .dropdown-content .dropdowns .toyota-footnote, .ca-edge .background .background-image .dropdown-content .dropdowns .competitor-footnote {
            font-size: 60%; } }
      .ca-edge .background .background-image .dropdown-content .dropdowns .competitor-msrp {
        width: 75%; }
      .ca-edge .background .background-image .dropdown-content .dropdowns .competitor-footnote {
        text-align: right; }
      .ca-edge .background .background-image .dropdown-content .vs {
        margin-top: 186px;
        margin-bottom: -280px;
        color: #EB0A1E;
        font: 36px "ToyotaType-Regular", sans-serif; }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .background .background-image .dropdown-content .vs {
            font: 24px "ToyotaType-Regular", sans-serif;
            margin-top: 101px; } }
        .ca-edge .background .background-image .dropdown-content .vs .vs-text {
          margin-left: -20px; }
        @media print {
          .ca-edge .background .background-image .dropdown-content .vs {
            margin-top: 75px; } }

.ca-edge .container-border {
  border-right: 1px #808080 dotted; }

.ca-edge .key-advantages {
  padding: 0 0.5%;
  margin: 15px;
  margin-top: 30px; }
  @media print {
    .ca-edge .key-advantages {
      margin-top: -15px;
      page-break-after: always; } }
  .ca-edge .key-advantages .ka-body {
    margin-top: 20px; }
    @media print {
      .ca-edge .key-advantages .ka-body {
        margin-top: 5px; } }
    @media print, screen and (max-width: 39.99875em) {
      .ca-edge .key-advantages .ka-body {
        margin-top: 10px; } }
    .ca-edge .key-advantages .ka-body .container .key-advantage {
      min-height: 50px;
      margin-bottom: 10px;
      margin-top: 10px;
      position: relative;
      width: 100%;
      overflow: auto; }
      .ca-edge .key-advantages .ka-body .container .key-advantage .icon-container {
        position: absolute;
        top: 5px; }
        .ca-edge .key-advantages .ka-body .container .key-advantage .icon-container .ka-icon {
          width: 45px; }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .key-advantages .ka-body .container .key-advantage .icon-container .ka-icon {
              display: none; } }
        @media print {
          .ca-edge .key-advantages .ka-body .container .key-advantage .icon-container {
            margin-top: -5px; } }
      .ca-edge .key-advantages .ka-body .container .key-advantage .description {
        float: left;
        margin-left: 50px;
        font: 18px "ToyotaType-Book", sans-serif;
        margin-bottom: 10px;
        padding-left: 15px; }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .key-advantages .ka-body .container .key-advantage .description {
            margin-left: 0;
            padding-left: 0;
            float: none;
            width: auto;
            display: inline-block;
            font-size: 16px; } }
        @media print {
          .ca-edge .key-advantages .ka-body .container .key-advantage .description {
            font: 24px "ToyotaType-Light", sans-serif;
            font-size: 70%;
            margin-bottom: 5px; } }
      @media print, screen and (max-width: 39.99875em) {
        .ca-edge .key-advantages .ka-body .container .key-advantage .redline {
          width: 30px;
          border-bottom: 1px solid #EB0A1E;
          position: relative;
          margin-bottom: 10px;
          margin-top: 3px; } }

.ca-edge .head-to-head-video {
  margin: 0;
  width: 100%;
  display: flex; }
  @media print {
    .ca-edge .head-to-head-video {
      display: none;
      /* hide video section on print */ } }
  @media only screen and (max-width: 460px) {
    .ca-edge .head-to-head-video {
      flex-direction: column; } }
  .ca-edge .head-to-head-video .video-box {
    padding: 0;
    overflow: hidden;
    display: grid;
    background: black; }
    @media only screen and (min-width: 460px) {
      .ca-edge .head-to-head-video .video-box {
        flex: 1;
        /* additionally, equal width */ } }
    .ca-edge .head-to-head-video .video-box .vjs-poster {
      background-size: cover; }
  @media print, screen and (max-width: 39.99875em) {
    .ca-edge .head-to-head-video .video-box-hidden {
      display: none; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .ca-edge .head-to-head-video .video-box-hidden {
      display: none; } }
  .ca-edge .head-to-head-video .text-box {
    background: black;
    padding-left: 0;
    margin: 0;
    overflow-y: scroll; }
    @media only screen and (min-width: 460px) {
      .ca-edge .head-to-head-video .text-box {
        flex: 1;
        /* additionally, equal width */ } }
    .ca-edge .head-to-head-video .text-box .text {
      color: white;
      margin: 10px 0px 0px 0px; }
      @media only screen and (max-width: 460px) {
        .ca-edge .head-to-head-video .text-box .text {
          margin: 0px; } }
    .ca-edge .head-to-head-video .text-box .head-to-head-text {
      color: white;
      margin: 0px 10px 0px 10px;
      padding: 50px; }
      @media only screen and (max-width: 1025px) {
        .ca-edge .head-to-head-video .text-box .head-to-head-text {
          /* IPad width */
          font-size: 15px;
          padding: 10px;
          line-height: 1.5; } }
      @media only screen and (max-width: 460px) {
        .ca-edge .head-to-head-video .text-box .head-to-head-text {
          padding: 20px; } }
      .ca-edge .head-to-head-video .text-box .head-to-head-text .description-text {
        font-size: 20px;
        padding-left: 11px;
        padding-right: 50px;
        line-height: 2.5; }
        @media only screen and (max-width: 769px) {
          .ca-edge .head-to-head-video .text-box .head-to-head-text .description-text {
            /* IPad width */
            font-size: 15px;
            padding: 0px 30px 0px 10px;
            line-height: 1.5; } }
        @media only screen and (max-width: 460px) {
          .ca-edge .head-to-head-video .text-box .head-to-head-text .description-text {
            padding-left: 7px; } }
    .ca-edge .head-to-head-video .text-box .bold-title {
      font: 36px "ToyotaType-Regular", sans-serif;
      font-weight: bold;
      font-size: 36px; }
      @media only screen and (max-width: 769px) {
        .ca-edge .head-to-head-video .text-box .bold-title {
          /* IPad width */
          font-size: 20px; } }
      @media only screen and (min-width: 770px) and (max-width: 1025px) {
        .ca-edge .head-to-head-video .text-box .bold-title {
          /* IPad Pro width */
          font-size: 26px; } }
    .ca-edge .head-to-head-video .text-box .title {
      font: 36px "ToyotaType-Regular", sans-serif;
      font-weight: normal;
      font-size: 36px; }
      @media only screen and (max-width: 769px) {
        .ca-edge .head-to-head-video .text-box .title {
          /* IPad width */
          font-size: 20px; } }
      @media only screen and (min-width: 770px) and (max-width: 1025px) {
        .ca-edge .head-to-head-video .text-box .title {
          /* IPad Pro width */
          font-size: 26px; } }

.ca-edge .value-story {
  page-break-before: always;
  padding: 0 0.5%;
  display: inline-block;
  margin-top: 30px;
  width: 100%; }
  @media print {
    .ca-edge .value-story {
      display: inline-block;
      page-break-before: always; } }
  .ca-edge .value-story .head-to-head-icon {
    margin-bottom: 10px;
    width: 65px; }
    @media only screen and (max-width: 769px) {
      .ca-edge .value-story .head-to-head-icon {
        /* IPad width */
        width: 40px;
        margin-bottom: 3px; } }
  .ca-edge .value-story .content {
    page-break-before: always;
    height: auto; }
    @media print, screen and (min-width: 64em) {
      .ca-edge .value-story .content {
        height: 400px; } }
    @media print {
      .ca-edge .value-story .content {
        height: auto;
        background-color: #252525 !important;
        color: #FFFFFF !important; } }
    .ca-edge .value-story .content .image-container {
      max-height: 400px;
      float: left; }
      @media print, screen and (min-width: 64em) {
        .ca-edge .value-story .content .image-container {
          width: 50%; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .ca-edge .value-story .content .image-container {
          max-height: 300px; } }
      @media print {
        .ca-edge .value-story .content .image-container {
          float: left;
          width: 50%; } }
      .ca-edge .value-story .content .image-container .vs-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .ca-edge .value-story .content .vs-textbox {
      page-break-before: always;
      width: 100%;
      height: 100%;
      float: right;
      color: #FFFFFF;
      background: #252525;
      padding: 15px; }
      @media print, screen and (min-width: 64em) {
        .ca-edge .value-story .content .vs-textbox {
          width: 50%; } }
      @media print {
        .ca-edge .value-story .content .vs-textbox {
          color: #252525;
          margin-top: -20px;
          width: 50%;
          float: right;
          background-color: #FFFFFF !important;
          font-size: 65%; } }
      @media print, screen and (max-width: 39.99875em) {
        .ca-edge .value-story .content .vs-textbox .vs-box {
          width: 100%;
          position: relative;
          text-align: center; } }
      .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline {
        width: fit-content;
        float: left; }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline {
            float: none; } }
        .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-icon {
          width: 35px;
          float: left;
          margin-top: 5px; }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-icon {
              width: 20px;
              margin-top: 5px; } }
        .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-title {
          margin-left: 50px;
          font: 36px "ToyotaType-Regular", sans-serif; }
          @media print {
            .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-title {
              font-size: 30px; } }
          .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-title .value {
            font-weight: bold; }
          @media print {
            .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-title {
              color: #252525 !important; }
              .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-title span {
                color: #252525 !important; } }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .value-story .content .vs-textbox .vs-box .vs-firstline .vs-title {
              margin-left: 30px;
              font: 24px "ToyotaType-Light", sans-serif; } }
      .ca-edge .value-story .content .vs-textbox .vs-text {
        display: inline-block; }
        @media print {
          .ca-edge .value-story .content .vs-textbox .vs-text {
            color: #252525; }
            .ca-edge .value-story .content .vs-textbox .vs-text span {
              color: #252525 !important; }
              .ca-edge .value-story .content .vs-textbox .vs-text span strong {
                color: #252525 !important; } }
        .ca-edge .value-story .content .vs-textbox .vs-text p {
          font: 18px "ToyotaType-Book", sans-serif;
          font-size: 16px;
          display: inline-block;
          margin-top: 10px; }
          @media print {
            .ca-edge .value-story .content .vs-textbox .vs-text p {
              font-size: 12px;
              color: #252525 !important; } }
          @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
            .ca-edge .value-story .content .vs-textbox .vs-text p {
              font-size: 14px; } }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .value-story .content .vs-textbox .vs-text p {
              font-size: 12px; } }

.ca-edge .cost-to-own {
  padding: 0 0.5%;
  margin: 15px;
  margin-top: 30px;
  page-break-before: avoid; }
  @media print {
    .ca-edge .cost-to-own {
      width: 100%;
      margin-bottom: 0px; } }
  .ca-edge .cost-to-own .advantage-footnote-container {
    height: 20px;
    font-style: italic;
    margin-top: 20px; }
    @media print, screen and (max-width: 39.99875em) {
      .ca-edge .cost-to-own .advantage-footnote-container span {
        margin-bottom: 15px; } }
  .ca-edge .cost-to-own img {
    width: 10px;
    padding-bottom: 4px; }
  .ca-edge .cost-to-own .cto-body {
    margin-top: 20px; }
    .ca-edge .cost-to-own .cto-body .column-header {
      text-align: center;
      font-weight: bold;
      font-size: 20px; }
      @media screen and (max-width: 39.9375em) {
        .ca-edge .cost-to-own .cto-body .column-header {
          font-size: 14px;
          border-bottom: 1px solid #E6E6E6; } }
    .ca-edge .cost-to-own .cto-body .cto {
      font: 18px "ToyotaType-Regular", sans-serif; }
      @media print, screen and (max-width: 39.99875em) {
        .ca-edge .cost-to-own .cto-body .cto {
          font-size: 14px; } }
      .ca-edge .cost-to-own .cto-body .cto .full-width-header {
        text-align: center;
        font-size: 16px;
        font-weight: bold; }
      .ca-edge .cost-to-own .cto-body .cto .cto-headers {
        width: 100%; }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .cost-to-own .cto-body .cto .cto-headers {
            margin-bottom: 15px; } }
        .ca-edge .cost-to-own .cto-body .cto .cto-headers .cto-space {
          width: 20%;
          display: inline-block; }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .cost-to-own .cto-body .cto .cto-headers .cto-space {
              display: none; } }
        .ca-edge .cost-to-own .cto-body .cto .cto-headers .toyota-header {
          width: 50%;
          float: left;
          text-align: center; }
        .ca-edge .cost-to-own .cto-body .cto .cto-headers .competitor-header {
          float: right;
          width: 50%;
          text-align: center; }
        .ca-edge .cost-to-own .cto-body .cto .cto-headers .cto-bold {
          width: 40%;
          display: inline-block;
          text-align: center;
          font-weight: bold; }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .cost-to-own .cto-body .cto .cto-headers .cto-bold {
              width: 50%; } }
        @media print {
          .ca-edge .cost-to-own .cto-body .cto .cto-headers {
            margin-top: -5px;
            font-size: 60%; } }
      .ca-edge .cost-to-own .cto-body .cto .cto-footnote {
        margin-top: 20px;
        font-style: italic;
        font-size: 8px; }
        @media print, screen and (min-width: 40em) {
          .ca-edge .cost-to-own .cto-body .cto .cto-footnote {
            font-size: 12px; } }
        @media print {
          .ca-edge .cost-to-own .cto-body .cto .cto-footnote {
            font-size: 50%; } }
      .ca-edge .cost-to-own .cto-body .cto .cto-permanent-footnote {
        font-style: italic;
        font-size: 8px; }
        @media print, screen and (min-width: 40em) {
          .ca-edge .cost-to-own .cto-body .cto .cto-permanent-footnote {
            font-size: 12px; } }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .cost-to-own .cto-body .cto .cto-permanent-footnote {
            margin-bottom: 30px; } }
        @media print {
          .ca-edge .cost-to-own .cto-body .cto .cto-permanent-footnote {
            font-size: 50%; } }
      .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop {
        margin-bottom: -10px; }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop {
            display: none !important; } }
        @media print {
          .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop {
            margin-top: -10px; } }
        .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader {
          text-align: center;
          padding-left: 20%;
          padding-right: 0;
          background: #E6E6E6;
          margin-top: 5px; }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader {
              padding-left: 0; } }
          @media print {
            .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader {
              padding-bottom: 3px;
              font-size: 65%;
              margin-bottom: -5px;
              -webkit-print-color-adjust: exact; }
              .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader sup {
                color: #252525 !important; } }
        .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row {
          background: none; }
          .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-title {
            width: 20%;
            border-bottom: 1px solid #E6E6E6; }
            @media print {
              .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-title {
                text-align: center;
                border-bottom: 2px solid #808080;
                font-size: 65%; } }
          .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-col {
            width: 40%;
            text-align: center;
            border-bottom: 1px solid #E6E6E6;
            border-left: 1px dotted #808080; }
            @media print {
              .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-col {
                border-bottom: 2px solid #808080;
                border-left: 2px dotted #808080;
                font-size: 70%;
                margin-top: -5px; } }
            .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-col .bold {
              font-weight: bold; }
          @media print {
            .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row {
              max-height: 0px;
              margin-top: -5px;
              font-size: 70%;
              line-height: 0px; } }
      @media screen and (max-width: 0em), screen and (min-width: 40em) {
        .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile {
          display: none !important; } }
      @media print {
        .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile {
          display: none !important; } }
      .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader {
        text-align: center;
        background: #E6E6E6;
        margin-top: -10px;
        padding: 5px;
        font-weight: bold; }
        @media print {
          .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader {
            padding: 0px; } }
      .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-title {
        overflow: hidden;
        width: 100%;
        text-align: center;
        padding: 5px;
        font-weight: bold; }
      .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-col {
        text-align: center;
        padding: 5px;
        margin-bottom: 5px; }
        .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-col.col2 {
          border-left: 1px dotted #808080; }
        .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-col .bold {
          font-weight: bold; }
      .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-line {
        border-bottom: 1px solid #E6E6E6; }
        @media print {
          .ca-edge .cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-line {
            margin-top: -5px;
            margin-bottom: -5px; } }
  .ca-edge .cost-to-own table tbody tr:nth-child(even) {
    background: transparent;
    /* no alternating color */ }

.ca-edge .quick-specs .header-line {
  margin-left: 30px;
  margin-right: 30px; }

@media print {
  .ca-edge .quick-specs .cost-to-own-table-desktop .column-header {
    font-size: 60%; } }

.ca-edge .third-party-reviews {
  padding: 0 15px;
  width: 100%;
  margin: 30px 0px 0px 0px;
  display: inline-block; }
  @media print {
    .ca-edge .third-party-reviews {
      display: inline;
      margin-top: -10px; } }
  .ca-edge .third-party-reviews .tpr-body {
    font: 24px "ToyotaType-Light", sans-serif;
    width: 100%;
    margin-top: 15px; }
    @media print {
      .ca-edge .third-party-reviews .tpr-body {
        margin-top: 0px; } }
    @media print, screen and (max-width: 39.99875em) {
      .ca-edge .third-party-reviews .tpr-body {
        display: block; } }
    @media print, screen and (max-width: 39.99875em) {
      .ca-edge .third-party-reviews .tpr-body .tpr-quote {
        display: none !important; } }
    .ca-edge .third-party-reviews .tpr-body .tpr-quote .quote {
      position: relative; }
      .ca-edge .third-party-reviews .tpr-body .tpr-quote .quote .quote-symbol-lrg {
        color: #0099FF;
        font-size: 60px;
        position: absolute;
        top: -10px; }
        @media print {
          .ca-edge .third-party-reviews .tpr-body .tpr-quote .quote .quote-symbol-lrg {
            top: -20px; } }
      .ca-edge .third-party-reviews .tpr-body .tpr-quote .quote .quote-desc {
        text-indent: 25px; }
        .ca-edge .third-party-reviews .tpr-body .tpr-quote .quote .quote-desc .quote-symbol {
          color: #0099FF; }
      @media print {
        .ca-edge .third-party-reviews .tpr-body .tpr-quote .quote {
          font-size: 50%; } }
    .ca-edge .third-party-reviews .tpr-body .tpr-quote .tpr-source-block {
      margin-top: 10px; }
      .ca-edge .third-party-reviews .tpr-body .tpr-quote .tpr-source-block .tpr-source {
        font: 18px "ToyotaType-Book", sans-serif;
        font-weight: bold;
        font-style: italic;
        color: #0099FF;
        border-bottom: 1px solid #0099FF; }
        @media print {
          .ca-edge .third-party-reviews .tpr-body .tpr-quote .tpr-source-block .tpr-source {
            font-size: 50%; } }
      @media print {
        .ca-edge .third-party-reviews .tpr-body .tpr-quote .tpr-source-block {
          margin-top: 0px; } }
    @media print {
      .ca-edge .third-party-reviews .tpr-body .tpr-quote {
        margin-top: 10px; } }
    @media print, screen and (max-width: 39.99875em) {
      .ca-edge .third-party-reviews .tpr-body {
        font-size: 16px;
        margin-bottom: 15%; } }
    .ca-edge .third-party-reviews .tpr-body [class*="column"] + [class*="column"]:last-child {
      float: left; }
    .ca-edge .third-party-reviews .tpr-body .tpr-carousel {
      page-break-before: always;
      width: 100%; }
      @media screen and (max-width: 0em), screen and (min-width: 40em) {
        .ca-edge .third-party-reviews .tpr-body .tpr-carousel {
          display: none !important; } }
      @media print {
        .ca-edge .third-party-reviews .tpr-body .tpr-carousel {
          display: none !important; } }
      .ca-edge .third-party-reviews .tpr-body .tpr-carousel .quote {
        position: relative; }
        .ca-edge .third-party-reviews .tpr-body .tpr-carousel .quote .quote-symbol-lrg {
          color: #0099FF;
          font-size: 40px;
          position: absolute;
          top: -5px; }
        .ca-edge .third-party-reviews .tpr-body .tpr-carousel .quote .quote-desc {
          text-indent: 18px; }
          .ca-edge .third-party-reviews .tpr-body .tpr-carousel .quote .quote-desc .quote-symbol {
            color: #0099FF; }
      .ca-edge .third-party-reviews .tpr-body .tpr-carousel .tpr-source-block {
        page-break-before: always;
        margin-top: 2%; }
        .ca-edge .third-party-reviews .tpr-body .tpr-carousel .tpr-source-block .tpr-source {
          font: 12px "ToyotaType-Regular", sans-serif;
          font-weight: bold;
          font-style: italic;
          color: #0099FF;
          border-bottom: 1px solid #0099FF; }
          @media print {
            .ca-edge .third-party-reviews .tpr-body .tpr-carousel .tpr-source-block .tpr-source a[href]:after {
              content: none !important; } }
      .ca-edge .third-party-reviews .tpr-body .tpr-carousel .slick-slide {
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 0;
        opacity: 0.5; }
      .ca-edge .third-party-reviews .tpr-body .tpr-carousel .slick-center {
        opacity: 1; }
      .ca-edge .third-party-reviews .tpr-body .tpr-carousel .slick-dots {
        display: block;
        margin-bottom: -5px; }

.ca-edge .top-ten-features {
  padding: 0 15px;
  width: 100%;
  margin: 40px 0px 0px 0px;
  display: inline-block; }
  @media print, screen and (max-width: 39.99875em) {
    .ca-edge .top-ten-features {
      margin-top: 15px; } }
  @media print {
    .ca-edge .top-ten-features {
      margin-top: 5px; } }
  .ca-edge .top-ten-features .ttf-body {
    margin-top: 5px;
    width: 100%; }
    .ca-edge .top-ten-features .ttf-body .ttf {
      font: 18px "ToyotaType-Book", sans-serif; }
      @media print, screen and (max-width: 39.99875em) {
        .ca-edge .top-ten-features .ttf-body .ttf {
          font-size: 16px; } }
      .ca-edge .top-ten-features .ttf-body .ttf .ttf-subheader {
        text-align: center;
        margin-bottom: 15px;
        font: 12px "ToyotaType-Regular", sans-serif; }
        .ca-edge .top-ten-features .ttf-body .ttf .ttf-subheader .ttf-redText {
          color: #EB0A1E; }
          @media print {
            .ca-edge .top-ten-features .ttf-body .ttf .ttf-subheader .ttf-redText {
              -webkit-print-color-adjust: exact; }
              .ca-edge .top-ten-features .ttf-body .ttf .ttf-subheader .ttf-redText span {
                color: #EB0A1E !important; } }
        @media print {
          .ca-edge .top-ten-features .ttf-body .ttf .ttf-subheader {
            margin-top: 0px; } }
      .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor {
        margin-top: 10px;
        display: inline-block;
        white-space: pre-wrap; }
        @media print {
          .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor {
            font-size: 65%;
            margin-top: -5px; } }
        .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota span li, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor span li {
          line-height: 1.6;
          margin-left: 1.25rem;
          text-indent: -18px;
          list-style: none; }
          @media print {
            .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota span li, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor span li {
              margin-top: -5px; } }
        .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota span li::before, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor span li::before {
          content: "• "; }
        .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota span ul, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor span ul {
          margin-left: 0; }
        .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota ul, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor ul {
          margin-bottom: 0; }
        .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota p, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor p {
          margin-bottom: 0; }
        .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .ttf-bold, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .ttf-bold {
          font-weight: bold; }
        @media print {
          .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red {
            -webkit-print-color-adjust: exact; }
            .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red span,
            .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red p,
            .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red div, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red span,
            .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red p,
            .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red div {
              color: #EB0A1E !important; }
              .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red span li,
              .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red p li,
              .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red div li, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red span li,
              .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red p li,
              .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red div li {
                color: #EB0A1E !important; } }
        @media screen {
          .ca-edge .top-ten-features .ttf-body .ttf .ttf-toyota .red, .ca-edge .top-ten-features .ttf-body .ttf .ttf-competitor .red {
            color: #EB0A1E !important; } }

.ca-edge .related-links {
  padding: 0 0.5%;
  margin: 15px; }
  @media print {
    .ca-edge .related-links {
      display: none; } }
  .ca-edge .related-links .rl-body {
    margin-top: 20px;
    font: 18px "ToyotaType-Book", sans-serif;
    font-size: 16px; }
    .ca-edge .related-links .rl-body .rl-link-box {
      float: left; }
      @media print, screen and (max-width: 39.99875em) {
        .ca-edge .related-links .rl-body .rl-link-box {
          margin-top: 10px; } }
      .ca-edge .related-links .rl-body .rl-link-box .inner-container {
        background: #E6E6E6;
        padding: 10px; }
        @media print {
          .ca-edge .related-links .rl-body .rl-link-box .inner-container {
            -webkit-print-color-adjust: exact;
            background: #E6E6E6 !important; } }
        @media print, screen and (max-width: 39.99875em) {
          .ca-edge .related-links .rl-body .rl-link-box .inner-container {
            margin: 0; } }
        .ca-edge .related-links .rl-body .rl-link-box .inner-container .link-icon {
          float: left;
          width: 15px;
          margin-right: 10px;
          padding-bottom: 10px; }
          @media print, screen and (max-width: 39.99875em) {
            .ca-edge .related-links .rl-body .rl-link-box .inner-container .link-icon {
              padding: 0; } }
        .ca-edge .related-links .rl-body .rl-link-box .inner-container .rl-link {
          color: #252525;
          font-weight: bold; }

.ca-edge .black-line {
  border-bottom: 1px solid #252525;
  position: relative;
  padding-top: 10px;
  margin-bottom: 10px; }
  @media print {
    .ca-edge .black-line {
      padding-top: 5px;
      margin-bottom: 5px; } }

.ca-edge .bold-line {
  border-bottom: 2px solid #252525;
  position: relative;
  padding-top: 5px;
  margin-bottom: 5px; }

.ca-edge .header-line {
  border-bottom: 1px solid #252525;
  position: relative;
  top: 25px;
  z-index: 0; }
  @media print {
    .ca-edge .header-line {
      display: none !important; } }
  @media print, screen and (max-width: 39.99875em) {
    .ca-edge .header-line {
      display: none; } }

.ca-edge .header-title {
  position: relative;
  text-align: center; }
  .ca-edge .header-title .header-box {
    background: white;
    font: 36px "ToyotaType-Regular", sans-serif;
    display: inline;
    padding: 5px; }
    @media print, screen and (max-width: 39.99875em) {
      .ca-edge .header-title .header-box {
        font: 24px "ToyotaType-Light", sans-serif; } }
    @media print {
      .ca-edge .header-title .header-box {
        font-size: 30px;
        margin-bottom: -5px;
        margin-top: -5px; } }
    .ca-edge .header-title .header-box .header-icon {
      vertical-align: -8px;
      width: 35px; }
      @media print, screen and (max-width: 39.99875em) {
        .ca-edge .header-title .header-box .header-icon {
          vertical-align: middle;
          width: 25px; } }
    .ca-edge .header-title .header-box .header-spacer {
      margin: 5px; }
    .ca-edge .header-title .header-box .header-bold {
      font-weight: bold; }

.no-edge {
  padding-top: 16px;
  padding-left: 50px;
  color: #EB0A1E !important; }
  @media print {
    .no-edge {
      margin-left: -260px; } }

.learnMore {
  color: #EB0A1E;
  text-decoration: underline;
  font-size: 0.9rem;
  font-weight: bold; }

.vehicleImage {
  background-size: contain;
  background-repeat: no-repeat;
  height: 0;
  padding-top: 28.50%;
  position: relative;
  width: 50%;
  float: left;
  padding-right: 2%; }

.ca-edge-legacy .vehicleRow {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 50px; }
  @media print, screen and (min-width: 40em) {
    .ca-edge-legacy .vehicleRow {
      padding-left: 50px; } }
  @media print, screen and (min-width: 64em) {
    .ca-edge-legacy .vehicleRow {
      padding-left: 0px; } }

.ca-edge-legacy img {
  width: 100%;
  margin-bottom: 5px; }

.ca-edge-legacy .learnMore {
  font-size: 18px; }

@media print {
  .show-for-small-only {
    display: none; }
  a:link:after,
  a:visited:after {
    display: none; } }

.head-to-head-header-new {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 85px;
  margin-bottom: 80px; }
  @media print, screen and (max-width: 39.99875em) {
    .head-to-head-header-new {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 70px;
      flex-wrap: wrap; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .head-to-head-header-new {
      margin-left: 0px; } }
  .head-to-head-header-new .vs-image-component {
    width: 9%;
    padding-left: 0rem;
    padding-right: 0rem; }
    @media print, screen and (max-width: 39.99875em) {
      .head-to-head-header-new .vs-image-component {
        padding-right: 0rem;
        width: 15%; } }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .head-to-head-header-new .vs-image-component {
        width: 75px;
        padding-right: 19px;
        padding-left: 0px; } }
  .head-to-head-header-new .video-box {
    height: 180px;
    width: 34%;
    margin-left: 25px; }
    @media print, screen and (max-width: 39.99875em) {
      .head-to-head-header-new .video-box {
        width: 100%;
        height: 100%;
        margin-left: 0px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .head-to-head-header-new .video-box {
        width: 45%; } }
  @media print, screen and (max-width: 39.99875em) {
    .head-to-head-header-new .video-box-hidden {
      display: none; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .head-to-head-header-new .video-box-hidden {
      display: none; } }
  .head-to-head-header-new .toyota-vehicle-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 0px;
    margin-left: 8%;
    width: 33%; }
    @media print, screen and (max-width: 39.99875em) {
      .head-to-head-header-new .toyota-vehicle-container {
        width: 40%;
        margin-left: 0px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .head-to-head-header-new .toyota-vehicle-container {
        margin-left: 0px; } }
    .head-to-head-header-new .toyota-vehicle-container .toyota-image-header {
      width: 90%;
      margin-left: 3%; }
    .head-to-head-header-new .toyota-vehicle-container .vehicle-year-model-trim {
      font-size: 26px; }
      @media print, screen and (max-width: 39.99875em) {
        .head-to-head-header-new .toyota-vehicle-container .vehicle-year-model-trim {
          font-size: 16px; } }
    .head-to-head-header-new .toyota-vehicle-container .text-holder {
      height: 50px;
      line-height: 1.3; }
      .head-to-head-header-new .toyota-vehicle-container .text-holder span {
        font-size: 30px; }
        @media print, screen and (max-width: 39.99875em) {
          .head-to-head-header-new .toyota-vehicle-container .text-holder span {
            font-size: 16px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .head-to-head-header-new .toyota-vehicle-container .text-holder span {
            font-size: 22px; } }
      @media print, screen and (max-width: 39.99875em) {
        .head-to-head-header-new .toyota-vehicle-container .text-holder {
          height: 25px;
          line-height: 1.3; } }
  .head-to-head-header-new .competitor-vehicle-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 9.5%;
    padding-left: 0px;
    width: 33%; }
    @media print, screen and (max-width: 39.99875em) {
      .head-to-head-header-new .competitor-vehicle-container {
        width: 40%;
        margin-right: 0px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .head-to-head-header-new .competitor-vehicle-container {
        margin-right: 3%; } }
    .head-to-head-header-new .competitor-vehicle-container .competitor-image-header {
      width: 90%; }
    .head-to-head-header-new .competitor-vehicle-container .text-holder {
      height: 50px;
      line-height: 1.3; }
      .head-to-head-header-new .competitor-vehicle-container .text-holder span {
        font-size: 30px; }
        @media print, screen and (max-width: 39.99875em) {
          .head-to-head-header-new .competitor-vehicle-container .text-holder span {
            font-size: 16px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .head-to-head-header-new .competitor-vehicle-container .text-holder span {
            font-size: 22px; } }
      @media print, screen and (max-width: 39.99875em) {
        .head-to-head-header-new .competitor-vehicle-container .text-holder {
          height: 25px;
          line-height: 1.3; } }

.key-features {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 80px; }
  @media print, screen and (max-width: 39.99875em) {
    .key-features {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 70px; }
      .key-features table {
        border-top: 1px dotted grey;
        border-spacing: 1px; } }
  .key-features table {
    border-top: 1px dotted grey;
    border-spacing: 1.5px; }
  .key-features .header-title {
    position: relative;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px; }
    .key-features .header-title .header-box {
      background: white;
      font: 36px "ToyotaType-Regular", sans-serif;
      display: block;
      padding: 5px;
      width: 62%;
      margin-left: 34%; }
      @media print, screen and (max-width: 39.99875em) {
        .key-features .header-title .header-box {
          font: 30px "ToyotaType-Light", sans-serif;
          width: 100%;
          margin-left: 0%; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .key-features .header-title .header-box {
          width: 100%;
          margin-left: 0%; } }
      @media print {
        .key-features .header-title .header-box {
          font-size: 30px;
          margin-bottom: -5px;
          margin-top: -5px; } }
      .key-features .header-title .header-box .header-icon {
        vertical-align: -8px;
        width: 35px; }
        @media print, screen and (max-width: 39.99875em) {
          .key-features .header-title .header-box .header-icon {
            vertical-align: middle;
            width: 25px; } }
      .key-features .header-title .header-box .header-spacer {
        margin: 5px; }
      .key-features .header-title .header-box .header-bold {
        font-weight: bold; }
    .key-features .header-title .toyota-competitor-table-text-holder {
      display: flex;
      justify-content: space-evenly;
      margin-left: 35.5%;
      width: 60%;
      margin-top: 15px; }
      @media print, screen and (max-width: 39.99875em) {
        .key-features .header-title .toyota-competitor-table-text-holder {
          margin-top: 10px;
          margin-left: 0px;
          width: 100%; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .key-features .header-title .toyota-competitor-table-text-holder {
          margin-left: 0px;
          width: 100%; } }
      .key-features .header-title .toyota-competitor-table-text-holder span {
        width: 33%; }
      .key-features .header-title .toyota-competitor-table-text-holder .vehicle-year-model-trim {
        font-size: 26px; }
      .key-features .header-title .toyota-competitor-table-text-holder .toyota-text-holder {
        width: 50%;
        height: auto;
        line-height: 1.3;
        margin-bottom: 10px; }
        @media print, screen and (max-width: 39.99875em) {
          .key-features .header-title .toyota-competitor-table-text-holder .toyota-text-holder span.vehicle-year-model-trim {
            font-size: 16px;
            margin-bottom: 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .key-features .header-title .toyota-competitor-table-text-holder .toyota-text-holder span.vehicle-year-model-trim {
            font-size: 22px;
            margin-bottom: 0px; } }
      .key-features .header-title .toyota-competitor-table-text-holder .competitor-text-holder {
        width: 50%;
        line-height: 1.3;
        margin-bottom: 10px; }
        @media print, screen and (max-width: 39.99875em) {
          .key-features .header-title .toyota-competitor-table-text-holder .competitor-text-holder span.vehicle-year-model-trim {
            font-size: 16px;
            margin-bottom: 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .key-features .header-title .toyota-competitor-table-text-holder .competitor-text-holder span.vehicle-year-model-trim {
            font-size: 22px;
            margin-bottom: 0px; } }

.quick-specs-v2 {
  margin-left: 50px;
  margin-right: 50px; }
  @media print, screen and (max-width: 39.99875em) {
    .quick-specs-v2 {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 50px; }
      .quick-specs-v2 table {
        border-top: 1px dotted grey;
        border-spacing: 1px; } }
  .quick-specs-v2 table {
    border-top: 1px dotted grey;
    border-spacing: 1px; }
  .quick-specs-v2 .header-title {
    position: relative;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px; }
    @media print, screen and (max-width: 39.99875em) {
      .quick-specs-v2 .header-title {
        margin-top: -20px; } }
    .quick-specs-v2 .header-title .header-box {
      background: white;
      font: 36px "ToyotaType-Regular", sans-serif;
      display: block;
      padding: 5px;
      width: 62%;
      margin-left: 34%; }
      @media print, screen and (max-width: 39.99875em) {
        .quick-specs-v2 .header-title .header-box {
          font: 30px "ToyotaType-Light", sans-serif;
          width: 100%;
          margin-left: 0%; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .quick-specs-v2 .header-title .header-box {
          width: 100%;
          margin-left: 0%; } }
      @media print {
        .quick-specs-v2 .header-title .header-box {
          font-size: 30px;
          margin-bottom: -5px;
          margin-top: -5px; } }
      .quick-specs-v2 .header-title .header-box .header-icon {
        vertical-align: -8px;
        width: 35px; }
        @media print, screen and (max-width: 39.99875em) {
          .quick-specs-v2 .header-title .header-box .header-icon {
            vertical-align: middle;
            width: 25px; } }
      .quick-specs-v2 .header-title .header-box .header-spacer {
        margin: 5px; }
      .quick-specs-v2 .header-title .header-box .header-bold {
        font-weight: bold; }
    .quick-specs-v2 .header-title .toyota-competitor-table-text-holder {
      display: flex;
      justify-content: space-evenly;
      margin-left: 35.5%;
      width: 60%;
      margin-top: 15px; }
      @media print, screen and (max-width: 39.99875em) {
        .quick-specs-v2 .header-title .toyota-competitor-table-text-holder {
          margin-top: 10px;
          margin-left: 0px;
          width: 100%; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .quick-specs-v2 .header-title .toyota-competitor-table-text-holder {
          margin-left: 0px;
          width: 100%; } }
      .quick-specs-v2 .header-title .toyota-competitor-table-text-holder span {
        width: 33%; }
      .quick-specs-v2 .header-title .toyota-competitor-table-text-holder .vehicle-year-model-trim {
        font-size: 26px; }
      .quick-specs-v2 .header-title .toyota-competitor-table-text-holder .toyota-text-holder {
        width: 50%;
        height: auto;
        line-height: 1.3;
        margin-bottom: 10px; }
        @media print, screen and (max-width: 39.99875em) {
          .quick-specs-v2 .header-title .toyota-competitor-table-text-holder .toyota-text-holder span.vehicle-year-model-trim {
            font-size: 16px;
            margin-bottom: 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .quick-specs-v2 .header-title .toyota-competitor-table-text-holder .toyota-text-holder span.vehicle-year-model-trim {
            font-size: 22px;
            margin-bottom: 0px; } }
      .quick-specs-v2 .header-title .toyota-competitor-table-text-holder .competitor-text-holder {
        width: 50%;
        line-height: 1.3;
        margin-bottom: 10px; }
        @media print, screen and (max-width: 39.99875em) {
          .quick-specs-v2 .header-title .toyota-competitor-table-text-holder .competitor-text-holder span.vehicle-year-model-trim {
            font-size: 16px;
            margin-bottom: 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .quick-specs-v2 .header-title .toyota-competitor-table-text-holder .competitor-text-holder span.vehicle-year-model-trim {
            font-size: 22px;
            margin-bottom: 0px; } }

.cto {
  margin-left: 50px;
  margin-right: 50px; }
  @media print, screen and (max-width: 39.99875em) {
    .cto {
      margin-left: 0px;
      margin-right: 0px; }
      .cto table {
        border-top: 1px dotted grey;
        border-spacing: 1px; } }
  .cto .header-title {
    position: relative;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px; }
    @media print, screen and (max-width: 39.99875em) {
      .cto .header-title {
        margin-bottom: 20px; } }
    .cto .header-title .header-box {
      background: white;
      font: 36px "ToyotaType-Regular", sans-serif;
      display: block;
      padding: 5px;
      width: 62%;
      margin-left: 34%; }
      @media print, screen and (max-width: 39.99875em) {
        .cto .header-title .header-box {
          font: 30px "ToyotaType-Light", sans-serif;
          width: 100%;
          margin-left: 0%; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .cto .header-title .header-box {
          width: 100%;
          margin-left: 0%; } }
      @media print {
        .cto .header-title .header-box {
          font-size: 30px;
          margin-bottom: -5px;
          margin-top: -5px; } }
      .cto .header-title .header-box .header-icon {
        vertical-align: -8px;
        width: 35px; }
        @media print, screen and (max-width: 39.99875em) {
          .cto .header-title .header-box .header-icon {
            vertical-align: middle;
            width: 25px; } }
      .cto .header-title .header-box .header-spacer {
        margin: 5px; }
      .cto .header-title .header-box .header-bold {
        font-weight: bold; }
    .cto .header-title .toyota-competitor-table-text-holder {
      display: flex;
      justify-content: space-evenly;
      margin-left: 35.5%;
      width: 60%;
      margin-top: 15px; }
      @media print, screen and (max-width: 39.99875em) {
        .cto .header-title .toyota-competitor-table-text-holder {
          margin-top: 10px;
          margin-left: 0px;
          width: 100%;
          margin-bottom: -50px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .cto .header-title .toyota-competitor-table-text-holder {
          margin-left: 0px;
          width: 100%;
          margin-bottom: -35px; } }
      .cto .header-title .toyota-competitor-table-text-holder span {
        width: 33%; }
      .cto .header-title .toyota-competitor-table-text-holder .vehicle-year-model-trim {
        font-size: 26px; }
      .cto .header-title .toyota-competitor-table-text-holder .toyota-text-holder {
        width: 50%;
        height: auto;
        line-height: 1.3;
        margin-bottom: 10px; }
        @media print, screen and (max-width: 39.99875em) {
          .cto .header-title .toyota-competitor-table-text-holder .toyota-text-holder span.vehicle-year-model-trim {
            font-size: 16px;
            margin-bottom: 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .cto .header-title .toyota-competitor-table-text-holder .toyota-text-holder span.vehicle-year-model-trim {
            font-size: 22px;
            margin-bottom: 0px; } }
      .cto .header-title .toyota-competitor-table-text-holder .competitor-text-holder {
        width: 50%;
        line-height: 1.3;
        margin-bottom: 10px; }
        @media print, screen and (max-width: 39.99875em) {
          .cto .header-title .toyota-competitor-table-text-holder .competitor-text-holder span.vehicle-year-model-trim {
            font-size: 16px;
            margin-bottom: 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .cto .header-title .toyota-competitor-table-text-holder .competitor-text-holder span.vehicle-year-model-trim {
            font-size: 22px;
            margin-bottom: 0px; } }

.cto-permanent-footnote {
  margin-top: 10px;
  margin-right: 50px;
  margin-left: 50px; }
  @media print, screen and (max-width: 39.99875em) {
    .cto-permanent-footnote {
      margin-left: 0px;
      margin-right: 0px;
      font-size: 12px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .cto-permanent-footnote {
      margin-top: 25px;
      margin-left: 20px; } }

.advantage-footnote-container {
  margin-left: 50px;
  margin-right: 50px; }
  @media print, screen and (max-width: 39.99875em) {
    .advantage-footnote-container {
      margin-left: 0px;
      margin-right: 0px;
      font-size: 12px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .advantage-footnote-container {
      margin-left: 20px; } }

.head-to-head-container-holder {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 96px;
  margin-right: 96px; }
  @media print, screen and (max-width: 39.99875em) {
    .head-to-head-container-holder {
      margin-left: 20px;
      margin-right: 20px;
      justify-content: space-around; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .head-to-head-container-holder {
      margin-right: 0px;
      margin-left: 0px; } }
  @media print, screen and (max-width: 63.99875em) {
    .head-to-head-container-holder .head-to-head-comparison-container:nth-child(even) {
      margin-right: 0px; } }
  .head-to-head-container-holder .head-to-head-comparison-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    border: 1px #F1F2F2 solid;
    width: 292px;
    margin-right: 27px; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .head-to-head-container-holder .head-to-head-comparison-container {
        margin-top: 20px; } }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .head-to-head-container-holder .head-to-head-comparison-container {
        margin-top: 30px; } }
    @media print, screen and (max-width: 39.99875em) {
      .head-to-head-container-holder .head-to-head-comparison-container {
        width: 45%;
        margin-right: 0px;
        margin-bottom: 20px; } }
    .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-top .vs {
      margin-top: 20px;
      margin-bottom: 20px; }
    .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-top .image-component {
      margin-top: 10px;
      margin-bottom: 10px; }
      .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-top .image-component .vsImage {
        width: 49px; }
    .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-top .image-title-component .toyota-model-trim-text {
      line-height: 1.3;
      font-size: 22px; }
    .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-bottom {
      background: #F1F2F2;
      padding: 20px;
      height: 100%; }
      @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
        .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-bottom {
          padding: 34px;
          margin-bottom: 150px; } }
      .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-bottom .model-trim-component a:hover {
        color: #EB0A1E; }
      .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-bottom .model-trim-component .model-title {
        font-size: 20px;
        color: #252525;
        line-height: 1.3; }
      .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-bottom .model-trim-component .model-title-trim {
        color: grey;
        font-size: 16px;
        line-height: 1.3;
        margin-top: 5px; }
      .head-to-head-container-holder .head-to-head-comparison-container .head-to-head-comparison-container-bottom .model-trim-component .ca-black-line {
        border: 1px black solid;
        width: 50px; }

.new-cost-to-own {
  padding: 0 0.5%;
  margin: 15px;
  margin-top: 0px;
  margin-bottom: 50px;
  page-break-before: avoid; }
  @media print, screen and (max-width: 39.99875em) {
    .new-cost-to-own {
      margin-bottom: 25px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .new-cost-to-own {
      margin-left: 0px;
      margin-right: 0px; } }
  @media print {
    .new-cost-to-own {
      width: 100%;
      margin-bottom: 0px; } }
  .new-cost-to-own .advantage-footnote-container {
    height: 20px;
    font-style: italic;
    margin-top: 20px; }
    @media print, screen and (max-width: 39.99875em) {
      .new-cost-to-own .advantage-footnote-container span {
        margin-bottom: 15px; } }
  .new-cost-to-own .cto-body .column-header {
    text-align: center;
    font-weight: bold;
    font-size: 20px; }
    @media screen and (max-width: 39.9375em) {
      .new-cost-to-own .cto-body .column-header {
        font-size: 14px;
        border-bottom: 1px solid #E6E6E6; } }
  .new-cost-to-own .cto-body .cto {
    font: 18px "ToyotaType-Regular", sans-serif; }
    @media print, screen and (max-width: 39.99875em) {
      .new-cost-to-own .cto-body .cto {
        font-size: 14px;
        margin-left: 0px;
        margin-right: 0px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .new-cost-to-own .cto-body .cto {
        margin-left: 0px;
        margin-right: 0px; } }
    .new-cost-to-own .cto-body .cto .full-width-header {
      text-align: center;
      font-size: 16px;
      font-weight: bold; }
    .new-cost-to-own .cto-body .cto .cto-headers {
      width: 100%; }
      @media print, screen and (max-width: 39.99875em) {
        .new-cost-to-own .cto-body .cto .cto-headers {
          margin-bottom: 15px; } }
      .new-cost-to-own .cto-body .cto .cto-headers .cto-space {
        width: 20%;
        display: inline-block; }
        @media print, screen and (max-width: 39.99875em) {
          .new-cost-to-own .cto-body .cto .cto-headers .cto-space {
            display: none; } }
      .new-cost-to-own .cto-body .cto .cto-headers .toyota-header {
        width: 50%;
        float: left;
        text-align: center; }
      .new-cost-to-own .cto-body .cto .cto-headers .competitor-header {
        float: right;
        width: 50%;
        text-align: center; }
      .new-cost-to-own .cto-body .cto .cto-headers .cto-bold {
        width: 40%;
        display: inline-block;
        text-align: center;
        font-weight: bold; }
        @media print, screen and (max-width: 39.99875em) {
          .new-cost-to-own .cto-body .cto .cto-headers .cto-bold {
            width: 50%; } }
      @media print {
        .new-cost-to-own .cto-body .cto .cto-headers {
          margin-top: -5px;
          font-size: 60%; } }
    .new-cost-to-own .cto-body .cto .cto-footnote {
      margin-top: 20px;
      font-style: italic;
      font-size: 8px; }
      @media print, screen and (min-width: 40em) {
        .new-cost-to-own .cto-body .cto .cto-footnote {
          font-size: 12px; } }
      @media print {
        .new-cost-to-own .cto-body .cto .cto-footnote {
          font-size: 50%; } }
    .new-cost-to-own .cto-body .cto .cto-permanent-footnote {
      font-style: italic;
      font-size: 8px; }
      @media print, screen and (min-width: 40em) {
        .new-cost-to-own .cto-body .cto .cto-permanent-footnote {
          font-size: 12px; } }
      @media print, screen and (max-width: 39.99875em) {
        .new-cost-to-own .cto-body .cto .cto-permanent-footnote {
          margin-bottom: 30px; } }
      @media print {
        .new-cost-to-own .cto-body .cto .cto-permanent-footnote {
          font-size: 50%; } }
    .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop {
      margin-bottom: -10px; }
      @media print, screen and (max-width: 39.99875em) {
        .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop {
          display: none !important; } }
      @media print {
        .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop {
          margin-top: -10px; } }
      .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader {
        text-align: center;
        padding-left: 34%;
        padding-right: 0;
        background: #E6E6E6;
        margin-top: 5px; }
        @media print, screen and (max-width: 39.99875em) {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader {
            padding-left: 0; } }
        @media print {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader {
            padding-bottom: 3px;
            font-size: 65%;
            margin-bottom: -5px;
            -webkit-print-color-adjust: exact; }
            .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .table-row-subheader sup {
              color: #252525 !important; } }
      .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row {
        background: none; }
        .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-title {
          border-bottom: 1px solid #E6E6E6; }
          @media print {
            .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-title {
              text-align: center;
              border-bottom: 2px solid #808080;
              font-size: 75%; } }
        .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .key-feature-title {
          display: grid;
          grid-template-columns: 1fr 7fr;
          grid-gap: 25px; }
          .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .key-feature-title span {
            margin-top: auto;
            margin-bottom: auto; }
        .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-col {
          width: 33%;
          text-align: center;
          border-bottom: 1px solid #E6E6E6;
          border-left: 1px dotted #808080; }
          @media print {
            .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-col {
              border-bottom: 2px solid #808080;
              border-left: 2px dotted #808080;
              font-size: 70%;
              margin-top: -5px; } }
          .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row .lg-col .bold {
            font-weight: bold; }
        @media print {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-desktop .lg-row {
            max-height: 0px;
            margin-top: -5px;
            font-size: 70%;
            line-height: 0px; } }
    .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile {
      margin-left: 0px; }
      .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader {
        text-align: center;
        background: #E6E6E6;
        margin-top: -10px;
        padding: 5px;
        font-weight: bold; }
        @media print {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader {
            padding: 0px; } }
      .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader-mobile-v2 {
        text-align: center;
        background: #E6E6E6;
        padding: 5px;
        font-weight: bold; }
        @media print {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader-mobile-v2 {
            padding: 0px; } }
        @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader-mobile-v2 {
            margin-top: 35px; } }
        @media print, screen and (max-width: 39.99875em) {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .table-row-subheader-mobile-v2 {
            margin-top: 35px; } }
      .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-title {
        overflow: hidden;
        width: 100%;
        text-align: center;
        padding: 5px;
        font-weight: bold; }
      .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-col {
        text-align: center;
        padding: 5px;
        margin-bottom: 5px; }
        .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-col.col2 {
          border-left: 1px dotted #808080; }
        .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-col .bold {
          font-weight: bold; }
      .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-line {
        border-bottom: 1px solid #E6E6E6; }
        @media print {
          .new-cost-to-own .cto-body .cto .cost-to-own-table-mobile .sm-line {
            margin-top: -5px;
            margin-bottom: -5px; } }
  .new-cost-to-own table tbody tr:nth-child(even) {
    background: transparent;
    /* no alternating color */ }

.key-features-image {
  margin-right: 25px; }

.advantage-icon {
  width: 10px;
  padding-bottom: 4px; }

.floating-back-button {
  width: 30px;
  height: 40px;
  position: fixed;
  top: 15%;
  left: 85%;
  background-color: #EB0A1E;
  border-radius: 10px;
  cursor: pointer;
  white-space: pre-wrap;
  z-index: 1;
  filter: drop-shadow(0px 0px 1px black); }
  .floating-back-button img {
    height: 17px;
    vertical-align: 0%; }
  .floating-back-button .back-text {
    color: white;
    font-size: 17px;
    display: none;
    vertical-align: 15%; }
  @media print, screen and (max-width: 74.99875em) {
    .floating-back-button {
      top: 10%;
      left: 80%;
      width: 80px;
      height: 35px;
      border-radius: 20px; }
      .floating-back-button img {
        height: 15px;
        vertical-align: 10%; }
      .floating-back-button .mobile {
        display: inline-block;
        font-size: 15px;
        vertical-align: 25%; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .floating-back-button {
      height: 40px; } }
  @media print, screen and (max-width: 39.99875em) {
    .floating-back-button {
      top: 20%;
      left: 70%; } }
  .floating-back-button.button-hidden {
    display: none; }
  .floating-back-button.button-visible {
    display: visible; }

.floating-back-button:hover {
  width: 230px;
  left: 75%;
  border-radius: 25px; }
  .floating-back-button:hover .hover {
    display: inline-block; }

.stuck-back-button {
  width: 230px;
  height: 40px;
  border-radius: 25px;
  position: absolute;
  top: 6.75%;
  left: 75%;
  background-color: #EB0A1E;
  cursor: pointer;
  white-space: pre-wrap;
  filter: drop-shadow(0px 0px 1px black); }
  .stuck-back-button img {
    height: 17px;
    vertical-align: 0%; }
  .stuck-back-button span {
    color: white;
    font-size: 17px;
    vertical-align: 15%; }
  @media print, screen and (max-width: 39.99875em) {
    .stuck-back-button {
      position: relative;
      left: auto;
      top: auto;
      margin-top: 25px;
      margin-left: calc((100% - 230px) / 2);
      margin-right: calc((100% - 230px) / 2); }
      .stuck-back-button img {
        height: 16px; }
      .stuck-back-button .back-text {
        font-size: 16px;
        vertical-align: 13%; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    .stuck-back-button {
      height: 35px;
      width: 165px;
      top: 4.3%;
      left: 70%; }
      .stuck-back-button img {
        height: 13px; }
      .stuck-back-button .back-text {
        font-size: 13px;
        vertical-align: 17%; } }
  @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
    .stuck-back-button {
      height: 35px;
      width: 190px;
      top: 9%;
      left: 70%; }
      .stuck-back-button img {
        height: 15px; }
      .stuck-back-button .back-text {
        font-size: 15px;
        vertical-align: 17%; } }

.dropdowns.h2h-images {
  background-color: white;
  padding-left: 0px;
  padding-right: 0px; }
  @media print {
    .dropdowns.h2h-images .columns {
      width: 48%; } }
