@import './toyota-style-standards.scss';
@import "../../../node_modules/foundation-sites/scss/foundation.scss";

.download-file-container {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0px 20px 5px 0px;
    width: 100%;
    max-width: 590px;

    @include breakpoint(small down){
        max-width: 354px;
    }

    .download-text {
        color: #000;
        font: $h12-reg;
        line-height: 35px;
        @include breakpoint(medium up){
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }
    }

    .download-inner-container {
        display: inline-flex;
        align-items: center;
        margin: 10px 0px;
        background-color: #e6e6e6;
        width: 100%;
        justify-content: space-between;
        height: fit-content;
        min-height: 84px;
        border-radius: 25px;
        padding: 15px;
        color: #eb0a1e;

        .download-link-arrow {
            width: auto;
            padding: 5px;
            @media print {
                display: none;
            }
        }

        .download-link-text {
            align-items: center;
            justify-content: center;
            font: $font25-bold;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

}