@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";


.designed-for-future {

    background: transparent linear-gradient(rgba(0, 135, 235, 0.5) -90%, #FFFFFF 50%);
    padding: 80px 0;
    margin-top: 60px;

    @include breakpoint(medium only){
        padding: 70px 0 40px 0;
        margin-top: 80px;
    }

    @include breakpoint(small only){
        padding: 50px 0 40px 0;
    }

    .heading-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .designed-for-future-title {
            font: 45px $toyota-bold;
            text-align: center;

            @include breakpoint(medium only){
                font: $font35-bold;
            }

            @include breakpoint(small only) {
                font: $h13-bold
            }
            
        }

        .description {
            font: $h12-reg;
            line-height: 32px;
            text-align: center;
            max-width: 965px;
            margin-top: 30px;

            @include breakpoint(medium only) {
                margin: 20px 133px 0 133px;
                font: $h2-reg;
                line-height: 32px;
            }

            @include breakpoint(small only) {
                margin: 20px 34px 0 34px;
                font: $h4-reg;
                line-height: 32px;
            }
        }

        .tab-selector { 
            width: 700px; 
            margin: 60px 0;

            @include breakpoint(medium only) {
                width: 100%;
                max-width: 820px;
                padding: 0 34px 0 34px;
            }

            @include breakpoint(small only) {
                width: 100%;
                padding: 0 34px 0 34px;
            }
        }
    }
}