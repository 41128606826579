@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";
@import "../base";

.accordion-legend.sop.left {
  justify-content: center;
  @include breakpoint(1200px up) {
    justify-content: flex-start;
    position: absolute;
    margin: 15px 0px;
  }
}

.accordionV2, .combined-accordion {
  .accordion-header {
    justify-content: center;
    @include breakpoint(1200px up) {
      justify-content: flex-end;
    }
    .accordion-expand-collapse-group {
      justify-content: center;
      @include breakpoint(1200px up) {
        justify-content: flex-end;
      }
    }
  }

  &.specs-accordion,
  &.optionsPackages-accordion,
  &.accessories-accordion {
    > .accordion-header {
      justify-content: flex-end;

      > .accordion-expand-collapse-group {
        justify-content: center;
        @include breakpoint(1200px up) {
          justify-content: flex-end;
        }
      }
    }
  }
}

