@import '../../../node_modules/foundation-sites/scss/foundation.scss';
@import './toyota-style-standards.scss';
.content {
    margin-left: 10px;
    margin-right: 10px;
    @include breakpoint(large) {
        margin-left: 100px;
        margin-right: 100px;
    }
    .title {
        @include show-for(large);
        font: $h2-book;
        text-align: right;
        margin-bottom: 10px;
        padding-top: 10px;
        @include breakpoint(large) {
            text-align: left;
            font: $h1-reg;
            padding: 0;
        }
    }
    p {
        font: $body-1x;
    }
    h3 {
        font: $h2-semibold;
    }
}

body,button,input,h1,h2,h3,
h4,h5,h6{
    font-family: $toyota-reg;
}

li>p {
    margin: 0;
}

li>ul>li {
    list-style-type: circle;
}

@mixin rowGapFallbackMargin($gapSize) {
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
            margin-right: $gapSize;
        }
    }
}

@mixin columnGapFallbackMargin($gapSize) {
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
            margin-right: $gapSize;
        }
    }
}