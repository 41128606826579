@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.overview-video-container {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 15px;
    @include breakpoint(medium only) {
        gap: 15px;
    }
    @include breakpoint(small only) {
        flex-direction: column;
        row-gap: 11px;
        margin-bottom: 0px;
    }
}

.overview-video {
    height: auto;
    width: 66%;
    aspect-ratio: 16 / 9;

    @include breakpoint(small only) {
        width: 100%;
    }

    .kv-info-panel {
        position: absolute;
        @include breakpoint(small only) {
            position: relative;
        }
    }
}

.overview-video-info {
    background-color: black;
    height: auto;
    width: 32.2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 60px 20px 60px;

    @include breakpoint(medium only) {
        padding: 15px 30px 15px 30px;
    }

    @include breakpoint(small only) {
        padding: 30px 36px 30px 36px;
        width: 100%;
    }
}

.info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.overview-video-icon {
    max-width: 41px;

    @include breakpoint(medium only) {
        max-width: 24px;
    }

    @include breakpoint(small only) {
        max-width: 24px;
    }
}

.overview-video-title {
    font-family: $toyota-bold;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 35px;

    @include breakpoint(medium only) {
        font-size: 17px;
        line-height: 25px;
    }

    @include breakpoint(small only) {
        font-size: 17px;
        line-height: 25px;
    }
}

.overview-video-description {
    font-family: $toyota-reg;
    font-size: 16px;
    color: #FFFFFF;
    // max-width: 328px;
    line-height: 25px;
    @include breakpoint(medium only) {
        line-height: 22px;
    }
    @include breakpoint(small only) {
        font-size: 15px;
        line-height: 22px;
    }
}