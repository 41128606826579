@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

@media print {
    #disclaimers-footnote-print-only {
        display: block;
        margin-top: 50px;
        p {
          font-size: 12px;
          text {
            opacity: 0.7;
          }
        }

        .disclaimers-title {
          font: $h4-semibold;
      }

        .disclaimers-footnote-vehicle-title {
          margin-top: 15px;
          font-size: 14px;
        }
    }
}