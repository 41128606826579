@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.featuresAccordionContent, .specsAccordionContent {
  display: flex;
  width: 100%;
  padding: 28px 28px 0px 28px;
  @include breakpoint(small only) {
    padding: 15px 15px 0px 15px;
  }
  .featureResultColumn, .specResultColumn {
    display: flex;
    flex-direction: column; 
    width: 100%;

    .notAvailable {
      font: $font15-book;
      text-align: center;
    }

    .availabilitiesList, .specsList {
      display: flex;
      flex-direction: column;
      gap: 70px;
      width: 100%;
  
      .availabilityTypeModels, .specModels {
        display: flex;
        flex-direction: row;
        gap: 25px;
        @include breakpoint(small only) {
          justify-content: flex-start;
        }
      }
    }

    &.comparedResults {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 50%;
      .trimName {
        text-align: center;
        font: $font17-bold;
      }
      .availabilitiesList, .specsList {
        .availabilityTypeModels {
          @include breakpoint(small only) {
            flex-direction: column;
          }
        }
        .modelCodeNameTiles {
          .modelTile {
            width: 100%;
          }
        }
      }
      &:first-child {
        padding-right: 30px;
      }
      &:last-child {
        padding-left: 30px;
        border-left: 1px solid #96969687;
      }
    }
  }
}

.featuresAccordionContent {
  flex-direction: row;
  .availabilityTypeModels {
    .availabilityIcon {
      display: flex;
      min-width: 47px;
      max-width: 175px;
      width: 100%;
      @include breakpoint(medium only) {
        width: 140px;
      }
      @include breakpoint(small only) {
        width: unset;
      }
      img {
        width: 47px;
        height: 47px;
      }
    }
    .modelCodeNameTiles {
      max-width: 780px;
      @include breakpoint(medium only) {
        max-width: 443px;
      }
      @include breakpoint(small only) {
        max-width: 100%;
      }
    }
  }
  .featureResultColumn.comparedResults {
    .availabilityTypeModels .availabilityIcon {
      max-width: 80px;

      @include breakpoint(small only) {
        max-width: unset;
        justify-content: center;
      }
    }
    .modelCodeNameTiles {
      width: 100%;
    }
  }
}

.specsContentContainer {
  display: flex;
  flex-direction: column;
  .comparedTrimNamesHeader {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #96969687;

    .trimNameContainer {
      background-color: #F4F4F4;
      width: 50%;
      padding: 6px 0px 7px 0px;

      &:last-child {
        .trimName {
          border-left: 1px solid #96969687;
        }
      }
    }
    .trimName {
      font: $h4-bold;
      text-align: center;
      padding: 0 30px;
      span {
        width: 100%;
      }
    }
  }

  .specsAccordionContent {
    display: flex;
    flex-direction: row;

    .specResultColumn.comparedResults {
      .specModels {
        @include breakpoint(medium only) {
          flex-direction: column;
        }
      }
      .modelCodeNameTiles {
        width: 100%;
      }
    }

    .specResultColumn {
      .specModels {
        @include breakpoint(small only) {
          flex-direction: column;
        }
        .specName {
          display: flex;
          min-width: 150px;
          max-width: 244px;
          width: 100%;
          font: $font15-bold;

          @include breakpoint(small only) {
            font: $h4-bold;
            max-width: unset;
          }

          span {
            width: 212px;
            @include breakpoint(medium only) {
              width: 100%;
            }
            @include breakpoint(small only) {
              width: 100%;
            }
          }
        }

        .modelCodeNameTiles {
          max-width: 711px;
          column-gap: 73px;
          @include breakpoint(medium only) {
            max-width: 400px;
            column-gap: 36px;
          }
          @include breakpoint(small only) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.feats-specs-skeleton-loading-container {
  overflow: hidden;

  .feats-specs-accordion-loading {
    margin-top: 25px;
  }

  .accordion-legend-loading {
    margin-left: calc(100% - 300px);
    margin-bottom: 5px;
  }
}