@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.resources-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 1215px;
  padding: 90px;
  margin: auto;

  @include breakpoint(small only) {
    padding: 20px;
  }

  .see-more-button {
    font: $font15-bold;
    color: $primary-red;
    cursor: pointer;
    margin: auto;
    text-align: center;
  }
}