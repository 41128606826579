/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
@media print, screen and (max-width: 39.99875em) {
  .smartpath-overview-features {
    padding-top: 30px; }
    .smartpath-overview-features .smartpath-overview-features-header {
      font: 25px "ToyotaType-Black", sans-serif;
      text-align: center;
      padding-bottom: 9px; }
      .smartpath-overview-features .smartpath-overview-features-header strong {
        font: 25px "ToyotaType-Black", sans-serif; }
    .smartpath-overview-features .smartpath-overview-features-content {
      width: 315px;
      margin: auto; }
    .smartpath-overview-features .smartpath-feature-title {
      text-align: left;
      font: 25px "ToyotaType-Semibold", sans-serif;
      text-transform: uppercase;
      padding-top: 35px;
      margin-bottom: 15px; }
    .smartpath-overview-features .smartpath-feature-content {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px; }
    .smartpath-overview-features .smartpath-feature-cards {
      text-align: center;
      width: 308px;
      height: 321px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px #00000034;
      border-radius: 12px;
      margin: 0px auto 30px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-label-container {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
        padding-right: 19px; }
        .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-label-container .smartpath-feature-cardlabel {
          text-align: center;
          width: 79px;
          height: 23px;
          font: 16px "ToyotaType-Bold", sans-serif;
          color: #FFFFFF;
          border: 1px solid #007AEC;
          background: #007AEC;
          border-radius: 30px;
          line-height: 1;
          padding-top: 1px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-emptyCardlabel {
        margin-top: 19px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardIcon {
        padding-bottom: 11px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 51px; }
        .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardIcon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardTitle {
        font: 18px "ToyotaType-Semibold", sans-serif;
        margin-bottom: 10px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardContent {
        text-align: left;
        font: 16px "ToyotaType-Regular", sans-serif;
        margin: 0px 30px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .smartpath-overview-features {
    padding-top: 30px;
    padding-bottom: 40px; }
    .smartpath-overview-features .smartpath-overview-features-header {
      font: 36px "ToyotaType-Black", sans-serif;
      text-align: center;
      margin-bottom: 60px; }
      .smartpath-overview-features .smartpath-overview-features-header strong {
        font: 36px "ToyotaType-Black", sans-serif; }
    .smartpath-overview-features .smartpath-overview-features-content {
      width: 644px;
      margin: auto; }
    .smartpath-overview-features .smartpath-feature-title {
      text-align: left;
      font: 30px "ToyotaType-Semibold", sans-serif;
      text-transform: uppercase;
      margin-bottom: 33px; }
    .smartpath-overview-features .smartpath-feature-content {
      text-align: left;
      display: flex;
      justify-content: space-between;
      margin-bottom: 52px;
      flex-wrap: wrap; }
    .smartpath-overview-features .smartpath-feature-cards {
      text-align: center;
      width: 308px;
      height: 321px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px #00000034;
      border-radius: 12px;
      margin-bottom: 28px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-label-container {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
        padding-right: 19px;
        margin-bottom: -15px; }
        .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-label-container .smartpath-feature-cardlabel {
          text-align: center;
          width: 79px;
          height: 23px;
          font: 16px "ToyotaType-Bold", sans-serif;
          color: #FFFFFF;
          border: 1px solid #007AEC;
          background: #007AEC;
          border-radius: 30px;
          line-height: 1;
          padding-top: 1px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-emptyCardlabel {
        height: 23px;
        margin-top: 12px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardIcon {
        padding-bottom: 12px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 53px; }
        .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardIcon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardTitle {
        font: 18px "ToyotaType-Semibold", sans-serif;
        margin-bottom: 10px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardContent {
        text-align: left;
        font: 16px "ToyotaType-Regular", sans-serif;
        margin-left: 32px;
        margin-right: 29px; }
    .smartpath-overview-features .smartpath-feature-cards:last-child {
      margin-bottom: 0px; } }

@media print, screen and (min-width: 64em) {
  .smartpath-overview-features {
    padding-top: 100px;
    padding-bottom: 40px; }
    .smartpath-overview-features .smartpath-overview-features-header {
      font: 42px "ToyotaType-Black", sans-serif;
      text-align: center;
      margin-bottom: 52px; }
      .smartpath-overview-features .smartpath-overview-features-header strong {
        font: 42px "ToyotaType-Black", sans-serif; }
    .smartpath-overview-features .smartpath-overview-features-content {
      width: 1040px;
      margin: auto; }
    .smartpath-overview-features .smartpath-feature-title {
      text-align: left;
      font: 30px "ToyotaType-Semibold", sans-serif;
      text-transform: uppercase;
      margin-bottom: 15px; }
    .smartpath-overview-features .smartpath-feature-content {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 45px; }
    .smartpath-overview-features .smartpath-feature-cards {
      text-align: center;
      width: 327px;
      height: 310px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px #00000034;
      border-radius: 12px;
      margin-bottom: 30px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardlabel {
        display: flex;
        text-align: center;
        width: 79px;
        height: 23px;
        font: 16px "ToyotaType-Bold", sans-serif;
        color: #FFFFFF;
        border: 1px solid #007AEC;
        background: #007AEC;
        margin-left: 225px;
        margin-top: 19px;
        border-radius: 30px;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 1px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-emptyCardlabel {
        height: 23px;
        margin-top: 19px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardIcon {
        padding-bottom: 16px;
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardTitle {
        font: 18px "ToyotaType-Semibold", sans-serif;
        margin-bottom: 10px; }
      .smartpath-overview-features .smartpath-feature-cards .smartpath-feature-cardContent {
        text-align: left;
        font: 16px "ToyotaType-Regular", sans-serif;
        margin-left: 32px;
        margin-right: 29px; }
    .smartpath-overview-features .smartpath-feature-cards:nth-child(3n-1) {
      margin-right: 29px;
      margin-left: 30px; } }
