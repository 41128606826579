@import '../../../node_modules/foundation-sites/scss/foundation.scss';
@import './toyota-style-standards.scss';

@media print{
    .feature-data-rows {

        .feature-data-row {
            padding: 8px 28px;
        }

        #main-feature-data-print, #comparison-feature-data-print {
            font: $p7-reg;

            #standard-icon-print {
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                width: 16px;
                height: 11px;
                margin: auto;
            }
            #option-icon-print {
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                width: 13px;
                height: 13px;
                margin: auto;
            }
            #h2h-availability-indication-print {
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                font: 13px $toyota-semibold;
                margin: unset;
            }
            #h2h-not-availability-indication-print {
                filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                font: 15px $toyota-reg;
                margin: 0px 4px 3px 0px;
            }
        }

        #title-feature-name-print {
            font: $p7-semibold;
        }
    }
}