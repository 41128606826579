/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.quickAnswers {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 154px;
  width: 307px;
  margin-top: 17px;
  padding: 25px 24px 0px; }
  @media print, screen and (min-width: 40em) {
    .quickAnswers {
      width: 324px;
      margin-top: 38px;
      padding: 25px 24px 0px; } }
  @media print, screen and (min-width: 64em) {
    .quickAnswers {
      width: 810px;
      padding: 34px 50px 0px; } }
  .quickAnswers .quickAnswersExpandButton {
    display: flex;
    justify-content: center; }
  .quickAnswers .quickAnswersCollapseButton {
    display: flex;
    justify-content: center;
    margin-bottom: 26px; }
  .quickAnswers .arrowButton {
    margin-top: 20px;
    width: 16px; }
  .quickAnswers .quickAnswersText {
    margin: auto;
    text-transform: uppercase; }
    @media print, screen and (min-width: 64em) {
      .quickAnswers .quickAnswersText span {
        display: none; } }
    @media print, screen and (max-width: 39.99875em) {
      .quickAnswers .quickAnswersText {
        margin-top: 7px;
        font: 14px "ToyotaType-Semibold", sans-serif; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .quickAnswers .quickAnswersText {
        margin-top: 7px;
        font: 14px "ToyotaType-Semibold", sans-serif; } }
    @media print, screen and (min-width: 64em) {
      .quickAnswers .quickAnswersText {
        margin-top: 4px;
        font: 16px "ToyotaType-Semibold", sans-serif;
        letter-spacing: 1.6px; } }
  .quickAnswers .quickAnswersType {
    color: #EB0A1E;
    font-style: italic; }
  .quickAnswers .quickSearchDesktopType {
    color: #EB0A1E;
    font-style: italic; }
    @media print, screen and (max-width: 39.99875em) {
      .quickAnswers .quickSearchDesktopType {
        display: none; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      .quickAnswers .quickSearchDesktopType {
        display: none; } }
    @media print, screen and (min-width: 64em) {
      .quickAnswers .quickSearchDesktopType {
        font: 16px "ToyotaType-Semibold", sans-serif;
        color: #EB0A1E;
        font-style: italic;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        padding-top: 35px; } }
  .quickAnswers .quickAnswersHeader {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .quickAnswers .quickAnswersHeader {
        margin: auto; } }
    .quickAnswers .quickAnswersHeader .quickAnswersHeaderTitle {
      margin: auto; }
      @media print, screen and (max-width: 39.99875em) {
        .quickAnswers .quickAnswersHeader .quickAnswersHeaderTitle {
          font: 22px "ToyotaType-Regular", sans-serif; }
          .quickAnswers .quickAnswersHeader .quickAnswersHeaderTitle .model {
            font: 22px "ToyotaType-Bold", sans-serif; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .quickAnswers .quickAnswersHeader .quickAnswersHeaderTitle {
          font: 22px "ToyotaType-Regular", sans-serif; }
          .quickAnswers .quickAnswersHeader .quickAnswersHeaderTitle .model {
            font: 22px "ToyotaType-Bold", sans-serif; } }
      @media print, screen and (min-width: 64em) {
        .quickAnswers .quickAnswersHeader .quickAnswersHeaderTitle {
          font: 28px "ToyotaType-Regular", sans-serif; }
          .quickAnswers .quickAnswersHeader .quickAnswersHeaderTitle .model {
            font: 28px "ToyotaType-Bold", sans-serif; } }
    .quickAnswers .quickAnswersHeader .red-line-component {
      margin: auto;
      margin-top: 15px;
      width: 44px; }
      @media print, screen and (max-width: 39.99875em) {
        .quickAnswers .quickAnswersHeader .red-line-component {
          margin-bottom: 22px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .quickAnswers .quickAnswersHeader .red-line-component {
          margin-bottom: 22px; } }
  .quickAnswers .quickAnswersBody {
    display: flex;
    flex-wrap: wrap; }
    .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .content-dropdowns {
        width: 259px;
        margin: auto; }
      .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-slide {
        padding: 0px; }
      .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-prev img {
        width: 7px;
        height: 13px; }
      .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-next img {
        width: 7px;
        height: 13px; }
      .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .quickAnswersSeeMore {
        display: flex;
        justify-content: center; }
        .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .quickAnswersSeeMore .learnMoreContainer {
          margin: auto;
          width: 244px;
          height: 33px;
          text-decoration: none;
          border-radius: 18px;
          border: black solid 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 3px; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .quickAnswersSeeMore .learnMoreContainer a {
            font: 16px "ToyotaType-Semibold", sans-serif;
            margin: auto;
            color: black; }
      @media print, screen and (max-width: 39.99875em) {
        .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-prev {
            order: 1;
            width: 12px;
            height: 13px;
            position: initial;
            top: initial;
            transform: initial;
            margin-left: 6px;
            flex-grow: 1; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-next {
            order: 3;
            width: 12px;
            height: 13px;
            position: initial;
            transform: rotate(180deg);
            margin-right: 6px;
            flex-grow: 1; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots {
            order: 2;
            display: flex !important;
            flex-direction: row;
            width: 223px;
            justify-content: center;
            position: initial;
            bottom: initial; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots .dot {
              width: 100px;
              height: 6px;
              z-index: 5; }
              .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots .dot .tabArrow {
                display: none; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li {
              height: 6px;
              width: 16px;
              margin: 0px; }
              .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button {
                display: flex;
                padding: 0px;
                width: 15px;
                height: 6px; }
                .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot {
                  display: flex;
                  flex-direction: column; }
                  .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot .tabName {
                    display: none; }
                  .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot ::-webkit-scrollbar {
                    background: transparent;
                    height: 0; }
                  .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot .tabArrow {
                    display: none; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button:before {
              font-size: 16px;
              line-height: 6px;
              opacity: 1;
              color: #000000;
              width: 16px;
              height: 6px;
              z-index: 1; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li.slick-active button:before {
              font-size: 16px;
              line-height: 6px;
              color: #EB0A1E;
              width: 16px;
              height: 6px;
              z-index: 1; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-list {
            order: 4;
            margin-top: 20px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-prev {
            order: 1;
            width: 12px;
            height: 13px;
            position: initial;
            top: initial;
            transform: initial;
            margin-left: 15px; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-next {
            order: 3;
            width: 12px;
            height: 13px;
            position: initial;
            transform: rotate(180deg);
            margin-right: 15px; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots {
            order: 2;
            display: flex !important;
            flex-direction: row;
            width: 222px;
            justify-content: center;
            position: initial;
            bottom: initial; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots .dot {
              width: 100px;
              height: 6px;
              z-index: 5; }
              .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots .dot .tabArrow {
                display: none; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li {
              height: 6px;
              width: 16px;
              margin: 0px; }
              .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button {
                padding: 0px;
                width: 15px;
                height: 6px;
                z-index: 1; }
                .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot {
                  display: flex;
                  flex-direction: column; }
                  .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot .tabName {
                    display: none; }
                  .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot ::-webkit-scrollbar {
                    background: transparent;
                    height: 0; }
                  .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button .dot .tabArrow {
                    display: none; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li button:before {
              font-size: 6px;
              line-height: 6px;
              opacity: 1;
              color: #000000;
              width: 16px;
              height: 6px; }
            .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-dots li.slick-active button:before {
              font-size: 6px;
              line-height: 6px;
              color: #EB0A1E;
              width: 16px;
              height: 6px; }
          .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel .slick-slider .slick-list {
            order: 4;
            margin-top: 20px; } }
      @media print, screen and (min-width: 64em) {
        .quickAnswers .quickAnswersBody .quickAnswersMobileCarousel {
          display: none; } }
    .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel {
      display: flex;
      flex-direction: column; }
      @media print, screen and (max-width: 39.99875em) {
        .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel {
          display: none; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel {
          display: none; } }
      @media print, screen and (min-width: 64em) {
        .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel {
          width: 810px;
          margin-top: -30px; }
          .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider {
            display: flex; }
            .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-prev {
              display: none; }
            .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-next {
              display: none; }
            .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots {
              display: flex !important;
              position: relative;
              order: 1;
              flex-direction: column;
              width: 259px;
              bottom: initial;
              padding-top: 55px;
              border-right: 1px #000000 solid; }
              .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots .slick-active .dot .tabName {
                font: 16px "ToyotaType-Bold", sans-serif; }
              .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots .slick-active .dot .tabArrow {
                display: initial; }
              .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots li {
                width: 260px;
                margin: 0px;
                height: 55px; }
                .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots li button {
                  color: initial;
                  font-size: initial;
                  line-height: initial;
                  width: initial;
                  padding: 0px;
                  height: 55px; }
                  .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots li button .dot {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 258px;
                    height: 100%; }
                    .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots li button .dot .tabName {
                      text-align: left;
                      max-width: 211px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis; }
                    .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots li button .dot .tabNameHover:hover {
                      max-width: 250px;
                      overflow: visible;
                      text-overflow: clip;
                      white-space: normal;
                      z-index: 100;
                      background: #FFFFFF 0% 0% no-repeat padding-box;
                      box-shadow: 3px 3px 6px #00000029;
                      border-radius: 5px;
                      padding: 10px;
                      margin: -10px; }
                    .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots li button .dot .tabArrow {
                      display: none; }
                .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-dots li button:before {
                  content: none; }
            .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-list {
              width: 457px;
              order: 2;
              margin-left: -2px;
              height: 100%; }
              .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .slick-slider .slick-list .slick-slide {
                padding: 0px 0px 15px 65px; }
          .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .quickAnswersSeeMore {
            display: flex;
            justify-content: flex-start;
            margin-top: 34px; }
            .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .quickAnswersSeeMore .learnMoreContainer {
              width: 155px;
              height: 33px;
              text-decoration: none;
              border-radius: 18px;
              border: black solid 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 3px; }
              .quickAnswers .quickAnswersBody .quickAnswersDesktopCarousel .quickAnswersSeeMore .learnMoreContainer a {
                font: 16px "ToyotaType-Semibold", sans-serif;
                margin: auto;
                color: black; } }
