@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.container {
  display: flex;
  flex-direction: column;
  max-width: 398px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid $light-grey;
  gap: 25px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include breakpoint(medium down) {
    max-width: 362px;
    gap: 19px;
  }

  @include breakpoint(small down) {
    max-width: 360px;
  }

  & > img {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }

  & > section {
    display: flex;
    flex-direction: column;
    padding: 0 28px 40px 39px;

    @include breakpoint(medium down) {
      padding-left: 35px;
    }
    @include breakpoint(medium down) {
      padding-bottom: 38px;
    }

    & > h4 {
      font: $h3-bold;
      color: $dark-black;
      margin-bottom: 11px;
      font-weight: bold;

      @include breakpoint(medium down) {
        font: $h7-bold;
        margin-bottom: 13px;
      }
    }

    & > p {
      font: $h4-reg;
      color: $dark-black;
      margin-bottom: 25px;

      @include breakpoint(medium down) {
        font: $p5-reg;
        margin-bottom: 20px;
      }
    }

    & > a {
      font: $h4-bold;
      color: $primary-red;
      font-weight: bold;

      @include breakpoint(medium down) {
        font: $font15-bold;
      }

      @include breakpoint(small down) {
        font: $h4-bold;
      }
    }
  }
}
