@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.leaderboard-wrapper {
    background-color: #020412;

    .leaderboard-container {
        max-width: 1484px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $primary-white;
        justify-content: flex-start;
        margin-bottom: -30px;
        padding-bottom: 80px;

        * {
            background-color: transparent;
            color: $primary-white;
        }

        @include breakpoint(1504px down) {
            max-width: 897px;
        }

        @include breakpoint(917px down) {
            max-width: 360px;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 42px 4px 49px 0px;
            width: 100%;
            max-width: 1484px;

            @include breakpoint(917px down) {
                max-width: 897px;
                padding: 42px 4px 34px -4px;
            }

            .header-title {
                font: $font40-bold;
                @include breakpoint(917px down) {
                    font: $h6-bold;
                }
            }
        }

        .alt-message {
            font: $h3-semibold;
            padding: 20px 0 103px;
            text-align: center;
            @include breakpoint(1504px down) {
                max-width: 650px;
            }
            @include breakpoint(917px down) {
                font: $h12-semibold;
                max-width: 300px;
            }
        }

        table {
            width: 100%;
            max-width: 1484px;
            margin-bottom: 0;
            border: 1px solid $primary-white;
            border-radius: 10px;
            background-color: transparent;
            padding: 0 45px 20px 55px;
            border-collapse: separate;
            border-spacing: 0 5px;

            @include breakpoint(1504px down) {
                padding: 0 25px 20px 35px;
            }

            @include breakpoint(917px down) {
                padding: 0 15px 20px 25px;
            }

            thead {
                th {
                    font: $h4-reg;
                    padding: 30px 50px;

                    &:first-child {
                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    p {
                        margin: 0;
                        line-height: 1.2;
                    }

                    @include breakpoint(1504px down) {
                        padding: 38px 25px 23px 25px;
                    }

                    &:last-child {
                        min-width: 170px;
                    }

                    @include breakpoint(917px down) {
                        padding: 17px 20px;
                        font: $font15-reg;
                        &.not-shown-on-mobile {
                            display: none;
                        }
                    }
                }
            }

            tbody {
                * {
                    font: $font17-semibold;
                    border-style: hidden;

                    @include breakpoint(917px down) {
                        font: $h4-semibold;
                    }
                }

                tr {
                    background-color: #151d2c;

                    td {
                        height: 87px;
                        position: relative;
                        padding: 17px 50px;
                        line-height: 41px;

                        @include breakpoint(1504px down) {
                            line-height: 27px;
                            padding: 0px 25px 0px 25px;

                            // number
                            &:nth-child(1) {
                                max-width: 47px;
                            }

                            // name column
                            &:nth-child(2) {
                                max-width: 190px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            // points
                            &:nth-child(4) {
                                min-width: 150px;
                            }
                        }

                        @include breakpoint(917px down) {
                            line-height: 21px;
                            height: 65px;
                            padding-bottom: 5px;

                            &.not-shown-on-mobile {
                                display: none;
                            }

                            // name column
                            &:nth-child(2) {
                                max-width: 130px;
                            }

                            // points
                            &:nth-child(4) {
                                min-width: auto;
                            }
                        }

                        &:first-child {
                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font: $h6-bold;

                                @include breakpoint(917px down) {
                                    font: $h12-bold;
                                }

                                &::before {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: -10px;
                                    height: 100%;
                                    width: 10px;
                                    background-color: #0c56c9;
                                    border-radius: 10px 0px 0px 10px;
                                }
                                &::after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: 0px;
                                    height: 100%;
                                    width: 2px;
                                    background-color: #020412;
                                }
                            }
                        }

                        &:nth-child(2) {
                            font: $h7-bold;

                            @include breakpoint(917px down) {
                                font: $p5-bold;
                            }
                        }

                        &:last-child {
                            border-radius: 0px 10px 10px 0px;
                        }

                        @include breakpoint(917px down) {
                            &:nth-child(4) {
                                border-radius: 0px 10px 10px 0px;
                            }
                        }
                    }

                    &.my-rank {
                        border-spacing: 50px;
                    }

                    &.top-three td:first-child div::before {
                        background-color: $primary-blue-nitro;
                    }

                    &:nth-child(even):not(.leaderboard-pagination):not(.my-rank):not(.spacing) {
                        background-color: #273141;

                        @include breakpoint(917px down) {
                            background-color: #151d2c;
                        }

                        @include breakpoint(1504px down) {
                            background-color: #151d2c;
                        }
                    }

                    &:last-child {
                        td {
                            display: table-cell;
                            div {
                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }

                    &.spacing {
                        td {
                            height: 50px;

                            @include breakpoint(917px down) {
                                height: 30px;
                            }
                        }
                        background-color: transparent;
                    }

                    &.loading {
                        td {
                            font: $h6-bold;
                            width: auto;
                            line-height: 21px;
                            height: 65px;
                            padding: 5px;
                            @include breakpoint(917px down) {
                                &.not-shown-on-mobile {
                                    display: none;
                                }
                            }

                        }
                    }

                    &.my-rank {
                        background-color: $primary-blue-nitro;
                        td {
                            div {
                                &::before {
                                    background-color: rgba($color: $primary-blue-nitro, $alpha: 0.74) !important;
                                }
                            }
                        }
                    }
                }
            }

            .leaderboard-pagination {
                background-color: transparent;
                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: $primary-white;

                    &.inactive {
                        background-color: rgba($color: $primary-white, $alpha: 0.5);
                    }

                    img {
                        filter: invert(100);
                    }
                }
            }
        }
    }
}
