/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.content, .accessories {
  margin-left: 10px;
  margin-right: 10px; }
  @media print, screen and (min-width: 64em) {
    .content, .accessories {
      margin-left: 100px;
      margin-right: 100px; } }
  .content .title, .accessories .title {
    font: 18px "ToyotaType-Book", sans-serif;
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px; }
    @media screen and (max-width: 63.99875em) {
      .content .title, .accessories .title {
        display: none !important; } }
    @media print, screen and (min-width: 64em) {
      .content .title, .accessories .title {
        text-align: left;
        font: 36px "ToyotaType-Regular", sans-serif;
        padding: 0; } }
  .content p, .accessories p {
    font: 12px "ToyotaType-Regular", sans-serif; }
  .content h3, .accessories h3 {
    font: 18px "ToyotaType-Semibold", sans-serif; }

body, button, input, h1, h2, h3,
h4, h5, h6 {
  font-family: "ToyotaType-Regular", sans-serif; }

li > p {
  margin: 0; }

li > ul > li {
  list-style-type: circle; }

.accessories .accessory-container {
  border-top: 1px solid grey;
  margin: 0px 10px;
  font-size: 16px; }
  .accessories .accessory-container ul {
    margin-bottom: 0px;
    font-size: 13px; }
  .accessories .accessory-container p {
    font-size: 13px; }
  @media print, screen and (min-width: 40em) {
    .accessories .accessory-container {
      padding-top: 30px;
      padding-bottom: 30px; } }
  .accessories .accessory-container b {
    font: 15px "ToyotaType-Semibold", sans-serif; }
  .accessories .accessory-container .accessory-title {
    font: 24px "ToyotaType-Bold", sans-serif;
    line-height: 2; }
  .accessories .accessory-container .accessory-detail {
    font: 16px "ToyotaType-Regular", sans-serif; }
  .accessories .accessory-container .accessory-detail-bold {
    font: 16px "ToyotaType-Bold", sans-serif; }
  @media print, screen and (max-width: 39.99875em) {
    .accessories .accessory-container div.columns.large-4 {
      margin-top: 20px;
      margin-bottom: 20px; } }
  .accessories .accessory-container .accessory-image {
    padding: 0; }

.accessories .acc-disclaimer {
  padding-top: 16px;
  font-size: 13px; }
  .accessories .acc-disclaimer p {
    font-size: 13px; }

.accessories .visible-count {
  margin: 10px 0px;
  font-weight: 600; }

.accessories .accessories-header {
  margin-left: 5px;
  margin-right: 5px; }
  @media print, screen and (min-width: 64em) {
    .accessories .accessories-header {
      margin-top: 20px; } }
  .accessories .accessories-header .disclaimer {
    font: 12px "ToyotaType-Regular", sans-serif;
    display: flex;
    align-items: center;
    margin-top: 12px; }
    @media print, screen and (min-width: 64em) {
      .accessories .accessories-header .disclaimer {
        padding-left: 35px;
        margin-top: 0px; } }
    .accessories .accessories-header .disclaimer img {
      height: 40px;
      margin-right: 10px; }
    .accessories .accessories-header .disclaimer span {
      color: #EB0A1E; }

.accessories .filter-and-search {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #E6E6E6; }
  .accessories .filter-and-search .filterBy {
    font: 14px "ToyotaType-Bold", sans-serif; }
  .accessories .filter-and-search .filters {
    min-width: 400px;
    max-width: 400px;
    margin-top: 15px;
    margin-left: 8px; }
    .accessories .filter-and-search .filters label {
      font: 14px "ToyotaType-Bold", sans-serif;
      margin-right: 20px; }
      @media print, screen and (max-width: 39.99875em) {
        .accessories .filter-and-search .filters label {
          margin-right: 10px;
          font-size: 2.9vw; } }
  .accessories .filter-and-search .search {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 50px; }
    @media print, screen and (min-width: 64em) {
      .accessories .filter-and-search .search {
        border-left: 1px dotted; } }
    .accessories .filter-and-search .search .search-button {
      background-color: transparent;
      color: #EB0A1E;
      font: 14px "ToyotaType-Bold", sans-serif; }
      .accessories .filter-and-search .search .search-button:hover {
        background-color: transparent; }
  .accessories .filter-and-search input[type='checkbox'] {
    accent-color: #943e96; }
