@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";

.profile-page-recent-comments {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(medium only) {
        margin-right: 0;
    }

    span.title {
        font: $h3-semibold;
        color: $dark-black;
        margin-bottom: 20px;
        .unread-count {
            font: $h4-bold;
            color: $primary-red;
            margin-left: 13px;
        
            @include breakpoint(small only) {
                font: $p11-bold;
            }
            @include breakpoint(medium only) {
                font: $p11-bold;
            }
          }
    }

    button.show-hide-replies {
        justify-content: flex-start;
        display: flex;
        margin-bottom: 20px;
        font: $font15-reg;
        color: $primary-red;
        cursor: pointer;
    }

    .no-results {
        font: $h2-bold;
        color: #EB0A1E;
    }

    .comments-skeleton-loading {
        .react-loading-skeleton {
            border-radius: 12px;
            margin-top: 20px;
        }
    }
}