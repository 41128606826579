@import "../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../toyota-style-standards.scss";


 .trim-walk-accordion-body{
    @media print {
        .trim-walk-column-header {
            background-color: #F7F7F7 !important;
            print-color-adjust: exact;
            margin-bottom: 8px;

            .trim-walk-column-header-content {
                font: $p6-semibold;
                border-left: unset;
                position: relative;
                text-align: left;
    
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 6px;
                    min-height: 45px;
                    height: 100px;
                    background-color: #FFFFFF;
                    print-color-adjust: exact;
                }

                &:first-child::before {
                    content: '';
                    width: 0px;
                    height: 0px;
                }
            }
        }
        .trim-walk-data-section {
            .trim-walk-column {
                gap: 10px;
                .trim-walk-feature-row {
                    .trim-walk-feature-name {
                        font: $p12-reg;
                    }
            
                    .standard-icon {
                        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                        width: 16px;
                        height: 11px;
                        margin-right: unset;
                    }

                    .option-icon {
                        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                        width: 13px;
                        height: 13px;
                        margin-right: unset;
                    }

                    .package-icon {
                        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0%) contrast(100%);
                        width: 10px;
                        height: 13px;
                        margin-right: unset;
                    }
                }

                .trim-walk-package-row {
                    .trim-walk-package-title {
                            font: $p12-semibold;
                    }
                    .trim-walk-package-description {
                            font: $p12-reg;
                    }
                }
            }

            .trim-walk-accordion-no-results {
                font: $p12-semibold;
            }
        }
    }
}